<template>
  <div class="flex flex-col md:flex-row justify-between items-center w-full mb-16">
    <RadiusBreadcrumbs :title="title" :target="target" />
    <div v-if="!simple" class="flex flex-col md:flex-row flex-grow justify-end">
      <button
        v-if="$permissions.has('home-viewqualityassessment')"
        class="
          text-theme-150
          font-medium
          text-gray-400
          focus:outline-none
          hover:text-darkblue
          transition
          ease-in-out
          duration-150
          flex-shrink-0
          mb-4
          md:mb-0
          mr-0
          md:mr-12
        "
        :class="{ 'text-darkblue': selected === 'qualityassessment' }"
        @click="navigate('QualityAssessment')"
      >
        {{ $t('Radius.QualityAssessment') }}
      </button>
      <button
        v-if="$permissions.has('home-viewlivingenvironmentassessment')"
        class="
          text-theme-150
          font-medium
          text-gray-400
          focus:outline-none
          hover:text-darkblue
          transition
          ease-in-out
          duration-150
          flex-shrink-0
          mb-4
          md:mb-0
          mr-0
          md:mr-12
        "
        :class="{ 'text-darkblue': selected === 'livingenvironmentassessment' }"
        @click="showLivingEnvironmentAssessment"
      >
        {{ $t('Radius.EnvironmentAssessment') }}
      </button>
      <button
        v-if="$permissions.has('home-viewcollaborationassessment')"
        class="
          flex flex-row
          items-center
          text-theme-150
          font-medium
          text-gray-400
          focus:outline-none
          hover:text-darkblue
          transition
          ease-in-out
          duration-150
          flex-shrink-0
          mb-4
          md:mb-0
        "
        :class="{ 'text-darkblue': selected === 'collaborationassessment' }"
        @click="navigate('CollaborationAssessment')"
      >
        <span>{{ $t('Radius.CollaborationAssessment') }}</span>
        <CollaborationAssessmentLabel :collaborationAssessmentYear="collaborationAssessmentYear" />
      </button>
    </div>
  </div>
</template>

<script>
import { GraphQLQuery } from '@/graphql';
import CollaborationAssessmentLabel from '@/components/CollaborationAssessmentLabel';
import RadiusBreadcrumbs from '@/components/radius/RadiusBreadcrumbs';

export default {
  name: 'RadiusHeader',
  components: {
    CollaborationAssessmentLabel,
    RadiusBreadcrumbs
  },
  props: {
    simple: {
      type: Boolean,
      default: false,
      required: false
    },
    title: {
      type: String,
      default: '',
      required: false
    },
    target: {
      type: String,
      default: 'QualityAssessment',
      required: false
    },
    selected: {
      type: String,
      default: 'qualityassessment',
      required: false
    },
    collaborationAssessmentYear: {
      type: String,
      default: '',
      required: false
    }
  },
  apollo: {
    configuration: {
      query: GraphQLQuery.Configuration
    }
  },
  computed: {
    homeID() {
      if (this.$route.params.homeID) {
        return Number(this.$route.params.homeID);
      }

      return this.$store.state.currentManagedHome ? Number(this.$store.state.currentManagedHome.id) : undefined;
    }
  },
  methods: {
    displayComingSoonModal(typeText) {
      const bodyText = `1x per jaar word je gevraagd om deel te nemen aan het ${typeText}. Dit wordt uitgevoerd in samenwerking met de Hogeschool Leiden en werkt aan de hand van een digitale vragenlijst. De resultaten krijg je in de vorm van een rapport toegestuurd en kan je uploaden in het portal.`;
      this.$modal.show('ComingSoon', { title: typeText, body: bodyText });
    },

    showLivingEnvironmentAssessment() {
      if (
        this.configuration &&
        this.configuration.environment_research &&
        this.configuration.environment_research.environment_research_enabled
      ) {
        this.navigate('LivingEnvironmentAssessment');
        return;
      }

      this.displayComingSoonModal(this.$t('Radius.EnvironmentAssessment'));
    },

    navigate(name) {
      this.$router.push({
        name,
        params: {
          homeID: this.homeID
        }
      });
    }
  }
};
</script>
