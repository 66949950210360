<template>
  <main class="relative pt-16 bg-lightblue-background" @click.self="hideDatePicker">
    <UploadModal
      ref="uploadModal"
      :libraryEnabled="true"
      @completed="uploadCompleted"
      uploadLibraryType="private"
      :homeID="homeID"
    />
    <img src="@/assets/background.svg" class="absolute top-0 right-0 z-0" />
    <div class="relative max-w-5xl px-4 pb-12 mx-auto sm:px-6 lg:px-8 z-1">
      <div class="flex flex-col items-center justify-between w-full mb-4 sm:flex-row">
        <h1 class="flex-grow text-theme-500">
          <span>{{ $t('Professionalization.Title') }}</span>
        </h1>
      </div>
      <div class="w-full min-h-screen-pagination" @click.self="hideDatePicker">
        <div
          class="
            relative
            w-full
            max-w-3xl
            p-8
            font-medium
            bg-white
            border-b-4
            rounded-md
            text-theme-150 text-darkblue
            sm:p-10
            border-lightblue-border
          "
          @click.self="hideDatePicker"
        >
          <div
            v-if="loading"
            class="absolute inset-0 z-20 flex flex-col items-center justify-center rounded-md bg-black bg-opacity-50"
          >
            <img class="w-8 h-8 mb-4" src="@/assets/loading.gif" alt="Loading" />
            <span class="text-150 font-semibold text-white">
              {{ $t('General.Loading') }}
            </span>
          </div>
          <div
            class="flex flex-col items-start justify-start w-full px-4 mb-2 font-semibold text-darkblue"
            @click.self="hideDatePicker"
          >
            <div class="flex flex-col items-start justify-between w-full mb-4 sm:flex-row sm:items-center sm:mb-4">
              <span>{{ $t('Professionalization.Types.Title') }}</span>
              <DropdownSelectElement
                class="min-w-60"
                :values="['skj', 'registerplein', 'other']"
                translationFormat="Professionalization.Types.{0}"
                :selectedValue="type"
                :placeholder="$t('Professionalization.Types.Placeholder')"
                @selected="typeSelected"
              />
            </div>
            <div
              v-if="type !== 'other'"
              class="flex flex-col items-start justify-between w-full mb-4 sm:flex-row sm:items-center sm:mb-2"
            >
              <span>{{ $t('Professionalization.Code') }}</span>
              <InputVerify
                class="w-full max-w-account-textfield"
                required
                :placeholder="$t('Professionalization.Code')"
                v-model="code"
                :message="error"
                messageVariant="error"
              />
            </div>
            <div class="flex flex-col items-start justify-between w-full mb-4 sm:flex-row sm:items-center sm:mb-2">
              <span>{{ $t('Professionalization.ExpirationDate') }}</span>
              <div>
                <button
                  @click="toggleDatePicker()"
                  class="focus:outline-none"
                  :class="{
                    'text-lightblue-link': !expirationDate,
                    'hover:text-lightblue-link transition ease-in-out duration-150': expirationDate
                  }"
                >
                  {{ expirationDate !== null ? $filters.date(expirationDate) : $t('Library.Action.AddExpirationDate') }}
                </button>
                <button v-if="expirationDate" class="ml-2 focus:outline-none" @click="removeExpiryDate()">
                  <IconCross class="w-2 h-2 text-red-500" />
                </button>

                <DateSelector :visible="datePickerVisible" v-model="expirationDate" />
              </div>
            </div>
            <span class="mt-8 mb-2">{{ $t('Professionalization.FileUpload') }}</span>
            <div
              v-if="error.length > 0 && documents.length === 0"
              class="
                flex flex-col
                p-2
                mb-4
                text-center
                rounded-md
                shadow-sm
                text-error-balloon-text
                bg-error-balloon-background
                width-full
                animation-error-scale-in animation-once animation-fill-both animation-500
              "
            >
              <span class="text-sm">{{ error }}</span>
            </div>
            <div
              v-if="!documents || documents.length === 0"
              class="
                flex flex-col
                items-center
                w-full
                px-6
                text-center
                bg-gray-100
                border-2 border-gray-300 border-dashed
                rounded-md
                cursor-pointer
                sm:flex-row
                py-7
                text-100
              "
              @click="showUploadDialog"
            >
              <IconExclamationMark class="flex-shrink-0 w-5 h-5 mr-4" />
              {{ $t('Radius.QualityAssessment.NoDocumentsFound') }}
              <button class="ml-1 font-semibold lowercase text-lightblue-link focus:outline-none">
                {{ $t('Radius.QualityAssessment.Action.AddDocument') }}
              </button>
            </div>
            <div v-if="documents && documents.length > 0" class="w-full">
              <div
                class="
                  w-full
                  px-6
                  py-4
                  flex
                  items-center
                  justify-between
                  bg-white
                  text-darkblue
                  rounded-md
                  border border-gray-200
                  mb-1
                "
                v-for="(doc, index) in visibleDocumentsLengthCapEnabled
                  ? documents.slice(0, visibleDocumentsLengthCap)
                  : documents"
                :key="index"
                :class="{
                  [`animation-list-scale-in animation-once animation-fill-backwards animation-300 animation-delay-${index}`]: true
                }"
              >
                <button
                  class="flex text-left items-center focus:outline-none cursor-pointer flex-1"
                  @click="$helper.showDocumentDetailsModal(doc)"
                >
                  <IconFile class="flex-shrink-0" :document="doc" />
                  <div class="min-w-2xl ml-8">
                    <span class="block text-theme-150 font-semibold">{{ $filters.documentname(doc) }}</span>
                    <span class="block text-theme-100">
                      <span>Geupload op {{ doc.created_at | date }}</span>
                      <span
                        v-if="doc.expiration_date"
                        :class="{
                          'text-red-500 font-semibold': $filters.monthsremaining(doc.expiration_date) <= 0,
                          'text-yellow-400 font-semibold':
                            $filters.monthsremaining(doc.expiration_date) > 0 &&
                            $filters.monthsremaining(doc.expiration_date) <= 2
                        }"
                      >
                        - Verloopt op {{ doc.expiration_date | date }}</span
                      >
                    </span>
                  </div>
                </button>
                <div class="flex text-lightblue-hover">
                  <button
                    class="focus:outline-none w-8 flex-shrink-0 flex justify-center items-center group"
                    @click="
                      $modal.confirm(() => {
                        deleteDocument(doc);
                      }, 'ConfirmDeleteDocument')
                    "
                  >
                    <IconTrash class="flex-shrink-0 group-hover:text-darkblue" />
                    <span
                      class="
                        absolute
                        py-1
                        px-2
                        text-50
                        bg-darkblue
                        text-white
                        rounded-md
                        transform
                        translate-y-7
                        opacity-0
                        invisible
                        group-hover:visible group-hover:opacity-100
                        transition
                        ease-in-out
                        duration-150
                      "
                    >
                      {{ $t('Radius.QualityAssessment.Action.RemoveDocument') }}
                    </span>
                  </button>
                </div>
              </div>
              <div class="text-50 font-semibold leading-5 ml-6 mt-3">
                <span
                  class="text-lightblue-link focus:outline-none focus:underline transition ease-in-out duration-150"
                  v-if="visibleDocumentsLengthCapEnabled && documents && documents.length > visibleDocumentsLengthCap"
                  @click="visibleDocumentsLengthCapEnabled = false"
                >
                  {{
                    $t('Radius.QualityAssessment.ViewMoreFiles') | format(documents.length - visibleDocumentsLengthCap)
                  }}
                </span>
              </div>
            </div>
            <div class="flex items-center justify-end w-full mt-8">
              <span
                v-if="saved"
                class="px-2 rounded-md text-sm bg-success-balloon-text border-textfield-disabled text-white mr-4"
                >Opgeslagen.</span
              >
              <ButtonElement variant="primary-small" @click.native="persist">
                {{ $t('Account.Action.Save') }}
              </ButtonElement>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import InputVerify from '@/components/InputVerify';
import ButtonElement from '@/components/ButtonElement';
import UploadModal from '@/components/modals/UploadModal';
import DropdownSelectElement from '@/components/DropdownSelectElement';
import { GraphQLQuery, GraphQLMutation } from '@/graphql';

export default {
  name: 'Professionalization',
  components: {
    InputVerify,
    ButtonElement,
    UploadModal,
    DropdownSelectElement
  },
  apollo: {
    user: {
      query: GraphQLQuery.Me,
      update(data) {
        this.$store.commit('currentUser', data.me);
        return this.$store.state.currentUser;
      },
      result() {
        this.loading = false;

        if (this.professionalization) {
          this.code = this.professionalization.code || '';
          this.type = this.professionalization.type || '';
          this.expirationDate = this.professionalization.expiration_date
            ? new Date(this.professionalization.expiration_date)
            : null;
          this.documents = this.professionalization.related_documents || [];
        }

        this.$forceUpdate();
      }
    }
  },
  computed: {
    homeID() {
      if (this.$route.params.homeID) {
        return Number(this.$route.params.homeID);
      }

      return this.$store.state.currentManagedHome ? Number(this.$store.state.currentManagedHome.id) : undefined;
    },
    home() {
      if (!this.$store.state.currentUser || this.$store.state.currentUser.homes.length === 0) {
        return null;
      }

      for (let i = 0; i < this.$store.state.currentUser.homes.length; i += 1) {
        if (this.$store.state.currentUser.homes[i].id === this.homeID) {
          return this.$store.state.currentUser.homes[i];
        }
      }

      return this.$store.state.currentUser.homes[0];
    },
    professionalization() {
      if (!this.home.professionalization || this.home.professionalization.length === 0) {
        return null;
      }

      for (let i = 0; i < this.home.professionalization.length; i += 1) {
        const item = this.home.professionalization[i];
        if (item.user && item.user.id === this.$store.state.currentUser.id) {
          return item;
        }
      }

      return null;
    }
  },
  data() {
    return {
      visibleDocumentsLengthCapEnabled: true,
      visibleDocumentsLengthCap: 3,
      expirationDate: null,
      datePickerVisible: false,
      type: 'skj',
      code: '',
      documents: [],
      error: '',
      saved: false,
      loading: true
    };
  },
  methods: {
    typeSelected(newValue) {
      if (!newValue || newValue.length === 0) {
        this.error = this.$t('Professionalization.Error.EmptyFields');
        return;
      }

      this.type = newValue;
      this.error = '';
    },
    showUploadDialog() {
      this.$refs.uploadModal.show();
    },
    async uploadCompleted(addedDocuments) {
      try {
        this.documents = this.documents.concat(addedDocuments);
      } catch {
        // Document error
      }
    },
    toggleDatePicker() {
      if (!this.expirationDate) {
        this.expirationDate = new Date();
      }

      this.datePickerVisible = !this.datePickerVisible;
    },

    hideDatePicker() {
      this.datePickerVisible = false;
    },

    removeExpiryDate() {
      this.expirationDate = null;
    },
    async persist() {
      const codeRequiredTypes = ['skj', 'registerplein'];
      if (codeRequiredTypes.includes(this.type) && this.code.length === 0) {
        this.error = this.$t('Professionalization.Error.EmptyFields');
        return;
      }

      const documentIDs =
        this.documents.map(doc => {
          return Number(doc.id);
        }) || [];

      let expiryDate = this.expirationDate;
      if (expiryDate) {
        expiryDate.setHours(expiryDate.getHours() + 5);
        [expiryDate] = expiryDate.toISOString().split('T');
      }

      const updatedData = {
        code: this.code,
        type: this.type,
        expiration_date: expiryDate,
        related_documents: documentIDs,
        user: this.$store.state.currentUser.id
      };

      if (this.professionalization) {
        updatedData.id = this.professionalization.id;
      }

      const otherProfessionalizations = (this.home.professionalization || [])
        .filter(item => {
          if (!this.professionalization) {
            return true;
          }

          return item && item.id !== this.professionalization.id;
        })
        .map(item => {
          return {
            code: item.code,
            type: item.type,
            expiration_date: item.expiration_date,
            related_documents: item.related_documents.map(doc => {
              return doc.id;
            }),
            user: item.user.id
          };
        });

      const data = [...otherProfessionalizations, { ...updatedData }];

      this.loading = true;
      await this.$apollo.mutate({
        mutation: GraphQLMutation.HomeProfessionalizationUpdate,
        variables: {
          data
        },
        update: (
          queryCacheStore,
          {
            data: {
              updateHome: { home }
            }
          }
        ) => {
          if (this.$store.state.currentManagedHome) {
            this.$store.commit('currentManagedHome', home);
          }

          const updatedHomes = this.$store.state.currentUser.homes.map(userHome => {
            if (Number(userHome.id) === Number(home.id)) {
              return home;
            }
            return userHome;
          });

          this.$store.state.currentUser.homes = updatedHomes;
          this.$store.commit('currentUser', this.$store.state.currentUser);
          this.saved = true;
          this.loading = false;
        }
      });
    },
    async deleteDocument(targetDocument) {
      const index = this.documents.indexOf(targetDocument);
      if (index > -1) {
        this.documents.splice(index, 1);
      }
    }
  }
};
</script>
