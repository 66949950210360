<template>
  <ProgressReportSection v-bind="$props">
    <template v-slot:header>
      <div class="flex flex-col sm:flex-row justify-between">
        <span class="block text-theme-300 mb-3"> {{ paragraph.sort_number }}. {{ paragraph.name }} </span>
        <div
          v-if="progressReportRadius"
          class="flex text-theme-50 rounded-sm p-1.5 border-2 border-gray-300"
          :class="{ 'bg-light-green border-light-green': progressReportRadius.base_qualification_finished }"
        >
          <span
            class="text-darkblue flex items-center"
            :class="{ 'text-progress-fill': progressReportRadius.base_qualification_finished }"
          >
            <IconStar class="flex-shrink-0" />
            <span class="ml-1">
              {{
                progressReportRadius.base_qualification_finished
                  ? $t('Radius.QualityAssessment.BaseQualification.Complete')
                  : $t('Radius.QualityAssessment.BaseQualification.Incomplete')
              }}
            </span>
          </span>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <ReadMoreSpan class="block text-100" :text="markdownAvailableParagraphs" :maxLength="400" :markdown="true" />
    </template>
  </ProgressReportSection>
</template>

<script>
import { GraphQLQuery } from '@/graphql';
import ProgressReportSection from '@/views/ProgressReport/Sections/ProgressReportSection';
import ReadMoreSpan from '@/components/ReadMoreSpan';

export default {
  name: 'ProgressReportSectionBaseQualification',
  extends: ProgressReportSection,
  inheritAttrs: false,
  components: {
    ProgressReportSection,
    ReadMoreSpan
  },
  apollo: {
    chapters: {
      query: GraphQLQuery.RadiusQualityChapters,
      skip() {
        return !this.$store.state.currentUser;
      }
    },
    progressReportRadius: {
      query: GraphQLQuery.ProgressReportRadius,
      fetchPolicy: 'cache-and-network',
      skip() {
        return !this.$store.state.currentUser;
      }
    }
  },
  computed: {
    markdownAvailableParagraphs() {
      let markdown = '';

      this.availableParagraphs.forEach(paragraph => {
        markdown += `- R${paragraph.chapter_sort}.${paragraph.sort_number} ${paragraph.name}\n`;
      });

      return markdown;
    },
    availableParagraphs() {
      if (!this.chapters || this.chapters.length === 0) {
        return [];
      }

      let availableParagraphs = [];
      this.chapters.forEach(chapter => {
        const available = chapter.paragraphs.filter(paragraph => {
          return paragraph.base_qualification;
        });

        available.map(availableParagraph => {
          availableParagraph.chapter_sort = chapter.sort_number;
          return availableParagraph;
        });

        if (available && available.length > 0) {
          availableParagraphs = availableParagraphs.concat(available);
        }
      });

      return availableParagraphs;
    }
  }
};
</script>
