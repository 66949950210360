<template>
  <div
    class="rounded-md w-12 h-12 flex-shrink-0 flex flex-col justify-center items-center font-semibold"
    :class="{
      'lg:w-16 lg:h-16 bg-gray-100': !small,
      'bg-white': small
    }"
  >
    <span
      class="text-310 text-gray-800 leading-none"
      :class="{
        'lg:text-500': !small
      }"
    >
      {{ getDay }}
    </span>
    <span class="uppercase text-gray-500 text-50">{{ getMonth }}</span>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    getDay() {
      return new Date(this.date).getDate();
    },
    getMonth() {
      return new Intl.DateTimeFormat('nl', { month: 'short' }).format(new Date(this.date));
    }
  }
};
</script>
