<template>
  <div class="grid gap-12">
    <section v-for="(year, value) in eventListByMonthAndYears" :key="year.index" class="grid gap-12">
      <h2 class="text-500 font-bold text-darkblue tracking-tight">{{ $t('Events.YearOverview') }} {{ value }}</h2>

      <div v-for="(events, monthIndex, i) in year" :key="i" class="grid gap-4">
        <h3 class="font-semibold text-310 text-darkblue tracking-tight">{{ getMonthStringByIndex(monthIndex) }}</h3>
        <EventsCard v-for="(event, eventIndex) in events" :key="eventIndex" :event="event" />
      </div>
    </section>
  </div>
</template>

<script>
import EventsCard from '@/components/events/EventsCard.vue';

export default {
  components: {
    EventsCard
  },
  props: {
    events: {
      type: Array,
      required: true
    }
  },
  computed: {
    eventListByMonthAndYears() {
      const eventsByMonthAndYears = {};

      this.events.forEach(event => {
        const startDate = new Date(event.start_date);
        const endDate = new Date(event.end_date);

        const currentDate = new Date(startDate.getTime());
        while (currentDate.getTime() <= endDate.getTime()) {
          const year = currentDate.getFullYear();
          const month = currentDate.getMonth();

          if (!eventsByMonthAndYears[year]) {
            eventsByMonthAndYears[year] = {};
          }

          if (!eventsByMonthAndYears[year][month]) {
            eventsByMonthAndYears[year][month] = [];
          }

          eventsByMonthAndYears[year][month].push({
            ...event,
            date: currentDate.toISOString()
          });
          currentDate.setDate(currentDate.getDate() + 1);
        }
      });

      return eventsByMonthAndYears;
    }
  },
  methods: {
    getMonthStringByIndex(monthIndex) {
      const months = [
        'Januari',
        'Februari',
        'Maart',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Augustus',
        'September',
        'Oktober',
        'November',
        'December'
      ];

      return months[monthIndex];
    }
  }
};
</script>
