<template>
  <div class="bg-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-login w-full">
      <div>
        <img class="mx-auto w-auto" src="@/assets/logo.svg" alt="Workflow" />
        <h2 class="mt-6 text-center text-3xl leading-9 font-bold text-gray-900">
          {{ $t('Register.Title') }}
        </h2>
      </div>
      <div class="text-theme-200 text-center w-full">
        <span>{{ $t('Register.Description') }}</span>
      </div>
      <div v-if="!$apollo.loading && !user" class="text-center w-full">
        <span class="text-theme-200">
          {{ $t('Register.Error.TokenIsUsed') }}
        </span>

        <div class="text-sm mt-5 leading-5">
          <router-link
            :to="{ name: 'Login' }"
            class="font-normal text-lightblue-link focus:outline-none focus:underline transition ease-in-out duration-150"
          >
            {{ $t('Register.Action.BackToLogin') }}
          </router-link>
        </div>
      </div>
      <form class="mt-8" @submit.prevent="register" v-if="!$apollo.loading && user">
        <InputVerify
          v-model="user.email"
          :placeholder="$t('Register.EmailAddress')"
          type="email"
          required
          :messageIconVisible="error.email.length > 0"
          messageVariant="error"
        ></InputVerify>
        <InputVerify
          v-model="user.username"
          :placeholder="$t('Register.Username')"
          type="text"
          required
          :message="error.username"
          :messageIconVisible="error.username.length > 0"
          messageVariant="error"
        ></InputVerify>
        <InputVerify
          v-model="password"
          :placeholder="$t('Register.Password')"
          type="password"
          required
          :messageIconVisible="error.password.length > 0"
          messageVariant="error"
        ></InputVerify>
        <InputVerify
          v-model="passwordRepeat"
          :placeholder="$t('Register.PasswordRepeat')"
          type="password"
          required
          :message="error.password"
          :messageIconVisible="error.password.length > 0"
          messageVariant="error"
        ></InputVerify>

        <div class="mt-6 flex w-full justify-center">
          <ButtonElement type="submit">
            {{ $t('Register.Action.Create') }}
          </ButtonElement>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import InputVerify from '@/components/InputVerify';
import ButtonElement from '@/components/ButtonElement';
import { GraphQLQuery, GraphQLMutation } from '@/graphql';

export default {
  name: 'Register',
  components: {
    InputVerify,
    ButtonElement
  },
  apollo: {
    user: {
      query: GraphQLQuery.UserByRegistrationHash,
      variables() {
        return {
          token: this.token
        };
      },
      update(data) {
        const user = data.userByRegistrationHash;
        user.username = '';
        return user;
      }
    }
  },
  data() {
    return {
      error: {
        email: '',
        username: '',
        password: ''
      },
      token: this.$route.params.token,
      password: '',
      passwordRepeat: ''
    };
  },
  mounted() {
    this.clearError();
  },
  methods: {
    clearError() {
      this.error.email = '';
      this.error.username = '';
      this.error.password = '';
    },

    async register() {
      try {
        if (this.password.length === 0 || this.password !== this.passwordRepeat) {
          this.clearError();
          this.error.password = this.$t('Register.Error.PasswordsNoMatch');
          return;
        }

        const response = await this.$apollo.mutate({
          mutation: GraphQLMutation.UserCompletion,
          variables: {
            email: this.user.email,
            username: this.user.username,
            hash: this.token,
            password: this.password
          }
        });

        const { jwt, user } = response.data.login;
        this.$store.commit('accessToken', jwt);
        this.$store.commit('currentUser', user);
        this.$store.commit('prefilledCredentials', {
          username: this.user.username,
          email: this.user.email,
          password: ''
        });

        this.$router.push({ name: 'Dashboard' });
      } catch (e) {
        this.clearError();

        if (e && e.message.indexOf('ER_DUP_ENTRY')) {
          this.error.username = this.$t('Register.Error.UsernameExists');
        } else {
          this.error.username = this.$t('Register.Error.UnknownError');
        }
      }
    }
  }
};
</script>
