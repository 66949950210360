<template>
  <DashboardTile>
    <template v-slot:header>
      <div class="w-full flex justify-between items-center mb-4">
        <div class="text-theme-300 flex-grow flex-shrink-0">
          <span class="font-semibold text-100 sm:text-200">{{ $t('Dashboard.Widget.Website.Title') }}</span>
        </div>
      </div>
    </template>
    <span class="text-theme-100 block leading-relaxed mt-6">{{ $t('Dashboard.Widget.Website.Body') }}</span>
    <span class="text-theme-100 block leading-relaxed">
      <span>{{ $t('Dashboard.Widget.Website.Change') }} </span>
      <span @click="isEditing = !isEditing" class="text-lightblue-link">
        {{ $t('Dashboard.Widget.Website.Action.Change') }}
      </span>
    </span>
    <span class="text-theme-100 mt-2 w-full">
      <InputMessage
        v-if="isEditing"
        v-model="websiteURL"
        :multiline="false"
        :allowEmptySend="true"
        @send="changeWebsiteURL"
        class="w-full"
      />
      <div class="text-lightblue-link flex items-center mt-3" v-if="!isEditing && websiteURL.length > 0">
        <IconWebsite />
        <a @click.stop :href="websiteURL" target="_blank" class="underline ml-2">
          {{ websiteURL }}
        </a>
      </div>
    </span>
  </DashboardTile>
</template>

<script>
import DashboardTile from '@/components/dashboard-tiles/DashboardTile';
import InputMessage from '@/components/InputMessage';

export default {
  name: 'DashboardTileWebsite',
  extends: DashboardTile,
  inheritAttrs: false,
  components: {
    DashboardTile,
    InputMessage
  },
  data() {
    return {
      isEditing: false,
      websiteURL: 'https://www.gezinshuis.com'
    };
  },
  methods: {
    changeWebsiteURL() {
      // eslint-disable-next-line
      if (this.websiteURL.length === 0 || this.websiteURL.indexOf('javascript:') > -1) {
        this.websiteURL = '';
        this.isEditing = false;
        return;
      }

      if (this.websiteURL.indexOf('http') !== 0) {
        this.websiteURL = `https://${this.websiteURL}`;
      }

      this.isEditing = false;
    }
  }
};
</script>
