<template>
  <main class="bg-lightblue-background pt-16 relative">
    <img src="@/assets/background.svg" class="absolute right-0 top-0 z-0" />
    <div class="max-w-5xl mx-auto pb-12 px-4 sm:px-6 lg:px-8 z-1 relative">
      <div class="flex flex-col sm:flex-row justify-between items-center w-full mb-8 sm:mb-16">
        <h1 class="text-theme-500 flex-grow">
          <span>{{ $t('Homes.Title') }}</span>
          <span class="ml-2" v-if="homes">({{ page.total }})</span>
        </h1>
        <InputSearch
          class="flex-grow mt-4 sm:mt-0"
          :placeholder="$t('Homes.Search.Placeholder')"
          variant="big"
          @search="performSearch"
        ></InputSearch>
      </div>
      <div class="w-full min-h-screen-pagination">
        <div v-if="$apollo.loading" class="flex flex-col items-center justify-center w-full h-full pt-8 mb-8">
          <img class="w-8 h-8 mb-4" src="@/assets/loading-dark.gif" alt="Loading" />
          <span class="text-150 font-semibold text-darkblue">
            {{ $t('General.Loading') }}
          </span>
        </div>
        <div
          class="w-full p-4 flex items-center cursor-pointer"
          v-for="(home, index) in homes"
          :key="index"
          :class="{
            'bg-white border border-gray-200': index % 2 == 0,
            [`animation-list-scale-in group animation-once animation-fill-backwards animation-300 animation-delay-${index}`]: true
          }"
          @click="selectHome(home)"
        >
          <button class="flex text-left items-center focus:outline-none w-1/2 group">
            <IconHome
              class="flex-shrink-0 text-lightblue-hover group-hover:text-orange transition ease-in-out duration-150"
            />
            <div class="min-w-2xl ml-8">
              <span class="block text-theme-150 text-darkblue font-semibold">
                {{ home.name }}
              </span>
            </div>
          </button>
          <div class="flex justify-between items-center w-1/2">
            <span class="block text-theme-200">{{ home.address | empty('') }}</span>
          </div>
          <div class="flex justify-between items-center">
            <IconChevronRight />
          </div>
        </div>
      </div>
      <Pagination :current="page.current + 1" :total="page.total / page.limit" @change="pageChanged"></Pagination>
    </div>
  </main>
</template>

<script>
import Pagination from '@/components/Pagination';
import InputSearch from '@/components/InputSearch';
import { GraphQLQuery } from '@/graphql';

export default {
  name: 'Homes',
  components: {
    Pagination,
    InputSearch
  },
  apollo: {
    homes: {
      query: GraphQLQuery.Homes,
      variables() {
        return {
          query: this.searchQuery,
          limit: this.page.limit,
          start: this.page.current * this.page.limit
        };
      },
      update(data) {
        this.page.total = data.count.length;
        return data.homes;
      }
    }
  },
  data() {
    return {
      searchQuery: '',
      page: {
        limit: 10,
        current: 0,
        total: 0
      }
    };
  },
  methods: {
    performSearch(query) {
      this.searchQuery = query;
    },
    pageChanged(current) {
      this.page.current = current - 1;
    },
    selectHome(home) {
      this.$apollo.provider.defaultClient.resetStore();

      this.$store.commit('currentManagedHome', home);
      this.$router.push({ name: 'Dashboard' });
    }
  }
};
</script>
