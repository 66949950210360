<template>
  <ProgressReportSection v-bind="$props">
    <ActivityList class="mt-4" />
  </ProgressReportSection>
</template>

<script>
import ProgressReportSection from '@/views/ProgressReport/Sections/ProgressReportSection';
import ActivityList from '@/components/ActivityList';

export default {
  name: 'ProgressReportSectionActivityList',
  extends: ProgressReportSection,
  inheritAttrs: false,
  components: {
    ProgressReportSection,
    ActivityList
  }
};
</script>
