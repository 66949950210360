var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DashboardTile',{attrs:{"homeID":_vm.homeID,"to":{ name: 'Library' }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"w-full group flex justify-between items-center mb-4"},[_c('div',{staticClass:"text-theme-300 flex-grow flex-shrink-0"},[_c('span',{staticClass:"font-semibold text-100 sm:text-200"},[_vm._v(_vm._s(_vm.$t('Library.Title')))])]),_c('div',{staticClass:"text-theme-300 transform group-hover:translate-x-1 transition ease-in-out duration-150"},[_c('IconChevronRightBig')],1)])]},proxy:true}])},[_c('div',{staticClass:"mt-6"},[(
        (!_vm.$permissions.has('home-find') || _vm.$store.state.currentManagedHome) &&
          _vm.$permissions.has('document-viewprivatedocuments')
      )?_c('div',{staticClass:"flex mb-4"},[_c('DashboardTileLink',{attrs:{"title":_vm.$t('Library.Section.Private'),"to":{ name: 'Library' }}})],1):_vm._e(),(_vm.$permissions.has('document-viewhealthinsurerdocuments'))?_c('div',{staticClass:"flex mb-4"},[_c('DashboardTileLink',{attrs:{"title":_vm.$t('Library.Section.Healthinsurer'),"to":{
          name: 'Library',
          params: {
            type: 'zorgaanbieder'
          }
        }}})],1):_vm._e(),(_vm.$permissions.has('document-viewpublicdocuments'))?_c('div',{staticClass:"flex mb-4"},[_c('DashboardTileLink',{attrs:{"title":_vm.$t('Library.Section.Public'),"to":{
          name: 'Library',
          params: {
            type: 'publiek'
          }
        }}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }