<template>
  <div class="bg-white w-full px-8 pt-12 pb-12 mb-8 border border border-b-4 border-gray-200 rounded-md text-theme-200">
    <slot name="header">
      <span class="block text-theme-300 mb-3"> {{ paragraph.sort_number }}. {{ paragraph.name }} </span>
    </slot>
    <ReadMoreSpan
      class="block text-100"
      :text="paragraph.description"
      :maxLength="1000"
      :markdown="true"
      :centerImages="true"
    />

    <slot></slot>
    <slot name="footer"></slot>
  </div>
</template>

<script>
import ReadMoreSpan from '@/components/ReadMoreSpan';

export default {
  name: 'ProgressReportSection',
  components: {
    ReadMoreSpan
  },
  props: {
    paragraph: {
      type: Object,
      required: true
    },
    paragraphUserData: {
      type: Object,
      default: null,
      required: false
    },
    homeID: {
      type: Number,
      default: undefined,
      required: false
    }
  }
};
</script>
