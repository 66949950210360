var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DashboardCard',{attrs:{"title":_vm.$t('Dashboard.Library.Title'),"icon":"IconLibrary","url":"/bibliotheek"}},[_c('div',{staticClass:"grid gap-2"},[(
        (!_vm.$permissions.has('home-find') || _vm.$store.state.currentManagedHome) &&
          _vm.$permissions.has('document-viewprivatedocuments')
      )?_c('router-link',{attrs:{"to":{
        name: 'Library'
      }}},[_c('DashboardButton',[_vm._v(" "+_vm._s(_vm.$t('Dashboard.Library.PersonalDocuments'))+" ")])],1):_vm._e(),(_vm.$permissions.has('document-viewhealthinsurerdocuments'))?_c('router-link',{attrs:{"to":{
        name: 'Library',
        params: {
          type: 'zorgaanbieder'
        }
      }}},[_c('DashboardButton',[_vm._v(" "+_vm._s(_vm.$t('Dashboard.Library.HealthinsurerDocuments'))+" ")])],1):_vm._e(),(_vm.$permissions.has('document-viewpublicdocuments'))?_c('router-link',{attrs:{"to":{
        name: 'Library',
        params: {
          type: 'publiek'
        }
      }}},[_c('DashboardButton',[_vm._v(" "+_vm._s(_vm.$t('Dashboard.Library.PublicDocuments'))+" ")])],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }