import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloClient } from 'apollo-client';
import { onError } from 'apollo-link-error';
import { ApolloLink, from } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import store from '@/store';
import env from '@/env';

Vue.use(VueApollo);

const httpLink = createHttpLink({
  uri: `${env.value('VUE_APP_API_BASE_URL', 'http://localhost:1337')}${env.value('VUE_APP_API_URL', '/graphql')}`
});

const authMiddleware = new ApolloLink((operation, forward) => {
  let token = store.state.accessToken || '';
  if (operation.variables && operation.variables.apolloAccessToken !== undefined) {
    token = operation.variables.apolloAccessToken;
  }

  const managedHomeID = store.state.currentManagedHome ? store.state.currentManagedHome.id : '';
  const headers = {};

  if (token && token.length > 0) {
    headers.authorization = `Bearer ${token}`;
  }

  if (managedHomeID && managedHomeID.length > 0) {
    headers['X-Active-Home'] = managedHomeID;
  }

  operation.setContext({
    headers
  });

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  let title = window.rootVue.$t('Modal.Error.UnknownError.Title');
  let error = window.rootVue.$t('Modal.Error.UnknownError.Body');
  let errorDetails = 'Debug:\n';
  let displayError = false; // Hide errors by default

  const allowedErrors = ['Bad Request', 'document.inUse', 'external_auditor.noPermissions'];
  const invalidTokenErrors = ['Invalid token.', 'User Not Found'];

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) => {
      if (message === 'Forbidden') {
        title = window.rootVue.$t('Modal.Error.AccessForbidden.Title');
        error = window.rootVue.$t('Modal.Error.AccessForbidden.Body');
        displayError = true;

        if (!window.rootVue.$store.state?.currentUser) {
          displayError = false;
        }
      } else if (invalidTokenErrors.includes(message)) {
        try {
          store.commit('logout');
          window.rootVue.$router.push({ name: 'Login' });
        } catch {
          // Stub
        }
        displayError = false;
      } else if (allowedErrors.includes(message)) {
        displayError = false;
      }

      if (path && path.length > 0) {
        errorDetails += `GraphQL - ${message}: ${path}\n`;
      } else {
        errorDetails += `GraphQL - ${message}\n`;
      }
    });
  } else if (networkError) {
    displayError = false;
  }

  if (process.env.NODE_ENV !== 'production') {
    error += `\n\n${errorDetails}`;
  }

  if (!displayError) {
    return;
  }

  window.rootVue.$modal.showIfNone('ErrorModal', {
    title,
    error
  });
});

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  link: from([errorLink, authMiddleware, httpLink]),
  cache,
  resolvers: {}
});

/* Refresh token:
import { fromPromise } from 'apollo-link';

return fromPromise(refreshToken().then(token => {
  operation.setContext({
    headers: {
      ...oldHeaders,
      authorization: token,
    },
  });
  return forward(operation);
})) */

/* Resolver demo:
Launch: {
  isInCart: (launch, _args, { cache }) => {
    const { cartItems } = cache.readQuery({ query: GET_CART_ITEMS });
    return cartItems.includes(launch.id);
  },
}, */

export default new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all'
    }
  }
});
