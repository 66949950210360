<template>
  <main class="bg-gray-100 py-16" v-scroll="clientScrolled">
    <div class="max-w-7xl mx-auto pb-12 px-4 md:px-6 lg:px-8">
      <div class="flex items-center justify-between w-full mb-8">
        <RadiusBreadcrumbs :title="title" />

        <ProgressReportSaveButton
          :report="report"
          :saving="saving"
          :hasUnsavedChanges="hasUnsavedChanges"
          @save="persistAnswers"
        />
      </div>

      <div
        class="flex flex-col sm:flex-row w-full border border-b-4 border-gray-200 rounded-md bg-white px-8 py-6 items-center mb-10"
      >
        <span
          class="block mt-4 sm:mt-0 order-2 sm:order-1 mr-12 text-theme-150 font-medium text-darkblue whitespace-no-wrap"
        >
          {{ $t('ProgressReport.TotalProgress') }}
        </span>
        <ProgressBar
          class="order-1 sm:order-2"
          :percentage="Math.min(100, (finishedParagraphIds.length / paragraphs.length) * 100)"
          :calloutVisible="true"
        />
      </div>

      <div
        class="flex flex-col md:flex-row animation-page-scale-in animation-once animation-fill-backwards animation-300"
      >
        <div class="block md:sticky self-start top-0 mt-0 md:-mt-8">
          <ParagraphChecklist
            v-if="paragraphs"
            class="mr-0 md:mr-16 mt-0 md:mt-20 mb-8 md:mb-0"
            :paragraphs="paragraphs"
            :user-data-paragraphs="finishedParagraphs"
            :activeParagraph="activeParagraphIndex"
            @changeActiveParagraph="changeActiveParagraph"
          />
        </div>
        <div class="flex flex-col w-full">
          <ProgressReportSectionForm
            v-for="(paragraph, index) of paragraphs"
            :key="index"
            :index="index"
            :paragraph="paragraph"
            :ref="`section-${index}`"
            @completion-changed="updateFinishedParagraphs(paragraph, $event)"
            @date-picked="reportDatePicked"
          />
          <div
            v-if="loading"
            class="bg-white w-full p-3 lg:p-10 mb-6 border text-center border-b-4 border-gray-200 rounded-md text-black"
          >
            <Loading />
          </div>
          <div
            v-else
            class="bg-white w-full p-6 lg:p-10 mb-6 border text-center border-b-4 border-gray-200 rounded-md text-black"
          >
            <h3 class="text-300 font-semibold text-black mb-4">{{ $t('YearlyQuarterlyReport.FooterTitle') }}</h3>
            <p class="max-w-md mx-auto text-gray-500 mb-6 text-100">
              {{ $t(isYearly ? 'YearlyReport.FooterDescription' : 'QuarterlyReport.FooterDescription') }}
            </p>
            <div class="flex justify-center items-start gap-4">
              <ProgressReportSaveButton
                :report="report"
                :saving="saving"
                :hasUnsavedChanges="hasUnsavedChanges"
                @save="persistAnswers"
              />
              <ButtonElement
                v-if="$permissions.has('yearly-quarterly-report-generatepdfdocument')"
                variant="primary-small"
                @click.native="submit"
                >{{ $t(isYearly ? 'YearlyReport.FooterButton' : 'QuarterlyReport.FooterButton') }}</ButtonElement
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { GraphQLQuery, GraphQLMutation } from '@/graphql';
import ProgressReportSaveButton from '@/views/ProgressReport/SaveButton';
import ProgressReportSectionForm from '@/views/ProgressReport/Sections/ProgressReportSectionForm';
import ParagraphChecklist from '@/components/ParagraphChecklist';
import ProgressBar from '@/components/ProgressBar';
import ButtonElement from '@/components/ButtonElement';
import RadiusBreadcrumbs from '@/components/radius/RadiusBreadcrumbs';
import Loading from '@/components/library/Loading';

export default {
  name: 'YearlyQuarterlyReport',
  components: {
    ProgressReportSaveButton,
    ProgressReportSectionForm,
    ParagraphChecklist,
    ProgressBar,
    ButtonElement,
    Loading,
    RadiusBreadcrumbs
  },
  apollo: {
    report: {
      query: GraphQLQuery.FindYearlyQuarterlyReport,
      fetchPolicy: 'network-only',
      variables() {
        return {
          identifier: this.identifier
        };
      },
      result() {
        this.loadAnswers();
      }
    }
  },
  data() {
    return {
      hasUnsavedChanges: false,
      persistAnswersTimeoutRef: null,
      activeParagraphIndex: 0,
      finishedParagraphIds: [],
      loading: false,
      saving: false,
      paragraphs: [],
      reportDate: null,
      year: 0,
      quarterNumber: 0
    };
  },
  watch: {
    reportDate() {
      this.year = this.reportDate ? this.reportDate.getFullYear() : 0;
      this.quarterNumber = !this.isYearly && this.reportDate ? Math.floor((this.reportDate.getMonth() + 3) / 3) : 0;
    }
  },
  computed: {
    homeID() {
      if (this.$route.params.homeID) {
        return Number(this.$route.params.homeID);
      }

      return this.$store.state.currentManagedHome ? Number(this.$store.state.currentManagedHome.id) : undefined;
    },

    isYearly() {
      return this.$route.params.type === 'year';
    },

    title() {
      let addition = '';
      if (this.year > 0) {
        addition += this.year;
      }

      if (this.quarterNumber > 0) {
        addition += ` Q${this.quarterNumber}`;
      }

      return this.$t(this.isYearly ? 'YearlyReport.Title' : 'QuarterlyReport.Title', [addition]);
    },

    identifier() {
      if (!this.report?.finished && !this.loading) {
        return `hm-${this.isYearly ? 'yearly' : 'quarterly'}-unfinished`;
      }

      return `hm-${this.isYearly ? 'yearly' : 'quarterly'}-${this.year}-${this.quarterNumber}`;
    },

    finishedParagraphs() {
      return this.paragraphs.map(paragraph => {
        return { paragraph, status: this.finishedParagraphIds.includes(paragraph.id) ? 'finished' : 'open' };
      });
    }
  },
  mounted() {
    this.setParagraphsForType();
    this.clientScrolled();
  },
  beforeRouteLeave(to, from, next) {
    if (confirm('Weet je zeker dat je de pagina wilt verlaten? Alle niet opgeslagen wijzigingen gaan verloren.')) {
      next();
    }
  },
  methods: {
    reportDatePicked({ field, value }) {
      if (!field.isReportDate) {
        return;
      }

      this.reportDate = value;
    },

    changeActiveParagraph(index) {
      const element = this.$refs[`section-${index}`][0].$el;
      if (!element) {
        return;
      }
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },

    clientScrolled() {
      if (!this.paragraphs || this.paragraphs.length === 0) {
        return;
      }

      const height = Math.max(document.documentElement.scrollHeight, document.documentElement.clientHeight);

      const isAtBottom = document.documentElement.scrollTop > height - document.documentElement.clientHeight - 20;
      if (isAtBottom) {
        this.activeParagraphIndex = this.paragraphs.length - 1;
        return;
      }

      this.activeParagraphIndex = Math.min(
        this.paragraphs.length - 1,
        Math.max(0, Math.round((document.documentElement.scrollTop * this.paragraphs.length) / height))
      );
    },

    updateFinishedParagraphs(paragraph, { isComplete, isInitial }) {
      if (isComplete) {
        this.finishedParagraphIds.push(paragraph.id);
      } else {
        this.finishedParagraphIds = this.finishedParagraphIds.filter(id => id !== paragraph.id);
      }

      this.finishedParagraphIds = this.finishedParagraphIds.filter((id, index) => {
        return this.finishedParagraphIds.indexOf(id) === index;
      });

      if (!isInitial) {
        this.hasUnsavedChanges = true;

        // Temporarily disabled autosaving -- re-enable after testing and reports have calmed down
        // if (this.persistAnswersTimeoutRef) {
        //   clearTimeout(this.persistAnswersTimeoutRef);
        // }

        // this.persistAnswersTimeoutRef = setTimeout(() => {
        //   this.persistAnswers();
        // }, 2000);
      }
    },

    async persistAnswers() {
      if (this.saving || this.$apollo.queries.report.loading) {
        return;
      }

      const paragraphAnswers = [];
      for (let i = 0; i < this.paragraphs.length; i += 1) {
        const section = this.$refs[`section-${i}`]?.[0];
        if (!section) {
          continue;
        }

        const data = section.answers();
        if (data) {
          paragraphAnswers.push({
            id: this.paragraphs[i].id,
            answers: data
          });
        }
      }

      if (paragraphAnswers.length > 0) {
        this.saving = true;

        await this.$apollo.mutate({
          mutation: GraphQLMutation.SaveYearlyQuarterlyReport,
          variables: {
            identifier: this.identifier,
            title: this.title,
            data: JSON.stringify(paragraphAnswers)
          }
        });

        this.$apollo.queries.report.refetch();

        this.hasUnsavedChanges = false;
        this.saving = false;
      }
    },

    loadAnswers() {
      let answers = [];

      try {
        if (typeof this.report?.data === 'string') {
          answers = JSON.parse(this.report?.data);
        } else if (typeof this.report?.data === 'object') {
          answers = this.report?.data;
        }
      } catch (e) {
        // Ignore
      }

      if (!answers?.length) {
        answers = this.paragraphs.map(p => {
          return { id: p.id, answers: {} };
        });
      }

      for (let i = 0; i < this.paragraphs.length; i += 1) {
        for (let j = 0; j < this.paragraphs[i].fields.length; j += 1) {
          const field = this.paragraphs[i].fields[j];
          if (field?.value?.length && !answers[i].answers[field.name]) {
            answers[i].answers[field.name] = field.value;
          }

          if (field.isReportDate && answers[i].answers[field.name]?.length) {
            const dateText = answers[i].answers[field.name]
              .split('/')
              .reverse()
              .join('/');

            this.reportDate = new Date(dateText);
          }
        }

        const answersForParagraph = answers.find(a => a.id === this.paragraphs[i].id);
        const sections = this.$refs[`section-${i}`];
        if (sections?.length) {
          sections[0].setValues(answersForParagraph);
        }
      }
    },

    async submit() {
      this.persistAnswers();

      const paragraphAnswers = [];
      for (let i = 0; i < this.paragraphs.length; i += 1) {
        const section = this.$refs[`section-${i}`]?.[0];
        if (!section) {
          continue;
        }

        if (!section.validate(true)) {
          this.changeActiveParagraph(i);
          return;
        }

        const data = section.answers();
        paragraphAnswers.push({
          paragraph: this.paragraphs[i],
          answers: data
        });
      }

      this.$modal.confirm(async () => {
        const identifier = this.identifier;
        this.loading = true;
        const targetIdentifier = this.identifier;

        try {
          const response = await this.$apollo.mutate({
            mutation: GraphQLMutation.GenerateYearlyQuarterlyReport,
            variables: {
              title: this.title,
              identifier,
              targetIdentifier,
              filename: `${this.title}.pdf`,
              paragraphID: this.$route.params.paragraphID ? Number(this.$route.params.paragraphID) : 0,
              data: paragraphAnswers,
              addToLibrary: true
            }
          });

          const { filename, url } = response?.data?.generatePDFDocument;
          if (url?.length) {
            this.$modal.show('PlanCreatedModal', { filename, url });
            localStorage.removeItem(this.identifier);
          }
        } catch {
          // Ignore
        }

        this.loading = false;
      }, 'ConfirmSubmitPlan');
    },

    setParagraphsForType() {
      let homeName;
      if (this.$store.state.currentUser?.homes?.length === 1) {
        homeName = this.$store.state.currentUser?.homes?.[0]?.name;
      }

      if (!this.isYearly) {
        this.paragraphs = [
          {
            id: 1,
            name: 'Basisgegevens',
            description: '',
            type: 'two-column',
            fields: [
              { name: 'Naam gezinshuis', type: 'text', value: homeName },
              { name: 'Zorgaanbieder / gemeente', type: 'text' },
              { name: 'Contactpersoon zorgaanbieder / gemeente', type: 'text' },
              { name: 'Gedragswetenschapper', type: 'text' },
              { name: 'Stagiares, medewerkers, vrijwilligers', type: 'text' },
              { name: 'Datum kwartaalevaluatie', type: 'date', isReportDate: true }
            ]
          },
          {
            id: 2,
            name: 'Speerpunten',
            description: '',
            fields: [
              {
                name: 'Welke stappen heb je dit kwartaal gezet? (hoe, met wie, wat, waar en wanneer)',
                type: 'textarea'
              },
              { name: 'Wat heb je bereikt?', type: 'textarea' }
            ]
          },
          {
            id: 3,
            name: 'Successen',
            description: '',
            fields: [
              { name: 'Welke successen hebben we behaald in het afgelopen kwartaal:', type: 'textarea' },
              {
                name: 'Waarom is dit een succes? (wat, met wie, waar, wanneer en beschrijving van het succes hoe)',
                type: 'textarea'
              },
              {
                name: 'Wat heb je/ hebben jullie hier als professional(s) in gedaan? (hoe, wie, wanneer, waar)',
                type: 'textarea'
              }
            ]
          },
          {
            id: 4,
            name: 'Incidenten en/of meldingen',
            description: '',
            fields: [
              { name: 'Zijn er incidenten en/of meldingen geweest in het afgelopen kwartaal:', type: 'textarea' },
              { name: 'Hoe kijk je terug op de afhandeling ervan? Wat neem je mee voor de toekomst?', type: 'textarea' }
            ]
          },
          {
            id: 5,
            name: 'Uitdagingen',
            description: '',
            fields: [
              { name: 'Welke uitdagingen zijn er geweest in het afgelopen kwartaal:', type: 'textarea' },
              { name: 'Waarom was dit een uitdaging?', type: 'textarea' },
              { name: 'Wat heb je/ hebben jullie hier als professional(s) in gedaan?', type: 'textarea' }
            ]
          },
          {
            id: 6,
            name: 'Leerpunten voor het komende kwartaal',
            description: '',
            fields: [
              { name: 'Welke leerpunten hebben we voor het komende kwartaal:', type: 'textarea' },
              { name: 'Hoe ga je dit toepassen?', type: 'textarea' }
            ]
          },
          {
            id: 7,
            name: 'Professionalisering',
            description: '',
            fields: [
              { name: 'Heb je nog trainingen gevolgd? Coaching en of intervisie?', type: 'textarea' },
              {
                name: 'Zijn er specifieke competenties die het komende kwartaal jouw extra aandacht gaan krijgen? ',
                type: 'textarea'
              }
            ]
          },
          {
            id: 8,
            name: 'Keurmerk',
            description: '',
            fields: [{ name: 'Welke acties zijn er gedaan voor het keurmerk?', type: 'textarea' }]
          }
        ];
        return;
      }

      this.paragraphs = [
        {
          id: 1,
          name: 'Basisgegevens',
          description: '',
          type: 'two-column',
          fields: [
            { name: 'Naam gezinshuis', type: 'text', value: homeName },
            { name: 'Zorgaanbieder / gemeente', type: 'text' },
            { name: 'Contactpersoon zorgaanbieder / gemeente', type: 'text' },
            { name: 'Gedragswetenschapper', type: 'text' },
            { name: 'Stagiares, medewerkers, vrijwilligers', type: 'text' },
            { name: 'Datum jaarevaluatie', type: 'date', isReportDate: true }
          ]
        },
        {
          id: 2,
          name: 'Basisgegevens geplaatste gezinsleden (jeugdigen)',
          description: '',
          fields: [{ name: 'Jeugdige', type: 'children' }]
        },
        {
          id: 3,
          name: 'Successen',
          description: 'Beschrijf hier je drie grootste successen en waar je trots op bent.',
          fields: [
            { name: 'Succes 1', type: 'text' },
            { name: 'Succes 2', type: 'text' },
            { name: 'Succes 3', type: 'text' }
          ]
        },
        {
          id: 4,
          name: 'Plan: Speerpunten',
          description: '',
          fields: [
            { name: 'Speerpunt 1', type: 'text' },
            { name: 'Speerpunt 2', type: 'text' },
            { name: 'Speerpunt 3', type: 'text' },
            { name: 'Hoe ga je jouw speerpunten aanpakken?', type: 'textarea' }
          ]
        },
        {
          id: 5,
          name: 'Do: SWOT',
          description: '',
          fields: [
            { name: "Op welke thema's zet je jouw reflectie in? Denk aan coach / intervisie.", type: 'textarea' },
            {
              name: 'Op welke manier ga je jouw successen borgen en versterken? Denk aan scholing en trainingen.',
              type: 'textarea'
            }
          ]
        },
        {
          id: 6,
          name: 'Check: Vanuit het kwaliteitskader',
          description: '',
          fields: [
            {
              name: 'Wat heb je gedaan met de resultaten van je thuisklimaatonderzoek?',
              type: 'textarea'
            },
            { name: 'Wat hebben de incidentmeldingen met jou en je gezinshuis gedaan?', type: 'textarea' },
            {
              name:
                'Welke aandachtspunten heb je als je kijkt naar:\n\n1. Audits\n2. Wat betekent dat voor je portfolio\n3. Overig',
              type: 'textarea'
            }
          ]
        },
        {
          id: 7,
          name: 'Act: Vonk competenties',
          description: '',
          fields: [
            {
              name: 'Hoe zet je de competenties dagelijks en praktisch in binnen je gezinshuis?',
              type: 'textarea'
            }
          ]
        },
        {
          id: 8,
          name: 'Samenwerken',
          description: '',
          fields: [
            {
              name:
                'Hoe heb je de samenwerking ervaren in het afgelopen jaar? Gebruik hierbij ook de resultaten uit je samenwerkingsonderzoek.',
              type: 'textarea'
            }
          ]
        },
        {
          id: 9,
          name: 'Financieel',
          description: '',
          fields: [
            {
              name: 'Welke bijzonderheden verwacht je binnen je financiële situatie? Ben je financieel gezond?',
              type: 'textarea'
            }
          ]
        },
        {
          id: 10,
          name: 'Afspraken',
          description: '',
          fields: [
            {
              name:
                'Wat zijn de gemaakte afspraken mbt:\n- Contract\n- Indexering\n- Datum jaargesprek volgend jaar\n- Professionalisering\n- Overig',
              type: 'textarea'
            }
          ]
        }
      ];
    }
  }
};
</script>
