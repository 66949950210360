<template>
  <div
    v-if="portalMessage && !messageHiddenInLocalStorage && !hideMessage && portalMessage.active"
    class="portal-message relative bg-lightblue-100 p-6 rounded-md flex -mt-8"
  >
    <i
      class="w-12 relative overflow-hidden h-12 flex flex-shrink-0 items-center justify-center rounded-full bg-lightblue-link"
    >
      <span class="absolute top-0 left-0 w-full h-full bg-white opacity-20"></span>
      <span class="absolute top-0 left-0 w-full h-full bg-white opacity-20"></span>
      <IconSpeakerphone class="relative" />
    </i>
    <div class="pl-4">
      <h3 class="font-bold text-gray-800">{{ portalMessage.title }}</h3>
      <p v-html="$filters.markdown2html(portalMessage.content)" class="text-gray-700 text-100 leading-relaxed"></p>
    </div>
    <div
      class="w-12 h-12 flex items-center justify-center absolute right-0 top-0 cursor-pointer"
      @click="hidePortalMessage"
    >
      <IconX class="text-gray-700" />
    </div>
  </div>
</template>

<script>
import { GraphQLQuery } from '@/graphql';

export default {
  apollo: {
    portalMessage: {
      query: GraphQLQuery.PortalMessage,
      fetchPolicy: 'cache-and-network'
    }
  },

  data() {
    return {
      hideMessage: false
    };
  },

  computed: {
    messageHiddenInLocalStorage() {
      const localStorageItem = localStorage.getItem('portalMessageHidden');

      return localStorageItem === this.portalMessage.updated_at;
    }
  },

  methods: {
    hidePortalMessage() {
      this.hideMessage = true;
      if (localStorage) {
        localStorage.setItem('portalMessageHidden', this.portalMessage.updated_at);
      }
    }
  }
};
</script>

<style>
.portal-message p a {
  @apply font-medium underline;
}
.portal-message p a:hover {
  @apply no-underline;
}
</style>
