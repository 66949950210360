<template>
  <div>
    <div
      v-for="(paragraph, index) in paragraphs"
      :key="index"
      class="flex flex-col select-none"
      :class="{
        [`animation-list-scale-in animation-once animation-fill-backwards animation-300 animation-delay-${index}`]: true
      }"
    >
      <div class="flex items-center flex-shrink-0 text-theme-250 cursor-pointer" @click="changeActiveParagraph(index)">
        <div
          class="
            flex
            justify-center
            items-center
            rounded-full
            border-2
            w-8
            h-8
            z-10
            transition
            ease-in-out
            duration-150
            flex-shrink-0
          "
          :class="{
            'bg-success-balloon-text border-textfield-disabled text-white':
              !isActive(paragraph) && isFinished(paragraph),
            'bg-white border-lightblue text-darkblue': isActive(paragraph),
            'bg-lightblue border-textfield-disabled text-white': !isActive(paragraph) && !isFinished(paragraph)
          }"
        >
          <IconCheck
            v-if="isFinished(paragraph)"
            :class="{
              [`animation-general-overscale-in animation-once animation-fill-backwards animation-300 animation-delay-${6 +
                index * 2}`]: true
            }"
          />
          <IconExclamationMark
            v-else-if="hasExpiredDocuments(paragraph)"
            :class="{
              [`w-5 h-5 text-error-balloon-text animation-general-overscale-in animation-once animation-fill-backwards animation-300 animation-delay-${6 +
                index * 2}`]: true
            }"
          />
          <span
            v-else
            class="font-semibold text-50"
            :class="{
              [`animation-general-overscale-in animation-once animation-fill-backwards animation-300 animation-delay-${6 +
                index * 2}`]: true
            }"
          >
            {{ index + 1 }}
          </span>
        </div>

        <span
          class="text-100 font-medium ml-2 truncate w-full md:w-40"
          :class="{
            'text-lightblue-text': paragraph.id !== paragraphs[activeParagraph].id,
            'text-darkblue': paragraph.id === paragraphs[activeParagraph].id
          }"
        >
          {{ paragraph.name }}
        </span>
      </div>

      <div class="w-8 h-3 flex justify-center flex-grow">
        <div
          class="h-full w-2px"
          :class="{
            'bg-success-balloon-text': isFinished(nextParagraph(index)),
            'bg-lightblue': true,
            hidden: index + 1 >= paragraphs.length
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    paragraphs: {
      type: Array,
      required: true
    },
    userDataParagraphs: {
      type: Array,
      default() {
        return [];
      },
      required: false
    },
    activeParagraph: {
      type: Number,
      default: 0,
      required: false
    }
  },
  watch: {
    userDataParagraphs: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          this.finishedParagraphs = [];
          return true;
        }

        this.finishedParagraphs = newValue.filter(info => {
          return info.status && info.status !== 'open' && !this.hasExpiredDocuments(info);
        });

        return true;
      }
    }
  },
  data() {
    return {
      finishedParagraphs: []
    };
  },
  methods: {
    hasExpiredDocuments(paragraph) {
      if (!paragraph.documents) {
        paragraph = this.userDataParagraphs.find(userData => {
          return userData.paragraph && paragraph && userData.paragraph.id === paragraph.id;
        });
      }

      if (!paragraph) {
        return false;
      }

      return this.$helper.expiredDocumentsCount(paragraph.documents) > 0;
    },

    changeActiveParagraph(index) {
      if (index === this.activeParagraph) {
        return;
      }

      this.$emit('changeActiveParagraph', index);
    },

    nextParagraph(index) {
      if (index >= this.paragraphs.length) {
        return null;
      }

      return this.paragraphs[index + 1];
    },

    isActive(paragraph) {
      if (!paragraph) {
        return false;
      }

      return paragraph.id === this.paragraphs[this.activeParagraph].id;
    },

    isFinished(paragraph) {
      if (!paragraph) {
        return false;
      }

      const result = this.finishedParagraphs.find(finishedParagraph => {
        return finishedParagraph.paragraph.id === paragraph.id;
      });
      return result;
    }
  }
};
</script>
