import dayjs from 'dayjs';
import 'dayjs/locale/nl';

export default function(value, format = 'DD MMMM YYYY') {
  if (value === null || value === undefined) {
    return null;
  }

  return dayjs(value)
    .locale('nl')
    .format(format);
}
