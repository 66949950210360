<template>
  <div class="flex items-center w-full mb-8">
    <button
      class="
            border
            bg-white
            border-lightblue
            hover:border-lightblue-hover
            transition
            ease-in-out
            duration-150
            text-darkblue
            w-10
            h-10
            mr-4
            rounded-full
            justify-center
            items-center
            flex flex-shrink-0
            focus:outline-none
          "
      @click="$router.push({ name: target })"
    >
      <IconChevronLeft />
    </button>
    <div class="text-theme-300 cursor-pointer flex gap-x-1" @click="$router.push({ name: target })">
      <span class="font-medium font-310 text-gray-500 hidden md:block">{{ $t('Radius.Title') }}</span>
      <span v-if="title" class="font-medium font-310 text-gray-500 hidden md:block">/</span>
    </div>
    <span v-if="title" class="block text-theme-300 ml-2">{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: 'QualityAssessment',
      required: false
    }
  }
};
</script>
