<template>
  <DashboardTile :homeID="homeID" :to="{ name: 'ManageUsers' }">
    <template v-slot:header>
      <div class="w-full group flex justify-between items-center mb-4">
        <div class="text-theme-300 flex-grow flex-shrink-0">
          <span class="font-semibold text-100 sm:text-200">{{ $t('Dashboard.Widget.ExternalUsers.Title') }}</span>
        </div>
      </div>
    </template>
    <span class="text-theme-100 mt-6 mb-4">{{
      $t('Dashboard.Widget.ExternalUsers.UserCountAccess') | format(usersCount)
    }}</span>
    <DashboardTileLink
      :title="$t('Dashboard.Widget.ExternalUsers.Action.Manage')"
      :to="{
        name: 'ManageUsers'
      }"
    />
  </DashboardTile>
</template>

<script>
import { GraphQLQuery } from '@/graphql';
import DashboardTile from '@/components/dashboard-tiles/DashboardTile';
import DashboardTileLink from '@/components/DashboardTileLink';

export default {
  name: 'DashboardTileExternalUsers',
  extends: DashboardTile,
  inheritAttrs: false,
  components: {
    DashboardTile,
    DashboardTileLink
  },
  apollo: {
    users: {
      query: GraphQLQuery.ExternalUsers,
      update(data) {
        return data.getExternalUsers;
      }
    }
  },
  computed: {
    currentHome() {
      if (
        !this.$store.state.currentUser ||
        !this.$store.state.currentUser.homes ||
        this.$store.state.currentUser.homes.length === 0
      ) {
        return null;
      }

      if (!this.homeID) {
        return this.$store.state.currentUser.homes[0];
      }

      return this.$store.state.currentUser.homes.find(home => {
        return Number(home.id) === this.homeID;
      });
    },

    usersCount() {
      if (!this.users || this.users.length === 0) {
        return 0;
      }

      const now = new Date();
      const activeUsers = this.users.filter(user => {
        const expirationDate = new Date(user.expiration_date);
        return expirationDate > now;
      });
      return activeUsers.length;
    }
  }
};
</script>
