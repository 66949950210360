<template>
  <DashboardCard :title="$t('Dashboard.Checklist.Title')" icon="IconChecklist">
    <section class="mb-6">
      <div class="relative w-full">
        <select
          v-model="selectedYear"
          class="rounded-md w-full h-12 border border-gray-200 shadow-sm pl-4 pr-10 text-100 appearance-none cursor-pointer focus:outline-none"
        >
          <option v-for="year of availableYears" :value="year" :key="year">{{ year }}</option>
        </select>
        <IconChevronRight class="absolute right-5 top-5 rotate-90 transform pointer-events-none" />
      </div>
    </section>

    <section class="mb-5">
      <div class="flex justify-between mb-3">
        <h4 class="text-black font-semibold text-50">{{ $t('Dashboard.Progress') }}</h4>
        <div class="text-progress-fill text-50">{{ finishedProgress.toFixed(0) }}%</div>
      </div>
      <div class="relative overflow-hidden transform-gpu bg-gray-200 rounded-full h-1">
        <span
          class="absolute top-0 left-0 bottom-0 bg-progress-fill transition-all duration-200"
          :style="{ width: `${finishedProgress.toFixed(0)}%` }"
        ></span>
      </div>
    </section>

    <section>
      <ul class="-ml-6 -mb-6 -mr-6 overflow-auto border-t border-gray-100" style="max-height: 168px">
        <li
          v-for="(item, index) in sortedItems"
          :key="index"
          class="checklist-item group flex py-4 px-6 gap-4 text-100 text-gray-700 font-medium border border-transparent hover:border-gray-400 transition-all duration-200 cursor-pointer"
          @click="toggleCompleted(item)"
        >
          <span
            class="w-5 cursor-pointer h-5 flex-shrink-0 rounded-full bg-white border border-gray-300 flex items-center justify-center text-white"
            :class="{
              'bg-progress-fill border-progress-fill': item.completed_date,
              'bg-gray-200 border-gray-300': !item.completed_date && item.removed_date,
              'hover:border-gray-400': !item.completed_date && !item.removed_date
            }"
          >
            <IconCheck v-if="item.completed_date" />
          </span>
          <span
            class="flex-grow"
            :class="{
              'line-through': item.completed_date,
              'text-gray-400 line-through': item.removed_date
            }"
          >
            {{ item.title }}
          </span>
          <span
            class="text-gray-400 opacity-0 transition-all duration-200 cursor-pointer group-hover:opacity-100 hover:text-gray-500"
            @click.stop="removeChecklistItem(item)"
          >
            <IconTrashOff v-if="item.removed_date" />
            <IconTrash v-else />
          </span>
        </li>
      </ul>
    </section>
  </DashboardCard>
</template>

<script>
import DashboardCard from '@/components/dashboard/DashboardCard.vue';
import { GraphQLQuery, GraphQLMutation } from '@/graphql';

export default {
  components: {
    DashboardCard
  },
  apollo: {
    checklist: {
      query: GraphQLQuery.Checklist,
      variables() {
        return {
          year: this.selectedYear > 0 ? this.selectedYear : null
        };
      },
      skip() {
        return this.$permissions.isAdmin() && !this.$store.state.currentManagedHome?.id;
      },
      update(data) {
        if (!data || !data.checklistMy) {
          return null;
        }

        return data.checklistMy;
      },
      fetchPolicy: 'network-only'
    }
  },
  data() {
    return {
      selectedYear: new Date().getFullYear(),
      availableYears: [2023] // The year this feature was developed
    };
  },
  computed: {
    sortedItems() {
      return (this.checklist?.items || []).sort((a, b) => {
        if (a.removed_date || b.removed_date) {
          return new Date(a?.removed_date || 0).getTime() - new Date(b?.removed_date || 0).getTime();
        }

        if (a.completed_date || b.completed_date) {
          return new Date(a?.completed_date || 0).getTime() - new Date(b?.completed_date || 0).getTime();
        }

        return 0;
      });
    },

    finishedProgress() {
      if (!this.checklist?.items?.length) {
        return 0;
      }

      const finishedItemCount = this.checklist.items.reduce((acc, item) => {
        if (item.completed_date) {
          acc += 1;
        }

        return acc;
      }, 0);

      const totalItemCount = this.checklist.items.reduce((acc, item) => {
        if (!item.removed_date) {
          acc += 1;
        }

        return acc;
      }, 0);

      return (finishedItemCount / totalItemCount) * 100;
    }
  },
  mounted() {
    if (this.availableYears[0]) {
      while (this.availableYears[this.availableYears.length - 1] < new Date().getFullYear()) {
        this.availableYears.push(this.availableYears[this.availableYears.length - 1] + 1);
      }
    }
  },
  methods: {
    async toggleCompleted(item) {
      if (item.removed_date) {
        return;
      }

      item.completed_date = item.completed_date ? null : new Date().toISOString();

      const { data } = await this.$apollo.mutate({
        mutation: GraphQLMutation.ChecklistToggleItem,
        variables: {
          id: item.id,
          year: this.selectedYear > 0 ? this.selectedYear : null
        }
      });

      if (data?.checklistToggleItem?.id) {
        this.$apollo.queries.checklist.refetch();
      }
    },

    removeChecklistItem(item) {
      const action = async () => {
        item.removed_date = item.removed_date ? null : new Date().toISOString();

        const { data } = await this.$apollo.mutate({
          mutation: GraphQLMutation.ChecklistRemoveItem,
          variables: {
            id: item.id,
            year: this.selectedYear > 0 ? this.selectedYear : null
          }
        });

        if (data?.checklistRemoveItem?.id) {
          this.$apollo.queries.checklist.refetch();
        }
      };

      if (item.removed_date) {
        action();
        return;
      }

      this.$modal.confirm(async () => {
        action();
      }, 'ConfirmDeleteChecklistItem');
    }
  }
};
</script>

<style>
.checklist-item:nth-child(odd) {
  @apply bg-gray-100;
}
</style>
