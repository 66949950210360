<template>
  <BaseModal
    v-bind="$props"
    :tabs="libraryEnabled ? [$t('Modal.Upload.Tab.Library'), $t('Modal.Upload.Tab.Upload')] : []"
    :title="libraryEnabled ? '' : $t('Modal.Upload.Tab.Upload')"
    @backdropClicked="hideDatePicker"
    @tabselected="tabSelected"
    :selectedTab="selectedTabIndex"
    :withTitleDivider="true"
    :withBottomDivider="libraryEnabled && selectedTabIndex == 0"
  >
    <template>
      <div>
        <div v-if="libraryEnabled && selectedTabIndex == 0">
          <div class="flex justify-between items-center mb-8">
            <span v-if="multiple" class="block text-theme-200 font-medium">
              <span class="font-bold text-lightblue-link">
                {{
                  (selectedDocuments.length == 1
                    ? $t('Modal.Upload.SelectedFiles.Single')
                    : $t('Modal.Upload.SelectedFiles.Multiple')) | format(selectedDocuments.length)
                }}
              </span>
              <span>{{ $t('Modal.Upload.SelectedFiles.Appendix') }}</span>
            </span>
            <InputSearch class="w-1/2" placeholder="Filter lijst" @search="performSearch" />
          </div>
          <div class="w-full sm:min-w-modal-upload-library max-h-modal-upload-library overflow-y-auto">
            <div
              class="w-full px-4 py-3 flex items-center cursor-pointer relative rounded-sm bg-white border border-gray-200 mb-2 transition ease-in-out duration-150"
              v-for="(doc, index) in filteredDocuments"
              @click="selectDocument(doc)"
              :key="index"
              :class="{
                'bg-lightblue-background border-blue-200':
                  selectedDocuments.length && selectedDocuments.find(el => el.id === doc.id),
                [`animation-list-scale-in animation-once animation-fill-backwards animation-300 animation-delay-${index}`]: true
              }"
            >
              <button class="flex text-left justify-between items-center focus:outline-none w-full">
                <div class="flex text-left items-center">
                  <IconFile :document="doc" />
                  <div class="min-w-2xl ml-4">
                    <span class="block text-theme-150 text-darkblue font-semibold">{{
                      $filters.documentname(doc)
                    }}</span>
                    <span class="block text-theme-100" v-if="doc.file.length > 0">{{
                      doc.file[0].size | filesize
                    }}</span>
                  </div>
                </div>
                <svg
                  v-if="selectedDocuments.length && selectedDocuments.find(el => el.id === doc.id)"
                  class="fill-current text-blue-800"
                  width="12"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g transform="translate(-644 -23)" fill="none" fill-rule="evenodd">
                    <path
                      d="M654.53 23c-.37.012-.721.18-.98.468l-5.377 5.878-1.854-1.618a1.346 1.346 0 00-1.049-.333 1.403 1.403 0 00-.957.576c-.493.674-.393 1.655.223 2.193l2.853 2.495c.568.496 1.385.445 1.899-.117l6.277-6.861c.423-.447.552-1.134.323-1.728-.23-.593-.768-.972-1.357-.952z"
                      fill="#2E9CDC"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div v-if="!libraryEnabled || selectedTabIndex == 1">
          <FileUpload
            ref="fileUpload"
            @completed="uploadCompleted"
            :uploadLibraryType="uploadLibraryType"
            :multiple="multiple"
            :replacementDocument="replacementDocument"
            :homeID="homeID"
            :folderID="folderID"
            :tags="tags"
            :types="types"
          />

          <span class="block text-theme-150 text-darkblue font-semibold mt-8 mb-2"
            >Maak bestand makkelijk vindbaar</span
          >
          <InputTags @changeTags="newTags => (tags = newTags)" :placeholder="getTagsPlaceholder()" />

          <div class="flex justify-end mt-6">
            <ButtonElement class="mr-2" variant="secondary-small" @click.native="hide">{{
              $t('Modal.Generic.Cancel')
            }}</ButtonElement>
            <ButtonElement @click.native="startUpload" variant="primary-small">Afronden</ButtonElement>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:bottom>
      <div class="flex justify-end p-6">
        <ButtonElement class="mr-2" variant="secondary-small" @click.native="hide">{{
          $t('Modal.Generic.Cancel')
        }}</ButtonElement>
        <ButtonElement @click.native="addDocuments" variant="primary-small">Voeg bestanden toe</ButtonElement>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { GraphQLQuery } from '@/graphql';
import BaseModal from '@/components/modals/BaseModal';
import ButtonElement from '@/components/ButtonElement';
import InputTags from '@/components/InputTags';
import InputSearch from '@/components/InputSearch';
import FileUpload from '@/components/FileUpload';

export default {
  name: 'UploadModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal,
    ButtonElement,
    InputTags,
    FileUpload,
    InputSearch
  },
  props: {
    libraryEnabled: {
      type: Boolean,
      default: false,
      required: false
    },
    homeID: {
      type: Number,
      default: undefined,
      required: false
    },
    folderID: {
      type: Number,
      default: undefined,
      required: false
    },
    uploadLibraryType: {
      type: String,
      default: 'private',
      required: false
    },
    multiple: {
      type: Boolean,
      default: true,
      required: false
    },
    replacementDocument: {
      type: Object,
      default: null,
      required: false
    },
    types: {
      type: Array,
      default() {
        return [];
      },
      required: false
    }
  },
  computed: {
    filteredDocuments() {
      let results = this.searchQuery.length > 0 ? this.documentsSearch : this.documents;
      if (!results) {
        results = [];
      }

      if (this.types && this.types.length > 0) {
        results = results.filter(doc => {
          const type = doc.file.length > 0 ? this.$helper.typeForFileExtension(doc.file[0].ext) : '';
          return this.types.includes(type);
        });
      }

      return results;
    }
  },
  apollo: {
    documents: {
      query: GraphQLQuery.Documents,
      variables() {
        return {
          libraryType: 'private'
        };
      }
    },
    documentsSearch: {
      query: GraphQLQuery.DocumentsSearch,
      variables() {
        return {
          query: this.searchQuery,
          libraryType: 'private'
        };
      },
      skip() {
        return !this.searchQuery || this.searchQuery.length === 0;
      },
      update(data) {
        return data.searchDocuments;
      }
    }
  },
  data() {
    return {
      tags: [],
      searchQuery: '',
      selectedTabIndex: 0,
      selectedDocuments: []
    };
  },

  created() {
    this.$apollo.queries.documents.refresh();
  },

  mounted() {
    if (this.libraryEnabled && this.filteredDocuments.length === 0) {
      this.selectedTabIndex = 1;
    }
  },
  methods: {
    performSearch(query) {
      this.searchQuery = query;
    },

    hideDatePicker() {
      if (this.$refs.fileUpload) {
        this.$refs.fileUpload.hideDatePicker();
      }
    },

    getTagsPlaceholder() {
      let result = '';
      if (!this.tags.length) {
        result = 'Voeg keywords toe aan bestand';
      }
      return result;
    },

    startUpload() {
      this.$refs.fileUpload.startUpload();
    },

    uploadCompleted(uploadResponse) {
      this.hide();
      this.$emit('completed', uploadResponse);
    },

    selectDocument(doc) {
      if (!this.multiple) {
        this.selectedDocuments.push(doc);
        this.addDocuments();
        return;
      }

      if (this.selectedDocuments.length >= 1) {
        const result = this.selectedDocuments.find(el => el.id === doc.id);
        if (result) {
          this.selectedDocuments = this.selectedDocuments.filter(el => el.id !== doc.id);
        } else {
          this.selectedDocuments.push(doc);
        }
      } else {
        this.selectedDocuments.push(doc);
      }
    },

    addDocuments() {
      this.hide();
      if (this.selectedDocuments.length >= 1) {
        this.$emit('completed', this.selectedDocuments);
      }
      this.selectedDocuments = [];
    },

    tabSelected(index) {
      this.selectedTabIndex = index;
    }
  }
};
</script>
