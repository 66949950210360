<template>
  <button
    :type="type"
    class="group relative flex justify-center items-center border border-transparent font-semibold text-white focus:outline-none focus:outline-none transition duration-150 ease-in-out"
    :class="{
      'py-3 px-10 text-white bg-orange hover:bg-orange-hover focus:border-orange focus:shadow-outline-orange rounded-full text-theme-250':
        variant === 'primary',
      'py-3 px-10 text-darkblue bg-white border-lightblue hover:bg-lightblue-50 hover:border-lightblue-hover focus:border-lightblue-hover focus:shadow-outline-lightblue-hover rounded-full text-theme-250':
        variant === 'secondary',
      'py-3 px-10 text-darkblue bg-transparent border-lightblue hover:bg-lightblue-50 hover:border-lightblue-hover focus:border-lightblue-hover focus:shadow-outline-lightblue-hover rounded-full text-theme-250':
        variant === 'secondary-transparent',
      'py-2 px-8 text-white bg-orange hover:bg-orange-hover focus:border-orange focus:shadow-outline-orange rounded-full text-100':
        variant === 'primary-small',
      'py-2 px-8 text-darkblue bg-white border-lightblue hover:bg-lightblue-50 hover:border-lightblue-hover focus:border-lightblue-hover focus:shadow-outline-lightblue-hover rounded-full text-100':
        variant === 'secondary-small',
      'py-2 px-3 text-darkblue bg-white border-lightblue hover:bg-lightblue-50 hover:border-lightblue-hover focus:border-lightblue-hover focus:shadow-outline-lightblue-hover rounded-md text-theme-250':
        variant === 'secondary-small-square'
    }"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'ButtonElement',
  props: {
    type: {
      type: String,
      default: 'button',
      required: false
    },
    variant: {
      type: String,
      default: 'primary',
      required: false
    }
  }
};
</script>
