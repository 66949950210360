<template>
  <main class="bg-gray-100 py-16 relative" v-scroll="scrollHandler">
    <div class="h-1 w-full fixed z-50 -mt-16">
      <div
        class="bg-orange h-full transform origin-left"
        :style="{
          transform: `scaleX(${scrollProgress})`
        }"
      ></div>
    </div>
    <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
      <div
        class="
          flex flex-col
          justify-center
          items-center
          animation-page-scale-in animation-once animation-fill-backwards animation-300
        "
      >
        <div
          v-if="status === 'open'"
          class="
            flex flex-col
            items-center
            justify-center
            text-center
            max-w-2xl
            mb-16
            sm:mb-32
            text-theme-450 text-lightblue-text
          "
        >
          <IconCollaborationOpen class="mb-8" />
          <span class="text-400 font-bold text-darkblue mb-4 px-4 sm:px-0">{{
            $t('Radius.CollaborationAssessment.Questions.Title') | format(currentYear)
          }}</span>
          <span class="text-200 font-normal px-4 sm:px-0">{{
            $t('Radius.CollaborationAssessment.Questions.Description')
          }}</span>
          <ButtonElement
            v-if="questionValidation && questionValidation.status"
            class="mt-8"
            variant="primary"
            @click.native="nextStage(-1)"
            >{{ $t('Radius.CollaborationAssessment.Questions.Action.Start') }}</ButtonElement
          >
          <div
            v-if="!questionValidation || !questionValidation.status"
            class="
              flex flex-row
              items-center
              justify-center
              bg-orange
              rounded-xl
              py-2
              px-4
              mt-8
              space-x-2
              text-200
              select-none
            "
          >
            <img class="w-8 h-8" src="@/assets/loading.gif" alt="Loading" />
            <span class="font-semibold text-white">
              {{ $t('General.Loading') }}
            </span>
          </div>
          <ReadMoreSpan
            v-if="questionnaire"
            class="mt-8 text-200 font-normal"
            :text="questionnaire.description"
            :maxLength="9000"
            :markdown="true"
          />
        </div>

        <div
          v-if="status === 'closed'"
          class="
            flex flex-col
            items-center
            justify-center
            text-center
            max-w-2xl
            mb-16
            sm:mb-32
            text-theme-450 text-lightblue-text
          "
        >
          <IconCollaborationClosed class="mb-8" />
          <span class="text-400 font-bold text-darkblue mb-4">{{
            $t('Radius.CollaborationAssessment.Questions.Title.Closed')
          }}</span>
          <span class="text-200 font-normal">{{
            $t('Radius.CollaborationAssessment.Questions.Description.Closed')
          }}</span>
          <ButtonElement class="mt-8" variant="secondary-transparent" @click.native="navigateWebsite">{{
            $t('Radius.CollaborationAssessment.Questions.Action.GoToWebsite')
          }}</ButtonElement>
        </div>

        <div
          v-if="status === 'finished'"
          class="
            flex flex-col
            items-center
            justify-center
            text-center
            max-w-2xl
            mb-16
            sm:mb-32
            text-theme-450 text-lightblue-text
          "
        >
          <IconCollaborationFinished class="mb-8" />
          <span class="text-400 font-bold text-darkblue mb-4">{{
            $t('Radius.CollaborationAssessment.Questions.Title.Finished')
          }}</span>
          <span class="text-200 font-normal">{{
            $t('Radius.CollaborationAssessment.Questions.Description.Finished')
          }}</span>
          <ButtonElement class="mt-8" variant="secondary-transparent" @click.native="navigateWebsite">{{
            $t('Radius.CollaborationAssessment.Questions.Action.GoToWebsite')
          }}</ButtonElement>
        </div>

        <div
          v-for="(section, sectionIndex) in sections"
          :key="sectionIndex"
          :ref="`section-${sectionIndex}`"
          class="
            flex flex-col
            items-center
            justify-center
            bg-white
            w-full
            flex-grow
            p-4
            sm:p-14
            border border-b-4 border-gray-200
            rounded-md
            text-theme-200
            mb-16
            select-none
          "
          :class="{
            'opacity-50 pointer-events-none': stage < sectionIndex + 1
          }"
        >
          <div class="flex space-x-0 sm:space-x-8 w-full">
            <div
              class="
                bg-orange
                text-white text-200
                font-bold
                rounded-md
                w-8
                h-8
                flex
                items-center
                justify-center
                flex-shrink-0
                absolute
                sm:relative
              "
            >
              <span>{{ sectionIndex + 1 }}</span>
            </div>
            <div class="flex flex-col flex-grow w-full pr-0 sm:pr-16">
              <div class="flex flex-col flex-grow w-full pl-14 sm:pl-0">
                <span class="text-darkblue font-bold text-200 sm:text-400">{{ section.title }}</span>
                <span class="text-lightblue-text text-100 sm:text-200">{{ section.description }}</span>
              </div>
              <div class="mt-8 flex flex-col w-full space-y-8">
                <div
                  v-for="(ratingQuestion, ratingQuestionIndex) in section.ratingquestions"
                  :key="ratingQuestionIndex"
                  class="flex flex-col"
                >
                  <span
                    v-if="ratingQuestion.title"
                    class="font-semibold text-200 text-darkblue"
                    :class="{
                      'mb-2': !ratingQuestion.description
                    }"
                    >{{ ratingQuestion.title }}</span
                  >
                  <ReadMoreSpan
                    v-if="ratingQuestion.description"
                    class="font-normal text-100 sm:text-200 text-lightblue-text mb-2"
                    :text="ratingQuestion.description"
                    :maxLength="9000"
                    :markdown="true"
                  />
                  <div
                    class="flex flex-wrap flex-row justify-between items-center w-full"
                    v-if="ratingQuestion.layout === 'twocolumns'"
                  >
                    <div
                      v-for="i in 7"
                      :key="i"
                      class="
                        cursor-pointer
                        p-3
                        flex flex-row
                        justify-start
                        items-center
                        w-full
                        mb-2
                        hover:bg-darkblue
                        group
                        border border-lightblue
                        rounded-md
                        h-16
                        text-100
                        sm:text-200
                      "
                      :class="{
                        'sm:flex-2': i < 7,
                        'mt-6': i >= 7,
                        'bg-darkblue text-white': formData[`${section.id}_${ratingQuestion.id}__rating`] === i,
                        'bg-textfield-disabled text-darkblue':
                          formData[`${section.id}_${ratingQuestion.id}__rating`] !== i
                      }"
                      @click="setRating(`${section.id}_${ratingQuestion.id}__rating`, i)"
                    >
                      <div
                        class="
                          flex
                          justify-center
                          items-center
                          rounded-full
                          w-5
                          h-5
                          bg-white
                          border border-lightblue
                          text-darkblue
                        "
                      >
                        <IconCheck v-if="formData[`${section.id}_${ratingQuestion.id}__rating`] === i" />
                      </div>
                      <span class="ml-3 group-hover:text-white">{{
                        ratingQuestion[`name_rating_${i}`]
                          | empty($t('Radius.CollaborationAssessment.Questions.NoChoice'))
                      }}</span>
                    </div>
                  </div>

                  <div v-if="ratingQuestion.layout === 'fullwidth'" class="flex flex-col">
                    <div
                      class="
                        flex flex-col
                        sm:flex-row
                        justify-between
                        items-end
                        text-100
                        border border-lightblue
                        rounded-md
                        divide-x-0 divide-y
                        sm:divide-y-0 sm:divide-x
                        divide-lightblue
                        overflow-hidden
                        bg-textfield-disabled
                      "
                    >
                      <div
                        class="
                          cursor-pointer
                          p-3
                          flex flex-row
                          sm:flex-col
                          justify-start
                          sm:justify-end
                          items-start
                          sm:items-center
                          h-12
                          sm:h-21
                          w-32
                          text-center
                          w-full
                          group
                        "
                        v-for="i in 7"
                        :key="i"
                        :class="{
                          'bg-darkblue text-white': formData[`${section.id}_${ratingQuestion.id}__rating`] === i,
                          'bg-textfield-disabled hover:bg-darkblue text-darkblue':
                            formData[`${section.id}_${ratingQuestion.id}__rating`] !== i
                        }"
                        @click="setRating(`${section.id}_${ratingQuestion.id}__rating`, i)"
                      >
                        <span class="group-hover:text-white mb-2">{{
                          ratingQuestion[`name_rating_${i}`]
                            | empty($t('Radius.CollaborationAssessment.Questions.NoChoiceShorter'))
                        }}</span>
                        <div class="flex-grow h-full"></div>
                        <div
                          class="
                            flex flex-shrink-0
                            justify-center
                            items-center
                            rounded-full
                            w-5
                            h-5
                            bg-white
                            border border-lightblue
                            text-darkblue
                          "
                        >
                          <IconCheck v-if="formData[`${section.id}_${ratingQuestion.id}__rating`] === i" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-for="(openQuestion, openQuestionIndex) in section.openquestions" :key="openQuestionIndex">
                  <div v-for="(field, fieldIndex) in openQuestion.fields" :key="fieldIndex">
                    <div
                      class="
                        flex flex-col
                        sm:flex-row
                        justify-between
                        items-start
                        sm:items-center
                        w-full
                        space-x-0 space-y-2
                        sm:space-x-8 sm:space-y-0
                        mb-4
                        sm:mb-0
                      "
                    >
                      <span class="text-darkblue text-100 sm:text-200 flex-shrink-0 w-full sm:w-32">{{
                        field.name
                      }}</span>
                      <InputMessage
                        class="w-full"
                        :placeholder="$t('Radius.CollaborationAssessment.Questions.AddComment.Placeholder')"
                        :multiline="true"
                        :hideSendButton="true"
                        v-model="formData[`${section.id}_${openQuestion.id}_${field.id}`]"
                      />
                    </div>
                  </div>
                </div>

                <div v-if="section.has_comment" class="flex flex-col justify-start items-start w-full mt-16 mb-2">
                  <span class="font-bold text-darkblue text-200">{{
                    $t('Radius.CollaborationAssessment.Questions.AddComment')
                  }}</span>
                  <InputMessage
                    class="w-full"
                    :placeholder="$t('Radius.CollaborationAssessment.Questions.AddComment.Placeholder')"
                    :multiline="true"
                    :hideSendButton="true"
                    v-model="formData[`${section.id}_comment`]"
                  />
                </div>

                <div class="flex flex-row justify-between items-center w-full">
                  <ButtonElement
                    v-if="sectionIndex > 0"
                    class="mt-0 sm:mt-8"
                    variant="secondary-small"
                    @click.native="previousStage(sectionIndex)"
                    >{{ $t('Radius.CollaborationAssessment.Questions.Action.Previous') }}</ButtonElement
                  >
                  <div v-if="sectionIndex === 0" class="flex-grow w-full"></div>
                  <ButtonElement class="mt-0 sm:mt-8" variant="primary-small" @click.native="nextStage(sectionIndex)">{{
                    $t('Radius.CollaborationAssessment.Questions.Action.Next')
                  }}</ButtonElement>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="sections && sections.length > 0"
          class="
            flex flex-col
            items-center
            justify-center
            bg-white
            w-full
            flex-grow
            p-4
            sm:p-14
            border border-b-4 border-gray-200
            rounded-md
            text-theme-200
            mb-16
            select-none
          "
          :ref="`section-${sections.length}`"
          :class="{
            'opacity-50 pointer-events-none': stage < sections.length + 1
          }"
        >
          <div class="flex flex-col items-center justify-center text-center max-w-2xl">
            <span class="text-darkblue font-bold text-400 mb-2">{{
              $t('Radius.CollaborationAssessment.Questions.Finish.Title')
            }}</span>
            <span class="text-lightblue-text text-200">{{
              $t('Radius.CollaborationAssessment.Questions.Finish.Body')
            }}</span>
            <ButtonElement class="my-8" variant="primary-small" @click.native="confirm">{{
              $t('Radius.CollaborationAssessment.Questions.Action.Send')
            }}</ButtonElement>
            <span class="text-darkblue text-100 sm:text-200">{{
              $t('Radius.CollaborationAssessment.Questions.Finish.Disclaimer')
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { GraphQLQuery, GraphQLMutation } from '@/graphql';
import ReadMoreSpan from '@/components/ReadMoreSpan';
import ButtonElement from '@/components/ButtonElement';
import InputMessage from '@/components/InputMessage';

export default {
  name: 'CollaborationAssessmentQuestions',
  components: {
    ButtonElement,
    ReadMoreSpan,
    InputMessage
  },
  apollo: {
    questionnaires: {
      query: GraphQLQuery.RadiusCollaborationQuestionSections
    },
    questionValidation: {
      query: GraphQLQuery.RadiusCollaborationQuestionValidation,
      variables() {
        return {
          collaborationID: this.collaborationID
        };
      },
      result() {
        if (!this.questionValidation) {
          return;
        }

        if (this.questionValidation.status === false) {
          this.status = 'closed';
        }

        if (this.questionValidation.name.length > 0) {
          this.$store.commit('navigationBarNotice', {
            header: 'Uitgenodigd door',
            body: this.questionValidation.name
          });
        } else {
          this.$store.commit('navigationBarNotice', null);
        }
      }
    }
  },
  data() {
    return {
      status: 'open',
      scrollProgress: 0,
      stage: 0,
      formData: {}
    };
  },
  computed: {
    collaborationID() {
      return this.$route.params.collaborationID;
    },
    questionnaire() {
      if (!this.questionnaires || this.questionnaires.length === 0) {
        return null;
      }

      return this.questionnaires[0];
    },
    currentYear() {
      return this.$filters.date(new Date(), 'YYYY');
      /* if (!this.questionnaire) {
        return this.$filters.date(new Date(), 'YYYY');
      }

      return this.$filters.date(this.questionnaire.created_at, 'YYYY'); */
    },
    sections() {
      if (!this.questionValidation || !this.questionValidation.status || this.status !== 'open') {
        return [];
      }

      if (!this.questionnaire) {
        return [];
      }

      return this.questionnaire.SWOsection;
    }
  },
  beforeDestroy() {
    this.$store.commit('navigationBarNotice', null);
  },
  methods: {
    nextStage(index) {
      if (index + 1 >= this.stage && this.stage <= this.sections.length) {
        this.stage += 1;
      }

      let element = this.$refs[`section-${index + 1}`];
      if (element && Array.isArray(element) && element.length > 0) {
        [element] = element;
      }

      if (!element) {
        return;
      }
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },

    previousStage(index) {
      if (index <= 0) {
        return;
      }

      setTimeout(() => {
        const [element] = this.$refs[`section-${index - 1}`];
        if (!element) {
          return;
        }
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 1);
    },

    navigateWebsite() {
      window.location = 'https://www.gezinshuis.com';
    },

    setRating(key, index) {
      this.formData[key] = index;
      this.$forceUpdate();
    },

    scrollHandler() {
      const viewportHeight = window.innerHeight;
      const height = (document.height !== undefined ? document.height : document.body.offsetHeight) - viewportHeight;
      const scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName('html')[0].scrollTop;
      this.scrollProgress = scrollPos / height;
    },

    async confirm() {
      const keys = Object.keys(this.formData);

      const data = [];
      for (let i = 0; i < keys.length; i += 1) {
        let key = keys[i];
        let value = this.formData[key];

        if (key.indexOf('__rating') > -1) {
          key = key.replace('__rating', '');
          if (value === 7) {
            value = 0;
          }
        }

        data.push({
          key,
          value: String(value)
        });
      }

      try {
        await this.$apollo.mutate({
          mutation: GraphQLMutation.RadiusCollaborationQuestions,
          variables: {
            collaborationID: this.collaborationID,
            data
          },
          update: () => {
            this.status = 'finished';
          }
        });
      } catch (error) {
        if (error.graphQLErrors && error.graphQLErrors[0].message.indexOf('.notFound') > -1) {
          this.$modal.show('ErrorModal', {
            title: this.$t('Radius.CollaborationAssessment.Questions.Modal.EmptyFields.Title'),
            error: this.$t('Radius.CollaborationAssessment.Questions.Modal.EmptyFields.Description')
          });
        }
      }
    }
  }
};
</script>
