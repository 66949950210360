<template>
  <div
    class="h-12 cursor-pointer font-medium truncate transition-all duration-200 rounded-md flex items-center px-4 text-100 border"
    :class="{
      'bg-gray-100 border-transparent hover:bg-gray-200 text-gray-800': !disabled,
      'bg-white border-gray-100 cursor-not-allowed text-gray-400': disabled
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
