var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white w-full p-6 lg:p-10 mb-6 border border-b-4 border-gray-200 rounded-md text-black"},[_c('header',{staticClass:"flex items-center gap-4 text-300 font-semibold text-black",class:{
      'mb-8': !_vm.paragraph.description,
      'mb-4': _vm.paragraph.description
    }},[_c('div',{staticClass:"rounded bg-orange w-8 text-200 h-8 flex items-center justify-center text-white"},[_c('span',[_vm._v(_vm._s(_vm.index + 1))])]),_c('div',[_vm._v(_vm._s(_vm.paragraph.name))])]),(_vm.paragraph.description)?_c('div',{staticClass:"text-100 text-gray-500 mb-4"},[_vm._v(_vm._s(_vm.paragraph.description))]):_vm._e(),_c('section',{staticClass:"grid gap-6",class:{
      'grid-cols-2': _vm.paragraph.type === 'two-column'
    }},_vm._l((_vm.paragraph.fields),function(field,i){return _c('div',{key:i,staticClass:"w-full"},[(field.type !== 'children')?_c('label',{staticClass:"font-medium block text-100 mb-1",class:{
          'text-red-700': _vm.errorField && /*errorField.name === field.name*/ !_vm.values[field.name]?.length
        },domProps:{"innerHTML":_vm._s(field.name.replace(/\n/gim, '<br />'))}}):_vm._e(),(field.type === 'text')?_c('InputVerify',{staticClass:"w-full",model:{value:(_vm.values[field.name]),callback:function ($$v) {_vm.$set(_vm.values, field.name, $$v)},expression:"values[field.name]"}}):_vm._e(),(field.type === 'textarea')?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.values[field.name]),expression:"values[field.name]"}],staticClass:"appearance-none resize-none block bg-transparent h-36 w-full p-6 leading-tight rounded placeholder-lightblue-text text-100 font-normal text-darkblue focus:outline-none focus:z-10 border",domProps:{"value":(_vm.values[field.name])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.values, field.name, $event.target.value)}}}):_vm._e(),(field.type === 'date')?_c('div',{staticClass:"w-full"},[_c('InputVerify',{staticClass:"w-full",nativeOn:{"click":function($event){return _vm.showDatePicker(field)}},model:{value:(_vm.values[field.name]),callback:function ($$v) {_vm.$set(_vm.values, field.name, $$v)},expression:"values[field.name]"}}),_c('DateSelector',{attrs:{"visible":Boolean(_vm.datePickerField)},on:{"change":_vm.pickedDateChanged},model:{value:(_vm.pickedDate),callback:function ($$v) {_vm.pickedDate=$$v},expression:"pickedDate"}})],1):_vm._e(),(field.type === 'children')?_c('ProgressReportSectionFormChildrenSelector',{ref:"childrenElement",refInFor:true,attrs:{"value":_vm.values[field.name]},on:{"update":function($event){return _vm.updateValueForChildren(field, $event)}}}):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }