var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ProgressReportSection',_vm._b({scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._t("header")]},proxy:true},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true}],null,true)},'ProgressReportSection',_vm.$props,false),[(_vm.currentHome)?_c('div',{staticClass:"mt-8"},_vm._l((_vm.currentHome.professionalization),function(info,index){return _c('div',{key:index},[_c('div',{staticClass:"grid grid-cols-2 text-100"},[_c('span',{staticClass:"col-span-2 font-semibold"},[_vm._v(_vm._s(_vm._f("empty")(info.user.username,'-'))+" ("+_vm._s(_vm._f("empty")(info.user.email,'-'))+")")]),_c('span',[_vm._v(_vm._s(_vm.$t('Professionalization.Code')))]),_c('span',[_vm._v(_vm._s(_vm.$t(`Professionalization.Types.${info.type}`))+": "+_vm._s(info.code))]),_c('span',[_vm._v(_vm._s(_vm.$t('Professionalization.ExpirationDate')))]),_c('span',[_vm._v(_vm._s(_vm._f("empty")(info.expiration_date,'-')))])]),_vm._l((info.related_documents),function(doc,documentIndex){return _c('div',{key:documentIndex,staticClass:"w-full px-6 py-4 flex items-center justify-between bg-white text-darkblue rounded-md border border-gray-200 mt-4 mb-1",class:{
          [`animation-list-scale-in animation-once animation-fill-backwards animation-300 animation-delay-${documentIndex}`]: true
        }},[_c('button',{staticClass:"flex text-left items-center focus:outline-none cursor-pointer flex-1",on:{"click":function($event){return _vm.$helper.showDocumentDetailsModal(doc, false)}}},[_c('IconFile',{staticClass:"flex-shrink-0",attrs:{"document":doc}}),_c('div',{staticClass:"min-w-2xl ml-8"},[_c('span',{staticClass:"block text-theme-150 font-semibold"},[_vm._v(_vm._s(_vm.$filters.documentname(doc)))]),_c('span',{staticClass:"block text-theme-100"},[_c('span',[_vm._v("Geupload op "+_vm._s(_vm._f("date")(doc.created_at)))]),(doc.expiration_date)?_c('span',{class:{
                  'text-red-500 font-semibold': _vm.$filters.monthsremaining(doc.expiration_date) <= 0,
                  'text-yellow-400 font-semibold':
                    _vm.$filters.monthsremaining(doc.expiration_date) > 0 &&
                    _vm.$filters.monthsremaining(doc.expiration_date) <= 2
                }},[_vm._v(" - Verloopt op "+_vm._s(_vm._f("date")(doc.expiration_date)))]):_vm._e()])])],1),_c('div',{staticClass:"flex text-lightblue-hover"},[_c('button',{staticClass:"focus:outline-none w-8 flex-shrink-0 flex justify-center items-center group",on:{"click":function($event){return _vm.$helper.downloadDocument(doc)}}},[_c('IconDownload',{staticClass:"flex-shrink-0 group-hover:text-darkblue"}),_c('span',{staticClass:"absolute py-1 px-2 text-50 bg-darkblue text-white rounded-md transform translate-y-7 opacity-0 invisible group-hover:visible group-hover:opacity-100 transition ease-in-out duration-150"},[_vm._v(" "+_vm._s(_vm.$t('Radius.QualityAssessment.Action.DownloadDocument'))+" ")])],1)])])})],2)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }