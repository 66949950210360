<template>
  <main class="bg-gray-100 py-16" v-scroll="clientScrolled">
    <div class="max-w-7xl mx-auto pb-12 px-4 md:px-6 lg:px-8">
      <div class="flex items-center w-full mb-8">
        <RadiusBreadcrumbs :title="$t('ProgressReport.Title')" />
      </div>
      <div
        class="flex flex-col md:flex-row animation-page-scale-in animation-once animation-fill-backwards animation-300"
      >
        <div class="block md:sticky self-start top-0 mt-0 md:-mt-8">
          <ParagraphChecklist
            v-if="paragraphs"
            class="mr-0 md:mr-16 mt-0 md:mt-20 mb-8 md:mb-0"
            :paragraphs="paragraphs"
            :activeParagraph="activeParagraphIndex"
            :userDataParagraphs="userDataParagraphs"
            @changeActiveParagraph="changeActiveParagraph"
          />
        </div>
        <div class="flex flex-col w-full">
          <component
            v-for="(paragraph, index) in paragraphs"
            :key="index"
            :ref="`section-${index}`"
            v-bind:is="sectionForParagraph(paragraph)"
            :homeID="homeID"
            :paragraph="paragraph"
            :paragraphUserData="paragraphUserData(paragraph)"
            @refreshUserData="refreshUserData"
            :class="{
              [`animation-list-scale-in animation-once animation-fill-backwards animation-300 animation-delay-${index}`]: true
            }"
          ></component>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { GraphQLQuery } from '@/graphql';
import RadiusBreadcrumbs from '@/components/radius/RadiusBreadcrumbs';
import ParagraphChecklist from '@/components/ParagraphChecklist';
import ProgressReportSection from '@/views/ProgressReport/Sections/ProgressReportSection';
import ProgressReportSectionFileUpload from '@/views/ProgressReport/Sections/ProgressReportSectionFileUpload';
import ProgressReportSectionBaseQualification from '@/views/ProgressReport/Sections/ProgressReportSectionBaseQualification';
import ProgressReportSectionQualityAssessment from '@/views/ProgressReport/Sections/ProgressReportSectionQualityAssessment';
import ProgressReportSectionCollaborationAssessment from '@/views/ProgressReport/Sections/ProgressReportSectionCollaborationAssessment';
import ProgressReportSectionProfessionalization from '@/views/ProgressReport/Sections/ProgressReportSectionProfessionalization';
import ProgressReportSectionActivityList from '@/views/ProgressReport/Sections/ProgressReportSectionActivityList';
import ProgressReportSectionForm from '@/views/ProgressReport/Sections/ProgressReportSectionForm';
import ProgressBar from '@/components/ProgressBar';
import ButtonElement from '@/components/ButtonElement';

export default {
  name: 'ProgressReport',
  components: {
    RadiusBreadcrumbs,
    ParagraphChecklist,
    ProgressReportSection,
    ProgressReportSectionFileUpload,
    ProgressReportSectionBaseQualification,
    ProgressReportSectionQualityAssessment,
    ProgressReportSectionCollaborationAssessment,
    ProgressReportSectionProfessionalization,
    ProgressReportSectionActivityList,
    ProgressReportSectionForm,
    ProgressBar,
    ButtonElement
  },
  apollo: {
    userDataParagraphs: {
      query: GraphQLQuery.ProgressReportUserData,
      variables() {
        return {
          homeID: this.homeID
        };
      }
    },
    paragraphs: {
      query: GraphQLQuery.ProgressReport,
      result() {
        this.$nextTick(() => {
          this.changeActiveParagraph((this.$route.hash || '').replace('#', ''));
        });
      }
    }
  },
  computed: {
    homeID() {
      if (this.$route.params.homeID) {
        return Number(this.$route.params.homeID);
      }

      return this.$store.state.currentManagedHome ? Number(this.$store.state.currentManagedHome.id) : undefined;
    }
  },
  data() {
    return {
      activeParagraphIndex: 0
    };
  },
  mounted() {
    this.clientScrolled();
  },
  methods: {
    sectionForParagraph(paragraph) {
      const type = paragraph?.block_type?.toLowerCase() || 'default';
      switch (type) {
        case 'activity_list':
          return 'ProgressReportSectionActivityList';

        case 'quality_assessment_progress':
          return 'ProgressReportSectionQualityAssessment';

        case 'base_qualification':
          return 'ProgressReportSectionBaseQualification';

        case 'collaboration_assessment':
          return 'ProgressReportSectionCollaborationAssessment';

        case 'professionalization':
          return 'ProgressReportSectionProfessionalization';

        case 'file_upload_admin_only':
        case 'file_upload':
          return 'ProgressReportSectionFileUpload';

        case 'text':
        case 'default':
        default:
          return 'ProgressReportSection';
      }
    },

    paragraphUserData(paragraph) {
      if (!this.userDataParagraphs) {
        return null;
      }

      return this.userDataParagraphs.find(userDataParagraph => {
        return userDataParagraph.progress_report.id === paragraph.id;
      });
    },

    async refreshUserData() {
      this.$apollo.queries.userDataParagraphs.refresh();
    },

    changeActiveParagraph(index) {
      const element = this.$refs[`section-${index}`]?.[0]?.$el;
      if (!element) {
        return;
      }
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },

    clientScrolled() {
      if (!this.paragraphs || this.paragraphs.length === 0) {
        return;
      }

      const height = Math.max(document.documentElement.scrollHeight, document.documentElement.clientHeight);

      const isAtBottom = document.documentElement.scrollTop > height - document.documentElement.clientHeight - 20;
      if (isAtBottom) {
        this.activeParagraphIndex = this.paragraphs.length - 1;
        return;
      }

      this.activeParagraphIndex = Math.min(
        this.paragraphs.length - 1,
        Math.max(0, Math.round((document.documentElement.scrollTop * this.paragraphs.length) / height))
      );
    }
  }
};
</script>
