<template>
  <main class="bg-gray-100 py-16">
    <div class="max-w-5xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col sm:flex-row justify-between items-center w-full mb-8">
        <div class="lg:w-1/3 flex items-center">
          <button
            class="border bg-white border-lightblue hover:border-lightblue-hover transition ease-in-out duration-150 text-darkblue w-10 h-10 mr-4 rounded-full justify-center items-center flex flex-shrink-0 focus:outline-none"
            @click="$router.push({ name: 'Dashboard' })"
          >
            <IconChevronLeft />
          </button>
          <h1 class="text-theme-500 flex-grow">{{ $t('News.Title') }}</h1>
        </div>
        <div class="lg:w-2/3 flex items-center mt-4 sm:mt-0">
          <InputSearch
            class="flex-grow mr-2"
            :placeholder="$t('News.Search.Placeholder')"
            variant="medium"
            @search="performSearch"
          ></InputSearch>

          <div v-if="newsItemTags && newsItemTags.length" class="relative w-full" style="max-width: 378px">
            <select
              @input="filterNewsTags($event.target.value)"
              class="rounded-md w-full h-12 border border-gray-200 shadow-sm pl-4 pr-10 text-100 appearance-none cursor-pointer focus:outline-none"
            >
              <option value="">Alle Rubrieken</option>
              <option v-for="(tag, index) in newsItemTags" :key="index" :value="tag.name">{{ tag.name }}</option>
            </select>
            <IconChevronRight class="absolute right-4 top-5 rotate-90 transform pointer-events-none" />
          </div>
        </div>
      </div>
      <div v-if="$apollo.loading" class="flex flex-col items-center justify-center w-full h-full pt-8 mb-8">
        <img class="w-8 h-8 mb-4" src="@/assets/loading-dark.gif" alt="Loading" />
        <span class="text-150 font-semibold text-darkblue">
          {{ $t('General.Loading') }}
        </span>
      </div>
      <div v-else class="w-full">
        <div
          class="w-full p-4 flex items-center justify-between cursor-pointer relative"
          v-for="(article, index) in news"
          :key="index"
          :class="{
            'bg-white border border-gray-200': index % 2 == 0,
            [`animation-list-scale-in animation-once animation-fill-backwards animation-300 animation-delay-${index}`]: true
          }"
        >
          <button
            class="flex text-left items-center focus:outline-none cursor-pointer flex-1"
            @click="displayNewsArticle(article)"
          >
            <div class="ml-8 flex justify-center items-center">
              <div class="relative flex-shrink-0 w-14 h-14 rounded-md overflow-hidden">
                <img
                  class="object-cover absolute inset-0 w-full h-full"
                  v-if="article.image && article.image[0]"
                  :src="article.image[0].url"
                  alt=""
                />
                <div v-else class="w-full h-full bg-gray-200"></div>
              </div>

              <div class="ml-4 text-theme-100">
                <div class="flex items-center gap-x-2 mb-1">
                  <IconPin v-if="article.pinned" class="w-3 text-darkblue" />
                  <span class="text-darkblue font-bold whitespace-pre-wrap">{{ article.title }}</span>
                </div>
                <div v-if="article.news_item_tags.length" class="flex items-center">
                  <div
                    v-for="(tag, index) in article.news_item_tags"
                    :key="index"
                    class="px-2 py-1 cursor-pointer rounded-sm text-25 font-bold text-darkblue bg-line uppercase flex items-center mr-1"
                  >
                    {{ tag.name }}
                  </div>
                </div>
              </div>

              <!-- <div class="ml-4 truncate text-theme-100 flex gap-x-2">
                <IconPin v-if="article.pinned" class="w-3 text-darkblue" />
                <span class="text-darkblue font-bold whitespace-pre-wrap">{{ article.title }}</span>
              </div> -->

              <!-- <div v-if="article.news_item_tags.length" class="">
                <span
                  v-for="(tag, index) in article.news_item_tags"
                  :key="index"
                  class="px-2 py-1 cursor-pointer rounded-sm text-25 font-bold text-darkblue bg-line uppercase flex items-center mr-1"
                >
                  {{ tag.name }}</span
                >
              </div> -->
            </div>
          </button>
          <div class="flex justify-between items-center ml-4">
            <div class="flex-col flex-shrink-0">
              <span class="hidden sm:block text-theme-100">
                <span class="ml-2">{{ article.created_at | date }}</span>
              </span>
            </div>
          </div>
        </div>
        <div
          v-if="!news || news.length === 0"
          class="bg-white w-full border border-b-4 border-gray-200 rounded-md overflow-hidden text-theme-200 animation-page-scale-in animation-once animation-fill-backwards animation-300 p-8 flex flex-col justify-center items-center"
        >
          <div class="flex items-center justify-center rounded-full border-2 border-line w-18 h-18">
            <IconFileNotAvailable />
          </div>
          <span class="text-center w-full mt-4 text-100">{{ $t('Dashboard.Widget.News.BodyUnavailable') }}</span>
        </div>
      </div>
      <Pagination :current="page.current + 1" :total="page.total / page.limit" @change="pageChanged"></Pagination>
    </div>
  </main>
</template>

<script>
import { GraphQLQuery } from '@/graphql';
import Pagination from '@/components/Pagination';
import InputSearch from '@/components/InputSearch';

export default {
  name: 'News',
  components: {
    Pagination,
    InputSearch
  },
  apollo: {
    news: {
      query: GraphQLQuery.News,
      variables() {
        return {
          query: this.buildSearchQuery,
          limit: this.page.limit,
          start: this.page.current * this.page.limit
        };
      },
      update(data) {
        this.page.total = data.count.length;
        return data.news.slice(0, this.page.limit);
      }
    },
    newsItemTags: {
      query: GraphQLQuery.newsItemTags
    }
  },
  data() {
    return {
      searchQuery: '',
      selectedTag: '',
      page: {
        limit: 10,
        current: 0,
        total: 0
      }
    };
  },
  computed: {
    buildSearchQuery() {
      const query = {
        _and: [{ _or: [{ title_contains: this.searchQuery }, { text_contains: this.searchQuery }] }]
      };

      if (this.selectedTag !== '') {
        query._and.push({
          news_item_tags: {
            name_eq: this.selectedTag
          }
        });
      }

      return query;
    }
  },
  methods: {
    displayNewsArticle(article) {
      this.$router.push({
        name: 'NewsDetail',
        params: {
          articleID: article.id
        }
      });
    },
    performSearch(query) {
      this.searchQuery = query;
    },

    filterNewsTags(tag) {
      if (tag !== '') {
        this.selectedTag = tag;
      } else {
        this.selectedTag = '';
      }
    },

    pageChanged(current) {
      this.page.current = current - 1;
    }
  }
};
</script>
