<template>
  <VueTagsInput v-model="tag" :placeholder="placeholder" @tags-changed="tagsChanged" :add-on-key="[188, 13, 32, 9]" />
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import '@/assets/styles/input-tags.css';

export default {
  name: 'InputTags',
  components: {
    VueTagsInput
  },
  props: {
    placeholder: {
      type: String,
      default: 'Tags toevoegen',
      required: false
    }
  },
  data() {
    return {
      tag: ''
    };
  },
  methods: {
    tagsChanged(tagInfo) {
      const tags = tagInfo.map(tag => {
        return tag.text;
      });
      this.$emit('changeTags', tags);
    }
  }
};
</script>
