<template>
  <div id="app" class="min-h-screen bg-white antialiased flex flex-col">
    <Navigation
      class="sticky top-0 flex-grow-0"
      v-if="showNavigationBar"
      :activeRouteName="$router.currentRoute.name"
      :activeRoutePath="$router.currentRoute.path"
    />
    <router-view class="flex-grow" />
    <Footer :minimal="!showNavigationBar" class="flex-grow-0" />
    <div
      v-if="$store.state.loading"
      class="fixed inset-0 bg-darkblue-opacity-80 min-h-screen w-full h-full min-w-screen z-30 animation-general-opacity-in animation-once animation-fill-backwards animation-300"
    >
      <div
        class="flex flex-col items-center justify-center w-full h-full animation-general-overscale-in animation-once animation-fill-backwards animation-500 animation-delay-2"
      >
        <img class="w-8 h-8 mb-4" src="@/assets/loading.gif" alt="Loading" />
        <span class="text-150 font-semibold text-white">
          {{ $t('General.Loading') }}
        </span>
      </div>
    </div>
  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800&display=swap');

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>

<script>
import Navigation from './components/Navigation';
import Footer from './components/Footer';

export default {
  name: 'App',
  components: {
    Navigation,
    Footer
  },
  data() {
    return {
      initialized: false
    };
  },
  computed: {
    showNavigationBar() {
      if (
        !this.initialized ||
        !this.$router.currentRoute ||
        !this.$router.currentRoute.name ||
        this.$router.currentRoute.length === 0
      ) {
        return false;
      }

      if (this.$store.state.currentUser) {
        return true;
      }

      const [activeRoute] = this.$router.options.routes.filter(route => {
        return this.$router.currentRoute.name === route.name;
      });

      if (activeRoute) {
        return activeRoute.forceNavigationBar === true;
      }

      return false;
    }
  },
  mounted() {
    this.$store.commit('loading', false);

    setTimeout(() => {
      this.initialized = true;
    }, 1);
  }
};
</script>
