<template>
  <BaseModal
    v-bind="$props"
    :title="$t('ManageUsers.Modal.Manage.ModalTitle')"
    :withBottomDivider="true"
    @backdropClicked="hide"
  >
    <template>
      <div class="flex flex-col w-full text-theme-100 mb-5">
        <div
          v-if="error.length > 0"
          class="rounded-md shadow-sm mb-8 p-4 text-error-balloon-text bg-error-balloon-background width-full text-center flex flex-col animation-error-scale-in animation-once animation-fill-both animation-500"
        >
          <span class="text-sm">{{ error }}</span>
        </div>
        <span class="text-100 text-darkblue font-semibold mb-2">{{ $t('ManageUsers.Modal.Manage.Email') }}</span>
        <InputVerify
          class="w-full max-w-account-textfield"
          required
          :disabled="user != null"
          :placeholder="$t('ManageUsers.Modal.Manage.Email.Placeholder')"
          v-model="email"
          :messageIconVisible="error.length > 0"
          messageVariant="error"
        />
        <ButtonElement
          v-if="user"
          @click.native="
            $modal.confirm(() => {
              remove();
            }, 'ConfirmDeleteManagedUser')
          "
          class="my-2 w-full max-w-account-textfield"
          variant="secondary-small"
          >{{ $t('ManageUsers.Modal.Manage.Action.Remove') }}</ButtonElement
        >

        <span
          class="font-semibold text-darkblue mt-4 mb-2"
          :class="{
            'text-error-balloon-text font-bold': !expiryDate && error
          }"
          >{{ $t('ManageUsers.Modal.Manage.ExpiryDate') }}</span
        >
        <div>
          <button
            @click="toggleDatePicker"
            class="focus:outline-none"
            :class="{
              'text-lightblue-link': !expiryDate,
              'hover:text-lightblue-link transition ease-in-out duration-150': expiryDate
            }"
          >
            {{ expiryDate | date | empty($t('ManageUsers.Modal.Manage.Action.AddExpirationDate')) }}
          </button>
          <button v-if="expiryDate" class="ml-2 focus:outline-none" @click="removeExpiryDate">
            <IconCross class="w-2 h-2 text-red-500" />
          </button>

          <DateSelector :visible="datePickerVisible" v-model="expiryDate" @change="hideDatePicker" />
        </div>
      </div>
    </template>

    <template v-slot:bottom>
      <div class="flex flex-col-reverse sm:flex-row w-full sm:w-auto justify-end p-6 pb-4 sm:pb-6">
        <ButtonElement class="my-2 sm:my-0 sm:mr-2" variant="secondary-small" @click.native="hide">{{
          $t('Modal.Generic.Cancel')
        }}</ButtonElement>
        <ButtonElement @click.native="confirm" variant="primary-small">{{
          user ? $t('ManageUsers.Modal.Manage.Action.Edit') : $t('ManageUsers.Modal.Manage.Action.Add')
        }}</ButtonElement>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal';
import { GraphQLQuery, GraphQLMutation } from '@/graphql';
import ButtonElement from '@/components/ButtonElement';
import InputVerify from '@/components/InputVerify';

export default {
  name: 'AddManagedUserModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal,
    InputVerify,
    ButtonElement
  },
  props: {
    user: {
      type: Object,
      required: false,
      default: null
    }
  },
  watch: {
    user: {
      handler(value) {
        if (!value || !value.email || value.email.length === 0) {
          return;
        }

        this.email = value.email.trim();
        this.expiryDate = value.expiration_date;
      },
      immediate: true
    }
  },
  data() {
    return {
      email: '',
      expiryDate: null,
      datePickerVisible: false,
      error: ''
    };
  },
  methods: {
    toggleDatePicker() {
      this.datePickerVisible = !this.datePickerVisible;

      if (!this.expiryDate) {
        this.expiryDate = new Date();
        this.expiryDate.setDate(this.expiryDate.getDate() + 14);
        this.datePickerVisible = false;
      }
    },

    hideDatePicker() {
      this.datePickerVisible = false;
    },

    removeExpiryDate() {
      this.expiryDate = null;
    },

    async confirm() {
      if (this.email.trim().length === 0 || !this.expiryDate) {
        this.error = this.$t('ManageUsers.Modal.Manage.Error.EmptyFields');
        return;
      }

      this.error = '';
      this.hide();

      let expirationDate = this.expiryDate;
      if (expirationDate) {
        expirationDate.setHours(expirationDate.getHours() + 5);
        [expirationDate] = expirationDate.toISOString().split('T');
      }

      try {
        await this.$apollo.mutate({
          mutation: GraphQLMutation.ExternalUsersCreate,
          variables: {
            email: this.email.trim(),
            expirationDate
          },
          update: (queryCacheStore, { data: { upsertExternalUser: user } }) => {
            const queryInfo = {
              query: GraphQLQuery.ExternalUsers
            };

            let data = queryCacheStore.readQuery(queryInfo) || [];
            if (!data || !data.getExternalUsers) {
              data = {
                getExternalUsers: []
              };
            }

            const exists = data.getExternalUsers?.find(u => u.email.trim() === user.email.trim());
            if (!exists) {
              data.getExternalUsers.push({ ...user, description: 'Externe auditor' });
            } else {
              data.getExternalUsers = data.getExternalUsers.map(u => {
                if (u.email === user.email) {
                  return { ...u, expiration_date: user.expiration_date };
                }

                return u;
              });
            }
            queryCacheStore.writeQuery({
              ...queryInfo,
              data
            });

            this.$emit('created');
          }
        });
      } catch (error) {
        if (error.graphQLErrors && error.graphQLErrors[0].message === 'email.inUse') {
          this.$modal.show('ErrorModal', {
            title: this.$t('ManageUsers.Modal.Manage.ModalTitle'),
            error: this.$t('ManageUsers.Modal.Manage.EmailInUse.Body')
          });
        }
      }
    },

    async remove() {
      this.error = '';
      this.hide();

      await this.$apollo.mutate({
        mutation: GraphQLMutation.ExternalUsersDelete,
        variables: {
          email: this.email
        },
        update: queryCacheStore => {
          const queryInfo = {
            query: GraphQLQuery.ExternalUsers
          };

          let data = queryCacheStore.readQuery(queryInfo) || [];
          if (!data || !data.getExternalUsers) {
            data = {
              getExternalUsers: []
            };
          }

          data.getExternalUsers = data.getExternalUsers.filter(user => {
            return user.email !== this.email;
          });

          queryCacheStore.writeQuery({
            ...queryInfo,
            data
          });

          this.$emit('deleted');
        }
      });
    }
  }
};
</script>
