<template>
  <BaseModal
    v-bind="$props"
    :title="$t('ActivityList.Modal.Create.ModalTitle')"
    :withBottomDivider="true"
    @backdropClicked="hide"
  >
    <template>
      <div class="flex flex-col w-full text-theme-100">
        <div
          v-if="error.length > 0"
          class="rounded-md shadow-sm mb-8 p-4 text-error-balloon-text bg-error-balloon-background width-full text-center flex flex-col animation-error-scale-in animation-once animation-fill-both animation-500"
        >
          <span class="text-sm">{{ error }}</span>
        </div>
        <span class="text-100 text-darkblue font-semibold mb-2">{{ $t('ActivityList.Modal.Create.Title') }}</span>
        <InputMessage
          :placeholder="$t('ActivityList.Modal.Create.Title.Placeholder')"
          :multiline="true"
          :hideSendButton="true"
          required
          v-model="activity.title"
        />
        <span class="text-100 text-darkblue font-semibold mt-4 mb-2">{{ $t('ActivityList.Modal.Create.Owner') }}</span>
        <InputVerify :placeholder="$t('ActivityList.Modal.Create.Owner.Placeholder')" v-model="activity.owner" />
        <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center my-4">
          <span class="font-semibold text-darkblue">{{ $t('ActivityList.Modal.Create.ExpiryDate') }}</span>
          <div class="relative">
            <button
              @click="toggleDatePicker"
              class="focus:outline-none"
              :class="{
                'text-lightblue-link': !activity.expiryDate,
                'hover:text-lightblue-link transition ease-in-out duration-150': activity.expiryDate
              }"
            >
              {{ activity.expiryDate | date | empty($t('ActivityList.Modal.Create.ExpiryDate.Add.Action')) }}
            </button>
            <button v-if="activity.expiryDate" class="ml-2 focus:outline-none" @click="activity.expiryDate = null">
              <IconCross class="w-2 h-2 text-red-500" />
            </button>

            <DateSelector :visible="datePickerShown" v-model="activity.expiryDate" @change="datePickerShown = false" />
          </div>
        </div>
      </div>
    </template>

    <template v-slot:bottom>
      <div class="flex flex-col-reverse sm:flex-row w-full sm:w-auto justify-end p-6 pb-4 sm:pb-6">
        <ButtonElement class="my-2 sm:my-0 sm:mr-2" variant="secondary-small" @click.native="hide">{{
          $t('Modal.Generic.Cancel')
        }}</ButtonElement>
        <ButtonElement @click.native="confirm" variant="primary-small">{{
          $t('ActivityList.Modal.Create.Action')
        }}</ButtonElement>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal';
import { GraphQLMutation } from '@/graphql';
import ButtonElement from '@/components/ButtonElement';
import InputVerify from '@/components/InputVerify';
import InputMessage from '@/components/InputMessage';

export default {
  name: 'CollaborationAssessmentInviteModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal,
    ButtonElement,
    InputVerify,
    InputMessage
  },
  props: {
    completion: {
      type: Function,
      default: () => ({}),
      required: false
    }
  },
  data() {
    return {
      activity: {
        title: '',
        owner: '',
        expiryDate: null
      },
      error: '',
      datePickerShown: false
    };
  },
  methods: {
    toggleDatePicker() {
      this.datePickerShown = !this.datePickerShown;
    },

    async confirm() {
      if (this.activity.title.length === 0 || !this.activity.expiryDate) {
        this.error = this.$t('ActivityList.Error.EmptyFields');
        return;
      }

      this.error = '';
      this.hide();

      let expirationDate = this.activity.expiryDate;
      if (expirationDate) {
        expirationDate.setHours(expirationDate.getHours() + 5);
        [expirationDate] = expirationDate.toISOString().split('T');
      }

      await this.$apollo.mutate({
        mutation: GraphQLMutation.ActivitiesCreate,
        variables: {
          title: this.activity.title,
          owner: this.activity.owner,
          expirationDate
        }
      });

      this.$emit('created');

      if (this.completion) {
        this.completion();
      }
    }
  }
};
</script>
