import store from '@/store';

const GuardActiveSession = onFailRouterOptions => {
  return (to, from, next) => {
    if (!store.state.accessToken || !store.state.currentUser) {
      next(onFailRouterOptions);
      return;
    }
    next();
  };
};

export default GuardActiveSession;
