var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DashboardCard',{attrs:{"title":_vm.$t('Dashboard.Report.Title'),"icon":"IconProgressReport","url":"/startkwalificatie"}},[_c('div',{staticClass:"flex flex-col gap-2"},[_c('router-link',{attrs:{"to":{
        path: '/startkwalificatie#1',
        params: {
          homeID: _vm.homeID
        }
      }}},[_c('DashboardButton',[_vm._v(" "+_vm._s(_vm.$t('Dashboard.Report.Portfolio'))+" ")])],1),_c('router-link',{attrs:{"to":{
        path: '/startkwalificatie#2',
        params: {
          homeID: _vm.homeID
        }
      }}},[_c('DashboardButton',[_c('span',{staticClass:"flex-grow truncate"},[_vm._v(" "+_vm._s(_vm.$t('Dashboard.Report.Qualification'))+" ")]),(_vm.progressReportRadius)?_c('div',{staticClass:"flex text-theme-50 rounded-xs px-1 h-6 border-2 border-gray-200",class:{
            'bg-light-green border-light-green': _vm.progressReportRadius.base_qualification_finished,
            'bg-white': !_vm.progressReportRadius.base_qualification_finished
          }},[_c('span',{staticClass:"text-gray-600 flex items-center",class:{ 'text-progress-fill': _vm.progressReportRadius.base_qualification_finished }},[_c('IconStar',{staticClass:"flex-shrink-0"}),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.progressReportRadius.base_qualification_finished ? _vm.$t('Radius.QualityAssessment.BaseQualification.Complete') : _vm.$t('Radius.QualityAssessment.BaseQualification.Incomplete'))+" ")])],1)]):_vm._e()])],1),_c('router-link',{attrs:{"to":{
        path: '/startkwalificatie#6',
        params: {
          homeID: _vm.homeID
        }
      }}},[_c('DashboardButton',[_vm._v(" "+_vm._s(_vm.$t('Dashboard.Report.ActivityPlan'))+" ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }