<template>
  <div class="flex items-center justify-between">
    <div v-if="!saving" class="flex flex-col gap-2">
      <ButtonElement variant="primary-small" @click.native="persistAnswers">{{
        $t('YearlyQuarterlyReport.Save')
      }}</ButtonElement>
      <div v-if="report" class="text-50 text-darkblue-opacity-80 shrink-0 flex items-center gap-2">
        <div
          class="flex items-center justify-center h-3 w-3 rounded-full text-white"
          :class="{
            'bg-green-500': !hasUnsavedChanges,
            'bg-red-500': hasUnsavedChanges
          }"
        >
          <IconCheckSmall v-if="!hasUnsavedChanges" class="transform scale-60" />
          <IconExclamationMark v-if="hasUnsavedChanges" class="w-8 h-8" />
        </div>
        <span class="whitespace-no-wrap" v-if="!hasUnsavedChanges"
          >{{ report && report.updated_at | timeago }} opgeslagen</span
        >
        <span class="whitespace-no-wrap" v-if="hasUnsavedChanges"
          >Er zijn wijzigingen die nog niet zijn opgeslagen</span
        >
      </div>
    </div>
    <img v-else class="w-8 h-8 mr-12" src="@/assets/loading-dark.gif" alt="Loading" />
  </div>
</template>

<script>
import ButtonElement from '@/components/ButtonElement';

export default {
  name: 'ProgressReportSaveButton',
  components: {
    ButtonElement
  },
  props: {
    report: {
      type: Object,
      default: null,
      required: false
    },
    saving: {
      type: Boolean,
      required: true
    },
    hasUnsavedChanges: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    persistAnswers() {
      this.$emit('save');
    }
  }
};
</script>
