<template>
  <DashboardTile :homeID="homeID">
    <template v-slot:header>
      <div class="w-full flex group justify-between items-center mb-4">
        <div class="text-theme-300 flex-grow flex-shrink-0">
          <span class="font-semibold text-100 sm:text-200">{{ $t('Dashboard.Widget.Questionnaire.Title') }}</span>
        </div>
      </div>
    </template>
    <div class="flex mt-6 mb-4">
      <DashboardTileLink :title="linkTitle" :useClickCallback="true" @click="openLink" />
    </div>
  </DashboardTile>
</template>

<script>
import { GraphQLQuery } from '@/graphql';
import DashboardTile from '@/components/dashboard-tiles/DashboardTile';
import DashboardTileLink from '@/components/DashboardTileLink';

export default {
  name: 'DashboardTileQuestionnaire',
  extends: DashboardTile,
  inheritAttrs: false,
  components: {
    DashboardTile,
    DashboardTileLink
  },
  apollo: {
    configuration: {
      query: GraphQLQuery.Configuration
    }
  },
  computed: {
    linkTitle() {
      return this.$t('Dashboard.Widget.Questionnaire.View.Action');
    }
  },
  methods: {
    openLink() {
      window.open(this.configuration.dashboard_widget_link);
    }
  }
};
</script>
