<template>
  <div class="bg-white z-20" v-click-outside="hideMenu">
    <nav class="bg-white border-b border-gray-200">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div class="">
          <div class="flex items-center justify-between h-16 px-4 sm:px-0">
            <router-link :to="{ name: 'Dashboard' }">
              <img class="mx-auto w-auto" src="@/assets/logo_small.svg" alt="Workflow" />
            </router-link>
            <div v-if="!hide && $store.state.currentManagedHome" class="ml-6 flex items-center h-full">
              <router-link
                :to="{ name: 'Dashboard' }"
                class="px-2 py-1 cursor-pointer rounded-sm text-25 font-bold text-darkblue bg-line uppercase flex items-center"
              >
                <IconHomeSmall />
                <span class="ml-2">{{ $store.state.currentManagedHome.name }}</span>
              </router-link>
              <div
                class="text-red-500 cursor-pointer flex items-center justify-center w-8 h-full"
                @click="logoutManagedHome"
              >
                <IconCross />
              </div>
            </div>
            <div v-if="!hide" class="hidden lg:flex flex-grow items-center justify-end">
              <router-link
                v-for="(route, index) in navigationRoutes"
                :key="index"
                :to="{ name: route.name }"
                class="px-3 py-2 rounded-md text-50 font-sans font-semibold text-gray-400 hover:text-gray-800 focus:outline-none focus:text-gray-800 transition ease-in-out duration-150"
                :class="[
                  { 'text-gray-800': isActive(route) },
                  { hidden: $router.currentRoute.name === 'Dashboard' && !route.showOnDashboard }
                ]"
              >
                <div class="flex justify-center items-center">
                  <span>{{ $t(`Navigation.${route.name}`) | uppercase }}</span>
                  <span
                    v-if="route.unread > 0"
                    class="flex justify-center items-center p-2 h-4 ml-1 text-50 font-bold text-white rounded-full bg-red-500"
                    >{{ route.unread }}</span
                  >
                </div>
              </router-link>
              <div v-if="$router.currentRoute.name !== 'Dashboard'" class="relative">
                <button
                  class="cursor-pointer px-3 py-3 gap-1 flex items-center uppercase rounded-md text-50 font-sans font-semibold text-gray-400 hover:text-gray-800 focus:outline-none focus:text-gray-800 transition ease-in-out duration-150"
                  @click="toggle('openMore')"
                >
                  <span>
                    {{ $t('Navigation.More') }}
                  </span>
                  <IconChevronDown
                    class="flex-shrink-0 text-lightblue-text transition ease-in-out duration-300"
                    :class="{ 'transform rotate-180': openMore }"
                  />
                </button>
                <div
                  v-if="openMore"
                  class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg animation-menu-slide-in animation-once animation-fill-both animation-200"
                >
                  <div class="py-2 rounded-bl-md rounded-br-md bg-white shadow-xs">
                    <a
                      href="#"
                      @click="navigate({ name: 'News' })"
                      class="block px-4 py-2 text-theme-150 text-lightblue-text hover:bg-gray-100"
                    >
                      {{ $t('Navigation.News') }}
                    </a>
                    <a
                      href="#"
                      @click="navigate({ name: 'Events' })"
                      class="block px-4 py-2 text-theme-150 text-lightblue-text hover:bg-gray-100"
                    >
                      {{ $t('Navigation.Events') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!hide" class="hidden lg:block ml-3">
              <div class="flex items-center">
                <div class="relative">
                  <div>
                    <button @click="toggle('open')" class="max-w-xs flex items-center focus:outline-none relative">
                      <img
                        class="h-10 flex-shrink-0 w-10 rounded-sm mr-2"
                        :src="$store.state.currentUser.avatarURL"
                        alt="Profile Picture"
                      />

                      <MapChoiceBadge
                        v-if="currentRole === 'parent' || currentRole === 'admin'"
                        class="absolute top-0 right-2 -mt-1"
                      />
                      <IconChevronDown
                        class="flex-shrink-0 text-lightblue-text transition ease-in-out duration-300"
                        :class="{ 'transform rotate-180': open }"
                      />
                    </button>
                  </div>

                  <div
                    v-if="open"
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg animation-menu-slide-in animation-once animation-fill-both animation-300"
                  >
                    <div class="py-2 rounded-bl-md rounded-br-md bg-white shadow-xs">
                      <a
                        href="#"
                        @click="navigate({ name: 'Account' })"
                        class="block px-4 py-2 text-theme-150 text-lightblue-text hover:bg-gray-100"
                      >
                        {{ $t('Navigation.Account') }}
                      </a>
                      <a
                        href="#"
                        @click="navigate({ name: 'AccountHome' })"
                        class="flex px-4 py-2 text-theme-150 text-lightblue-text hover:bg-gray-100 gap-x-2"
                      >
                        <span>{{ $t('Navigation.AccountHome') }}</span>
                        <MapChoiceBadge />
                      </a>
                      <a
                        v-if="$permissions.has('user-upsertexternaluser')"
                        href="#"
                        @click="navigate({ name: 'ManageUsers' })"
                        class="block px-4 py-2 text-theme-150 text-lightblue-text hover:bg-gray-100"
                      >
                        {{ $t('Navigation.ManageUsers') }}
                      </a>
                      <a
                        href="#"
                        v-if="$store.state.currentManagedHome"
                        @click="logoutManagedHome"
                        class="block px-4 py-2 text-theme-150 text-lightblue-text hover:bg-gray-100 truncate"
                      >
                        {{ $t('Navigation.LogoutManagedHome') | format($store.state.currentManagedHome.name) }}
                      </a>
                      <a href="#" class="block px-4 py-2 text-theme-150 text-red-500 hover:bg-gray-100" @click="logout">
                        {{ $t('Navigation.Logout') }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="$store.state.navigationBarNotice" class="flex flex-col items-end ml-6">
              <span class="text-lightblue-text text-100">{{ $store.state.navigationBarNotice.header }}</span>
              <span class="text-darkblue font-semibold text-200">{{ $store.state.navigationBarNotice.body }}</span>
            </div>
            <div v-if="!hide" class="-mr-2 flex lg:hidden">
              <button
                @click="toggle('open')"
                class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-800 focus:outline-none focus:text-gray-800"
              >
                <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path
                    :class="{ hidden: open, 'inline-flex': !open }"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                  <path
                    :class="{ hidden: !open, 'inline-flex': open }"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div :class="{ block: open, hidden: !open }" class="border-b border-line lg:hidden">
        <div class="px-2 py-3 sm:px-3">
          <a
            href="#"
            v-for="(route, index) in navigationRoutes"
            :key="index"
            @click="navigate({ name: route.name })"
            class="mt-1 block px-3 py-2 rounded-md text-theme-250 text-gray-400 hover:text-gray-800 focus:outline-none focus:text-gray-800 transition ease-in-out duration-150"
            :class="{ 'text-gray-800': isActive(route) }"
          >
            {{ $t(`Navigation.${route.name}`) }}
          </a>
        </div>
        <div class="pt-4 pb-3 border-t border-line">
          <div class="px-2">
            <a
              href="#"
              @click="navigate({ name: 'Account' })"
              class="block px-3 py-2 rounded-md text-theme-150 text-gray-400 hover:text-gray-800 focus:outline-none focus:text-gray-800 transition ease-in-out duration-150"
            >
              {{ $t('Navigation.Account') }}
            </a>
            <a
              v-if="$permissions.has('user-upsertexternaluser')"
              href="#"
              @click="navigate({ name: 'ManageUsers' })"
              class="block px-3 py-2 rounded-md text-theme-150 text-gray-400 hover:text-gray-800 focus:outline-none focus:text-gray-800 transition ease-in-out duration-150"
            >
              {{ $t('Navigation.ManageUsers') }}
            </a>
            <a
              href="#"
              v-if="$store.state.currentManagedHome"
              @click="logoutManagedHome"
              class="block px-3 py-2 rounded-md text-theme-150 text-gray-400 hover:text-gray-800 focus:outline-none focus:text-gray-800 truncate transition ease-in-out duration-150"
            >
              {{ $t('Navigation.LogoutManagedHome') | format($store.state.currentManagedHome.name) }}
            </a>
            <a
              href="#"
              class="mt-1 block px-3 py-2 rounded-md text-theme-150 text-red-500 hover:text-gray-800 focus:outline-none focus:text-gray-800 transition ease-in-out duration-150"
              @click="logout"
            >
              {{ $t('Navigation.Logout') }}
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'Navigation',
  directives: {
    ClickOutside
  },
  props: {
    activeRouteName: {
      type: String,
      default: '',
      required: false
    },
    activeRoutePath: {
      type: String,
      default: '',
      required: false
    }
  },
  data() {
    return {
      open: false,
      isScrolled: false,
      openMore: false
    };
  },
  computed: {
    currentRole() {
      return this.$store.state.currentUser.role.type;
    },

    navigationRoutes() {
      const routes = this.$router.options.routes.filter(route => {
        if (route.permissions && !this.$permissions.hasAll(route.permissions)) {
          return false;
        }
        return route.showInNavigation;
      });

      routes.map(route => {
        route.unread = this.$store.state.routeUnread[route.name] || 0;
        return route;
      });

      return routes;
    },

    hide() {
      const [activeRoute] = this.$router.options.routes.filter(route => {
        return this.activeRouteName === route.name;
      });

      if (activeRoute) {
        return activeRoute.hideNavigationBarRoutes === true;
      }

      return false;
    }
  },
  methods: {
    navigate(parameters) {
      this.$router.push(parameters);
      this.hideMenu();
    },

    isActive(route) {
      if (this.activeRouteName === route.name) {
        return true;
      }

      const activeRoutePaths = this.activeRoutePath.split('/').filter(item => {
        return item && item.length > 0;
      });

      const routePaths = route.path.split('/').filter(item => {
        return item && item.length > 0;
      });

      if (activeRoutePaths.length > 0 && routePaths.length > 0) {
        return activeRoutePaths[0] === routePaths[0];
      }
      return false;
    },

    toggle(type) {
      if (type === 'openMore') {
        this.openMore = !this.openMore;
        this.open = false;
      } else {
        this.open = !this.open;
        this.openMore = false;
      }
    },

    hideMenu() {
      this.open = false;
      this.openMore = false;
    },

    logoutManagedHome() {
      this.$modal.confirm(() => {
        this.$store.commit('currentManagedHome', null);
        this.$router.push({ name: 'Homes' });
      }, 'LogoutManagedHome');
    },

    logout() {
      this.$modal.confirm(() => {
        this.$store.commit('logout');
        this.$router.push({ name: 'Login' });
      }, 'Logout');
    }
  }
};
</script>
