<template>
  <div class="flex flex-col gap-y-6">
    <div
      v-for="(child, index) of children"
      :key="index"
      class="w-full text-black border border-gray-300 rounded-md p-8"
    >
      <div class="font-semibold text-200 mb-8 flex justify-between items-center">
        <span>Jeugdige {{ index + 1 }}</span>
        <div v-if="index > 0" class="cursor-pointer p-4" @click="removeChild(index)">
          <IconCross />
        </div>
      </div>

      <div class="grid gap-6 grid-cols-2">
        <div class="w-full">
          <label class="font-medium  block text-100 mb-1">Initialen</label>
          <InputVerify
            v-model="child.initials"
            class="w-full"
            :message="error && errorField === 'initials' ? error : ''"
            messageVariant="error"
          />
        </div>

        <div class="w-full">
          <label class="font-medium  block text-100 mb-1">Geplaatst door</label>
          <InputVerify
            v-model="child.placedBy"
            class="w-full"
            :message="error && errorField === 'placedBy' ? error : ''"
            messageVariant="error"
          />
        </div>

        <div class="w-full">
          <label class="font-medium  block text-100 mb-1">Geplaatst sinds</label>
          <InputVerify v-model="child.placedSince" class="w-full" @click.native="openDatepicker(child)" />
          <DateSelector :visible="datepickerChild === child" v-model="pickedDate" @change="pickedDateChanged" />
        </div>

        <div class="w-full">
          <label class="font-medium  block text-100 mb-1">Dagvergoeding</label>
          <InputVerify
            v-model="child.dailyAllowance"
            class="w-full"
            :message="error && errorField === 'dailyAllowance' ? error : ''"
            messageVariant="error"
          />
        </div>
      </div>
    </div>
    <div
      class="cursor-pointer select-none flex w-full py-5 justify-center items-center bg-gray-100 rounded-md border-2 border-dashed border-gray-300 focus:outline-none text-100 gap-x-2 text-lightblue-link"
      @click="addChild"
    >
      <IconAdd />
      <span class="font-semibold mr-1">Jeugdige toevoegen</span>
    </div>
  </div>
</template>

<script>
import InputVerify from '@/components/InputVerify';

export default {
  name: 'ProgressReportSectionFormChildrenSelector',
  components: {
    InputVerify
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      children: [],
      pickedDate: null,
      datepickerChild: null,
      errorField: '',
      error: ''
    };
  },
  watch: {
    value: {
      handler(value) {
        this.children = value;
        if (this.children.length === 0) {
          this.addChild();
        }
      },
      immediate: true
    },
    children: {
      handler() {
        this.$emit('update', this.children);
      },
      deep: true
    }
  },
  mounted() {
    if (this.children.length === 0) {
      this.addChild();
    }
  },
  methods: {
    openDatepicker(child) {
      this.datepickerChild = child;
    },

    addChild() {
      this.children.push({
        initials: '',
        placedBy: '',
        placedSince: new Date().toLocaleDateString(),
        dailyAllowance: '0'
      });
    },

    removeChild(index) {
      this.children.splice(index, 1);
    },

    pickedDateChanged(newDate) {
      if (!newDate) {
        this.datepickerChild = null;
        return;
      }

      this.pickedDate = newDate;
      this.datepickerChild.placedSince = this.pickedDate.toLocaleDateString();
      this.datepickerChild = null;
    },

    validate(showError = false) {
      let errorMessage = '';
      let errorField = '';
      for (let i = 0; i < this.children.length; i += 1) {
        const child = this.children[i];

        if (!child.initials?.length) {
          errorMessage = 'Initialen zijn verplicht';
          errorField = 'initials';
        } else if (!child.placedBy?.length) {
          errorMessage = 'Geplaatst door is verplicht';
          errorField = 'placedBy';
        } else if (!child.dailyAllowance?.length) {
          errorMessage = 'Dagvergoeding is verplicht';
          errorField = 'dailyAllowance';
        }

        if (errorMessage.length > 0 && showError) {
          this.error = errorMessage;
          this.errorField = errorField;
          break;
        }
      }

      return errorMessage.length === 0;
    }
  }
};
</script>
