<template>
  <main class="bg-gray-100 py-16">
    <div class="max-w-5xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col sm:flex-row justify-between items-center w-full mb-8 sm:mb-16">
        <h1 class="text-theme-500 flex-grow">{{ $t('Account.Title') }}</h1>
      </div>

      <div
        v-if="$store.state.currentUser"
        class="bg-white text-theme-150 text-darkblue font-medium rounded-md w-full p-8 sm:p-10 max-w-3xl border-b-4 border-lightblue-border"
      >
        <div
          v-if="error.length > 0"
          class="rounded-md shadow-sm mb-8 p-4 text-error-balloon-text bg-error-balloon-background width-full text-center flex flex-col animation-error-scale-in animation-once animation-fill-both animation-500"
        >
          <span class="text-sm">{{ error }}</span>
        </div>
        <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-2">
          <span>{{ $t('Account.Avatar') }}</span>
          <div class="flex w-full max-w-account-textfield">
            <button
              v-for="index in availablePictureIndexes"
              :key="index"
              @click="selectProfilePicture(index)"
              class="p-1 -ml-1 mr-2 border rounded-md border-white hover:border-gray-300 focus:outline-none animation-general-overscale-in animation-once animation-fill-both animation-500"
              :class="{
                [`animation-delay-${3 + index * 2}`]: true,
                'border-gray-300': $store.state.currentUser.avatar_id == index
              }"
            >
              <img :src="`@/assets/profile/profile-${index}.svg` | resolve" alt="" />
            </button>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-2">
          <span>{{ $t('Account.Username') }}*</span>
          <InputVerify
            class="w-full max-w-account-textfield"
            required
            disabled
            :placeholder="$t('Account.Username')"
            v-model="$store.state.currentUser.username"
          />
        </div>
        <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-2">
          <span>{{ $t('Account.Email') }}*</span>
          <InputVerify
            class="w-full max-w-account-textfield"
            required
            :placeholder="$t('Account.Email')"
            v-model="$store.state.currentUser.email"
            :messageIconVisible="error.length > 0 || dataPersisted"
            :messageVariant="dataPersisted ? 'success' : 'error'"
          />
        </div>
        <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-2">
          <span>{{ $t('Account.FirstName') }}*</span>
          <InputVerify
            class="w-full max-w-account-textfield"
            required
            :placeholder="$t('Account.FirstName')"
            v-model="$store.state.currentUser.first_name"
            :messageIconVisible="error.length > 0 || dataPersisted"
            :messageVariant="dataPersisted ? 'success' : 'error'"
          />
        </div>
        <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-2">
          <span>{{ $t('Account.LastName') }}*</span>
          <InputVerify
            class="w-full max-w-account-textfield"
            required
            :placeholder="$t('Account.LastName')"
            v-model="$store.state.currentUser.last_name"
            :messageIconVisible="error.length > 0 || dataPersisted"
            :messageVariant="dataPersisted ? 'success' : 'error'"
          />
        </div>
        <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-2">
          <span>{{ $t('Account.JobTitle') }}</span>
          <InputVerify
            class="w-full max-w-account-textfield"
            required
            :placeholder="$t('Account.JobTitle')"
            v-model="$store.state.currentUser.job_title"
            :messageIconVisible="error.length > 0 || dataPersisted"
            :messageVariant="dataPersisted ? 'success' : 'error'"
          />
        </div>
        <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-2">
          <span>{{ $t('Account.Telephone') }}</span>
          <InputVerify
            class="w-full max-w-account-textfield"
            required
            :placeholder="$t('Account.Telephone')"
            v-model="$store.state.currentUser.phone"
            :messageIconVisible="error.length > 0 || dataPersisted"
            :messageVariant="dataPersisted ? 'success' : 'error'"
          />
        </div>
        <div class="mb-10 flex flex-col sm:flex-row justify-end items-start sm:items-center">
          * {{ $t('Account.RequiredFields') }}
        </div>
        <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-2">
          <span>{{ $t('Account.CurrentPassword') }}</span>
          <InputVerify class="w-full max-w-account-textfield" required disabled value="password" type="password" />
        </div>
        <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-2">
          <span>{{ $t('Account.NewPassword') }}</span>
          <InputVerify
            class="w-full max-w-account-textfield"
            required
            type="password"
            v-model="password"
            :messageIconVisible="
              error.length > 0 ||
                dataPersisted ||
                (password.length > 0 && passwordRepeat.length > 0 && password !== passwordRepeat)
            "
            :messageVariant="dataPersisted ? 'success' : 'error'"
          />
        </div>
        <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-2">
          <span>{{ $t('Account.ConfirmNewPassword') }}</span>
          <InputVerify
            class="w-full max-w-account-textfield"
            required
            type="password"
            v-model="passwordRepeat"
            :messageIconVisible="
              error.length > 0 ||
                dataPersisted ||
                (password.length > 0 && passwordRepeat.length > 0 && password !== passwordRepeat)
            "
            :messageVariant="dataPersisted ? 'success' : 'error'"
          />
        </div>
        <div class="w-full mt-8 flex justify-end">
          <ButtonElement variant="primary-small" @click.native="persist">
            {{ $t('Account.Action.Save') }}
          </ButtonElement>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ButtonElement from '@/components/ButtonElement';
import InputVerify from '@/components/InputVerify';
import { GraphQLQuery, GraphQLMutation } from '@/graphql';

export default {
  name: 'Account',
  components: {
    ButtonElement,
    InputVerify
  },
  apollo: {
    user: {
      query: GraphQLQuery.Me,
      update(data) {
        this.$store.commit('currentUser', data.me);
        return this.$store.state.currentUser;
      }
    }
  },
  data() {
    return {
      password: '',
      passwordRepeat: '',
      error: '',
      dataPersisted: false,
      availablePictureIndexes: []
    };
  },
  mounted() {
    for (let i = 0; i < 4; i += 1) {
      this.availablePictureIndexes.push(i);
    }
  },
  methods: {
    selectProfilePicture(index) {
      this.user.avatar_id = index;
    },

    async persist() {
      try {
        if (this.password !== this.passwordRepeat) {
          this.error = this.$t('Account.Error.PasswordsNoMatch');
          return;
        }

        if (!this.user.first_name || this.user.first_name.length === 0) {
          this.error = this.$t('Account.Error.EmptyField.FirstName');
          return;
        }

        if (!this.user.last_name || this.user.last_name.length === 0) {
          this.error = this.$t('Account.Error.EmptyField.LastName');
          return;
        }

        const parameters = {
          userID: this.user.id,
          email: this.user.email,
          firstName: this.user.first_name,
          lastName: this.user.last_name,
          jobTitle: this.user.job_title,
          telephone: this.user.phone,
          avatarID: Number(this.user.avatar_id)
        };

        if (this.password.length > 0) {
          parameters.password = this.password;
        }

        await this.$apollo.mutate({
          mutation: GraphQLMutation.UserUpdate,
          variables: parameters
        });

        this.error = '';
        this.dataPersisted = true;
        setTimeout(() => {
          this.dataPersisted = false;
        }, 2000);

        this.$apollo.queries.user.refresh();
      } catch (e) {
        this.error = this.$t('Account.Error.UnknownError');
      }
    }
  }
};
</script>
