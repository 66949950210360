/* eslint-disable-next-line no-redeclare */
/* global self */

self.__precacheManifest = [].concat(self.__precacheManifest || []);
workbox.precaching.precacheAndRoute(self.__precacheManifest, {});

self.addEventListener('message', event => {
  if (event.data && event.data.type === 'SKIP_WAITING') {
    self.skipWaiting();
  }
});

self.addEventListener('periodicsync', event => {
  self.clients
    .matchAll({
      includeUncontrolled: true,
      type: 'window'
    })
    .then(clients => {
      if (clients && clients.length) {
        clients[0].postMessage({
          identifier: event.tag
        });
      }
    });
});
