import Vue from 'vue';
import VueRouter from 'vue-router';
import GuardActiveSession from '@/router/guards/activesession';
import GuardInactiveSession from '@/router/guards/inactivesession';

Vue.use(VueRouter);

const routes = [
  {
    path: '/dashboard',
    alias: ['/'],
    name: 'Dashboard',
    component: () => import('../views/Dashboard/Dashboard.vue'),
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    permissions: [
      ({ store, permissions }) => {
        return store.state.currentManagedHome || permissions.has('home-viewdashboard');
      }
    ],
    showInNavigation: true,
    showOnDashboard: true
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/News/News.vue'),
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    showInNavigation: false
  },
  {
    path: '/news/:articleID',
    name: 'NewsDetail',
    component: () => import('../views/News/NewsDetail.vue'),
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    showInNavigation: false
  },
  {
    path: '/startkwalificatie/:homeID?',
    name: 'ProgressReport',
    component: () => import('../views/ProgressReport/ProgressReport.vue'),
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    showInNavigation: false,
    permissions: [
      'document-find',
      'chapter-find',
      'paragraph-home-find',
      'paragraph-find',
      ({ store, permissions }) => {
        return store.state.currentManagedHome || !permissions.has('home-find');
      }
    ]
  },
  {
    path: '/keurmerk/:homeID?',
    name: 'QualityAssessment',
    component: () => import('../views/Radius/QualityAssessment.vue'),
    alias: ['/radius'],
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    showInNavigation: true,
    showOnDashboard: true,
    permissions: [
      'document-find',
      'chapter-find',
      'paragraph-home-find',
      'paragraph-find',
      'home-viewqualityassessment',
      ({ store, permissions }) => {
        return store.state.currentManagedHome || !permissions.has('home-find');
      }
    ]
  },
  {
    path: '/keurmerk/:homeID?/par/:chapterID',
    name: 'QualityAssessmentDetail',
    component: () => import('../views/Radius/QualityAssessmentDetail.vue'),
    beforeEnter: GuardActiveSession({ name: 'Login' })
  },
  {
    path: '/keurmerk/:homeID?/samenwerkingsonderzoek',
    name: 'CollaborationAssessment',
    component: () => import('../views/Radius/CollaborationAssessment.vue'),
    alias: ['/samenwerkingsonderzoek'],
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    showInNavigation: false,
    permissions: [
      'document-find',
      'chapter-find',
      'paragraph-home-find',
      'paragraph-find',
      'home-viewcollaborationassessment',
      ({ store, permissions }) => {
        return store.state.currentManagedHome || !permissions.has('home-find');
      }
    ]
  },
  {
    path: '/keurmerk/:homeID?/leefklimaatonderzoek',
    name: 'LivingEnvironmentAssessment',
    component: () => import('../views/Radius/LivingEnvironmentAssessment.vue'),
    alias: ['/leefklimaatonderzoek'],
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    showInNavigation: false,
    permissions: [
      'document-find',
      'chapter-find',
      'paragraph-home-find',
      'paragraph-find',
      'home-viewlivingenvironmentassessment',
      ({ store, permissions }) => {
        return store.state.currentManagedHome || !permissions.has('home-find');
      }
    ]
  },
  {
    path: '/keurmerk/:homeID?/samenwerkingsonderzoek/:collaborationID',
    name: 'CollaborationAssessmentQuestions',
    component: () => import('../views/Radius/CollaborationAssessmentQuestions.vue'),
    showInNavigation: false,
    hideNavigationBarRoutes: true,
    forceNavigationBar: true
  },
  {
    path: '/keurmerk/:homeID?/par/:paragraphID/rapportage/:type',
    name: 'YearlyQuarterlyReport',
    component: () => import('../views/Radius/YearlyQuarterlyReport.vue'),
    showInNavigation: false
  },
  {
    path: '/gezinshuizen',
    name: 'Homes',
    component: () => import('../views/Homes/Homes.vue'),
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    showInNavigation: true,
    showOnDashboard: true,
    permissions: [
      'home-find',
      ({ store }) => {
        return !store.state.currentManagedHome;
      }
    ]
  },
  {
    path: '/professionalization',
    name: 'Professionalization',
    component: () => import('../views/Professionalization/Professionalization.vue'),
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    showInNavigation: false,
    permissions: [
      'home-find',
      ({ store }) => {
        return !store.state.currentManagedHome;
      }
    ]
  },
  {
    path: '/bibliotheek/:type?',
    name: 'Library',
    component: () => import('../views/Library/Library.vue'),
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    meta: {
      library: true
    },
    showInNavigation: true,
    showOnDashboard: false,
    permissions: [
      'document-find',
      'document-searchdocuments',
      ({ store, permissions }) => {
        if (
          !permissions.hasAny([
            'document-viewhealthinsurerdocuments',
            'document-viewprivatedocuments',
            'document-viewpublicdocuments'
          ])
        ) {
          return false;
        }

        if (permissions.has('document-viewhealthinsurerdocuments')) {
          return !store.state.currentManagedHome || permissions.hasAdminRole();
        }

        return store.state.currentManagedHome || !permissions.has('home-find') || permissions.hasAdminRole();
      }
    ]
  },
  {
    path: '/activiteiten',
    name: 'ActivityList',
    component: () => import('../views/ActivityList/ActivityList.vue'),
    beforeEnter: GuardActiveSession({ name: 'Login' }),
    showInNavigation: true,
    showOnDashboard: true,
    permissions: [
      'activity-find',
      ({ store, permissions }) => {
        return store.state.currentManagedHome || !permissions.has('home-find');
      }
    ]
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account/Account.vue'),
    beforeEnter: GuardActiveSession({ name: 'Login' })
  },
  {
    path: '/account/home',
    name: 'AccountHome',
    component: () => import('../views/Account/AccountHome.vue'),
    beforeEnter: GuardActiveSession({ name: 'Login' })
  },
  {
    path: '/account/users',
    name: 'ManageUsers',
    component: () => import('../views/Account/ManageUsers.vue'),
    beforeEnter: GuardActiveSession({ name: 'Login' })
  },
  {
    path: '/inloggen',
    name: 'Login',
    component: () => import('../views/Login/Login.vue'),
    beforeEnter: GuardInactiveSession({ name: 'Dashboard' })
  },
  {
    path: '/account/:token/activate',
    name: 'Register',
    component: () => import('../views/Login/Register.vue'),
    beforeEnter: GuardInactiveSession({ name: 'Dashboard' })
  },
  {
    path: '/account/password/reset/:token?',
    name: 'PasswordReset',
    component: () => import('../views/Login/PasswordReset.vue'),
    beforeEnter: GuardInactiveSession({ name: 'Dashboard' })
  },
  {
    path: '/jaaroverzicht',
    name: 'Events',
    component: () => import('../views/Events/Events.vue'),
    beforeEnter: GuardActiveSession({ name: 'Dashboard' })
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
