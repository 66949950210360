<template>
  <main class="bg-gray-100 py-16">
    <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
      <RadiusHeader selected="qualityassessment" simple target="Dashboard" />
      <div class="animation-page-scale-in animation-once animation-fill-backwards animation-300">
        <div class="flex w-full border border-b-4 border-gray-200 rounded-md bg-white px-8 py-6 items-center mb-10">
          <span class="block text-theme-150 font-medium text-darkblue whitespace-no-wrap mr-12">
            {{ $t('Radius.TotalProgress') }}
          </span>
          <ProgressBar :percentage="totalPercentage" :calloutVisible="true" />
        </div>
        <div class="flex flex-row items-center gap-2 text-theme-250 font-semibold text-darkblue mb-6">
          <span class="block">{{ $t('Radius.Chapters') }}</span>
          <img v-if="$apollo.loading" class="w-6 h-6" src="@/assets/loading-dark.gif" alt="Loading" />
        </div>
        <div
          v-for="(chapter, index) in chapters"
          :key="index"
          @click="showChapterDetail(chapter)"
          class="
            flex flex-col
            md:flex-row
            justify-between
            items-center
            w-full
            cursor-pointer
            border border-b-4 border-gray-200
            rounded-md
            bg-white
            px-8
            py-8
            mb-4
            group
          "
          :class="{
            [`animation-list-scale-in animation-once animation-fill-backwards animation-300 animation-delay-${index}`]: true,
            'opacity-50': chapter.action === 'no_access'
          }"
        >
          <div class="flex flex-grow justify-start w-full md:w-auto mb-8 md:mb-0">
            <span class="block text-theme-250 font-semibold text-darkblue mr-8 mt-2">{{ index + 1 }}.</span>
            <div>
              <div class="text-theme-400 mb-1">
                <span class="font-medium">{{ chapter.name }}</span>
              </div>
              <div class="text-theme-200"></div>
              <span class="block text-theme-200 font-100" v-html="$filters.markdown2html(chapter.description)"></span>
            </div>
          </div>
          <div class="flex items-center justify-end w-full md:w-auto">
            <div v-if="chapterHasBaseQualification(chapter)" class="flex flex-col space-y-2 mr-4">
              <div
                v-if="
                  !$permissions.has('home-find') ||
                    ($permissions.has('home-find') && chapterBaseQualificationStatus(chapter) !== 'Pending')
                "
                class="flex text-theme-50 rounded-sm p-1.5 border-2 border-gray-300"
                :class="{
                  'bg-light-green border-light-green': chapterBaseQualificationStatus(chapter) === 'Complete',
                  'bg-status-pending border-status-pending': chapterBaseQualificationStatus(chapter) === 'Pending'
                }"
              >
                <span
                  class="text-darkblue flex items-center justify-center"
                  :class="{ 'text-progress-fill': chapterBaseQualificationStatus(chapter) === 'Complete' }"
                >
                  <IconStar class="flex-shrink-0" />
                  <span class="ml-1">
                    {{ $t(`Radius.QualityAssessment.BaseQualification.${chapterBaseQualificationStatus(chapter)}`) }}
                  </span>
                </span>
              </div>
              <div
                v-if="$permissions.has('home-find') && chapterBaseQualificationStatus(chapter) === 'Pending'"
                class="flex flex-col items-start justify-center bg-gray-100 text-theme-150 p-4 rounded-md"
              >
                <span class="text-gray-600 mb-3">{{ $t('Radius.QualityAssessment.BaseQualification.Pending') }}</span>
                <div
                  class="
                    flex
                    items-center
                    justify-center
                    mt-1
                    bg-white
                    p-2
                    border border-gray-100
                    hover:bg-gray-50
                    hover:border-white
                  "
                  @click.stop="changeBaseQualificationStatus(chapter, 'approved')"
                >
                  <div class="flex items-center justify-center bg-progress-fill mr-2 rounded-full w-5 h-5">
                    <IconCheck class="text-white" />
                  </div>
                  <span class="text-darkblue font-semibold">{{
                    $t('Radius.QualityAssessment.BaseQualification.Action.Approve')
                  }}</span>
                </div>
              </div>
            </div>
            <div class="flex items-center relative z-10 overflow-hidden gap-x-4 pr-3">
              <div
                v-if="chapter.counts_for_percentage && percentageForChapter(chapter) >= 0"
                class="flex items-center gap-x-2"
              >
                <span class="text-theme-250 font-semibold text-darkblue "> {{ percentageForChapter(chapter) }}% </span>
                <ProgressCircle :percentage="percentageForChapter(chapter)" />
              </div>

              <button
                v-if="$permissions.has('chapter-access-find')"
                @click.stop="openAccessModal(chapter)"
                class="rounded-lg border border-gray-200 w-10 h-10 flex items-center justify-center relative z-10 hover:border-black transition-all duration-200"
              >
                <IconInzage />
              </button>

              <div
                class="
                  flex
                  justify-between
                  items-center
                  transform
                  group-hover:translate-x-1
                  text-darkblue
                  transition
                  ease-in-out
                  duration-150
                "
              >
                <IconChevronRight />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { GraphQLQuery, GraphQLMutation } from '@/graphql';
import ProgressBar from '@/components/ProgressBar';
import ProgressCircle from '@/components/ProgressCircle';
import RadiusHeader from '@/views/Radius/RadiusHeader';

export default {
  name: 'QualityAssessment',
  components: {
    ProgressBar,
    ProgressCircle,
    RadiusHeader
  },
  apollo: {
    chapters: {
      query: GraphQLQuery.RadiusQualityChapters
    },
    progress: {
      query: GraphQLQuery.RadiusQualityChaptersProgress,
      variables() {
        return {
          homeID: this.homeID
        };
      }
    },
    configuration: {
      query: GraphQLQuery.Configuration
    },
    progressReportRadius: {
      query: GraphQLQuery.ProgressReportRadius,
      fetchPolicy: 'cache-and-network',
      skip() {
        return !this.$store.state.currentUser;
      }
    }
  },
  computed: {
    homeID() {
      if (this.$route.params.homeID) {
        return Number(this.$route.params.homeID);
      }

      return this.$store.state.currentManagedHome ? Number(this.$store.state.currentManagedHome.id) : undefined;
    },

    totalPercentage() {
      if (!this.progressReportRadius) {
        return 0;
      }

      return this.progressReportRadius.progress_percentage;
    }
  },
  methods: {
    percentageForChapter(chapter) {
      if (!this.progress?.length) {
        return -1;
      }

      const finishedParagraphs = this.progress.filter(info => {
        return (
          info.paragraph &&
          info.paragraph.chapter &&
          info.paragraph.chapter.id === chapter.id &&
          info.status &&
          info.status !== 'open' &&
          this.$helper.expiredDocumentsCount(info.documents) === 0
        );
      });

      if (!finishedParagraphs || finishedParagraphs.length === 0) {
        return 0;
      }

      const progress = (finishedParagraphs.length / chapter.paragraphs.length) * 100;
      return Math.floor(progress);
    },

    async changeBaseQualificationStatus(chapter, status = '') {
      if (!chapter || status.length === 0) {
        return;
      }

      this.$modal.confirm(async () => {
        const paragraphUserDataResponse = await this.$apollo.query({
          query: GraphQLQuery.RadiusQualityParagraphsUserData,
          variables: {
            homeID: this.homeID,
            chapterID: chapter.id
          }
        });
        const paragraphUserData = paragraphUserDataResponse.data.paragraphHomes;

        const responses = [];
        for (let i = 0; i < chapter.paragraphs.length; i += 1) {
          const paragraph = chapter.paragraphs[i];
          const userData = paragraphUserData.find(data => {
            return data.paragraph.id === paragraph.id;
          });

          if (!userData) {
            continue;
          }

          const paragraphUpdateResponse = this.$apollo.mutate({
            mutation: GraphQLMutation.RadiusQualityParagraphsUserDataStatus,
            variables: {
              id: userData.id,
              status: 'accepted'
            }
          });
          responses.push(paragraphUpdateResponse);
        }

        await Promise.all(responses);
      }, 'ChangeBaseQualificationStatus');
    },

    chapterHasBaseQualification(chapter) {
      const result = chapter.paragraphs.find(paragraph => {
        return paragraph.base_qualification;
      });

      return result != null;
    },

    chapterBaseQualificationStatus(chapter) {
      if (!this.progress || this.progress.length === 0) {
        return 'Incomplete';
      }

      const availableParagraphs = chapter.paragraphs.filter(paragraph => {
        return paragraph.base_qualification;
      });

      const acceptedParagraphs = this.progress.filter(info => {
        return (
          info.paragraph &&
          info.paragraph.chapter &&
          info.paragraph.chapter.id === chapter.id &&
          info.status &&
          info.status === 'accepted' &&
          this.$helper.expiredDocumentsCount(info.documents) === 0 &&
          info.paragraph.base_qualification
        );
      });

      const pendingParagraphs = this.progress.filter(info => {
        return (
          info.paragraph &&
          info.paragraph.chapter &&
          info.paragraph.chapter.id === chapter.id &&
          info.status &&
          (info.status === 'pending' || info.status === 'declined') &&
          this.$helper.expiredDocumentsCount(info.documents) === 0 &&
          info.paragraph.base_qualification
        );
      });

      if (acceptedParagraphs.length >= availableParagraphs.length) {
        return 'Complete';
      }

      return pendingParagraphs.length >= availableParagraphs.length ? 'Pending' : 'Incomplete';
    },

    showChapterDetail(chapter) {
      if (!chapter.action || chapter.action === 'show_paragraphs') {
        this.$router.push({
          name: 'QualityAssessmentDetail',
          params: {
            chapterID: chapter.id,
            homeID: this.homeID
          }
        });
      } else if (chapter.action === 'thuisklimaatonderzoek') {
        this.showLivingEnvironmentAssessment();
      } else if (chapter.action === 'samenwerkingsonderzoek') {
        this.showCollaborationAssessment();
      } else if (chapter.action === 'startkwalificatie') {
        this.navigate('ProgressReport');
      } else if (chapter.action === 'no_access') {
        this.$modal.show('ErrorModal', {
          title: this.$t('Radius.Error.NoParagraphAccess.Title'),
          error: this.$t('Radius.Error.NoParagraphAccess.Body')
        });
      }
    },

    displayComingSoonModal(typeText) {
      const bodyText = `1x per jaar word je gevraagd om deel te nemen aan het ${typeText}. Dit wordt uitgevoerd in samenwerking met de Hogeschool Leiden en werkt aan de hand van een digitale vragenlijst. De resultaten krijg je in de vorm van een rapport toegestuurd en kan je uploaden in het portal.`;
      this.$modal.show('ComingSoon', { title: typeText, body: bodyText });
    },

    showLivingEnvironmentAssessment() {
      if (
        this.configuration &&
        this.configuration.environment_research &&
        this.configuration.environment_research.environment_research_enabled
      ) {
        this.navigate('LivingEnvironmentAssessment');
        return;
      }

      this.displayComingSoonModal(this.$t('Radius.EnvironmentAssessment'));
    },

    showCollaborationAssessment() {
      this.navigate('CollaborationAssessment');
    },

    openAccessModal(chapter) {
      this.$modal.show('AccessModal', {
        chapter
      });
    },

    navigate(name) {
      this.$router.push({
        name,
        params: {
          homeID: this.homeID
        }
      });
    }
  }
};
</script>
