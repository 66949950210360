<template>
  <a
    href="#"
    @click="linkAction"
    class="block text-theme-100 group flex flex-row justify-start items-center hover:text-lightblue-link"
  >
    <IconChevronRightSmall />
    <span class="ml-2 transform transition ease-in-out duration-150 group-hover:translate-x-1">{{ title }}</span>
  </a>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    to: {
      type: Object,
      default() {
        return {};
      },
      required: false
    },
    useClickCallback: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    linkAction() {
      if (!this.to || Object.keys(this.to).length === 0) {
        if (this.useClickCallback) {
          this.$emit('click');
        } else {
          this.$modal.show('ComingSoon');
        }
        return;
      }

      this.$router.push(this.to);
    }
  }
};
</script>
