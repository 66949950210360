<template>
  <DashboardTile :homeID="homeID" :to="{ name: 'Homes' }">
    <template v-slot:header>
      <div class="w-full group flex justify-between items-center mb-4">
        <div class="text-theme-300 flex-grow flex-shrink-0">
          <span class="font-semibold text-100 sm:text-200">{{ $t('Dashboard.Widget.Homes.Title') }}</span>
        </div>
        <div class="text-theme-300 transform group-hover:translate-x-1 transition ease-in-out duration-150">
          <IconChevronRightBig />
        </div>
      </div>
    </template>
    <div class="w-full flex-1 overflow-scroll mb-8">
      <div
        class="w-full p-2 flex items-center cursor-pointer"
        v-for="(home, index) in homes"
        :key="index"
        :class="{
          'bg-textfield-disabled': index % 2 == 0,
          [`animation-list-scale-in group animation-once animation-fill-backwards animation-300 animation-delay-${index}`]: true
        }"
        @click="selectHome(home)"
      >
        <button class="flex text-left items-center focus:outline-none group">
          <IconHome
            class="text-lightblue-hover flex-shrink-0 group-hover:text-orange transition ease-in-out duration-150"
          />
          <div class="ml-8 text-theme-150">
            <span class="block text-50 text-darkblue font-semibold">
              {{ home.name }}
            </span>
          </div>
        </button>
      </div>
    </div>
  </DashboardTile>
</template>

<script>
import DashboardTile from '@/components/dashboard-tiles/DashboardTile';
import { GraphQLQuery } from '@/graphql';

export default {
  name: 'DashboardTileHomes',
  extends: DashboardTile,
  inheritAttrs: false,
  components: {
    DashboardTile
  },
  apollo: {
    homes: {
      query: GraphQLQuery.Homes,
      variables() {
        return {
          query: '',
          limit: 50,
          start: 0
        };
      }
    }
  },
  methods: {
    selectHome(home) {
      this.$apollo.provider.defaultClient.resetStore();

      this.$store.commit('currentManagedHome', home);
      this.$router.push({ name: 'Dashboard' });
    }
  }
};
</script>
