export default function(value, length) {
  if (!value) {
    return '';
  }

  if (value.length < length) {
    return value;
  }

  return `${value.toString().substring(0, length)}...`;
}
