<template>
  <main class="bg-lightblue-background pt-32 relative pb-20 flex items-center flex-col">
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 relative w-full">
      <EventsList v-if="events" :events="events" />
    </div>
    <div class="fixed top-16 py-4 max-w-5xl w-full px-8 z-10">
      <div class="flex items-center border border-gray-200 border-b-4 bg-white shadow-sm rounded-md p-4">
        <InputSearch
          class="flex-grow mr-2"
          :placeholder="$t('Events.Search.Placeholder')"
          variant="medium"
          @search="performSearch"
        ></InputSearch>

        <div v-if="eventCategories && eventCategories.length" class="relative w-full" style="max-width: 378px">
          <select
            @input="filterCategory($event.target.value)"
            class="rounded-md w-full h-12 border border-gray-200 shadow-sm pl-4 pr-10 text-100 appearance-none cursor-pointer focus:outline-none"
          >
            <option value="">Alle Rubrieken</option>
            <option v-for="(category, index) in eventCategories" :key="index" :value="category.id">{{
              category.name
            }}</option>
          </select>
          <IconChevronRight class="absolute right-4 top-5 rotate-90 transform pointer-events-none" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import EventsList from '@/components/events/EventsList.vue';
import InputSearch from '@/components/InputSearch';
import { GraphQLQuery } from '@/graphql';

export default {
  components: {
    EventsList,
    InputSearch
  },
  apollo: {
    events: {
      query: GraphQLQuery.Events,
      variables() {
        return {
          query: this.buildSearchQuery
        };
      },
      update(data) {
        return data.events;
      },
      result() {
        setTimeout(() => {
          const element = document.getElementById(`event-${this.firstUpcomingEventId}`);
          if (element) {
            const y = element.getBoundingClientRect().top + window.pageYOffset - 90;
            window.scrollTo({ top: y });
          }
        }, 150);
      }
    },
    eventCategories: {
      query: GraphQLQuery.EventCategories,
      update(data) {
        return data.eventCategories;
      }
    }
  },
  data() {
    return {
      searchQuery: '',
      selectedCategoryId: 0
    };
  },

  computed: {
    firstUpcomingEventId() {
      if (this.events) {
        const firstItem = this.events.find(element => {
          return new Date(element.start_date) > new Date();
        });
        if (firstItem) {
          return firstItem.id;
        }
      }
      return 0;
    },

    buildSearchQuery() {
      const query = {
        _and: [{ _or: [{ title_contains: this.searchQuery }, { description_contains: this.searchQuery }] }]
      };

      if (this.selectedCategoryId > 0) {
        query._and.push({
          event_category: { id: this.selectedCategoryId }
        });
      }

      return query;
    }
  },

  methods: {
    performSearch(query) {
      this.searchQuery = query;
    },

    filterCategory(categoryId) {
      this.selectedCategoryId = categoryId || 0;
      this.$apollo.queries.events.refetch();
    }
  }
};
</script>
