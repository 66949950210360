import Vue from 'vue';

const requireFilter = require.context('.', false, /\.js$/);
const filterFunctions = {};

requireFilter.keys().forEach(fileName => {
  if (fileName === './index.js') {
    return;
  }

  const filter = requireFilter(fileName).default;
  const filterName = fileName.replace(/(\.\/|\.js)/g, '');
  if (filter) {
    Vue.filter(filterName, filter);
    filterFunctions[filterName] = filter;
  }
});

const FiltersVue = {
  install(V) {
    V.prototype.$filters = filterFunctions;
  }
};
Vue.use(FiltersVue);

export default filterFunctions;
