import Vue from 'vue';
import gql from 'graphql-tag';

class GraphQL {
  queries = {};
  mutations = {};

  constructor() {
    const requireGraph = require.context('.', false, /\.gql$/);
    requireGraph.keys().forEach(fileName => {
      const queryString = requireGraph(fileName).default;
      const query = gql`
        ${queryString}
      `;

      let queryName = fileName.replace(/(\.\/|\.gql)/g, '');
      if (queryName.indexOf('.mutation') > -1) {
        queryName = queryName.replace('.mutation', '');

        try {
          queryName = query.definitions[0].name.value;
        } catch {
          // Continue with filename
        }

        this.mutations[queryName] = query;
      } else {
        try {
          queryName = query.definitions[0].name.value;
        } catch {
          // Continue with filename
        }

        this.queries[queryName] = query;
      }
    });
  }
}

const graphQLInstance = new GraphQL();
const GraphQLQuery = graphQLInstance.queries;
const GraphQLMutation = graphQLInstance.mutations;

const GraphQLVue = {
  install(V) {
    V.prototype.$graphql = graphQLInstance;
  }
};
Vue.use(GraphQLVue);

export { GraphQLQuery, GraphQLMutation };
