<template>
  <ProgressReportSection v-bind="$props">
    <template v-slot:header>
      <slot name="header"></slot>
    </template>
    <div v-if="statistics" class="mt-6 pt-6 border-t border-gray-200">
      <div class="flex flex-row items-center justify-start space-x-4 mb-4">
        <span class="font-semibold text-darkblue text-100">{{ $t('ProgressReport.CollaborationAssessment') }}</span>
        <DropdownSelectElement class="min-w-60" :selectedValue="currentYear" :values="[currentYear]" :mutable="false" />
        <DropdownSelectElement
          v-if="$permissions.has('home-swo-filterenabled')"
          class="min-w-60"
          :values="[
            '',
            'parent',
            'guardian',
            'health_insurer',
            'contact',
            'behavioral_scientist',
            'pedagogical_employee',
            'other'
          ]"
          translationFormat="CollaborationAssessment.Modal.Invite.Role.{0}"
          :selectedValue="highlightedType"
          :placeholder="$t('CollaborationAssessment.Modal.Invite.Role.Placeholder')"
          @selected="filterStatistics"
        />
      </div>
      <div
        v-for="(key, scoreIndex) in Object.keys(statistics.score)"
        :key="scoreIndex"
        class="flex flex-row items-center justify-start w-full h-full text-200 text-lightblue-text px-4 py-2 rounded-md"
        :class="{
          'bg-textfield-disabled': scoreIndex % 2 == 0
        }"
      >
        <div
          class="flex items-center justify-center w-10 h-10 rounded-md text-white text-300 font-bold"
          :class="{
            'bg-progress-fill': (Number(statistics.score[key]) || 0) >= 5.5,
            'bg-gray-200': (Number(statistics.score[key]) || 0) === 0,
            'bg-error-balloon-text':
              (Number(statistics.score[key]) || 0) < 5.5 && (Number(statistics.score[key]) || 0) > 0
          }"
        >
          <span>{{ statistics.score[key] === 'NaN' ? 0 : Number(statistics.score[key]) }}</span>
        </div>

        <div class="flex flex-col items-start justify-center ml-4">
          <span class="font-bold text-darkblue">{{ $t(`Radius.CollaborationAssessment.Summary.Score.${key}`) }}</span>
          <span class="text-100">{{ textualScoreForScore(statistics.score[key]) }}</span>
        </div>
      </div>
    </div>

    <div v-if="statistics" class="mt-6 pt-6 border-t border-gray-20 w-full">
      <span class="block text-200 font-bold text-darkblue mb-4">{{
        $t('ProgressReport.CollaborationAssessment.Title')
      }}</span>
      <div class="flex flex-row justify-between items-center w-full text-100 mb-4">
        <span class="font-bold text-darkblue text-left w-full">{{
          $t('ProgressReport.CollaborationAssessment.Group')
        }}</span>
        <span class="font-bold text-darkblue text-left w-full">{{
          $t('ProgressReport.CollaborationAssessment.Invited')
        }}</span>
        <span class="font-bold text-darkblue text-left w-full">{{
          $t('ProgressReport.CollaborationAssessment.Responded')
        }}</span>
        <span class="font-bold text-darkblue text-left w-full">{{
          $t('ProgressReport.CollaborationAssessment.Percentage')
        }}</span>
      </div>
      <div
        v-for="(type, index) in [
          'parent',
          'guardian',
          'health_insurer',
          'contact',
          'behavioral_scientist',
          'pedagogical_employee',
          'other'
        ]"
        :key="index"
        class="flex flex-row justify-between items-center w-full text-100 px-4 py-2 rounded-md cursor-pointer"
        :class="{
          'bg-textfield-disabled': index % 2 == 0,
          'opacity-50': highlightedType != null && highlightedType !== type
        }"
        @click="filterStatistics(type)"
      >
        <div class="flex flex-row items-center justify-center w-full space-x-2">
          <img class="block w-8 h-8" :src="`@/assets/profile/profile-${index % 4}.svg` | resolve" alt="" />
          <span class="w-full text-left font-semibold text-darkblue text-50">{{
            $t($filters.format('CollaborationAssessment.Modal.Invite.Role.{0}', type))
          }}</span>
        </div>
        <span v-if="collaboration.stats[type]" class="w-full text-left">{{
          collaboration.stats[type].stats.respondent_count
        }}</span>
        <span v-if="collaboration.stats[type]" class="w-full text-left">{{
          collaboration.stats[type].stats.response_count
        }}</span>
        <span v-if="collaboration.stats[type]" class="w-full text-left"
          >{{
            Math.round(
              (Number(
                collaboration.stats[type].stats.response_count / collaboration.stats[type].stats.respondent_count
              ) || 0) * 100
            )
          }}%</span
        >
      </div>
      <div
        v-if="$permissions.has('home-viewcollaborationassessment')"
        class="flex w-full justify-end items-center mt-4"
      >
        <ButtonElement @click.native="analyseAnswers" variant="secondary-small">
          {{ $t('ProgressReport.CollaborationAssessment.Action.Analyse') }}
        </ButtonElement>
      </div>
    </div>
    <template v-slot:footer>
      <slot name="footer"></slot>
    </template>
  </ProgressReportSection>
</template>

<script>
import { GraphQLQuery } from '@/graphql';
import ProgressReportSection from '@/views/ProgressReport/Sections/ProgressReportSection';
import ButtonElement from '@/components/ButtonElement';
import DropdownSelectElement from '@/components/DropdownSelectElement';

export default {
  name: 'ProgressReportSectionCollaborationAssessment',
  extends: ProgressReportSection,
  inheritAttrs: false,
  components: {
    ProgressReportSection,
    DropdownSelectElement,
    ButtonElement
  },
  apollo: {
    collaboration: {
      query: GraphQLQuery.RadiusCollaborationProgressReport,
      update(data) {
        if (!data || !data.collaborations || data.collaborations.length === 0) {
          return null;
        }
        return data.collaborations[0];
      }
    }
  },
  computed: {
    currentYear() {
      if (!this.collaboration) {
        return this.$filters.date(new Date(), 'YYYY');
      }

      return this.$filters.date(this.collaboration.created_at, 'YYYY');
    },

    statistics() {
      if (!this.collaboration || !this.collaboration.stats) {
        return null;
      }

      let filter = 'overall';
      if (this.highlightedType && this.highlightedType.length > 0) {
        filter = this.highlightedType;
      }
      return this.collaboration.stats[filter];
    }
  },
  data() {
    return {
      highlightedType: null
    };
  },
  methods: {
    analyseAnswers() {
      this.$router.push({
        name: 'CollaborationAssessment',
        params: {
          homeID: this.homeID
        }
      });
    },

    filterStatistics(type) {
      if (!this.$permissions.has('home-swo-filterenabled')) {
        return;
      }

      if (this.highlightedType === type) {
        this.highlightedType = null;
        return;
      }

      this.highlightedType = type;
    },

    textualScoreForScore(score) {
      if (score === 'NaN') {
        return this.$t(`Radius.CollaborationAssessment.Summary.Score.Textual.0`);
      }

      return this.$t(`Radius.CollaborationAssessment.Summary.Score.Textual.${Math.floor(score)}`);
    },

    scoreForIndex(key, index) {
      if (!this.statistics.score_detail || !this.statistics.score_detail[key]) {
        return 0;
      }

      let i = index;
      if (i >= 7) {
        i = 0;
      }

      return this.statistics.score_detail[key][i];
    }
  }
};
</script>
