<template>
  <div>
    <InputMessage :placeholder="placeholder" v-model="message" @send="addComment" />
    <div v-for="(comment, index) in comments" :key="index">
      <div
        class="flex mt-6"
        :class="{
          [`animation-list-scale-in animation-once animation-fill-backwards animation-300 animation-delay-${index}`]: true
        }"
      >
        <img
          v-if="comment.user && comment.user.avatar_id"
          class="block w-8 h-8"
          :src="`@/assets/profile/profile-${comment.user.avatar_id}.svg` | resolve"
          alt=""
        />
        <div class="ml-4 w-full">
          <div class="flex w-full justify-between items-center py-1.5">
            <span v-if="comment.user && comment.user.role && comment.user.role.name === 'Admin'" class="">
              Beheerder
            </span>
            <span
              v-else-if="comment.user && comment.user.first_name && comment.user.last_name"
              class="block font-bold text-darkblue text-100"
              >{{ comment.user.first_name + ' ' + comment.user.last_name }}</span
            >
            <span v-else class="block font-bold text-darkblue text-100">
              Gebruiker
            </span>
            <div class="text-50">
              <span class="text-lightblue-hover">{{ comment.created_at | timeago }}</span>
            </div>
          </div>

          <ReadMoreSpan
            v-if="!comment.show_edit_mode"
            class="block mt-2 text-100 w-full"
            :text="comment.message"
            :maxLength="140"
          />
          <InputMessage
            v-if="comment.show_edit_mode"
            :placeholder="placeholder"
            v-model="comment.message"
            @send="editComment(comment)"
          />

          <div
            class="w-full flex justify-end text-25"
            v-if="
              !comment.show_edit_mode &&
                comment.user &&
                ($store.state.currentUser.id === comment.user.id || $permissions.isAdmin())
            "
          >
            <span class="text-lightblue-link" @click="comment.show_edit_mode = true">{{
              $t('Comments.Action.Edit')
            }}</span>
            <span class="text-lightblue mx-2">|</span>
            <span
              class="text-red-500"
              @click="
                $modal.confirm(() => {
                  deleteComment(comment);
                }, 'ConfirmDeleteComment')
              "
              >{{ $t('Comments.Action.Delete') }}</span
            >
          </div>
        </div>
      </div>
      <div class="border-b border-gray-200 w-full mt-4"></div>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import { GraphQLQuery, GraphQLMutation } from '@/graphql';
import InputMessage from '@/components/InputMessage';
import ReadMoreSpan from '@/components/ReadMoreSpan';

export default {
  components: {
    InputMessage,
    ReadMoreSpan
  },
  props: {
    type: {
      required: false,
      type: String,
      default: 'paragraph'
    },
    placeholder: {
      required: true,
      type: String
    },
    newsItem: {
      required: true,
      type: Object
    }
  },
  apollo: {
    comments: {
      query: GraphQLQuery.newsComments,
      pollInterval: 30000,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          newsItem: this.newsItem.id
        };
      },
      update(data) {
        const comments = get(data, 'newsItem.comments', []);
        comments.map(comment => {
          comment.show_edit_mode = false;
          return comment;
        });
        return comments;
      },
      result() {
        this.$forceUpdate();
      }
    }
  },
  data() {
    return {
      message: '',
      activeUserDataParagraphID: 0
    };
  },
  methods: {
    async addComment() {
      if (!this.message || this.message.length === 0) {
        return;
      }

      try {
        await this.$apollo.mutate({
          mutation: GraphQLMutation.newsCommentCreate,
          variables: {
            newsItemID: this.newsItem.id,
            message: this.message.trim()
          },
          update: (
            queryCacheStore,
            {
              data: {
                createComment: { comment }
              }
            }
          ) => {
            const queryInfo = {
              query: GraphQLQuery.newsComments,
              variables: {
                newsItem: Number(this.newsItem.id)
              }
            };

            let data = {};
            try {
              data = queryCacheStore.readQuery(queryInfo);
            } catch {
              data = {
                newsItem: {
                  comments: []
                }
              };
            }

            data.newsItem.comments = [comment].concat(data.newsItem.comments);

            queryCacheStore.writeQuery({
              ...queryInfo,
              data
            });

            this.$apollo.queries.comments.refresh();
          }
        });

        this.message = '';
      } catch {
        this.$modal.show('ErrorModal', {
          title: this.$t('Radius.Error.CommentCreateFailed.Title'),
          error: this.$t('Radius.Error.CommentCreateFailed.Body')
        });
      }
    },
    async deleteComment(comment) {
      try {
        await this.$apollo.mutate({
          mutation: GraphQLMutation.newsCommentDelete,
          variables: {
            commentID: comment.id
          },
          update: queryCacheStore => {
            const queryInfo = {
              query: GraphQLQuery.newsComments,
              variables: {
                newsItem: this.newsItem.id
              }
            };

            const data = queryCacheStore.readQuery(queryInfo);

            data.newsItem.comments = data.newsItem.comments.filter(existingComment => {
              return existingComment.id !== comment.id;
            });

            queryCacheStore.writeQuery({
              ...queryInfo,
              data
            });

            this.$apollo.queries.comments.refresh();
          }
        });
      } catch {
        this.$modal.show('ErrorModal', {
          title: this.$t('Radius.Error.CommentDeleteFailed.Title'),
          error: this.$t('Radius.Error.CommentDeleteFailed.Body')
        });
      }
    },
    async editComment(comment) {
      try {
        await this.$apollo.mutate({
          mutation: GraphQLMutation.newsCommentUpdate,
          variables: {
            commentID: comment.id,
            message: comment.message.trim()
          },
          update: queryCacheStore => {
            const queryInfo = {
              query: GraphQLQuery.newsComments,
              variables: {
                newsItem: this.newsItem.id
              }
            };

            const data = queryCacheStore.readQuery(queryInfo);

            data.newsItem.comments.map(existingComment => {
              if (existingComment.id === comment.id) {
                comment.show_edit_mode = false;
                return comment;
              }
              return existingComment;
            });

            queryCacheStore.writeQuery({
              ...queryInfo,
              data
            });

            this.$apollo.queries.comments.refresh();
          }
        });
      } catch {
        this.$modal.show('ErrorModal', {
          title: this.$t('Radius.Error.CommentCreateFailed.Title'),
          error: this.$t('Radius.Error.CommentCreateFailed.Body')
        });
      }
    }
  }
};
</script>
