<template>
  <ProgressReportSectionFileUpload v-bind="$props">
    <template v-slot:header>
      <span class="block text-theme-300 mb-3"> {{ paragraph.sort_number }}. {{ paragraph.name }} </span>
      <div
        class="flex flex-col sm:flex-row w-full border border border-b-4 border-gray-200 rounded-md bg-white px-8 py-6 items-center mb-10"
      >
        <span
          class="block mt-4 sm:mt-0 order-2 sm:order-1 mr-12 text-theme-150 font-medium text-darkblue whitespace-no-wrap"
        >
          {{ $t('ProgressReport.TotalProgress') }}
        </span>
        <ProgressBar
          v-if="progressReportRadius"
          class="order-1 sm:order-2"
          :percentage="progressReportRadius.progress_percentage"
          :calloutVisible="true"
        />
      </div>
    </template>
  </ProgressReportSectionFileUpload>
</template>

<script>
import { GraphQLQuery } from '@/graphql';
import ProgressReportSectionFileUpload from '@/views/ProgressReport/Sections/ProgressReportSectionFileUpload';
import ProgressBar from '@/components/ProgressBar';

export default {
  name: 'ProgressReportSectionQualityAssessment',
  extends: ProgressReportSectionFileUpload,
  inheritAttrs: false,
  components: {
    ProgressReportSectionFileUpload,
    ProgressBar
  },
  apollo: {
    progressReportRadius: {
      query: GraphQLQuery.ProgressReportRadius,
      fetchPolicy: 'cache-and-network',
      skip() {
        return !this.$store.state.currentUser;
      }
    }
  }
};
</script>
