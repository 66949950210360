<template>
  <ProgressReportSection v-bind="$props">
    <template v-slot:header>
      <slot name="header"></slot>
    </template>
    <div v-if="currentHome" class="mt-8">
      <div v-for="(info, index) in currentHome.professionalization" :key="index">
        <div class="grid grid-cols-2 text-100">
          <span class="col-span-2 font-semibold"
            >{{ info.user.username | empty('-') }} ({{ info.user.email | empty('-') }})</span
          >
          <span>{{ $t('Professionalization.Code') }}</span>
          <span>{{ $t(`Professionalization.Types.${info.type}`) }}: {{ info.code }}</span>
          <span>{{ $t('Professionalization.ExpirationDate') }}</span>
          <span>{{ info.expiration_date | empty('-') }}</span>
        </div>
        <div
          class="w-full px-6 py-4 flex items-center justify-between bg-white text-darkblue rounded-md border border-gray-200 mt-4 mb-1"
          v-for="(doc, documentIndex) in info.related_documents"
          :key="documentIndex"
          :class="{
            [`animation-list-scale-in animation-once animation-fill-backwards animation-300 animation-delay-${documentIndex}`]: true
          }"
        >
          <button
            class="flex text-left items-center focus:outline-none cursor-pointer flex-1"
            @click="$helper.showDocumentDetailsModal(doc, false)"
          >
            <IconFile class="flex-shrink-0" :document="doc" />
            <div class="min-w-2xl ml-8">
              <span class="block text-theme-150 font-semibold">{{ $filters.documentname(doc) }}</span>
              <span class="block text-theme-100">
                <span>Geupload op {{ doc.created_at | date }}</span>
                <span
                  v-if="doc.expiration_date"
                  :class="{
                    'text-red-500 font-semibold': $filters.monthsremaining(doc.expiration_date) <= 0,
                    'text-yellow-400 font-semibold':
                      $filters.monthsremaining(doc.expiration_date) > 0 &&
                      $filters.monthsremaining(doc.expiration_date) <= 2
                  }"
                >
                  - Verloopt op {{ doc.expiration_date | date }}</span
                >
              </span>
            </div>
          </button>
          <div class="flex text-lightblue-hover">
            <button
              class="focus:outline-none w-8 flex-shrink-0 flex justify-center items-center group"
              @click="$helper.downloadDocument(doc)"
            >
              <IconDownload class="flex-shrink-0 group-hover:text-darkblue" />
              <span
                class="absolute py-1 px-2 text-50 bg-darkblue text-white rounded-md transform translate-y-7 opacity-0 invisible group-hover:visible group-hover:opacity-100 transition ease-in-out duration-150"
              >
                {{ $t('Radius.QualityAssessment.Action.DownloadDocument') }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <slot name="footer"></slot>
    </template>
  </ProgressReportSection>
</template>

<script>
import { GraphQLQuery } from '@/graphql';
import ProgressReportSection from '@/views/ProgressReport/Sections/ProgressReportSection';

export default {
  name: 'ProgressReportSectionProfessionalization',
  extends: ProgressReportSection,
  inheritAttrs: false,
  components: {
    ProgressReportSection
  },
  apollo: {
    user: {
      query: GraphQLQuery.Me,
      update(data) {
        this.$store.commit('currentUser', data.me);
        return this.$store.state.currentUser;
      },
      result() {
        this.$forceUpdate();
      }
    }
  },
  computed: {
    currentHome() {
      if (
        !this.$store.state.currentUser ||
        !this.$store.state.currentUser.homes ||
        this.$store.state.currentUser.homes.length === 0
      ) {
        return null;
      }

      if (!this.homeID) {
        return this.$store.state.currentUser.homes[0];
      }

      return this.$store.state.currentUser.homes.find(home => {
        return Number(home.id) === this.homeID;
      });
    }
  }
};
</script>
