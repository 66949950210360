<template>
  <main class="bg-lightblue-background pt-16 relative">
    <img src="@/assets/background.svg" class="absolute right-0 top-0 z-0" />
    <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8 relative z-1" v-if="$store.state.currentUser">
      <div class="flex flex-col sm:flex-row justify-between items-end sm:items-start w-full mb-8 sm:mb-16">
        <div v-if="homeID" class="flex items-center">
          <button
            class="
              border border-gray-100
              bg-white
              rounded-full
              w-10
              h-10
              flex
              items-center
              justify-center
              focus:outline-none
              hover:border-gray-300
              transition
              ease-in-out
              duration-150
            "
            @click="logoutManagedHome"
          >
            <IconChevronLeft />
          </button>
          <span class="text-theme-400 ml-4">
            <span class="font-normal cursor-pointer" @click="logoutManagedHome">{{ $t('Homes.Title') }} / </span>
            <span class="font-bold">{{ $store.state.currentManagedHome.name }}</span>
          </span>
        </div>
        <div v-else class="flex flex-col w-full text-theme-200">
          <h1 class="text-theme-500">
            <span class="font-bold">{{
              $t('Dashboard.Greeting')
                | format(
                  $store.state.currentUser.first_name.length > 0
                    ? $store.state.currentUser.first_name
                    : $store.state.currentUser.username
                )
            }}</span>
          </h1>
          <span class="block mt-2 font-normal text-310">{{ $t('Dashboard.Subgreeting') }}</span>
        </div>
        <HomeImageTile v-if="homeID || !$permissions.has('home-find')" class="relative flex-shrink-0 mt-6 sm:mt-0" />
      </div>
      <div class="block xl:flex">
        <div class="w-full mb-4 xl:mb-0 grid md:grid-cols-2 gap-4 xl:gap-10">
          <component
            v-for="(tileName, index) in activeTiles"
            :key="index"
            v-bind:is="tileName"
            :homeID="homeID"
            :class="{
              [`animation-delay-${index}`]: true
            }"
          ></component>
        </div>

        <DashboardNews />
      </div>
    </div>
  </main>
</template>

<script>
import { GraphQLQuery } from '@/graphql';
import DashboardTile from '@/components/dashboard-tiles/DashboardTile';
import DashboardTileRadius from '@/components/dashboard-tiles/DashboardTileRadius';
import DashboardTileLibrary from '@/components/dashboard-tiles/DashboardTileLibrary';
import DashboardTileWebsite from '@/components/dashboard-tiles/DashboardTileWebsite';
import DashboardTileContact from '@/components/dashboard-tiles/DashboardTileContact';
import DashboardTileHomes from '@/components/dashboard-tiles/DashboardTileHomes';
import DashboardTileQuestionnaire from '@/components/dashboard-tiles/DashboardTileQuestionnaire';
import DashboardTileProgressReport from '@/components/dashboard-tiles/DashboardTileProgressReport';
import DashboardTileExplanationVideos from '@/components/dashboard-tiles/DashboardTileExplanationVideos';
import DashboardTileExternalUsers from '@/components/dashboard-tiles/DashboardTileExternalUsers';
import DashboardTileProfessionalization from '@/components/dashboard-tiles/DashboardTileProfessionalization';
import DashboardTileKrachtInHuis from '@/components/dashboard-tiles/DashboardTileKrachtInHuis';
import DashboardNews from '@/components/DashboardNews';
import HomeImageTile from '@/components/HomeImageTile';

export default {
  name: 'Dashboard',
  components: {
    DashboardTile,
    DashboardTileRadius,
    DashboardTileLibrary,
    DashboardTileWebsite,
    DashboardTileContact,
    DashboardTileHomes,
    DashboardTileQuestionnaire,
    DashboardTileProgressReport,
    DashboardTileExplanationVideos,
    DashboardTileExternalUsers,
    DashboardTileProfessionalization,
    DashboardTileKrachtInHuis,
    DashboardNews,
    HomeImageTile
  },
  apollo: {
    configuration: {
      query: GraphQLQuery.Configuration,
      result() {
        this.$forceUpdate();
      }
    },
    activities: {
      query: GraphQLQuery.Activities,
      result() {
        this.$store.commit('routeUnread', {
          ActivityList: (this.activities || []).filter(activity => {
            return (
              !activity.finished &&
              activity.expiration_date &&
              this.$filters.monthsremaining(activity.expiration_date) <= 2
            );
          }).length
        });
      },
      skip() {
        return !this.$store.state.currentUser || !this.$permissions.has('activity-find');
      }
    }
  },
  computed: {
    homeID() {
      return this.$store.state.currentManagedHome ? Number(this.$store.state.currentManagedHome.id) : undefined;
    },

    activeTiles() {
      const tiles = [];

      tiles.push('DashboardTileExplanationVideos');
      if (this.homeID || !this.$permissions.has('home-find')) {
        if (this.$permissions.hasAll(['document-find', 'chapter-find', 'paragraph-home-find', 'paragraph-find'])) {
          tiles.push('DashboardTileRadius');
        }

        if (this.$permissions.has('progress-report-home-find')) {
          tiles.push('DashboardTileProgressReport');
        }
        // tiles.push('DashboardTileWebsite');
        tiles.push('DashboardTileContact');
        tiles.push('DashboardTileKrachtInHuis');
      }

      if ((this.homeID || !this.$permissions.has('home-find')) && this.$permissions.has('user-upsertexternaluser')) {
        tiles.push('DashboardTileExternalUsers');
      }

      if (
        (this.homeID || !this.$permissions.has('home-find')) &&
        this.$permissions.has('user-canviewprofessionalization')
      ) {
        tiles.push('DashboardTileProfessionalization');
      }

      if (!this.homeID && this.$permissions.has('home-find')) {
        tiles.push('DashboardTileHomes');
      }

      if (
        (!this.$permissions.has('home-find') || this.$permissions.has('document-viewhealthinsurerdocuments')) &&
        this.$permissions.has('document-find') &&
        this.$permissions.hasAny([
          'document-viewhealthinsurerdocuments',
          'document-viewprivatedocuments',
          'document-viewpublicdocuments'
        ])
      ) {
        if (tiles.length > 0) {
          tiles.splice(1, 0, 'DashboardTileLibrary');
        } else {
          tiles.push('DashboardTileLibrary');
        }
      }

      if (this.configuration && (this.configuration.dashboard_tile_questionnaire || '').length > 0) {
        tiles.push('DashboardTileQuestionnaire');
      }

      while (tiles.length < 5) {
        tiles.push('DashboardTile');
      }
      return tiles;
    }
  },
  mounted() {
    this.$store.commit('navigationBarNotice', null);

    if (!this.homeID && !this.$permissions.has('home-viewdashboard')) {
      if (this.$permissions.has('home-find')) {
        this.$router.push({ name: 'Homes' });
      } else {
        this.$router.push({ name: 'Account' });
      }
    }
  },
  methods: {
    logoutManagedHome() {
      this.$modal.confirm(() => {
        this.$store.commit('currentManagedHome', null);
        this.$router.push({ name: 'Homes' });
      }, 'LogoutManagedHome');
    }
  }
};
</script>
