<template>
  <DashboardCard
    :title="$t('Dashboard.Events.Title')"
    url="/jaaroverzicht"
    icon="IconCalendar"
    style="min-height: 341px;"
  >
    <div v-if="$apollo.loading" class="text-100 text-gray-600 text-center">
      {{ $t('General.Loading') }}
    </div>
    <div v-if="events && events.length" class="grid gap-2">
      <router-link
        v-for="(event, index) in events"
        to="/jaaroverzicht"
        :key="index"
        class="rounded-md bg-gray-100 p-2 flex text-100 items-center hover:bg-gray-200 transition-all duration-200"
      >
        <EventsDate v-if="event.start_date" :date="event.start_date" small />
        <div class="pl-4 font-medium text-gray-700 custom-line-clamp">
          {{ event.title }}
        </div>
      </router-link>
    </div>

    <div v-if="!$apollo.loading && (!events || events.length === 0)">
      <p class="text-100 text-gray-600">
        {{ $t('Dashboard.Events.NotFound') }}
      </p>
    </div>
  </DashboardCard>
</template>

<script>
import DashboardCard from '@/components/dashboard/DashboardCard.vue';
import EventsDate from '@/components/events/EventsDate.vue';
import { GraphQLQuery } from '@/graphql';

export default {
  components: {
    DashboardCard,
    EventsDate
  },
  apollo: {
    events: {
      query: GraphQLQuery.Events,
      variables() {
        const startDateString = this.$filters.date(new Date(), 'YYYY-MM-DD');
        return {
          query: { start_date_gte: startDateString },
          limit: 3,
          start: 0
        };
      }
    }
  }
};
</script>

<style scoped>
.custom-line-clamp {
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
</style>
