<template>
  <div
    v-if="visible"
    v-click-outside="hide"
    class="origin-top-right absolute z-10 top-0 text-theme-150 normal-case font-normal right-0 mt-2 w-64 bg-white rounded-md shadow-lg animation-menu-slide-in animation-once animation-fill-both animation-300"
  >
    <slot></slot>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'DropdownElement',
  directives: {
    ClickOutside
  },
  watch: {
    visible: {
      immediate: false,
      handler(newVal) {
        this.visible = newVal;

        setTimeout(() => {
          this.hidingEnabled = newVal;
        }, 100);
      }
    }
  },
  data() {
    return {
      visible: false,
      hidingEnabled: false
    };
  },
  methods: {
    show() {
      this.visible = true;
    },

    hide() {
      if (!this.hidingEnabled) {
        return;
      }
      this.visible = false;
    },

    elementClicked(element) {
      if (element.action) {
        element.action();
        this.hide();
      }
    }
  }
};
</script>
