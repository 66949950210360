<template>
  <div class="relative" v-click-outside="hide">
    <ButtonElement variant="secondary-small" @click.native="open = !open">
      {{ $t('Events.AddToCalendar') }}
    </ButtonElement>
    <div
      v-if="open"
      class="absolute w-full border border-gray-100 bg-white overflow-hidden z-30 right-0 rounded shadow-md text-sm top-full -mt-1"
    >
      <add-to-calendar
        :title="event.title"
        :start="new Date(event.start_date)"
        :end="new Date(event.end_date)"
        :details="event.description"
        inline-template
      >
        <div class="flex flex-col">
          <google-calendar
            id="google-calendar"
            class="text-100 transition-all text-center hover:bg-gray-50 py-2 px-2 font-semibold border-b border-gray-200 text-gray-700 w-full"
          >
            Google calendar
          </google-calendar>

          <microsoft-calendar
            id="microsoft-calendar"
            class="text-100 transition-all text-center hover:bg-gray-50 py-2 px-2 font-semibold border-b border-gray-200 text-gray-700 w-full"
          >
            Microsoft live calendar
          </microsoft-calendar>

          <!--office365-calendar
            id="office365-calendar"
            class="text-100 transition-all text-center hover:bg-gray-50 py-2 px-2 font-semibold border-b border-gray-200 text-gray-700 w-full"
            >
            Outlook calendar
          </office365-calendar-->
        </div>
      </add-to-calendar>
      <div
        @click="addToOutlookCalendar"
        class="text-100 transition-all cursor-pointer text-center hover:bg-gray-50 py-2 px-2 font-semibold border-b border-gray-200 text-gray-700 w-full"
      >
        Outlook calendar
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import ButtonElement from '@/components/ButtonElement';

export default {
  components: {
    ButtonElement
  },

  directives: {
    ClickOutside
  },

  props: {
    event: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      open: false
    };
  },

  methods: {
    hide() {
      this.open = false;
    },

    addToOutlookCalendar() {
      // Generate your custom start and end dates in the desired format
      const start = new Date(this.event.start_date);
      const startDate = `${start.getFullYear()}${(start.getMonth() + 1).toString().padStart(2, '0')}${start
        .getDate()
        .toString()
        .padStart(2, '0')}T${start
        .getHours()
        .toString()
        .padStart(2, '0')}${start
        .getMinutes()
        .toString()
        .padStart(2, '0')}00Z`;

      const end = new Date(this.event.end_date);
      const endDate = `${end.getFullYear()}${(end.getMonth() + 1).toString().padStart(2, '0')}${end
        .getDate()
        .toString()
        .padStart(2, '0')}T${end
        .getHours()
        .toString()
        .padStart(2, '0')}${end
        .getMinutes()
        .toString()
        .padStart(2, '0')}00Z`;

      // Create the iCalendar content
      const calendarContent = `BEGIN:VCALENDAR
BEGIN:VEVENT
DTSTAMP:${new Date().toISOString().replace(/[-:.]/g, '')}Z
DTSTART:${startDate}
DTEND:${endDate}
SUMMARY:${this.event?.title || '-'}
LOCATION:${this.event?.location || '-'}
DESCRIPTION:${this.event?.description || '-'}
END:VEVENT
END:VCALENDAR`;

      const dataUri = `data:text/calendar;charset=utf-8,${encodeURIComponent(calendarContent)}`;
      window.open(dataUri);
    }
  }
};
</script>
