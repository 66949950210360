<template>
  <div class="cursor-pointer h-full flex items-center justify-center">
    <UploadModal
      v-if="$permissions.has('upload-upload')"
      ref="uploadModal"
      :libraryEnabled="true"
      :multiple="false"
      :types="['image']"
      @completed="homeImageChanged"
    />
    <div @click="changeHomeImage" v-if="$permissions.has('upload-upload') || homeImageURL">
      <div v-if="homeImageURL" class="max-w-home-image">
        <img :src="homeImageURL" class="absolute top-0 left-0 w-full h-full object-contain" alt="" />
      </div>
      <div v-if="!homeImageURL" class="flex flex-col justify-center items-center">
        <IconUpload class="w-5 h-5 text-lightblue-hover" />
        <button class="text-lightblue-link font-semibold focus:outline-none">
          {{ $t('Dashboard.Action.UploadHomeImage') }}
        </button>
        <div class="text-50 text-lightblue-hover">
          <span class="mr-1">{{ $t('FileType.image') }}</span>
          <span>{{ $t('FileUpload.FileDescription.SizeLimit') | format(25) }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="homeImageURL && $permissions.has('home-update')"
      @click="changeHomeImage"
      class="absolute top-0 left-0 w-full h-full rounded bg-darkblue-opacity-8 opacity-0 hover:opacity-100 cursor-pointer transition ease-in-out duration-150"
    >
      <div class="flex items-center justify-center h-full w-full">
        <div class="bg-darkblue-opacity-80 p-4 rounded">
          <IconTrash class="text-white" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadModal from '@/components/modals/UploadModal';
import { GraphQLMutation } from '@/graphql';

export default {
  name: 'HomeImageTile',
  components: {
    UploadModal
  },
  computed: {
    homeImageURL() {
      let imageURL = '';

      if (this.$store.state.currentManagedHome) {
        if (this.$store.state.currentManagedHome.logo && this.$store.state.currentManagedHome.logo.file.length > 0) {
          imageURL = this.$store.state.currentManagedHome.logo.file[0].url;
        }
      } else if (
        this.$store.state.currentUser.homes &&
        this.$store.state.currentUser.homes.length > 0 &&
        this.$store.state.currentUser.homes[0].logo &&
        this.$store.state.currentUser.homes[0].logo.file.length > 0
      ) {
        imageURL = this.$store.state.currentUser.homes[0].logo.file[0].url;
      }

      return imageURL.length > 0 ? imageURL : null;
    }
  },
  methods: {
    changeHomeImage() {
      if (!this.$permissions.has('home-update')) {
        return;
      }

      if (this.homeImageURL) {
        this.$modal.confirm(() => {
          this.updateLogoDocumentID(null);
        }, 'RemoveHomeImage');
        return;
      }

      this.$refs.uploadModal.show();
    },

    async homeImageChanged(files) {
      if (!files || files.length === 0) {
        return;
      }

      const logoDocument = files[0];
      await this.updateLogoDocumentID(logoDocument.id);
    },

    async updateLogoDocumentID(logoDocumentID) {
      await this.$apollo.mutate({
        mutation: GraphQLMutation.HomeLogoUpdate,
        variables: {
          logoDocumentID
        },
        update: (
          queryCacheStore,
          {
            data: {
              updateHome: { home }
            }
          }
        ) => {
          if (this.$store.state.currentManagedHome) {
            this.$store.commit('currentManagedHome', home);
          }

          const updatedHomes = this.$store.state.currentUser.homes.map(userHome => {
            if (Number(userHome.id) === Number(home.id)) {
              return home;
            }
            return userHome;
          });

          this.$store.state.currentUser.homes = updatedHomes;
          this.$store.commit('currentUser', this.$store.state.currentUser);
        }
      });
    }
  }
};
</script>
