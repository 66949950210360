<template>
  <main class="bg-gray-100 py-16">
    <div class="max-w-5xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col sm:flex-row justify-between items-center w-full mb-8 sm:mb-16">
        <h1 class="text-theme-500 flex-grow">{{ $t('AccountHome.Title') }}</h1>
      </div>

      <div
        v-if="$store.state.currentUser && home"
        class="bg-white text-theme-150 text-darkblue font-medium rounded-md w-full p-8 sm:p-10 max-w-3xl border-b-4 border-lightblue-border"
      >
        <div v-if="error === 'success'" class="rounded-md bg-green-50 p-4 mb-8">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: mini/check-circle -->
              <svg
                class="h-5 w-5 text-green-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-green-800">Succesvol opgeslagen</h3>
              <div class="mt-2 text-sm text-green-700">
                <p>
                  De wijzigingen zijn succesvol doorgevoerd.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="error !== 'success' && error.length > 0"
          class="rounded-md shadow-sm mb-8 p-4 text-error-balloon-text bg-error-balloon-background width-full text-center flex flex-col animation-error-scale-in animation-once animation-fill-both animation-500"
        >
          <span class="text-sm">{{ error }}</span>
        </div>
        <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-2">
          <span>{{ $t('AccountHome.Name') }}*</span>
          <InputVerify
            class="w-full max-w-account-textfield"
            required
            disabled
            :placeholder="$t('AccountHome.Name')"
            v-model="home.name"
          />
        </div>
        <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-2">
          <span>{{ $t('AccountHome.Address') }}*</span>
          <InputVerify
            class="w-full max-w-account-textfield"
            required
            :placeholder="$t('AccountHome.Name')"
            v-model="home.address"
          />
        </div>

        <div v-if="currentRole === 'parent' || currentRole === 'admin'">
          <div class="flex items-center font-bold text-50 mt-6 mb-2">
            {{ $t('AccountHome.VisibleOnMap') }} <MapChoiceBadge class="flex-shrink-0 ml-2" />
          </div>
          <div class="flex flex-col sm:flex-row justify-between items-start mb-4 sm:mb-2">
            <div class="flex-shrink-0 mr-2 w-1/3 "></div>
            <div class="flex flex-col items-end max-w-account-textfield">
              <div class="flex">
                <button
                  type="button"
                  class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mr-2"
                  :class="[
                    { 'bg-green-500': home.visible_on_map_website },
                    { 'bg-gray-200': !home.visible_on_map_website }
                  ]"
                  role="switch"
                  aria-checked="false"
                  aria-labelledby="availability-label"
                  aria-describedby="availability-description"
                  @click="toggleVisibleOnMapWebsite()"
                >
                  <span
                    aria-hidden="true"
                    class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    :class="[
                      { 'translate-x-5': home.visible_on_map_website },
                      { 'translate-x-0': !home.visible_on_map_website }
                    ]"
                  ></span>
                </button>

                <div class="text-sm text-gray-500" id="availability-description">
                  <div class="text-sm font-medium text-gray-900 mb-1">
                    {{ $t('AccountHome.VisibleOnMapWebsite.DisclaimerTitle') }}
                  </div>
                  <div>{{ $t('AccountHome.VisibleOnMapWebsite.Disclaimer') }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col sm:flex-row justify-between items-start mt-4 sm:mb-2">
            <div class="lg:flex-shrink-0 mr-2 lg:w-1/3 w-full"></div>
            <div class="flex flex-col items-end max-w-account-textfield">
              <div class="flex">
                <button
                  type="button"
                  class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mr-2"
                  :class="[
                    { 'bg-green-500': home.visible_on_map_portal },
                    { 'bg-gray-200': !home.visible_on_map_portal }
                  ]"
                  role="switch"
                  aria-checked="false"
                  aria-labelledby="availability-label"
                  aria-describedby="availability-description"
                  @click="toggleVisibleOnMapPortal()"
                >
                  <span
                    aria-hidden="true"
                    class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    :class="[
                      { 'translate-x-5': home.visible_on_map_portal },
                      { 'translate-x-0': !home.visible_on_map_portal }
                    ]"
                  ></span>
                </button>
                <div class="text-sm text-gray-500" id="availability-description">
                  <div class="text-sm font-medium text-gray-900 mb-1">
                    {{ $t('AccountHome.VisibleOnMapPortal.DisclaimerTitle') }}
                  </div>
                  <div>{{ $t('AccountHome.VisibleOnMapPortal.Disclaimer') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-10 flex flex-col sm:flex-row justify-end items-start sm:items-center">
          * {{ $t('Account.RequiredFields') }}
        </div>

        <div class="w-full mt-8 flex justify-end">
          <ButtonElement variant="primary-small" @click.native="persist">
            {{ $t('Account.Action.Save') }}
          </ButtonElement>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ButtonElement from '@/components/ButtonElement';
import InputVerify from '@/components/InputVerify';
import { GraphQLQuery, GraphQLMutation } from '@/graphql';

export default {
  name: 'AccountHome',
  components: {
    ButtonElement,
    InputVerify
  },
  apollo: {
    user: {
      query: GraphQLQuery.Me,
      update(data) {
        this.$store.commit('currentUser', data.me);
        return this.$store.state.currentUser;
      }
    }
  },
  computed: {
    currentRole() {
      return this.$store.state.currentUser.role.type;
    },

    home() {
      if (this.$store.state.currentManagedHome) {
        return this.$store.state.currentManagedHome;
      }

      if (this.$store.state.currentUser.homes && this.$store.state.currentUser.homes.length > 0) {
        return this.$store.state.currentUser.homes[0];
      }

      return null;
    },

    homeImageURL() {
      return this.home &&
        this.home.logo &&
        this.home.logo.file &&
        this.home.logo.file.length > 0 &&
        this.home.logo.file[0].url &&
        this.home.logo.file[0].url &&
        this.home.logo.file[0].url.length > 0
        ? this.home.logo.file[0].url
        : null;
    }
  },
  data() {
    return {
      error: ''
    };
  },
  methods: {
    toggleVisibleOnMapWebsite() {
      if (this.home.visible_on_map_website === null || this.home.visible_on_map_website === undefined) {
        this.home.visible_on_map_website = true;
      } else {
        this.home.visible_on_map_website = !this.home.visible_on_map_website;
      }
    },
    toggleVisibleOnMapPortal() {
      if (this.home.visible_on_map_portal === null || this.home.visible_on_map_portal === undefined) {
        this.home.visible_on_map_portal = true;
      } else {
        this.home.visible_on_map_portal = !this.home.visible_on_map_portal;
      }
    },
    async persist() {
      try {
        const parameters = {
          visibleOnMapWesbite: this.home.visible_on_map_website || false,
          visibleOnMapPortal: this.home.visible_on_map_portal || false,
          address: this.home.address || ''
        };

        await this.$apollo
          .mutate({
            mutation: GraphQLMutation.HomeUpdate,
            variables: parameters
          })
          .then(() => {
            this.error = 'success';
          });
        // this.error = '';
      } catch (e) {
        this.error = this.$t('Account.Error.UnknownError');
      }
    }
  }
};
</script>
