<template>
  <DashboardCard :title="$t('Dashboard.News.Title')" url="/news" icon="IconNews">
    <div v-if="$apollo.loading" class="text-100 text-gray-600 text-center">
      {{ $t('General.Loading') }}
    </div>
    <router-link
      v-for="article in news"
      :to="`/news/${article.id}`"
      :key="article.id"
      class="flex p-2 rounded-md hover:bg-gray-100 transition-all duration-200"
    >
      <div class="w-16 h-12 relative rounded-md overflow-hidden mr-4 flex-shrink-0 bg-gray-100">
        <img
          class="object-cover absolute inset-0 w-full h-full"
          v-if="article.image && article.image[0]"
          :src="article.image[0].url"
          alt=""
        />
      </div>
      <div>
        <div class="flex gap-1">
          <IconPin v-if="article.pinned" class="w-3 text-darkblue flex-shrink-0" />
          <div class="text-theme-50">{{ article.created_at | date }}</div>
        </div>

        <h4 class="text-100 font-semibold text-gray-800 custom-line-clamp">
          {{ article.title }}
        </h4>
      </div>
    </router-link>
  </DashboardCard>
</template>

<script>
import DashboardCard from '@/components/dashboard/DashboardCard.vue';
import { GraphQLQuery } from '@/graphql';

export default {
  components: {
    DashboardCard
  },
  apollo: {
    news: {
      query: GraphQLQuery.News,
      variables() {
        return {
          query: '',
          limit: 4,
          start: 0
        };
      }
    }
  }
};
</script>

<style scoped>
.custom-line-clamp {
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
</style>
