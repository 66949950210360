<template>
  <main class="bg-gray-100 py-16">
    <div class="max-w-5xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col sm:flex-row justify-between items-center w-full mb-8 sm:mb-16">
        <div class="w-full">
          <h1 class="text-theme-500 flex-grow">{{ $t('ManageUsers.Title') }}</h1>
          <span class="text-theme-250 text-lightblue-text">{{ $t('ManageUsers.Description') }}</span>
        </div>
        <ButtonElement class="flex-grow-0 flex-shrink-0" @click.native="showUserModal()">
          <span class="text-theme-250 text-white">{{ $t('ManageUsers.Action.Add') }}</span>
        </ButtonElement>
      </div>

      <div class="flex flex-col justify-between items-center mb-6">
        <div
          v-for="(user, index) in externalUsers"
          :key="index"
          class="flex flex-row items-center w-full px-6 py-1"
          :class="{
            'bg-white border border-gray-200': index % 2 == 0
          }"
        >
          <img class="block w-10 h-10" :src="`@/assets/profile/profile-${index % 4}.svg` | resolve" alt="" />
          <div class="flex flex-col w-full items-start justify-start py-1.5 ml-4">
            <span class="block font-bold text-darkblue text-100">{{ user.email }}</span>
            <span class="block font-normal text-lightblue-text text-100"
              >{{ user.description }},
              {{ $filters.format($t('ManageUsers.AccessUntil'), $filters.date(user.expiration_date)) }}</span
            >
          </div>
          <div class="text-lightblue-link cursor-pointer text-100 select-none" @click="showUserModal(user)">
            {{ $t('ManageUsers.Action.Edit') }}
          </div>
        </div>
        <div v-if="!externalUsers || externalUsers.length === 0" class="mt-16">
          <span class="text-200 text-lightblue-text">{{ $t('ManageUsers.Empty') }}</span>
        </div>
      </div>

      <div
        v-if="otherUsers && otherUsers.length"
        class="flex flex-col sm:flex-row justify-between items-center w-full mt-16 mb-4 sm:mb-8"
      >
        <div class="w-full">
          <h2 class="text-theme-300 flex-grow">{{ $t('ManageUsers.Other.Title') }}</h2>
          <span class="text-theme-250 text-lightblue-text">{{ $t('ManageUsers.Other.Description') }}</span>
        </div>
      </div>
      <div v-if="otherUsers && otherUsers.length" class="flex flex-col justify-between items-center mb-6">
        <div
          v-for="(user, index) in otherUsers"
          :key="index"
          class="flex flex-row items-center w-full px-6 py-1"
          :class="{
            'bg-white border border-gray-200': index % 2 == 0
          }"
        >
          <img class="block w-10 h-10" :src="`@/assets/profile/profile-${index % 4}.svg` | resolve" alt="" />
          <div class="flex flex-col w-full items-start justify-start py-1.5 ml-4">
            <span class="block font-bold text-darkblue text-100">{{ user.email }}</span>
            <span class="block font-normal text-lightblue-text text-100">{{ user.description }}</span>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ButtonElement from '@/components/ButtonElement';
import { GraphQLQuery } from '@/graphql';

export default {
  name: 'ManageUsers',
  components: {
    ButtonElement
  },
  apollo: {
    users: {
      query: GraphQLQuery.ExternalUsers,
      update(data) {
        return data.getExternalUsers;
      }
    }
  },
  computed: {
    externalUsers() {
      return this.users?.filter(user => user.expiration_date) || [];
    },
    otherUsers() {
      return this.users?.filter(user => !user.expiration_date) || [];
    }
  },
  methods: {
    async showUserModal(user = null) {
      this.$modal.show('AddManagedUserModal', { user });
    }
  }
};
</script>
