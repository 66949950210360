<template>
  <div class="flex flex-col mb-1">
    <div
      class="rounded-md shadow-sm bg-white flex items-center rounded-md relative mb-1 border transition ease-in-out duration-300"
      :class="{
        'border-lightblue hover:border-lightblue-hover focus-within:border-lightblue-hover': !disabled,
        'border-lightblue bg-textfield-disabled': disabled
      }"
    >
      <div class="flex-shrink w-full">
        <input
          class="appearance-none block bg-transparent h-12 w-full px-4 py-3 placeholder-lightblue-text text-100 font-normal text-darkblue focus:outline-none focus:z-10"
          :type="type"
          :required="required"
          :disabled="disabled"
          :placeholder="placeholder"
          :value="value"
          @input="updateValue($event.target.value)"
        />
      </div>
      <slot></slot>
      <div
        class="flex-shrink-0 w-13 h-12 flex justify-center items-center animation-general-slide-in animation-once animation-fill-both animation-500"
        v-if="messageIconVisible || message.length > 0"
      >
        <IconCross v-if="messageVariant == 'error'" class="text-error-balloon-text" />
        <IconCheck v-if="messageVariant == 'success'" class="text-success-balloon-text" />
      </div>
    </div>
    <div
      v-if="message && message.length > 0"
      class="mb-1 rounded-md px-4 py-2 text-theme-150 font-medium"
      :class="{
        'bg-success-balloon-background text-success-balloon-text': messageVariant == 'success',
        'bg-error-balloon-background text-error-balloon-text': messageVariant == 'error'
      }"
    >
      <span>{{ message }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputVerify',
  props: {
    value: {
      type: String
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    type: {
      type: String,
      default: 'text',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    message: {
      type: String,
      default: '',
      required: false
    },
    messageVariant: {
      type: String,
      default: 'success',
      required: false
    },
    messageIconVisible: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {};
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    }
  }
};
</script>
