<template>
  <div
    v-if="visible"
    class="flex items-center justify-center bg-red-500 w-4 h-4 font-bold rounded-full text-white text-50"
  >
    <span>{{ notificationCount }}</span>
  </div>
</template>

<script>
import { GraphQLQuery } from '@/graphql';

export default {
  name: 'MapChoiceBadge',
  apollo: {
    user: {
      query: GraphQLQuery.Me,
      update(data) {
        this.$store.commit('currentUser', data.me);
        return this.$store.state.currentUser;
      }
    }
  },
  computed: {
    notificationCount() {
      if (!this.$apollo.loading && this.home) {
        let count = 0;
        /* eslint-disable no-plusplus */
        if (this.home.visible_on_map_portal === null || this.home.visible_on_map_portal === undefined) count++;
        if (this.home.visible_on_map_website === null || this.home.visible_on_map_website === undefined) count++;
        /* eslint-ensable no-plusplus */
        return count;
      }
      return null;
    },

    home() {
      if (this.$store.state.currentManagedHome) {
        return this.$store.state.currentManagedHome;
      }

      if (this.$store.state.currentUser.homes && this.$store.state.currentUser.homes.length > 0) {
        return this.$store.state.currentUser.homes[0];
      }

      return null;
    },

    visible() {
      return (
        !this.$apollo.loading &&
        this.home &&
        (this.home.visible_on_map_portal === null ||
          this.home.visible_on_map_portal === undefined ||
          this.home.visible_on_map_website === null ||
          this.home.visible_on_map_website === undefined)
      );
    }
  }
};
</script>
