<template>
  <div
    class="rounded-md shadow-sm bg-white flex items-center rounded-md relative border border-lightblue hover:border-lightblue-hover focus-within:border-lightblue-hover"
  >
    <div class="flex-shrink w-full">
      <input
        class="appearance-none block bg-transparent w-full px-4 placeholder-gray-400 text-theme-150 text-gray-600 focus:outline-none focus:z-10 sm:text-sm sm:leading-5"
        :class="[{ 'py-4': variant == 'big' }, { 'py-3': variant === 'medium' }]"
        :placeholder="placeholder"
        :value="value"
        @input="updateValue($event.target.value)"
      />
    </div>
    <button class="flex-shrink-0 w-10 h-9 mr-2 flex justify-center items-center focus:outline-none">
      <IconSearch class="text-gray-400" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'InputVerify',
  props: {
    value: {
      type: String
    },
    type: {
      type: String,
      default: 'text',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    variant: {
      type: String,
      default: 'default',
      required: false
    }
  },
  data() {
    return {
      timeoutReference: null
    };
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);

      if (this.timeoutReference) {
        clearTimeout(this.timeoutReference);
      }
      this.timeoutReference = setTimeout(() => {
        this.$emit('search', value);
      }, 700);
    }
  }
};
</script>
