<template>
  <main class="bg-gray-100 py-16">
    <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
      <RadiusHeader selected="livingenvironmentassessment" simple :title="$t('Radius.EnvironmentAssessment')" />
      <div
        class="bg-white w-full border border-b-4 border-gray-200 rounded-md overflow-hidden text-theme-200 animation-page-scale-in animation-once animation-fill-backwards animation-300"
      >
        <div class="overflow-hidden mb-4 flex justify-center items-center max-h-64">
          <div class="w-full h-full bg-gray-200"></div>
        </div>
        <div class="p-8 pt-0 text-theme-100">
          <span class="block text-theme-300 mb-3 mt-8">
            {{ $t('Radius.EnvironmentAssessment') }}
          </span>
          <ReadMoreSpan
            v-if="
              configuration &&
                configuration.environment_research &&
                configuration.environment_research.environment_research_text.length > 0
            "
            class="block text-darkblue font-medium"
            :text="configuration.environment_research.environment_research_text"
            :maxLength="9000"
            :markdown="true"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { GraphQLQuery } from '@/graphql';
import RadiusHeader from '@/views/Radius/RadiusHeader';
import ReadMoreSpan from '@/components/ReadMoreSpan';

export default {
  name: 'LivingEnvironmentAssessment',
  components: {
    ReadMoreSpan,
    RadiusHeader
  },
  apollo: {
    configuration: {
      query: GraphQLQuery.Configuration
    }
  }
};
</script>
