<template>
  <DashboardTile :homeID="homeID" :to="{ name: 'ProgressReport' }">
    <template v-slot:header>
      <div class="w-full flex group justify-between items-center mb-4">
        <div class="text-theme-300 flex-grow flex-shrink-0">
          <span class="font-semibold text-100 sm:text-200">{{ $t('Dashboard.Widget.ProgressReport.Title') }}</span>
        </div>
      </div>
    </template>
    <div class="flex mt-6 mb-4">
      <DashboardTileLink :title="$t('Dashboard.Widget.ProgressReport.View.Action')" :to="{ name: 'ProgressReport' }" />
    </div>
  </DashboardTile>
</template>

<script>
import DashboardTile from '@/components/dashboard-tiles/DashboardTile';
import DashboardTileLink from '@/components/DashboardTileLink';

export default {
  name: 'DashboardTileProgressReport',
  extends: DashboardTile,
  inheritAttrs: false,
  components: {
    DashboardTile,
    DashboardTileLink
  }
};
</script>
