var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col mb-1"},[_c('div',{staticClass:"rounded-md shadow-sm bg-white flex items-center rounded-md relative mb-1 border transition ease-in-out duration-300",class:{
      'border-lightblue hover:border-lightblue-hover focus-within:border-lightblue-hover': !_vm.disabled,
      'border-lightblue bg-textfield-disabled': _vm.disabled
    }},[_c('div',{staticClass:"flex-shrink w-full"},[_c('input',{staticClass:"appearance-none block bg-transparent h-12 w-full px-4 py-3 placeholder-lightblue-text text-100 font-normal text-darkblue focus:outline-none focus:z-10",attrs:{"type":_vm.type,"required":_vm.required,"disabled":_vm.disabled,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue($event.target.value)}}})]),_vm._t("default"),(_vm.messageIconVisible || _vm.message.length > 0)?_c('div',{staticClass:"flex-shrink-0 w-13 h-12 flex justify-center items-center animation-general-slide-in animation-once animation-fill-both animation-500"},[(_vm.messageVariant == 'error')?_c('IconCross',{staticClass:"text-error-balloon-text"}):_vm._e(),(_vm.messageVariant == 'success')?_c('IconCheck',{staticClass:"text-success-balloon-text"}):_vm._e()],1):_vm._e()],2),(_vm.message && _vm.message.length > 0)?_c('div',{staticClass:"mb-1 rounded-md px-4 py-2 text-theme-150 font-medium",class:{
      'bg-success-balloon-background text-success-balloon-text': _vm.messageVariant == 'success',
      'bg-error-balloon-background text-error-balloon-text': _vm.messageVariant == 'error'
    }},[_c('span',[_vm._v(_vm._s(_vm.message))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }