<template>
  <main class="bg-lightblue-background pt-16 relative pb-20">
    <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8 relative grid gap-4 lg:gap-12">
      <DashboardMessage />
      <DashboardHero />

      <div class="grid lg:grid-cols-3 gap-4 lg:gap-10">
        <DashboardCardChecklist v-if="$permissions.has('checklist-findmy')" />
        <DashboardCardRadius
          v-if="
            (homeID || !$permissions.has('home-find')) &&
              $permissions.hasAll(['document-find', 'chapter-find', 'paragraph-home-find', 'paragraph-find'])
          "
        />

        <DashboardCardNews />
        <DashboardCardEvents />
        <DashboardCardServices
          v-if="
            (homeID || !$permissions.has('home-find')) &&
              $permissions.hasAll(['document-find', 'chapter-find', 'paragraph-home-find', 'paragraph-find'])
          "
        />

        <!-- <DashboardCardReport
          v-if="(homeID || !$permissions.has('home-find')) && $permissions.has('progress-report-home-find')"
        /> -->
        <DashboardCardLibrary
          v-if="
            (!$permissions.has('home-find') || $permissions.has('document-viewhealthinsurerdocuments')) &&
              $permissions.has('document-find') &&
              $permissions.hasAny([
                'document-viewhealthinsurerdocuments',
                'document-viewprivatedocuments',
                'document-viewpublicdocuments'
              ])
          "
        />
      </div>
      <div class="">
        <DashboardMap />
      </div>
    </div>
  </main>
</template>

<script>
import { GraphQLQuery } from '@/graphql';
import DashboardHero from '@/components/dashboard/DashboardHero.vue';
import DashboardCardEvents from '@/components/dashboard/DashboardCardEvents.vue';
import DashboardCardNews from '@/components/dashboard/DashboardCardNews.vue';
import DashboardCardServices from '@/components/dashboard/DashboardCardServices.vue';
import DashboardCardRadius from '@/components/dashboard/DashboardCardRadius.vue';
// import DashboardCardReport from '@/components/dashboard/DashboardCardReport.vue';
import DashboardCardLibrary from '@/components/dashboard/DashboardCardLibrary.vue';
import DashboardCardChecklist from '@/components/dashboard/DashboardCardChecklist.vue';
import DashboardMessage from '@/components/dashboard/DashboardMessage.vue';
import DashboardMap from '@/components/dashboard/DashboardMap.vue';

export default {
  name: 'Dashboard',
  components: {
    DashboardHero,
    DashboardCardEvents,
    DashboardCardNews,
    DashboardCardServices,
    DashboardCardRadius,
    // DashboardCardReport,
    DashboardCardLibrary,
    DashboardCardChecklist,
    DashboardMessage,
    DashboardMap
  },

  computed: {
    homeID() {
      return this.$store.state.currentManagedHome ? Number(this.$store.state.currentManagedHome.id) : undefined;
    }
  },

  mounted() {
    this.$store.commit('navigationBarNotice', null);

    if (!this.homeID && !this.$permissions.has('home-viewdashboard')) {
      if (this.$permissions.has('home-find')) {
        this.$router.push({ name: 'Homes' });
      } else {
        this.$router.push({ name: 'Account' });
      }
    }

    this.$periodicBackgroundSync.add('news-sync', this.handleBackgroundSync.bind(this));

    this.clearNewBadge();
  },

  methods: {
    async handleBackgroundSync() {
      const totalNewsCount = await this.fetchTotalNewsCount();
      const newMessagesCount = totalNewsCount - this.$store.state.seenNewsItemsCount;
      try {
        await navigator.setAppBadge(newMessagesCount);
      } catch (e) {
        // Ignore error
      }
    },

    async clearNewBadge() {
      const totalNewsCount = await this.fetchTotalNewsCount();
      try {
        await navigator.clearAppBadge();
        this.$store.commit('seenNewsItemsCount', totalNewsCount);
      } catch (e) {
        // Ignore error
      }
    },

    async fetchTotalNewsCount() {
      const result = await this.$apollo.query({
        query: GraphQLQuery.NewsCount,
        variables: {
          apolloAccessToken: ''
        }
      });

      const totalNewsCount = result?.data?.newsCount || 0;
      return totalNewsCount;
    }
  }
};
</script>
