import Vue from 'vue';
import ComingSoonModal from '@/components/modals/ComingSoonModal';
import ErrorModal from '@/components/modals/ErrorModal';
import ConfirmModal from '@/components/modals/ConfirmModal';
import DocumentDetailsModal from '@/components/modals/DocumentDetailsModal';
import ActivityListCreateItemModal from '@/components/modals/ActivityListCreateItemModal';
import CollaborationAssessmentInviteModal from '@/components/modals/CollaborationAssessmentInviteModal';
import AddManagedUserModal from '@/components/modals/AddManagedUserModal';
import SignupModal from '@/components/modals/SignupModal';
import DocumentMoveModal from '@/components/modals/DocumentMoveModal';
import AccessModal from '@/components/modals/AccessModal';
import PlanCreatedModal from '@/components/modals/PlanCreatedModal';

class ModalsController {
  #modalVisible = false;

  modalMapping = {
    ComingSoon: ComingSoonModal,
    ErrorModal,
    ConfirmModal,
    DocumentDetailsModal,
    ActivityListCreateItemModal,
    CollaborationAssessmentInviteModal,
    AddManagedUserModal,
    SignupModal,
    DocumentMoveModal,
    AccessModal,
    PlanCreatedModal
  };

  /* eslint-disable */
  async showIfNone(type, parameters = {}) {
    setTimeout(() => {
      if (!this.#modalVisible) {
        this.show(type, parameters);
      }
    }, 100);
  }

  show(type, parameters = {}) {
    this.#modalVisible = true;
    const instance = this.getModalInstance(type, parameters);
    instance.show();
    instance.$on('didhide', () => {
      this.#modalVisible = false;
      window.rootVue.$root.$el.removeChild(instance.$el);
    });

    return instance;
  }

  confirm(completion, variant = 'General') {
    this.#modalVisible = true;
    const instance = this.getModalInstance('ConfirmModal', {
      variant
    });
    instance.show();
    instance.$on('didhide', () => {
      this.#modalVisible = false;
      window.rootVue.$root.$el.removeChild(instance.$el);
    });
    instance.$on('didconfirm', () => {
      if (completion) {
        completion();
      }
    });

    return instance;
  }
  /* eslint-enable */

  getModalInstance(type, parameters = {}) {
    if (!this.modalMapping[type]) {
      return null;
    }

    const ComponentClass = Vue.extend(this.modalMapping[type]);
    const instance = new ComponentClass({
      parent: window.rootVue,
      propsData: parameters
    });
    instance.$mount();
    window.rootVue.$root.$el.appendChild(instance.$el);
    return instance;
  }
}

const modalInstance = new ModalsController();

const ModalsControllerVue = {
  install(V) {
    V.prototype.$modal = modalInstance;
  }
};
Vue.use(ModalsControllerVue);

export default modalInstance;
