import dayjs from 'dayjs';
import 'dayjs/locale/nl';

export default function(value) {
  if (value === null || value === undefined) {
    return null;
  }

  const date = dayjs(value);
  let difference = Number(date.diff(dayjs(), 'month'));

  if (difference === 0 && dayjs().isAfter(date)) {
    difference = -1;
  }

  return difference;
}
