<template>
  <div class="w-full flex justify-end mt-10" v-if="totalPages > 1">
    <button
      class="px-2 flex justify-center items-center rounded-md text-theme-250 text-darkblue font-medium mr-2 focus:outline-none hover:text-white hover:bg-red-500 transition ease-in-out duration-150"
      v-if="current > 1"
      @click="changePage(current - 1)"
    >
      {{ $t('Pagination.Previous') }}
    </button>
    <button
      v-for="index in totalPages"
      :key="index"
      class="w-8 h-8 flex justify-center items-center rounded-md text-theme-250 font-medium mr-2 focus:outline-none hover:text-white hover:bg-red-500 transition ease-in-out duration-150"
      :class="{
        'text-white bg-red-500': index === current,
        'text-darkblue': index !== current,
        hidden: totalPages > 25 && index > 5 && index < totalPages - 5 && Math.abs(index - current) > 2
      }"
      @click="changePage(index)"
    >
      {{ index }}
    </button>
    <button
      class="px-2 flex justify-center items-center rounded-md text-theme-250 text-darkblue font-medium mr-2 focus:outline-none hover:text-white hover:bg-red-500 transition ease-in-out duration-150"
      v-if="current < totalPages"
      @click="changePage(current + 1)"
    >
      {{ $t('Pagination.Next') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    current: {
      type: Number,
      default: 1,
      required: false
    },
    total: {
      type: Number,
      required: true
    }
  },
  watch: {
    total: {
      immediate: true,
      handler(newVal) {
        this.totalPages = Math.ceil(newVal);
      }
    }
  },
  data() {
    return {
      totalPages: 0
    };
  },
  methods: {
    changePage(target) {
      this.$emit('change', target);
    }
  }
};
</script>
