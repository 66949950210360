<template>
  <BaseModal v-bind="$props" :title="$t('Modal.MoveModal.Title')" @backdropClicked="hide">
    <span class="block text-100 w-full sm:w-modal text-black mb-1 font-medium">Selecteer map</span>
    <div class="relative w-full">
      <select
        v-model="selectedFolderId"
        class="rounded-md text-black w-full h-12 border border-gray-200 shadow-sm pl-4 pr-10 text-100 appearance-none cursor-pointer focus:outline-none"
      >
        <option value="0">{{ $t('Modal.MoveModal.Root') }}</option>
        <option v-for="folder of documentFolders" :key="folder.id" :value="folder.id">
          {{ folder.title }}
        </option>
      </select>
      <IconChevronRight class="absolute right-5 top-5 rotate-90 transform pointer-events-none" />
    </div>
    <div class="flex justify-end gap-2 mt-8">
      <ButtonElement @click.native="hide" variant="secondary-small">{{ $t('Modal.Generic.Cancel') }}</ButtonElement>
      <ButtonElement @click.native="moveFile" variant="primary-small">{{ $t('Modal.Generic.Move') }}</ButtonElement>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal';
import ButtonElement from '@/components/ButtonElement';
import { GraphQLQuery, GraphQLMutation } from '@/graphql';

export default {
  name: 'DocumentMoveModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal,
    ButtonElement
  },
  props: {
    doc: {
      type: Object,
      default: null,
      required: true
    },
    documentMoved: {
      type: Function,
      default: () => {},
      required: false
    },
    libraryType: {
      type: String,
      default: 'private',
      required: false
    }
  },
  apollo: {
    documentFolders: {
      query: GraphQLQuery.DocumentFolders,
      variables() {
        return {
          libraryType: this.libraryType
        };
      },
      update(data) {
        return (data?.documentFolders || []).sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      }
    }
  },
  data() {
    return {
      selectedFolderId: 0
    };
  },
  methods: {
    async moveFile() {
      await this.$apollo.mutate({
        mutation: GraphQLMutation.DocumentMoveToFolder,
        variables: {
          documentId: Number(this.doc.id),
          folderId: Number(this.selectedFolderId)
        }
      });

      if (this.documentMoved) {
        this.documentMoved(this.doc);
      }
      this.hide();
    }
  }
};
</script>
