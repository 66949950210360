<template>
  <div class="bg-white relative rounded-md border border-b-4 border-gray-200 sm:flex sm:flex-row-reverse">
    <div class="p-4 flex justify-center items-center">
      <div class="relative" style="width: 160px; height: 112px;">
        <DashboardHeroContactImage />
      </div>
    </div>
    <div
      class="px-5 py-6 text-100 font-medium leading-relaxed border-t sm:border-t-0 sm:border-r border-gray-200 flex-grow"
    >
      <div v-if="contact">
        <p class="font-semibold text-gray-800">{{ $t('Dashboard.Widget.Contact.Title') }}</p>
        <p class="text-gray-800">{{ `${contact.first_name} ${contact.last_name}` }}</p>

        <a v-if="contact.phone" :href="callURL" class="block text-lightblue-link hover:underline">
          {{ contact.phone }}
        </a>
        <a v-if="contact.email" :href="emailURL" class="block text-lightblue-link hover:underline">
          {{ contact.email }}
        </a>
      </div>
      <div v-if="!contact">
        <p class="text-100 text-gray-500">{{ $t('Dashboard.Widget.Contact.EmptyContact') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardHeroContactImage from '@/components/dashboard/DashboardHeroContactImage.vue';

export default {
  components: {
    DashboardHeroContactImage
  },
  computed: {
    currentHome() {
      if (this.$permissions.hasAdminRole() && this.$store.state.currentManagedHome) {
        return this.$store.state.currentManagedHome;
      }

      if (!this.$store.state.currentUser?.homes?.length) {
        return null;
      }

      if (!this.homeID) {
        return this.$store.state.currentUser.homes?.[0] || null;
      }

      return this.$store.state.currentUser.homes.find(home => {
        return Number(home.id) === this.homeID;
      });
    },

    contact() {
      if (!this.currentHome || !this.currentHome.users || this.currentHome.users.length === 0) {
        return null;
      }

      return this.currentHome.users.find(user => {
        return this.$permissions.hasRole('contact', user);
      });
    },

    callURL() {
      if (!this.contact || !this.contact.phone || this.contact.phone.length < 2) {
        return '#';
      }

      const strippedPhoneNumber = this.contact.phone.replace(/\s/g, '');
      return `tel://${strippedPhoneNumber}`;
    },

    emailURL() {
      if (!this.contact || !this.contact.email || this.contact.email.length < 2) {
        return '#';
      }

      return `mailto:${this.contact.email}`;
    }
  }
};
</script>
