<template>
  <div class="bg-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-login w-full">
      <div>
        <img class="mx-auto w-auto" src="@/assets/logo.svg" alt="Workflow" />
        <h2 class="mt-6 text-center text-3xl leading-9 font-bold text-gray-900">
          {{ $t(resetMode ? 'PasswordReset.Change.Title' : 'PasswordReset.Request.Title') }}
        </h2>
      </div>
      <div class="text-theme-200 text-center w-full">
        <span class="whitespace-pre-wrap">{{
          $t(resetMode ? 'PasswordReset.Change.Description' : 'PasswordReset.Request.Description')
        }}</span>
      </div>
      <form class="mt-8" @submit.prevent="formSubmission">
        <InputVerify
          v-if="!resetMode"
          v-model="username"
          :placeholder="$t('PasswordReset.Username')"
          type="text"
          required
          :message="success ? $t('PasswordReset.Success') : error"
          :messageIconVisible="error.length > 0 || success"
          :messageVariant="success ? 'success' : 'error'"
        ></InputVerify>

        <InputVerify
          v-if="resetMode"
          v-model="password"
          :placeholder="$t('PasswordReset.NewPassword')"
          type="password"
          required
          :messageIconVisible="
            error.length > 0 ||
              success ||
              (password.length > 0 && passwordRepeat.length > 0 && password !== passwordRepeat)
          "
          :messageVariant="success ? 'success' : 'error'"
        ></InputVerify>
        <InputVerify
          v-if="resetMode"
          v-model="passwordRepeat"
          :placeholder="$t('PasswordReset.ConfirmNewPassword')"
          type="password"
          required
          :message="success ? $t('PasswordReset.Success') : error"
          :messageIconVisible="
            error.length > 0 ||
              success ||
              (passwordRepeat.length > 0 && passwordRepeat.length > 0 && password !== passwordRepeat)
          "
          :messageVariant="success ? 'success' : 'error'"
        ></InputVerify>

        <div class="text-sm ml-4 leading-5">
          <router-link
            :to="{ name: 'Login' }"
            class="
              font-normal
              text-lightblue-link
              focus:outline-none
              focus:underline
              transition
              ease-in-out
              duration-150
            "
          >
            {{ $t('PasswordReset.Action.BackToLogin') }}
          </router-link>
        </div>

        <div class="mt-6 flex w-full justify-center">
          <ButtonElement type="submit">
            {{ $t(resetMode ? 'PasswordReset.Action.Reset' : 'PasswordReset.Action.Request') }}
          </ButtonElement>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import InputVerify from '@/components/InputVerify';
import ButtonElement from '@/components/ButtonElement';
import { GraphQLMutation } from '@/graphql';

export default {
  name: 'PasswordReset',
  components: {
    InputVerify,
    ButtonElement
  },
  data() {
    return {
      resetMode: this.$route.params.token && this.$route.params.token.length > 0,
      username: this.$store.state.prefilledCredentials.username || '',
      password: '',
      passwordRepeat: '',
      error: '',
      success: false
    };
  },
  methods: {
    async formSubmission() {
      if (this.$route.params.token) {
        return this.performReset();
      }

      return this.requestReset();
    },

    async requestReset() {
      try {
        const response = await this.$apollo.mutate({
          mutation: GraphQLMutation.PasswordRequestReset,
          variables: {
            username: this.username
          }
        });

        if (!response.data.passwordRequestReset.success) {
          throw new Error('Unsuccessful reset.');
        }

        this.success = true;
        this.error = '';
      } catch (e) {
        this.error = this.$t('PasswordReset.Error.UnknownError');
      }
    },

    async performReset() {
      try {
        if (this.password.length === 0 || this.password !== this.passwordRepeat) {
          this.error = this.$t('PasswordReset.Error.PasswordsNoMatch');
          return;
        }

        const response = await this.$apollo.mutate({
          mutation: GraphQLMutation.PasswordReset,
          variables: {
            newPassword: this.password,
            token: this.$route.params.token
          }
        });

        const { jwt, user } = response.data.passwordReset;

        this.$store.commit('accessToken', jwt);
        this.$store.commit('currentUser', user);

        this.$router.push({ name: 'Dashboard' });
      } catch (e) {
        this.error = 'Gebruikersnaam of wachtwoord is ongeldig. Controleer de gegevens en probeer het nogmaals.';
      }
    }
  }
};
</script>
