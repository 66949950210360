<template>
  <DashboardTile :homeID="homeID">
    <template v-slot:header>
      <div class="w-full group flex justify-between items-center mb-4">
        <div class="text-theme-300 flex-grow flex-shrink-0">
          <span class="font-semibold text-100 sm:text-200">{{ $t('Dashboard.Widget.ExplanationVideos.Title') }}</span>
        </div>
      </div>
    </template>
    <div class="h-full w-full overflow-scroll hide-scrollbar space-y-4 py-4 mb-2">
      <a
        href="https://youtu.be/cjkiSzY_shM"
        target="_blank"
        class="text-theme-100 group flex flex-row justify-start items-center hover:text-lightblue-link"
      >
        <IconChevronRightSmall />
        <span class="ml-2 transform transition ease-in-out duration-150 group-hover:translate-x-1">
          {{ $t('Dashboard.Widget.ExplanationVideos.CollaborationAssessmentSending.OpenLink') }}
        </span>
      </a>
      <a
        href="https://youtu.be/xgk5n-8koPc"
        target="_blank"
        class="text-theme-100 group flex flex-row justify-start items-center hover:text-lightblue-link"
      >
        <IconChevronRightSmall />
        <span class="ml-2 transform transition ease-in-out duration-150 group-hover:translate-x-1">
          {{ $t('Dashboard.Widget.ExplanationVideos.CollaborationAssessmentResults.OpenLink') }}
        </span>
      </a>
      <a
        href="https://youtu.be/8pEMSm7L8e4"
        target="_blank"
        class="text-theme-100 group flex flex-row justify-start items-center hover:text-lightblue-link"
      >
        <IconChevronRightSmall />
        <span class="ml-2 transform transition ease-in-out duration-150 group-hover:translate-x-1">
          {{ $t('Dashboard.Widget.ExplanationVideos.Dashboard.OpenLink') }}
        </span>
      </a>
      <a
        href="https://youtu.be/ui96k6RUSEw"
        target="_blank"
        class="text-theme-100 group flex flex-row justify-start items-center hover:text-lightblue-link"
      >
        <IconChevronRightSmall />
        <span class="ml-2 transform transition ease-in-out duration-150 group-hover:translate-x-1">
          {{ $t('Dashboard.Widget.ExplanationVideos.Radius.OpenLink') }}
        </span>
      </a>
      <a
        href="https://youtu.be/h_g9rEP3pi0"
        target="_blank"
        class="text-theme-100 group flex flex-row justify-start items-center hover:text-lightblue-link"
      >
        <IconChevronRightSmall />
        <span class="ml-2 transform transition ease-in-out duration-150 group-hover:translate-x-1">
          {{ $t('Dashboard.Widget.ExplanationVideos.Library.OpenLink') }}
        </span>
      </a>
      <a
        href="https://youtu.be/OoqhZOZoZ7E"
        target="_blank"
        class="text-theme-100 group flex flex-row justify-start items-center hover:text-lightblue-link"
      >
        <IconChevronRightSmall />
        <span class="ml-2 transform transition ease-in-out duration-150 group-hover:translate-x-1">
          {{ $t('Dashboard.Widget.ExplanationVideos.ForgotPassword.OpenLink') }}
        </span>
      </a>
    </div>
  </DashboardTile>
</template>

<script>
import DashboardTile from '@/components/dashboard-tiles/DashboardTile';

export default {
  name: 'DashboardTileExplanationVideos',
  extends: DashboardTile,
  inheritAttrs: false,
  components: {
    DashboardTile
  },
  props: {}
};
</script>
