<template>
  <DashboardTile>
    <template v-slot:header>
      <div class="w-full group flex justify-between items-center mb-4">
        <div class="text-theme-300 flex-grow flex-shrink-0">
          <span class="font-semibold text-100 sm:text-200">{{ $t('Dashboard.Widget.Contact.Title') }}</span>
        </div>
      </div>
    </template>
    <div class="mb-4 flex flex-col items-center justify-center w-full h-full" v-if="!contact">
      <IconExclamationMark class="w-8 h-8 text-lightblue-hover mb-2 flex-shrink-0" />
      <span class="text-50">{{ $t('Dashboard.Widget.Contact.EmptyContact') }}</span>
    </div>
    <span class="mt-6 mb-4" v-if="contact">{{ contact.first_name }} {{ contact.last_name }}</span>
    <div class="text-theme-100" v-if="contact">
      <a
        :href="callURL"
        class="block mb-2 flex flex-row justify-start items-center"
        :class="{
          'text-lightblue-link group': callURL && callURL.length > 2
        }"
      >
        <IconTelephone />
        <span class="ml-2 transform transition ease-in-out duration-150 group-hover:translate-x-1">{{
          contact.phone | empty($t('Dashboard.Widget.Contact.EmptyTelephone'))
        }}</span>
      </a>
      <a
        :href="emailURL"
        class="block mb-2 flex flex-row justify-start items-center"
        :class="{
          'text-lightblue-link group': emailURL && emailURL.length > 2
        }"
      >
        <IconEmail />
        <span class="ml-2 transform transition ease-in-out duration-150 group-hover:translate-x-1">{{
          contact.email | empty($t('Dashboard.Widget.Contact.EmptyEmail'))
        }}</span>
      </a>
    </div>
  </DashboardTile>
</template>

<script>
import DashboardTile from '@/components/dashboard-tiles/DashboardTile';

export default {
  name: 'DashboardTileContact',
  extends: DashboardTile,
  inheritAttrs: false,
  components: {
    DashboardTile
  },
  computed: {
    currentHome() {
      if (this.$permissions.hasAdminRole() && this.$store.state.currentManagedHome) {
        return this.$store.state.currentManagedHome;
      }

      if (
        !this.$store.state.currentUser ||
        !this.$store.state.currentUser.homes ||
        this.$store.state.currentUser.homes.length === 0
      ) {
        return null;
      }

      if (!this.homeID) {
        return this.$store.state.currentUser.homes[0];
      }

      return this.$store.state.currentUser.homes.find(home => {
        return Number(home.id) === this.homeID;
      });
    },

    contact() {
      if (!this.currentHome || !this.currentHome.users || this.currentHome.users.length === 0) {
        return null;
      }

      return this.currentHome.users.find(user => {
        return this.$permissions.hasRole('contact', user);
      });
    },

    callURL() {
      if (!this.contact || !this.contact.phone || this.contact.phone.length < 2) {
        return '#';
      }

      const strippedPhoneNumber = this.contact.phone.replace(/\s/g, '');
      return `tel://${strippedPhoneNumber}`;
    },

    emailURL() {
      if (!this.contact || !this.contact.email || this.contact.email.length < 2) {
        return '#';
      }

      return `mailto:${this.contact.email}`;
    }
  }
};
</script>
