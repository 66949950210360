<template>
  <header class="grid lg:grid-cols-12 items-center gap-10">
    <div class="lg:col-span-6 xl:col-span-7 pr-10">
      <div v-if="homeID" class="flex items-center">
        <button
          class="
          flex-shrink-0
              border border-gray-100
              bg-white
              rounded-full
              w-10
              h-10
              flex
              items-center
              justify-center
              focus:outline-none
              hover:border-gray-300
              transition
              ease-in-out
              duration-150
            "
          @click="logoutManagedHome"
        >
          <IconChevronLeft />
        </button>
        <span class="text-theme-400 ml-4">
          <span class="font-normal cursor-pointer" @click="logoutManagedHome">{{ $t('Homes.Title') }} / </span>
          <span class="font-bold">{{ $store.state.currentManagedHome.name }}</span>
        </span>
      </div>
      <div v-else>
        <h1 class="font-bold text-500 text-gray-800 leading-relaxed">
          {{
            $t('Dashboard.Greeting')
              | format(
                $store.state.currentUser && $store.state.currentUser.first_name.length > 0
                  ? $store.state.currentUser.first_name
                  : $store.state.currentUser.username
              )
          }}
        </h1>
        <p class="text-gray-500 font-medium text-300">{{ $t('Dashboard.Subgreeting') }}</p>
      </div>
    </div>
    <div class="lg:col-span-6 xl:col-span-5">
      <DashboardHeroContact />
    </div>
  </header>
</template>

<script>
import DashboardHeroContact from '@/components/dashboard/DashboardHeroContact.vue';

export default {
  components: {
    DashboardHeroContact
  },

  computed: {
    homeID() {
      return this.$store.state.currentManagedHome ? Number(this.$store.state.currentManagedHome.id) : undefined;
    }
  },

  methods: {
    logoutManagedHome() {
      this.$modal.confirm(() => {
        this.$store.commit('currentManagedHome', null);
        this.$router.push({ name: 'Homes' });
      }, 'LogoutManagedHome');
    }
  }
};
</script>
