<template>
  <DashboardTile
    :to="{
      name: 'QualityAssessment',
      params: {
        homeID
      }
    }"
  >
    <template v-slot:header>
      <div class="w-full flex group justify-between items-center mb-4">
        <div class="text-theme-300 flex-grow flex-shrink-0">
          <span class="font-semibold text-100 sm:text-200">{{ $t('Dashboard.Widget.Radius.Title') }}</span>
        </div>
        <div class="text-theme-300 transform group-hover:translate-x-1 transition ease-in-out duration-150">
          <IconChevronRightBig />
        </div>
      </div>
    </template>
    <div v-if="$permissions.has('home-viewqualityassessment')" class="flex w-full justify-between mt-6 mb-4">
      <DashboardTileLink
        :title="$t('Dashboard.Widget.Radius.Action.QualityAssessment')"
        :to="{
          name: 'QualityAssessment',
          params: {
            homeID
          }
        }"
      />
      <div class="flex items-center" v-if="progressReportRadius">
        <div class="w-10">
          <ProgressBar :percentage="progressReportRadius.progress_percentage"></ProgressBar>
        </div>
        <span class="text-theme-50">
          <span class="text-progress-fill ml-2"> {{ progressReportRadius.progress_percentage }}% </span>
        </span>
      </div>
    </div>
    <div v-if="$permissions.has('home-viewlivingenvironmentassessment')" class="flex mb-4">
      <DashboardTileLink
        :title="$t('Dashboard.Widget.Radius.Action.EnvironmentAssessment')"
        :useClickCallback="true"
        @click="showLivingEnvironmentAssessment"
      />
    </div>
    <div v-if="$permissions.has('home-viewcollaborationassessment')" class="flex items-center group">
      <DashboardTileLink
        :title="$t('Dashboard.Widget.Radius.Action.CollaborationAssessment')"
        :to="{
          name: 'CollaborationAssessment',
          params: {
            homeID
          }
        }"
      />
      <CollaborationAssessmentLabel class="transform transition ease-in-out duration-150 group-hover:translate-x-1" />
    </div>
  </DashboardTile>
</template>

<script>
import DashboardTile from '@/components/dashboard-tiles/DashboardTile';
import DashboardTileLink from '@/components/DashboardTileLink';
import ProgressBar from '@/components/ProgressBar';
import CollaborationAssessmentLabel from '@/components/CollaborationAssessmentLabel';
import { GraphQLQuery } from '@/graphql';

export default {
  name: 'DashboardTileRadius',
  extends: DashboardTile,
  inheritAttrs: false,
  components: {
    DashboardTile,
    DashboardTileLink,
    CollaborationAssessmentLabel,
    ProgressBar
  },
  props: {},
  apollo: {
    progressReportRadius: {
      query: GraphQLQuery.ProgressReportRadius,
      fetchPolicy: 'cache-and-network',
      skip() {
        return !this.$store.state.currentUser;
      }
    },
    configuration: {
      query: GraphQLQuery.Configuration
    }
  },
  methods: {
    displayComingSoonModal(typeText) {
      const bodyText = `1x per jaar word je gevraagd om deel te nemen aan het ${typeText}. Dit wordt uitgevoerd in samenwerking met de Hogeschool Leiden en werkt aan de hand van een digitale vragenlijst. De resultaten krijg je in de vorm van een rapport toegestuurd en kan je uploaden in het portal.`;
      this.$modal.show('ComingSoon', { title: typeText, body: bodyText });
    },

    showLivingEnvironmentAssessment() {
      if (
        this.configuration &&
        this.configuration.environment_research &&
        this.configuration.environment_research.environment_research_enabled
      ) {
        this.navigate('LivingEnvironmentAssessment');
        return;
      }

      this.displayComingSoonModal(this.$t('Radius.EnvironmentAssessment'));
    },

    navigate(name) {
      this.$router.push({
        name,
        params: {
          homeID: this.homeID
        }
      });
    }
  }
};
</script>
