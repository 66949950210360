<template>
  <main class="bg-gray-100 py-16">
    <UploadModal
      ref="uploadModal"
      @completed="uploadCompleted"
      :uploadLibraryType="libraryType"
      :libraryEnabled="false"
      :multiple="!replacementDocument"
      :replacementDocument="replacementDocument"
      :folderID="parentFolderID"
    />
    <CreateFolderModal
      ref="createFolderModal"
      :parent="parentFolderID"
      :library-type="libraryType"
      @completed="folderCreated"
    />
    <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col sm:flex-row justify-between items-center w-full mb-16">
        <LibraryBreadcrumbs
          v-if="parentFolderID"
          :folders="documentFolders"
          :current-folder-id="parentFolderID"
          @traverse="traverseFolder"
        />
        <h1 v-else class="text-theme-500 flex-grow">{{ $t('Library.Title') }}</h1>
      </div>

      <div class="flex flex-col sm:flex-row justify-between items-center mb-6">
        <div class="flex flex-col sm:flex-row flex-grow">
          <button
            v-for="(type, index) in libraryButtons"
            :key="index"
            class="text-theme-250 font-semibold text-gray-500 focus:outline-none hover:text-darkblue transition ease-in-out duration-150 flex-shrink-0 mb-4 sm:mb-0 mr-0 sm:mr-4"
            :class="{ 'underline text-darkblue': libraryType === type }"
            @click="switchLibraryType(type)"
          >
            {{ $t(`Library.Section.${$filters.capitalize(type)}`) }}
          </button>
        </div>
      </div>
      <div class="w-full">
        <div class="flex w-full gap-6 justify-between mb-6">
          <div class="w-full max-w-sm">
            <InputSearch
              class="flex-grow"
              :placeholder="$t('Library.Search.Placeholder')"
              variant="big"
              @search="performSearch"
            ></InputSearch>
          </div>
          <div class="flex gap-x-2 items-center">
            <ButtonElement
              variant="secondary"
              class="flex-grow-0 flex-shrink-0"
              @click.native="$refs.createFolderModal.show()"
              v-if="hasWritePermission"
            >
              <span class="text-theme-250 text-gray-600">{{ $t('Library.Action.CreateFolder') }}</span>
            </ButtonElement>
            <ButtonElement
              class="flex-grow-0 flex-shrink-0"
              @click.native="
                replacementDocument = null;
                $refs.uploadModal.show();
              "
              v-if="hasWritePermission"
            >
              <span class="text-theme-250 text-white">{{ $t('Library.Action.Upload') }}</span>
            </ButtonElement>
          </div>
        </div>

        <LibraryFolders
          v-if="!searchQuery.length"
          :folders="folders"
          :has-write-permission="hasWritePermission"
          @traverse="traverseFolder"
          @deleted="folderDeleted"
        />
        <LibraryLoading v-if="$apollo.loading" />
        <LibraryEmpty v-else-if="!hasResults" />

        <div
          v-if="hasResults"
          class="overflow-x-auto bg-white rounded mb-10 text-black border border-b-4 border-gray-200"
        >
          <table class="documents-table w-full font-medium">
            <tr>
              <th class="p-4 pl-8 text-100 font-semibold text-gray-600 text-left">Document</th>
              <th class="p-4 text-100 font-semibold text-gray-600 text-left">Uploaddatum</th>
              <th class="p-4 text-100 font-semibold text-gray-600 text-left">Vervaldatum</th>
              <th class="p-4 text-100 font-semibold text-gray-600 text-left">Acties</th>
            </tr>
            <tr
              v-for="(doc, index) in searchQuery.length > 0 ? documentsSearch : documents"
              :key="index"
              class="text-100 cursor-pointer"
              @click="$helper.showDocumentDetailsModal(doc)"
            >
              <td class="p-4 pl-8">
                <div class="flex gap-6 items-center">
                  <IconFile class="flex-shrink-0" :document="doc" />
                  <div>
                    <div class="font-semibold text-black">{{ $filters.documentname(doc) }}</div>
                    <div v-if="doc.file.length > 0" class="text-gray-500">{{ doc.file[0].size | filesize }}</div>
                  </div>
                </div>
              </td>
              <td class="p-4">
                {{ doc.created_at | date }}
              </td>
              <td class="p-4">
                <span
                  :class="{
                    'text-red-500 font-semibold': $filters.monthsremaining(doc.expiration_date) <= 0,
                    'text-yellow-400 font-semibold':
                      $filters.monthsremaining(doc.expiration_date) > 0 &&
                      $filters.monthsremaining(doc.expiration_date) <= 2
                  }"
                >
                  {{ doc.expiration_date | date }}
                </span>
              </td>
              <td class="p-4">
                <div class="flex gap-2">
                  <button
                    class="w-10 h-10 rounded bg-white text-black hover:border-gray-400 transition-all duration-200 border border-gray-300 flex items-center justify-center"
                    @click.stop="$helper.downloadDocument(doc)"
                  >
                    <IconDownload />
                  </button>
                  <button
                    v-if="hasWritePermission"
                    class="w-10 h-10 rounded bg-white text-black hover:border-gray-400 transition-all duration-200 border border-gray-300 flex items-center justify-center"
                    @click.stop="
                      $modal.confirm(() => {
                        deleteDocument(doc);
                      }, 'ConfirmDeleteDocument')
                    "
                  >
                    <IconTrash />
                  </button>
                  <button
                    v-if="hasWritePermission"
                    class="w-10 h-10 rounded bg-white text-black hover:border-gray-400 transition-all duration-200 border border-gray-300 flex items-center justify-center"
                    @click.stop="replaceDocument(doc)"
                  >
                    <IconReplace />
                  </button>
                  <button
                    v-if="hasWritePermission"
                    class="w-10 h-10 rounded bg-white text-black hover:border-gray-400 transition-all duration-200 border border-gray-300 flex items-center justify-center"
                    @click.stop="moveToFolder(doc)"
                  >
                    <IconMoveFolder />
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <Pagination
        v-if="hasResults"
        :current="page.current + 1"
        :total="page.total / page.limit"
        @change="pageChanged"
      ></Pagination>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import { GraphQLQuery, GraphQLMutation } from '@/graphql';
import LibraryLoading from '@/components/library/Loading';
import LibraryEmpty from '@/components/library/Empty';
import LibraryFolders from '@/components/library/Folders';
import LibraryBreadcrumbs from '@/components/library/Breadcrumbs';
import ButtonElement from '@/components/ButtonElement';
import Pagination from '@/components/Pagination';
import InputSearch from '@/components/InputSearch';
import UploadModal from '@/components/modals/UploadModal';
import CreateFolderModal from '@/components/modals/CreateFolderModal';

export default {
  name: 'Library',
  components: {
    ButtonElement,
    Pagination,
    LibraryLoading,
    LibraryEmpty,
    LibraryFolders,
    LibraryBreadcrumbs,
    InputSearch,
    UploadModal,
    CreateFolderModal
  },
  apollo: {
    documents: {
      query: GraphQLQuery.Documents,
      variables() {
        return this.documentsVariables;
      },
      skip() {
        return this.searchQuery && this.searchQuery.length > 0;
      },
      update(data) {
        this.page.total = (data.count || []).length;
        return data.documents.slice(0, this.page.limit);
      },
      fetchPolicy: 'network-only'
    },
    documentFolders: {
      query: GraphQLQuery.DocumentFolders,
      variables() {
        return this.documentsVariables;
      },
      skip() {
        return this.searchQuery && this.searchQuery.length > 0;
      },
      update(data) {
        return (data?.documentFolders || []).sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      },
      fetchPolicy: 'network-only'
    },
    documentsSearch: {
      query: GraphQLQuery.DocumentsSearch,
      variables() {
        return {
          query: this.searchQuery,
          limit: this.page.limit,
          start: this.page.current * this.page.limit,
          libraryType: this.libraryType
        };
      },
      skip() {
        return !this.searchQuery || this.searchQuery.length === 0;
      },
      update(data) {
        this.page.total = (data.count || []).length;
        return data.searchDocuments.slice(0, this.page.limit);
      }
    }
  },
  watch: {
    forceUpdate() {
      if (this.forceUpdate) {
        this.$apollo.queries.documents.refetch();
        this.$store.commit('forceUpdate', false);
      }
    }
  },
  computed: {
    ...mapGetters(['forceUpdate']),

    hasResults() {
      return (this.documents || this.documentsSearch || [])?.length > 0;
    },

    hasWritePermission() {
      let canWrite = false;
      if (this.libraryType === 'private') {
        canWrite = !this.$permissions.has('home-find') || Boolean(this.$store.state.currentManagedHome);
      } else if (this.libraryType === 'healthinsurer') {
        canWrite = this.$permissions.has('document-managehealthinsurerdocuments') || this.$permissions.hasAdminRole();
      } else if (this.libraryType === 'public') {
        canWrite = this.$permissions.hasAdminRole();
      }

      return canWrite;
    },
    documentsVariables() {
      const variables = {
        limit: this.page.limit,
        start: this.page.current * this.page.limit,
        libraryType: this.libraryType,
        parentFolderID: this.parentFolderID || undefined
      };
      return variables;
    },
    documentSearchVariables() {
      const variables = {
        query: this.searchQuery,
        limit: this.page.limit,
        start: this.page.current * this.page.limit,
        libraryType: this.libraryType
      };
      return variables;
    },
    folders() {
      return (this.documentFolders || []).filter(folder => {
        if (!folder.folder) {
          return this.parentFolderID === null;
        }

        return Number(folder.folder.id) === (this.parentFolderID || null);
      });
    }
  },
  data() {
    return {
      libraryType: 'private',
      parentFolderID: null,
      libraryButtons: [],
      searchQuery: '',
      replacementDocument: null,
      page: {
        limit: 15,
        current: 0,
        total: 0
      }
    };
  },
  mounted() {
    this.setupLibraryButtons();

    let targetLibraryType = 'private';
    if (this.$route.params.type === 'zorgaanbieder') {
      targetLibraryType = 'healthinsurer';
    } else if (
      this.$route.params.type === 'publiek' ||
      this.$permissions.has('home-find') ||
      this.$permissions.isAdmin()
    ) {
      targetLibraryType = 'public';
    }

    if (this.libraryButtons.indexOf(targetLibraryType) > -1) {
      this.libraryType = targetLibraryType;
    } else if (this.libraryButtons.length === 1) {
      [this.libraryType] = this.libraryButtons;
    }
  },
  methods: {
    traverseFolder(folder) {
      this.parentFolderID = folder ? Number(folder.id) : null;
      this.$apollo.queries.documents.refetch();
    },

    folderDeleted() {
      this.$apollo.queries.documents.refetch();
      this.$apollo.queries.documentFolders.refetch();
    },

    folderCreated() {
      this.$apollo.queries.documentFolders.refetch();
    },

    setupLibraryButtons() {
      if (!this.$permissions.has('home-find') || this.$store.state.currentManagedHome) {
        if (this.$permissions.has('document-viewprivatedocuments')) {
          this.libraryButtons.push('private');
        }
      }

      if (this.$permissions.has('document-viewpublicdocuments')) {
        this.libraryButtons.push('public');
      }

      if (this.$permissions.has('document-viewhealthinsurerdocuments')) {
        if (this.$permissions.isAdmin()) {
          if (this.$store.state.currentManagedHome) {
            this.libraryButtons.push('healthinsurer');
          }
        } else {
          this.libraryButtons.push('healthinsurer');
        }
      }
    },

    performSearch(query) {
      this.searchQuery = query;
      this.page.current = 0;
    },

    pageChanged(current) {
      this.page.current = current - 1;
    },

    uploadCompleted() {
      this.page.current = 0;
      this.$apollo.queries.documents.refresh();
    },

    switchLibraryType(type) {
      this.libraryType = type;
      this.page.current = 0;
      this.parentFolderID = null;
      this.$apollo.queries.documents.refetch();
      this.$apollo.queries.documentFolders.refetch();
    },

    replaceDocument(targetDocument) {
      this.replacementDocument = targetDocument;
      this.$refs.uploadModal.show();
    },

    moveToFolder(doc) {
      this.$modal.show('DocumentMoveModal', {
        doc,
        libraryType: this.libraryType,
        documentMoved: () => {
          this.$apollo.queries.documents.refresh();
        }
      });
    },

    async deleteDocument(targetDocument) {
      try {
        await this.$apollo.mutate({
          mutation: GraphQLMutation.DocumentDelete,
          variables: {
            documentID: targetDocument.id
          },
          update: (queryCacheStore, { data: removedDocument }) => {
            const queryInfo = {
              query: GraphQLQuery.Documents,
              variables: {
                limit: this.page.limit,
                start: this.page.current * this.page.limit,
                libraryType: this.libraryType
              }
            };

            const data = queryCacheStore.readQuery(queryInfo);
            data.documents = data.documents.filter(doc => {
              return doc.id !== removedDocument.deleteDocument.document.id;
            });
            data.count = (data.count || []).filter(doc => {
              return doc.id !== removedDocument.deleteDocument.document.id;
            });

            queryCacheStore.writeQuery({
              ...queryInfo,
              data
            });

            this.$apollo.queries.documents.refresh();
          }
        });
      } catch (error) {
        if (error.graphQLErrors && error.graphQLErrors[0].message === 'document.inUse') {
          this.$modal.show('ErrorModal', {
            title: this.$t('Library.Error.DocumentInUse.Title'),
            error: this.$t('Library.Error.DocumentInUse.Body')
          });
        }
      }
    }
  }
};
</script>

<style scoped>
.documents-table tr:nth-child(even) {
  @apply bg-gray-100;
}
</style>
