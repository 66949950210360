var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"bg-lightblue-background pt-16 relative pb-20"},[_c('div',{staticClass:"max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8 relative grid gap-4 lg:gap-12"},[_c('DashboardMessage'),_c('DashboardHero'),_c('div',{staticClass:"grid lg:grid-cols-3 gap-4 lg:gap-10"},[(_vm.$permissions.has('checklist-findmy'))?_c('DashboardCardChecklist'):_vm._e(),(
          (_vm.homeID || !_vm.$permissions.has('home-find')) &&
            _vm.$permissions.hasAll(['document-find', 'chapter-find', 'paragraph-home-find', 'paragraph-find'])
        )?_c('DashboardCardRadius'):_vm._e(),_c('DashboardCardNews'),_c('DashboardCardEvents'),(
          (_vm.homeID || !_vm.$permissions.has('home-find')) &&
            _vm.$permissions.hasAll(['document-find', 'chapter-find', 'paragraph-home-find', 'paragraph-find'])
        )?_c('DashboardCardServices'):_vm._e(),(
          (!_vm.$permissions.has('home-find') || _vm.$permissions.has('document-viewhealthinsurerdocuments')) &&
            _vm.$permissions.has('document-find') &&
            _vm.$permissions.hasAny([
              'document-viewhealthinsurerdocuments',
              'document-viewprivatedocuments',
              'document-viewpublicdocuments'
            ])
        )?_c('DashboardCardLibrary'):_vm._e()],1),_c('div',{},[_c('DashboardMap')],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }