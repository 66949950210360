<template>
  <span
    v-if="collaboration"
    class="
      flex flex-shrink-0
      justify-center
      items-center
      p-2
      h-4
      ml-1
      text-50
      font-semibold
      rounded-full
      truncate
      uppercase
      no-underline
    "
    :class="{
      'bg-progress-fill text-white': status === 'open',
      'text-darkblue bg-status-pending': status === 'send',
      'text-white bg-progress-fill': status === 'finished',
      'text-white bg-error-balloon-text': status === 'notsend'
    }"
  >
    {{ statusText }}
  </span>
</template>

<script>
import get from 'lodash/get';
import { GraphQLQuery } from '@/graphql';

export default {
  name: 'CollaborationAssessmentLabel',
  props: {
    collaborationAssessmentYear: {
      type: String,
      default: '',
      required: false
    }
  },
  apollo: {
    collaborations: {
      query: GraphQLQuery.RadiusCollaboration
    },
    chapters: {
      query: GraphQLQuery.RadiusQualityChapters
    },
    progress: {
      query: GraphQLQuery.RadiusQualityChaptersProgress,
      variables() {
        return {
          homeID: this.homeID
        };
      }
    }
  },
  computed: {
    homeID() {
      return this.$store.state.currentManagedHome ? Number(this.$store.state.currentManagedHome.id) : undefined;
    },

    status() {
      if (!this.collaboration) {
        return '-';
      }

      const useChapterStatus = true;
      if (useChapterStatus) {
        const chapterSwo = this.chapters?.find(chapter => chapter.name === 'Samenwerkingsonderzoek') ?? null;
        const chapterSwoProgress = this.progress?.find(
          progress => Number(progress?.paragraph?.chapter?.id) === Number(chapterSwo.id)
        );

        const status = chapterSwoProgress ? 'finished' : 'notsend';
        return status;
      }

      return this.collaboration.status;
    },

    statusText() {
      if (!this.status) {
        return '-';
      }

      return this.$t(`Radius.CollaborationAssessment.Status.${this.status}`).toUpperCase();
    },

    collaboration() {
      const collabs = get(this, 'collaborations', []) || [];
      if (collabs.length === 0) {
        return null;
      }

      for (let i = 0; i < collabs.length; i += 1) {
        const year = this.$filters.date(collabs[i].swo.end_date, 'YYYY');
        if (
          !this.collaborationAssessmentYear ||
          this.collaborationAssessmentYear.length === 0 ||
          year === this.collaborationAssessmentYear
        ) {
          return collabs[i];
        }
      }

      return null;
    }
  }
};
</script>
