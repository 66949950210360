var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ProgressReportSection',_vm._b({scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._t("header")]},proxy:true},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true}],null,true)},'ProgressReportSection',_vm.$props,false),[(_vm.statistics)?_c('div',{staticClass:"mt-6 pt-6 border-t border-gray-200"},[_c('div',{staticClass:"flex flex-row items-center justify-start space-x-4 mb-4"},[_c('span',{staticClass:"font-semibold text-darkblue text-100"},[_vm._v(_vm._s(_vm.$t('ProgressReport.CollaborationAssessment')))]),_c('DropdownSelectElement',{staticClass:"min-w-60",attrs:{"selectedValue":_vm.currentYear,"values":[_vm.currentYear],"mutable":false}}),(_vm.$permissions.has('home-swo-filterenabled'))?_c('DropdownSelectElement',{staticClass:"min-w-60",attrs:{"values":[
          '',
          'parent',
          'guardian',
          'health_insurer',
          'contact',
          'behavioral_scientist',
          'pedagogical_employee',
          'other'
        ],"translationFormat":"CollaborationAssessment.Modal.Invite.Role.{0}","selectedValue":_vm.highlightedType,"placeholder":_vm.$t('CollaborationAssessment.Modal.Invite.Role.Placeholder')},on:{"selected":_vm.filterStatistics}}):_vm._e()],1),_vm._l((Object.keys(_vm.statistics.score)),function(key,scoreIndex){return _c('div',{key:scoreIndex,staticClass:"flex flex-row items-center justify-start w-full h-full text-200 text-lightblue-text px-4 py-2 rounded-md",class:{
        'bg-textfield-disabled': scoreIndex % 2 == 0
      }},[_c('div',{staticClass:"flex items-center justify-center w-10 h-10 rounded-md text-white text-300 font-bold",class:{
          'bg-progress-fill': (Number(_vm.statistics.score[key]) || 0) >= 5.5,
          'bg-gray-200': (Number(_vm.statistics.score[key]) || 0) === 0,
          'bg-error-balloon-text':
            (Number(_vm.statistics.score[key]) || 0) < 5.5 && (Number(_vm.statistics.score[key]) || 0) > 0
        }},[_c('span',[_vm._v(_vm._s(_vm.statistics.score[key] === 'NaN' ? 0 : Number(_vm.statistics.score[key])))])]),_c('div',{staticClass:"flex flex-col items-start justify-center ml-4"},[_c('span',{staticClass:"font-bold text-darkblue"},[_vm._v(_vm._s(_vm.$t(`Radius.CollaborationAssessment.Summary.Score.${key}`)))]),_c('span',{staticClass:"text-100"},[_vm._v(_vm._s(_vm.textualScoreForScore(_vm.statistics.score[key])))])])])})],2):_vm._e(),(_vm.statistics)?_c('div',{staticClass:"mt-6 pt-6 border-t border-gray-20 w-full"},[_c('span',{staticClass:"block text-200 font-bold text-darkblue mb-4"},[_vm._v(_vm._s(_vm.$t('ProgressReport.CollaborationAssessment.Title')))]),_c('div',{staticClass:"flex flex-row justify-between items-center w-full text-100 mb-4"},[_c('span',{staticClass:"font-bold text-darkblue text-left w-full"},[_vm._v(_vm._s(_vm.$t('ProgressReport.CollaborationAssessment.Group')))]),_c('span',{staticClass:"font-bold text-darkblue text-left w-full"},[_vm._v(_vm._s(_vm.$t('ProgressReport.CollaborationAssessment.Invited')))]),_c('span',{staticClass:"font-bold text-darkblue text-left w-full"},[_vm._v(_vm._s(_vm.$t('ProgressReport.CollaborationAssessment.Responded')))]),_c('span',{staticClass:"font-bold text-darkblue text-left w-full"},[_vm._v(_vm._s(_vm.$t('ProgressReport.CollaborationAssessment.Percentage')))])]),_vm._l(([
        'parent',
        'guardian',
        'health_insurer',
        'contact',
        'behavioral_scientist',
        'pedagogical_employee',
        'other'
      ]),function(type,index){return _c('div',{key:index,staticClass:"flex flex-row justify-between items-center w-full text-100 px-4 py-2 rounded-md cursor-pointer",class:{
        'bg-textfield-disabled': index % 2 == 0,
        'opacity-50': _vm.highlightedType != null && _vm.highlightedType !== type
      },on:{"click":function($event){return _vm.filterStatistics(type)}}},[_c('div',{staticClass:"flex flex-row items-center justify-center w-full space-x-2"},[_c('img',{staticClass:"block w-8 h-8",attrs:{"src":_vm._f("resolve")(`@/assets/profile/profile-${index % 4}.svg`),"alt":""}}),_c('span',{staticClass:"w-full text-left font-semibold text-darkblue text-50"},[_vm._v(_vm._s(_vm.$t(_vm.$filters.format('CollaborationAssessment.Modal.Invite.Role.{0}', type))))])]),(_vm.collaboration.stats[type])?_c('span',{staticClass:"w-full text-left"},[_vm._v(_vm._s(_vm.collaboration.stats[type].stats.respondent_count))]):_vm._e(),(_vm.collaboration.stats[type])?_c('span',{staticClass:"w-full text-left"},[_vm._v(_vm._s(_vm.collaboration.stats[type].stats.response_count))]):_vm._e(),(_vm.collaboration.stats[type])?_c('span',{staticClass:"w-full text-left"},[_vm._v(_vm._s(Math.round( (Number( _vm.collaboration.stats[type].stats.response_count / _vm.collaboration.stats[type].stats.respondent_count ) || 0) * 100 ))+"%")]):_vm._e()])}),(_vm.$permissions.has('home-viewcollaborationassessment'))?_c('div',{staticClass:"flex w-full justify-end items-center mt-4"},[_c('ButtonElement',{attrs:{"variant":"secondary-small"},nativeOn:{"click":function($event){return _vm.analyseAnswers.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('ProgressReport.CollaborationAssessment.Action.Analyse'))+" ")])],1):_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }