<template>
  <main class="bg-gray-100 py-16">
    <div class="max-w-5xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col sm:flex-row justify-between items-center w-full mb-8">
        <button
          class="border bg-white border-lightblue hover:border-lightblue-hover transition ease-in-out duration-150 text-darkblue w-10 h-10 mr-4 rounded-full justify-center items-center flex flex-shrink-0 focus:outline-none"
          @click="$router.push({ name: 'News' })"
        >
          <IconChevronLeft />
        </button>
        <h1 class="text-theme-500 flex-grow">{{ $t('News.Title') }}</h1>
      </div>
      <div
        v-if="article"
        class="bg-white w-full border border-b-4 border-gray-200 rounded-md overflow-hidden text-theme-200 animation-page-scale-in animation-once animation-fill-backwards animation-300"
      >
        <div class="overflow-hidden mb-4 flex justify-center items-center max-h-64">
          <div v-if="hasImage" class="w-full flex items-center justify-center relative">
            <img class="w-full opacity-25" style="filter: blur(5px)" :src="article.image[0].url" alt="" />
            <img class="absolute w-full max-w-xl h-64 object-contain" :src="article.image[0].url" alt="" />
          </div>
          <div v-else class="w-full h-full bg-gray-200"></div>
        </div>
        <div class="p-8 pt-0 text-theme-100">
          <div class="flex items-center gap-x-2">
            <IconPin v-if="article.pinned" class="w-3 text-darkblue" />
            <span class="block text-theme-50">{{ article.created_at | date }}</span>
          </div>
          <span
            class="block text-theme-300 mb-3"
            :class="{
              'mt-3': !hasImage
            }"
          >
            {{ article.title }}
          </span>
          <ReadMoreSpan
            class="block text-darkblue font-medium"
            :text="article.text"
            :maxLength="9000"
            :markdown="true"
          />
        </div>
      </div>

      <Comments
        v-if="$permissions.has('comment-create') && article?.show_comments"
        class="w-full xl:min-w-radius-comments mt-4"
        :placeholder="$t('Radius.QualityAssessment.Comments.Placeholder')"
        :newsItem="article"
        type="news"
      />
    </div>
  </main>
</template>

<script>
import { GraphQLQuery } from '@/graphql';
import ReadMoreSpan from '@/components/ReadMoreSpan';
import Comments from '@/components/CommentsNews';

export default {
  name: 'NewsDetail',
  components: {
    ReadMoreSpan,
    Comments
  },
  apollo: {
    article: {
      query: GraphQLQuery.NewsArticle,
      variables() {
        return {
          articleID: this.$route.params.articleID
        };
      },
      skip() {
        return !this.$route.params.articleID;
      }
    }
  },
  computed: {
    hasImage() {
      return this.article && this.article.image && this.article.image[0] && this.article.image[0].url.length > 0;
    }
  },
  mounted() {
    this.$apollo.queries.article.refresh();
  }
};
</script>
