<template>
  <div class="bg-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-login w-full">
      <div>
        <img class="mx-auto w-auto" src="@/assets/logo.svg" alt="Gezinshuis" />
      </div>
      <form class="mt-10" @submit.prevent="login">
        <InputVerify
          v-model="username"
          :placeholder="$t('Login.Username')"
          type="text"
          required
          :messageIconVisible="error.length > 0"
          messageVariant="error"
        ></InputVerify>

        <InputVerify
          v-model="password"
          :placeholder="$t('Login.Password')"
          type="password"
          required
          :message="error"
          :messageIconVisible="error.length > 0"
          messageVariant="error"
        ></InputVerify>

        <div class="mt-4 flex justify-between flex-col ml-4">
          <div class="flex">
            <div class="text-sm leading-5">
              <router-link
                :to="{ name: 'PasswordReset' }"
                class="font-normal text-lightblue-link focus:outline-none focus:underline transition ease-in-out duration-150"
                >{{ $t('Login.Action.ForgotPassword') }}
              </router-link>
            </div>
          </div>
          <div class="flex mt-6 justify-between items-center">
            <div class="flex">
              <input
                id="remember_me"
                type="checkbox"
                class="h-4 w-4 transition duration-150 ease-in-out mt-0.5"
                v-model="persistCredentials"
              />
              <label for="remember_me" class="ml-2 text-sm font-normal text-darkblue">
                {{ $t('Login.RememberMe') }}
              </label>
            </div>
            <ButtonElement type="submit">
              {{ $t('Login.Action.Login') }}
            </ButtonElement>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import InputVerify from '@/components/InputVerify';
import ButtonElement from '@/components/ButtonElement';
import { GraphQLMutation } from '@/graphql';

export default {
  name: 'Login',
  components: {
    InputVerify,
    ButtonElement
  },
  data() {
    return {
      username: this.$store.state.prefilledCredentials.username || '',
      password: '',
      persistCredentials: true,
      error: ''
    };
  },
  methods: {
    async login() {
      try {
        this.$store.commit('loading', true);
        this.$store.commit('logout');
        this.$store.commit('navigationBarNotice', null);

        const response = await this.$apollo.mutate({
          mutation: GraphQLMutation.Login,
          variables: {
            username: this.username,
            password: this.password
          }
        });

        const { jwt, user } = response.data.login;

        this.$store.commit('accessToken', jwt);
        this.$store.commit('prefilledCredentials', {
          username: this.persistCredentials ? this.username : '',
          email: this.persistCredentials ? user.email : '',
          password: ''
        });

        this.$store.commit('currentUser', user);
        this.$store.commit('loading', false);

        this.$router.push({ name: 'Dashboard' });
      } catch (error) {
        this.$store.commit('loading', false);

        if (error.networkError) {
          if (error.networkError.statusCode >= 400 && error.networkError.statusCode <= 403) {
            this.error = this.$t('Login.Error.UnknownError');
            return;
          }
        }

        this.error = this.$t('Login.Error.UnknownError');
      }
    }
  }
};
</script>
