import Vue from 'vue';
import Vuex from 'vuex';
import resolve from '@/filters/resolve';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loading: false,
    accessToken: null,
    currentUser: null,
    currentManagedHome: null,
    prefilledCredentials: {},
    routeUnread: {},
    navigationBarNotice: null,
    seenNewsItemsCount: 0,
    permissions: [],
    forceUpdate: false
  },
  mutations: {
    loadPersistedStore(state) {
      try {
        let data = localStorage.getItem('persisted_store');
        if (!data) {
          throw new Error('No store data');
        }

        data = JSON.parse(data);

        delete data.loading;
        delete data.forceUpdate;

        this.replaceState({
          ...state,
          ...data
        });
      } catch {
        // Continue with an empty store.
      }
    },

    forceUpdate(state, value) {
      state.forceUpdate = value;
    },

    loading(state, value) {
      state.loading = value === true;
    },

    navigationBarNotice(state, value) {
      state.navigationBarNotice = value;
    },

    seenNewsItemsCount(state, value) {
      state.seenNewsItemsCount = value;
    },

    accessToken(state, value) {
      state.accessToken = value;
    },

    currentUser(state, value) {
      if (value) {
        const avatarID = value.avatar_id || 0;
        const avatarURL = resolve(`@/assets/profile/profile-${avatarID}.svg`);
        value.avatarURL = avatarURL;

        if (value.homes && value.homes.length > 0) {
          if (!value?.homes?.[0]?.users?.length) {
            return;
          }

          const selfUser = value.homes[0].users.find(homeUser => {
            return value.id === homeUser.id;
          });

          this.commit('permissions', selfUser.role.permissions);
        }
      }

      state.currentUser = value;
    },

    currentManagedHome(state, value) {
      if (value === undefined || value === null || value === false) {
        value = null;
      }
      state.currentManagedHome = value;
    },

    prefilledCredentials(state, value) {
      state.prefilledCredentials = value;
    },

    permissions(state, value) {
      const permissions = (value || []).filter(item => {
        return item.enabled;
      });
      state.permissions = permissions;
    },

    logout(state) {
      state.currentUser = null;
      state.accessToken = null;
      state.currentManagedHome = null;
      state.permissions = null;

      if (window.rootVue) {
        window.rootVue.$apollo.provider.defaultClient.resetStore();
      }
    },

    routeUnread(state, value) {
      Object.assign(state.routeUnread, value);
    }
  },
  actions: {},
  modules: {},
  getters: {
    application() {
      return {
        commit: process.env.VERSION_COMMIT,
        build: process.env.VERSION_BUILD,
        date: process.env.VERSION_DATE
      };
    },

    forceUpdate(state) {
      return state.forceUpdate;
    }
  }
});

// Initialize persistance
store.commit('loadPersistedStore');
store.subscribe((mutation, state) => {
  if (localStorage) {
    localStorage.setItem('persisted_store', JSON.stringify(state));
  }
});

export default store;
