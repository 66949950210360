<template>
  <BaseModal v-bind="$props" :title="$t('CollaborationAssessment.Modal.Invite.ModalTitle')" :withBottomDivider="true">
    <template>
      <div class="flex flex-col w-full text-theme-100 sm:min-w-modal-collaboration-invite">
        <div class="flex flex-row justify-between items-start space-x-2">
          <DropdownSelectElement
            class="w-1/2"
            :hasError="error.length > 0 && (!role || role.length === 0)"
            :values="[
              'parent',
              'guardian',
              'health_insurer',
              'contact',
              'behavioral_scientist',
              'pedagogical_employee',
              'other'
            ]"
            translationFormat="CollaborationAssessment.Modal.Invite.Role.{0}"
            :selectedValue="role"
            :placeholder="$t('CollaborationAssessment.Modal.Invite.Role.Placeholder')"
            @selected="roleSelected"
          />
          <InputMessage
            class="w-full"
            :hasError="error.length > 0 && (!email || email.length === 0)"
            :multiline="false"
            :placeholder="$t('CollaborationAssessment.Modal.Invite.EmailAddress.Placeholder')"
            v-model="email"
            @send="invite"
            iconVariant="add"
          />
        </div>
        <div
          v-if="error.length > 0"
          class="
            rounded-md
            shadow-sm
            p-2
            text-error-balloon-text
            bg-error-balloon-background
            width-full
            text-center
            flex flex-col
            animation-error-scale-in animation-once animation-fill-both animation-500
          "
        >
          <span class="text-sm">{{ error }}</span>
        </div>
        <span class="text-100 font-normal mt-4 mb-2">{{
          $t('CollaborationAssessment.Modal.Invite.SendList') | format(inviteList.length)
        }}</span>
        <div
          class="max-h-64 overflow-scroll py-4 space-y-1"
          :class="{
            'h-64': !collaboration.swo_respondents || collaboration.swo_respondents.length === 0
          }"
        >
          <div
            v-if="
              inviteList.length === 0 &&
                lastCollaboration &&
                lastCollaboration.swo_respondents &&
                lastCollaboration.swo_respondents.length > 0
            "
          >
            <div
              class="
                flex flex-row
                justify-start
                items-center
                w-full
                cursor-pointer
                text-lightblue-link text-100
                font-semibold
                focus:outline-none
                focus:underline
                transition
                ease-in-out
                duration-150
              "
              @click="fillLastYear"
            >
              <IconAdd class="mr-2" />
              <span>{{ $t('CollaborationAssessment.Modal.Invite.UseLastYear', [lastYear]) }}</span>
            </div>
          </div>
          <div v-for="(invitee, index) in inviteList" :key="index" class="flex flex-row items-center w-full">
            <img class="block w-10 h-10" :src="`@/assets/profile/profile-${index % 4}.svg` | resolve" alt="" />
            <div class="flex flex-col w-full items-start justify-start py-1.5 ml-4">
              <span class="block font-bold text-darkblue text-100">{{ invitee.email }}</span>
              <span class="block font-normal text-lightblue-text text-100">{{
                $t($filters.format('CollaborationAssessment.Modal.Invite.Role.{0}', invitee.role))
              }}</span>
            </div>
            <IconTrash class="mr-2 hover:text-lightblue-hover cursor-pointer" @click.native="remove(index)" />
          </div>
        </div>
        <span
          v-if="collaboration.swo_respondents && collaboration.swo_respondents.length > 0"
          class="text-100 font-normal mt-4 mb-2"
          >{{
            $t('CollaborationAssessment.Modal.Invite.InviteList') | format(collaboration.swo_respondents.length)
          }}</span
        >
        <div
          v-if="collaboration.swo_respondents && collaboration.swo_respondents.length > 0"
          class="h-64 overflow-scroll py-4 space-y-1"
        >
          <div
            v-for="(invitee, index) in collaboration.swo_respondents"
            :key="index"
            class="flex flex-row items-center w-full"
          >
            <img class="block w-10 h-10" :src="`@/assets/profile/profile-${index % 4}.svg` | resolve" alt="" />
            <div class="flex flex-col w-full items-start justify-start py-1.5 ml-4">
              <div class="flex flex-row items-center gap-2 font-bold text-darkblue text-100">
                <span>{{ invitee.email }}</span>
                <div class="rounded-full text-progress-fill bg-light-green p-1">
                  <IconCheckSmall />
                </div>
              </div>
              <span class="block font-normal text-lightblue-text text-100">{{
                $t($filters.format('CollaborationAssessment.Modal.Invite.Role.{0}', invitee.segment))
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:bottom>
      <div class="flex flex-col-reverse sm:flex-row w-full items-center justify-end p-6 pb-4 sm:pb-6">
        <div
          v-if="inviteSent"
          class="
            my-2
            sm:my-0
            sm:ml-2
            flex-grow flex-shrink-0
            text-center
            sm:text-left
            text-success-balloon-text
            font-bold
            text-200
          "
        >
          <span>{{ $t('CollaborationAssessment.Modal.InvitesSent') }}</span>
        </div>
        <ButtonElement class="my-2 sm:my-0 sm:mr-2" variant="secondary-small" @click.native="hide">{{
          $t('Modal.Generic.Cancel')
        }}</ButtonElement>
        <ButtonElement
          :class="{
            'opacity-25 pointer-events-none': sendButtonDisabled
          }"
          @click.native="confirm"
          variant="primary-small"
          >{{ $t('CollaborationAssessment.Modal.Send.Action') }}</ButtonElement
        >
      </div>
    </template>
  </BaseModal>
</template>

<script>
import get from 'lodash/get';
import BaseModal from '@/components/modals/BaseModal';
import { GraphQLQuery, GraphQLMutation } from '@/graphql';
import ButtonElement from '@/components/ButtonElement';
import InputMessage from '@/components/InputMessage';
import DropdownSelectElement from '@/components/DropdownSelectElement';

export default {
  name: 'CollaborationAssessmentInviteModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal,
    ButtonElement,
    DropdownSelectElement,
    InputMessage
  },
  props: {
    collaborationID: {
      type: Number,
      required: true
    }
  },
  apollo: {
    collaborations: {
      query: GraphQLQuery.RadiusCollaboration
    }
  },
  data() {
    return {
      email: '',
      role: '',
      error: '',
      inviteList: [],
      sendButtonDisabled: true,
      inviteSent: false
    };
  },
  computed: {
    collaboration() {
      const collabs = get(this, 'collaborations', []) || [];
      if (collabs.length === 0) {
        return null;
      }

      for (let i = 0; i < collabs.length; i += 1) {
        const year = this.$filters.date(collabs[i].swo.end_date, 'YYYY');
        if (!this.selectedYear || year === this.selectedYear) {
          return collabs[i];
        }
      }

      return null;
    },

    lastCollaboration() {
      let foundCurrentYear = false;
      for (let i = 0; i < this.collaborations.length; i += 1) {
        const collab = this.collaborations[i];
        if (collab.id === this.collaboration.id) {
          foundCurrentYear = true;
        } else if (foundCurrentYear) {
          return collab;
        }
      }

      return null;
    },

    lastYear() {
      if (!this.lastCollaboration) {
        return null;
      }

      const year = this.$filters.date(this.lastCollaboration.swo.end_date, 'YYYY');
      return year;
    }
  },
  methods: {
    isValidEmailAddress() {
      if (!this.email || this.email.length === 0) {
        return false;
      }

      /* eslint-disable no-useless-escape */
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      /* eslint-enable no-useless-escape */
      return re.test(String(this.email).toLowerCase());
    },

    async fillLastYear() {
      if (
        !this.lastCollaboration ||
        !this.lastCollaboration.swo_respondents ||
        this.lastCollaboration.swo_respondents.length === 0
      ) {
        return;
      }

      this.error = '';
      this.email = '';
      this.role = '';

      const invited = get(this, 'collaboration.swo_respondents', []) || [];

      for (let i = 0; i < this.lastCollaboration.swo_respondents.length; i += 1) {
        const respondent = this.lastCollaboration.swo_respondents[i];
        const email = respondent.email.toLowerCase();
        const role = respondent.segment;

        const existingEntry =
          invited.find(entry => {
            return entry.email === email && entry.segment === role;
          }) || null;

        if (!existingEntry) {
          this.inviteList.push({
            email,
            role
          });
        }
      }

      this.sendButtonDisabled = this.inviteList.length === 0;
    },

    async invite() {
      this.inviteSent = false;

      if (!this.email || this.email.length === 0 || !this.role || !this.role.length === 0) {
        this.error = this.$t('CollaborationAssessment.Modal.Error.EmptyFields');
        return;
      }

      if (!this.isValidEmailAddress()) {
        this.error = this.$t('CollaborationAssessment.Modal.Error.InvalidEmail');
        return;
      }

      for (let i = 0; i < this.inviteList.length; i += 1) {
        if (this.inviteList[i].email.toLowerCase() === this.email.toLowerCase()) {
          this.error = this.$t('CollaborationAssessment.Modal.Error.EmailAlreadyExists');
          return;
        }
      }

      this.error = '';
      this.inviteList.push({
        email: this.email.toLowerCase(),
        role: this.role
      });

      this.email = '';
      this.role = '';
      this.sendButtonDisabled = this.inviteList.length === 0;
    },

    roleSelected(role) {
      if (!role || role.length === 0) {
        this.error = this.$t('CollaborationAssessment.Modal.Error.EmptyFields');
        return;
      }

      this.role = role;
      this.error = '';
    },

    async remove(index) {
      this.inviteList.splice(index, 1);

      this.sendButtonDisabled = this.inviteList.length === 0;
    },

    async confirm() {
      if (!this.collaboration) {
        this.error = this.$t('Modal.Error.UnknownError.Body');
        return;
      }

      if (this.inviteList.length === 0) {
        this.error = this.$t('CollaborationAssessment.Modal.Error.EmptyList');
        return;
      }

      this.error = '';
      const respondentList = this.inviteList.map(invitee => {
        return {
          email: invitee.email,
          segment: invitee.role
        };
      });

      try {
        await this.$apollo.mutate({
          mutation: GraphQLMutation.RadiusCollaborationInvite,
          variables: {
            homeSwoID: Number(this.collaboration.id),
            respondents: respondentList
          },
          update: (queryCacheStore, { data: { sendInvites } }) => {
            if (sendInvites && sendInvites.id) {
              const queryInfo = {
                query: GraphQLQuery.RadiusCollaboration
              };

              const data = queryCacheStore.readQuery(queryInfo);
              data.collaborations = data.collaborations.map(collaboration => {
                collaboration.swo_respondents = collaboration.swo_respondents.concat(respondentList);
                return collaboration;
              });
              queryCacheStore.writeQuery({
                ...queryInfo,
                data
              });

              this.inviteList = [];
              this.$apollo.queries.collaborations.refresh();
              this.inviteSent = true;
              this.sendButtonDisabled = true;
            } else {
              this.error = this.$t('Modal.Error.UnknownError.Body');
            }
          }
        });
      } catch {
        this.error = this.$t('Modal.Error.UnknownError.Body');
      }
    }
  }
};
</script>
