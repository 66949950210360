var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.paragraphs),function(paragraph,index){return _c('div',{key:index,staticClass:"flex flex-col select-none",class:{
      [`animation-list-scale-in animation-once animation-fill-backwards animation-300 animation-delay-${index}`]: true
    }},[_c('div',{staticClass:"flex items-center flex-shrink-0 text-theme-250 cursor-pointer",on:{"click":function($event){return _vm.changeActiveParagraph(index)}}},[_c('div',{staticClass:"flex justify-center items-center rounded-full border-2 w-8 h-8 z-10 transition ease-in-out duration-150 flex-shrink-0",class:{
          'bg-success-balloon-text border-textfield-disabled text-white':
            !_vm.isActive(paragraph) && _vm.isFinished(paragraph),
          'bg-white border-lightblue text-darkblue': _vm.isActive(paragraph),
          'bg-lightblue border-textfield-disabled text-white': !_vm.isActive(paragraph) && !_vm.isFinished(paragraph)
        }},[(_vm.isFinished(paragraph))?_c('IconCheck',{class:{
            [`animation-general-overscale-in animation-once animation-fill-backwards animation-300 animation-delay-${6 +
              index * 2}`]: true
          }}):(_vm.hasExpiredDocuments(paragraph))?_c('IconExclamationMark',{class:{
            [`w-5 h-5 text-error-balloon-text animation-general-overscale-in animation-once animation-fill-backwards animation-300 animation-delay-${6 +
              index * 2}`]: true
          }}):_c('span',{staticClass:"font-semibold text-50",class:{
            [`animation-general-overscale-in animation-once animation-fill-backwards animation-300 animation-delay-${6 +
              index * 2}`]: true
          }},[_vm._v(" "+_vm._s(index + 1)+" ")])],1),_c('span',{staticClass:"text-100 font-medium ml-2 truncate w-full md:w-40",class:{
          'text-lightblue-text': paragraph.id !== _vm.paragraphs[_vm.activeParagraph].id,
          'text-darkblue': paragraph.id === _vm.paragraphs[_vm.activeParagraph].id
        }},[_vm._v(" "+_vm._s(paragraph.name)+" ")])]),_c('div',{staticClass:"w-8 h-3 flex justify-center flex-grow"},[_c('div',{staticClass:"h-full w-2px",class:{
          'bg-success-balloon-text': _vm.isFinished(_vm.nextParagraph(index)),
          'bg-lightblue': true,
          hidden: index + 1 >= _vm.paragraphs.length
        }})])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }