<template>
  <div class="flex items-center gap-1 w-full mb-8">
    <button
      class="
            border
            bg-white
            border-lightblue
            hover:border-lightblue-hover
            transition
            ease-in-out
            duration-150
            text-darkblue
            w-10
            h-10
            mr-4
            rounded-full
            justify-center
            items-center
            flex flex-shrink-0
            focus:outline-none
          "
      @click="back"
    >
      <IconChevronLeft />
    </button>
    <span v-for="(folder, index) of foldersAbove" :key="index" class="text-theme-300 cursor-pointer">
      <span @click="traverse(folder)" class="font-medium font-310 text-gray-500 hidden md:block"
        >{{ folder.title }} /</span
      >
    </span>
    <span v-if="currentFolder" class="block text-theme-300">{{ currentFolder.title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    folders: {
      type: Array,
      default: () => [],
      required: false
    },
    currentFolderId: {
      type: Number,
      default: -1,
      required: false
    }
  },
  computed: {
    foldersAbove() {
      return this.findParentFoldersById(this.currentFolderId, true).reverse();
    },

    currentFolder() {
      return this.folders.find(f => Number(f.id) === Number(this.currentFolderId));
    }
  },
  methods: {
    back() {
      const last = this.foldersAbove[this.foldersAbove.length - 1] || null;
      this.$emit('traverse', last);
    },

    traverse(folder) {
      this.$emit('traverse', folder);
    },

    findParentFoldersById(folderId, ignoreFirst = false) {
      const folder = this.folders.find(f => Number(f.id) === Number(folderId));
      if (!folder) {
        return [];
      }

      const parentFolders = [];

      if (!ignoreFirst) {
        parentFolders.push(folder);
      }

      if (folder.folder) {
        parentFolders.push(...this.findParentFoldersById(folder.folder.id));
      }

      return parentFolders;
    }
  }
};
</script>
