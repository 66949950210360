<template>
  <div class="space-y-1" v-click-outside="close">
    <div class="relative">
      <span class="flex justify-start items-center w-full h-12">
        <button
          type="button"
          aria-haspopup="listbox"
          aria-expanded="true"
          class="relative w-full h-full text-left text-100 focus:outline-none focus:shadow-outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-md shadow-sm flex items-center border transition ease-in-out duration-300 mt-1 p-2 pr-10"
          :class="{
            'border-lightblue hover:border-lightblue-hover focus-within:border-lightblue-hover': mutable,
            'cursor-default border-lightblue': !mutable,
            'bg-error-balloon-background border-error-balloon-text text-error-balloon-text': hasError,
            'bg-white': !hasError
          }"
          @click="toggle"
        >
          <span class="text-lightblue-text" v-if="selection < 0">{{ placeholder }}</span>
          <span class="text-darkblue truncate" v-if="selection >= 0">{{
            $t($filters.format(translationFormat, values[selection]))
          }}</span>
          <span v-if="mutable" class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.293 2.293a1 1 0 011.32-.083l.094.083L8 4.585l2.293-2.292a1 1 0 011.32-.083l.094.083a1 1 0 01.083 1.32l-.083.094-3 3a1 1 0 01-1.32.083l-.094-.083-3-3a1 1 0 010-1.414z"
                fill="currentColor"
                fill-rule="nonzero"
              />
            </svg>
          </span>
        </button>
      </span>

      <div
        class="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg animation-general-slide-y-in animation-once animation-100"
        v-if="mutable && opened"
      >
        <ul
          tabindex="-1"
          role="listbox"
          class="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
        >
          <li
            v-for="(value, index) in values"
            :key="index"
            @click="valueSelected(value)"
            role="option"
            class="text-gray-900 hover:text-white hover:font-semibold hover:bg-gray-500 cursor-pointer select-none relative text-100"
          >
            <div class="flex items-center px-2 py-2 space-x-2">
              <span class="block truncate">
                {{ $t($filters.format(translationFormat, value ? value : 'All')) }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'DropdownSelectElement',
  directives: {
    ClickOutside
  },
  props: {
    values: {
      type: Array,
      default() {
        return [];
      },
      required: false
    },
    selectedValue: {
      type: [Object, String, Number, Boolean, Array, Date],
      default: null,
      required: false
    },
    translationFormat: {
      type: String,
      default: '{0}',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    mutable: {
      type: Boolean,
      default: true,
      required: false
    },
    hasError: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  watch: {
    selectedValue: {
      handler(value) {
        for (let i = 0; i < this.values.length; i += 1) {
          if (this.values[i].length > 0 && value === this.values[i]) {
            this.selection = i;
            this.$forceUpdate();
            return;
          }
        }

        this.selection = -1;
      },
      immediate: true
    }
  },
  data() {
    return {
      opened: false
    };
  },
  methods: {
    toggle() {
      this.opened = !this.opened;
    },

    close() {
      this.opened = false;
    },

    valueSelected(value) {
      if (!value || value.length === 0) {
        this.$emit('selected', null);
        this.close();
        return;
      }
      this.selection = this.values.indexOf(value);
      this.$emit('selected', value);
      this.close();
    }
  }
};
</script>
