import store from '@/store';

const GuardInactiveSession = onFailRouterOptions => {
  return (to, from, next) => {
    if (store.state.accessToken && store.state.currentUser) {
      next(onFailRouterOptions);
      return;
    }
    next();
  };
};

export default GuardInactiveSession;
