var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"bg-gray-100 py-16"},[_c('div',{staticClass:"max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8"},[_c('RadiusHeader',{attrs:{"selected":"collaborationassessment","collaborationAssessmentYear":_vm.selectedYear,"simple":"","title":_vm.$t('Radius.CollaborationAssessment.Title', [_vm.selectedYear])}}),_c('div',{staticClass:"flex flex-row animation-page-scale-in animation-once animation-fill-backwards animation-300"},[_c('div',{staticClass:"flex-shrink-0 mr-10 min-w-64 max-w-sm"},[_c('span',{staticClass:"font-semibold text-darkblue text-50"},[_vm._v(_vm._s(_vm.$t('Radius.CollaborationAssessment.SelectYear')))]),_c('DropdownSelectElement',{attrs:{"selectedValue":_vm.currentYear,"values":_vm.availableYears,"mutable":_vm.availableYears.length > 0},on:{"selected":_vm.changeYear}}),(_vm.collaboration && (_vm.collaboration.status === 'notsend' || _vm.collaboration.status === 'finished'))?_c('div',{staticClass:"w-full mt-4"},[_c('span',{staticClass:"font-semibold text-darkblue text-50"},[_vm._v(_vm._s(_vm.$t('Radius.CollaborationAssessment.Summary.Filter')))]),_c('DropdownSelectElement',{attrs:{"values":[
              '',
              'parent',
              'guardian',
              'health_insurer',
              'contact',
              'behavioral_scientist',
              'pedagogical_employee',
              'other'
            ],"translationFormat":"CollaborationAssessment.Modal.Invite.Role.{0}","selectedValue":_vm.highlightedType,"placeholder":_vm.$t('CollaborationAssessment.Modal.Invite.Role.Placeholder')},on:{"selected":_vm.filterStatistics}}),(_vm.collaboration.status === 'finished')?_c('div',{staticClass:"flex items-center justify-center w-full mt-8"},[_c('ButtonElement',{staticClass:"my-2 sm:my-0 sm:mr-2 w-full",attrs:{"variant":"primary-small"},nativeOn:{"click":function($event){return _vm.downloadResults.apply(null, arguments)}}},[(_vm.downloadingResults)?_c('img',{staticClass:"w-8 h-8",attrs:{"src":require("@/assets/loading.gif"),"alt":"Loading"}}):_vm._e(),(!_vm.downloadingResults)?_c('span',[_vm._v(_vm._s(_vm.$t('Radius.CollaborationAssessment.DownloadResults')))]):_vm._e()])],1):_vm._e(),_c('div',{staticClass:"flex flex-col bg-white w-full mt-10 p-8 border border-b-4 border-gray-200 rounded-md text-theme-100"},[_c('span',{staticClass:"text-theme-250 text-darkblue font-semibold"},[_vm._v(_vm._s(_vm.$t('Radius.CollaborationAssessment.Summary.Title')))]),_c('span',{staticClass:"text-100"},[_vm._v(_vm._s(_vm._f("format")(_vm.$t('Radius.CollaborationAssessment.Summary.Description'),_vm.responsePercentage)))]),_vm._l(([
                'parent',
                'guardian',
                'health_insurer',
                'contact',
                'behavioral_scientist',
                'pedagogical_employee',
                'other'
              ]),function(type,index){return _c('div',{key:index,staticClass:"flex flex-row items-center w-full mt-4 cursor-pointer select-none",class:{
                'opacity-50': _vm.highlightedType != null && _vm.highlightedType !== type
              },on:{"click":function($event){return _vm.filterStatistics(type)}}},[_c('img',{staticClass:"block w-8 h-8",attrs:{"src":_vm._f("resolve")(`@/assets/profile/profile-${index % 4}.svg`),"alt":""}}),_c('div',{staticClass:"flex flex-col w-full items-start justify-start py-1.5 ml-4"},[_c('span',{staticClass:"block font-bold text-darkblue text-50"},[_vm._v(_vm._s(_vm.$t(_vm.$filters.format('CollaborationAssessment.Modal.Invite.Role.{0}', type))))]),_c('span',{staticClass:"block font-normal text-lightblue-text text-50"},[_vm._v(_vm._s(_vm._f("format")(_vm.$t('Radius.CollaborationAssessment.Summary.Participated'),_vm.statsForType(type))))])])])})],2)],1):_vm._e(),(!_vm.collaboration || _vm.collaboration.status === 'open' || _vm.collaboration.status === 'send')?_c('div',{staticClass:"flex flex-col bg-white w-full mt-10 p-8 border border-b-4 border-gray-200 rounded-md text-theme-100"},[_c('span',{staticClass:"text-theme-250 text-darkblue font-semibold mb-4"},[_vm._v(_vm._s(_vm.$t('Radius.CollaborationAssessment.Invites.Title')))]),_c('span',{staticClass:"text-100"},[_vm._v(_vm._s(_vm.$t('Radius.CollaborationAssessment.Invites.Description')))]),_c('div',{staticClass:"flex flex-col items-center justify-center my-8 h-64 border border-gray-200 rounded-md"},[(!_vm.collaboration || !_vm.collaboration.swo_respondents || _vm.collaboration.swo_respondents.length === 0)?_c('div',{staticClass:"flex flex-col items-center justify-center"},[_c('IconPerson',{staticClass:"mb-4"}),_vm._v(" "+_vm._s(_vm.$t('Radius.CollaborationAssessment.Invites.Empty'))+" ")],1):_vm._e(),(_vm.collaboration && _vm.collaboration.swo_respondents && _vm.collaboration.swo_respondents.length > 0)?_c('div',{staticClass:"py-2 w-full h-full overflow-scroll"},_vm._l((_vm.collaboration.swo_respondents),function(invitee,index){return _c('div',{key:index,staticClass:"flex flex-row items-center w-full px-4",class:{
                  'bg-textfield-disabled': index % 2 == 0
                }},[_c('img',{staticClass:"block w-8 h-8",attrs:{"src":_vm._f("resolve")(`@/assets/profile/profile-${index % 4}.svg`),"alt":""}}),_c('div',{staticClass:"flex flex-col w-full items-start justify-start py-1.5 ml-4"},[_c('div',{staticClass:"flex flex-row items-center gap-2 font-bold text-darkblue text-50"},[_c('span',[_vm._v(_vm._s(invitee.email))]),_c('div',{staticClass:"rounded-full text-progress-fill bg-light-green p-1"},[_c('IconCheckSmall')],1)]),_c('span',{staticClass:"block font-normal text-lightblue-text text-50"},[_vm._v(_vm._s(_vm.$t(_vm.$filters.format('CollaborationAssessment.Modal.Invite.Role.{0}', invitee.segment))))])])])}),0):_vm._e()]),(_vm.collaboration)?_c('div',{staticClass:"flex flex-row space-x-2"},[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.$filters.markdown2html(
                  _vm.$filters.format(_vm.$t('Radius.CollaborationAssessment.Invites.AmountSent'), _vm.sentInvitesAmount)
                )
              )}}),_c('ButtonElement',{staticClass:"my-2 sm:my-0 sm:mr-2",attrs:{"variant":"primary-small"},nativeOn:{"click":function($event){return _vm.displayInviteModal.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('Radius.CollaborationAssessment.Invites.Action.Send')))])],1):_vm._e()]):_vm._e()],1),(!_vm.collaboration || _vm.collaboration.status === 'open' || _vm.collaboration.status === 'send')?_c('div',{staticClass:"flex flex-col items-center justify-center bg-white flex-grow px-8 pt-12 pb-20 border border-b-4 border-gray-200 rounded-md text-theme-200 w-full"},[_c('div',{staticClass:"flex flex-col items-center justify-center text-center flex-grow w-full"},[_c('IconCollaboration',{staticClass:"mb-4"}),_c('span',{staticClass:"text-theme-250 text-darkblue font-semibold mb-4"},[_vm._v(_vm._s(_vm._f("format")(_vm.$t('Radius.CollaborationAssessment.Title'),_vm.currentYear)))]),_c('span',{staticClass:"text-100"},[_vm._v(_vm._s(_vm.$t('Radius.CollaborationAssessment.Description')))])],1),(_vm.collaboration)?_c('span',{staticClass:"text-100 text-center mt-16",domProps:{"innerHTML":_vm._s(
            _vm.$filters.markdown2html(
              _vm.$filters.format(
                _vm.$t('Radius.CollaborationAssessment.EndDateDescription'),
                _vm.$filters.date(_vm.collaboration.swo.end_date)
              )
            )
          )}}):_vm._e()]):_vm._e(),(_vm.collaboration && (_vm.collaboration.status === 'notsend' || _vm.collaboration.status === 'finished'))?_c('div',{staticClass:"w-full"},[(_vm.statistics)?_c('div',{staticClass:"flex flex-row items-center justify-center bg-white w-full flex-shrink-0 h-40 border border-b-4 border-gray-200 rounded-md text-theme-200 mb-10 divide-x divide-lightblue"},_vm._l((Object.keys(_vm.statistics.score)),function(key,scoreIndex){return _c('div',{key:scoreIndex,staticClass:"flex flex-col items-center justify-center w-full h-full text-200 text-lightblue-text"},[_c('div',{staticClass:"flex items-center justify-center w-10 h-10 rounded-md text-white text-300 font-bold",class:{
                'bg-progress-fill': (Number(_vm.statistics.score[key]) || 0) >= 5.5,
                'bg-gray-200': (Number(_vm.statistics.score[key]) || 0) === 0,
                'bg-error-balloon-text':
                  (Number(_vm.statistics.score[key]) || 0) < 5.5 && (Number(_vm.statistics.score[key]) || 0) > 0
              }},[_c('span',[_vm._v(_vm._s(_vm.statistics.score[key] === 'NaN' ? 0 : Number(_vm.statistics.score[key])))])]),_c('span',{staticClass:"font-bold text-darkblue mt-3"},[_vm._v(_vm._s(_vm.$t(`Radius.CollaborationAssessment.Summary.Score.${key}`)))]),_c('span',{staticClass:"text-100"},[_vm._v(_vm._s(_vm.textualScoreForScore(_vm.statistics.score[key])))])])}),0):_vm._e(),_c('div',{staticClass:"flex flex-col items-center justify-center bg-white flex-grow px-8 pt-12 pb-20 border border-b-4 border-gray-200 rounded-md text-theme-200 w-full"},[_c('div',{staticClass:"flex flex-col items-center justify-center text-center flex-grow w-full pb-6 mb-6 border-b border-gray-200"},[_c('div',{staticClass:"flex flex-row justify-start items-center w-full"},[_c('span',{staticClass:"flex-grow text-400 text-darkblue font-bold text-left"},[_vm._v(_vm._s(_vm.selectedQuestionnaireSectionIndex + 1)+". "+_vm._s(_vm.selectedQuestionnaireSection.title))]),_c('div',{staticClass:"flex flex-row items-center justify-center flex-shrink-0 text-lightblue-text text-100 select-none cursor-default"},[_c('div',{staticClass:"flex items-center justify-center cursor-pointer w-8 h-8",class:{
                    'text-darkblue': _vm.selectedQuestionnaireSectionIndex > 0
                  },on:{"click":function($event){return _vm.switchSection(-1)}}},[_c('IconChevronLeft')],1),_c('span',{staticClass:"font-bold text-darkblue"},[_vm._v(_vm._s(_vm.selectedQuestionnaireSectionIndex + 1)+"/"+_vm._s(_vm.questionnaireSections.length))]),_c('div',{staticClass:"flex items-center justify-center cursor-pointer w-8 h-8",class:{
                    'text-darkblue': _vm.selectedQuestionnaireSectionIndex < _vm.questionnaireSections.length - 1
                  },on:{"click":function($event){return _vm.switchSection(1)}}},[_c('IconChevronRight')],1)])])]),_vm._l((_vm.selectedQuestionnaireSection.ratingquestions),function(ratingQuestion,ratingQuestionIndex){return _c('div',{key:ratingQuestionIndex,staticClass:"w-full mb-6"},[(ratingQuestion.layout === 'twocolumns')?_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex flex-row justify-between items-center w-full text-100 mb-4"},[_c('span',{staticClass:"font-bold text-darkblue"},[_vm._v(_vm._s(_vm.$t('Radius.CollaborationAssessment.Summary.Answers')))]),_c('span',{staticClass:"font-bold text-darkblue"},[_vm._v(_vm._s(_vm.$t('Radius.CollaborationAssessment.Summary.AnswersCount')))])]),_vm._l((7),function(i){return _c('div',{key:i,staticClass:"flex flex-row justify-between items-center w-full text-100 h-10 px-4 rounded-md",class:{
                  'bg-textfield-disabled': i % 2 == 1
                }},[_c('span',[_vm._v(_vm._s(_vm._f("empty")(ratingQuestion[`name_rating_${i}`],_vm.$t('Radius.CollaborationAssessment.Questions.NoChoice'))))]),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.scoreForIndex(`${_vm.selectedQuestionnaireSection.id}_${ratingQuestion.id}`, i)))])])})],2):_vm._e(),(ratingQuestion.layout === 'fullwidth')?_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex flex-row justify-between items-center w-full text-100 mb-4"},[_c('span',{staticClass:"font-bold text-darkblue"},[_vm._v(_vm._s(ratingQuestion.title))])]),_c('div',{staticClass:"flex flex-row justify-between items-end text-100 border border-lightblue rounded-md divide-x divide-lightblue overflow-hidden bg-textfield-disabled"},_vm._l((7),function(i){return _c('div',{key:i,staticClass:"p-3 flex flex-col justify-end items-center h-21 w-32 text-center w-full text-darkblue",class:{
                    'bg-white font-normal':
                      _vm.scoreForIndex(`${_vm.selectedQuestionnaireSection.id}_${ratingQuestion.id}`, i) === 0,
                    'bg-textfield-disabled font-bold':
                      _vm.scoreForIndex(`${_vm.selectedQuestionnaireSection.id}_${ratingQuestion.id}`, i) > 0
                  }},[_c('span',{staticClass:"mb-2 font-normal text-50"},[_vm._v(_vm._s(_vm._f("empty")(ratingQuestion[`name_rating_${i}`],_vm.$t('Radius.CollaborationAssessment.Questions.NoChoiceShorter'))))]),_c('div',{staticClass:"flex-grow h-full"}),_c('div',{staticClass:"flex flex-shrink-0 justify-center items-center rounded-full text-darkblue text-50"},[_vm._v(" "+_vm._s(_vm.scoreForIndex(`${_vm.selectedQuestionnaireSection.id}_${ratingQuestion.id}`, i))+" ")])])}),0)]):_vm._e()])}),(_vm.commentsForQuestionnaireSection.length > 0)?_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex flex-row justify-between items-center w-full text-100 mt-6 mb-4"},[_c('span',{staticClass:"font-bold text-darkblue"},[_vm._v(_vm._s(_vm.$t('Radius.CollaborationAssessment.Summary.Comments')))])]),_vm._l((_vm.commentsForQuestionnaireSection),function(comment,commentIndex){return _c('div',{key:commentIndex,staticClass:"flex flex-row justify-start items-start w-full text-100 border border-textfield-disabled rounded-md p-4 mb-4"},[_c('IconSpeech',{staticClass:"text-darkblue flex-shrink-0 mr-4"}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-50"},[_c('span',{staticClass:"font-bold mr-2"},[_vm._v(_vm._s(_vm.$t('Radius.CollaborationAssessment.Summary.Answer'))+":")]),_c('span',[_vm._v(_vm._s(comment.answer))])]),_c('ReadMoreSpan',{staticClass:"font-normal text-100 text-lightblue-text",attrs:{"text":comment.text,"maxLength":9000,"markdown":true}})],1)],1)})],2):_vm._e()],2)]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }