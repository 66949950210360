import Vue from 'vue';

class PeriodicBackgroundSync {
  #callbacks = {};
  #available = false;

  constructor() {
    this.initialize();
  }

  async initialize() {
    if (!navigator || !navigator.permissions || !navigator.permissions.query) {
      this.#available = false;
      return;
    }

    const status = await navigator.permissions.query({
      name: 'periodic-background-sync'
    });

    this.#available = status.state === 'granted';

    navigator.serviceWorker.addEventListener('message', event => {
      this.#handleMessage(event.data);
    });
  }

  async add(identifier, callback) {
    if (!identifier || identifier.length === 0 || !callback) {
      return false;
    }

    if (!this.#available) {
      if (
        !(await new Promise(resolve => {
          setTimeout(() => {
            resolve(this.#available);
          }, 1000);
        }))
      ) {
        return false;
      }
    }

    this.#callbacks[identifier] = callback;

    const registration = await navigator.serviceWorker.ready;
    if ('periodicSync' in registration) {
      try {
        await registration.periodicSync.unregister(identifier);
      } catch (e) {
        // Could not unregister
      }

      try {
        await registration.periodicSync.register(identifier, {
          // An interval of 12 hours.
          minInterval: 12 * 60 * 60 * 1000
        });
      } catch (error) {
        // Periodic background sync cannot be used.
        return false;
      }
    }

    return true;
  }

  #handleMessage(event) {
    const callback = this.#callbacks[event.identifier];
    if (callback) {
      callback(event);
    }
  }
}

const periodicBackgroundSync = new PeriodicBackgroundSync();

const PeriodicBackgroundSyncVue = {
  install(V) {
    V.prototype.$periodicBackgroundSync = periodicBackgroundSync;
  }
};
Vue.use(PeriodicBackgroundSyncVue);

export default periodicBackgroundSync;
