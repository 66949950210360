<template>
  <ul class="grid gap-1 mb-10">
    <li
      v-for="(folder, index) in folders"
      :key="index"
      class="bg-white group overflow-hidden gap-4 text-black px-6 h-18 flex items-center border border-gray-200 transition-all hover:border-gray-400 cursor-pointer"
      @click="traverseFolder(folder)"
    >
      <div class="flex items-center gap-6 overflow-hidden min-w-0">
        <div class="relative flex-shrink-0 w-10 h-10 text-lightblue-link flex items-center justify-center rounded">
          <div class="absolute rounded top-0 left-0 w-full h-full bg-lightblue-link opacity-20"></div>
          <IconFolder />
        </div>
        <div class="font-semibold truncate flex-grow">
          {{ folder.title }}
        </div>
      </div>
      <div class="flex-shrink-0 ml-auto flex gap-x-2 items-center">
        <button
          v-if="hasWritePermission"
          class="w-10 h-10 rounded bg-white text-black hover:border-gray-400 transition-all duration-200 border border-gray-300 flex items-center justify-center"
          @click.stop="
            $modal.confirm(() => {
              deleteFolder(folder);
            }, 'ConfirmDeleteDocument')
          "
        >
          <IconTrash />
        </button>
        <IconChevronRight class="opacity-0 group-hover:opacity-100 transition-all duration-200" />
      </div>
    </li>
  </ul>
</template>

<script>
import { GraphQLMutation } from '@/graphql';

export default {
  props: {
    folders: {
      type: Array,
      default: () => [],
      required: false
    },
    hasWritePermission: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    traverseFolder(folder) {
      this.$emit('traverse', folder);
    },

    async deleteFolder(folder) {
      await this.$apollo.mutate({
        mutation: GraphQLMutation.DocumentFolderDelete,
        variables: {
          id: folder.id
        }
      });

      this.$emit('deleted', folder);
    }
  }
};
</script>
