<template>
  <div
    class="rounded-md border border-b-4 border-gray-200 cursor-pointer bg-white md:min-w-dashboard-tile animation-tile-scale-in animation-once animation-fill-both animation-300"
  >
    <div class="pt-8 px-8 flex flex-row flex-wrap items-start">
      <div class="w-full flex justify-between border-b border-line" @click="tileAction">
        <slot name="header">
          <div class="w-full flex justify-between items-center mb-4">
            <div class="text-theme-300 flex-grow flex-shrink-0">
              <span class="font-semibold text-100 sm:text-200">{{ $t('Dashboard.Widget.ComingSoon.Title') }}</span>
            </div>
            <div class="text-theme-50 bg-coming-soon-background px-1.5 py-1 flex-shrink-0">
              <span class="text-progress-fill text-xs font-bold uppercase">{{
                $t('Dashboard.Widget.ComingSoon.ExtraLabel')
              }}</span>
            </div>
          </div>
        </slot>
      </div>

      <div class="flex flex-col w-full h-dashboard-tile justify-start items-stretch text-lightblue-text text-100">
        <slot>
          <div class="flex mt-6">
            <span class="text-theme-100 leading-relaxed">{{ $t('Dashboard.Widget.ComingSoon.Body') }}</span>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardTile',
  props: {
    to: {
      type: Object,
      default() {
        return {};
      },
      required: false
    },
    homeID: {
      type: Number,
      default: undefined,
      required: false
    }
  },
  methods: {
    tileAction() {
      if (!this.to || Object.keys(this.to).length === 0) {
        return;
      }

      this.$router.push(this.to);
    }
  }
};
</script>
