import showdown from 'showdown';

export default function(value) {
  if (!value) {
    return '';
  }

  const converter = new showdown.Converter({ tables: true, strikethrough: true });
  return converter.makeHtml(value);
}
