<template>
  <BaseModal
    v-bind="$props"
    :withBottomDivider="true"
    :title="$t(`Modal.Confirm.${variant}.Title`)"
    @backdropClicked="hide"
  >
    <template>
      <span class="text-theme-100 block w-full sm:w-modal mb-4">{{ $t(`Modal.Confirm.${variant}.Body`) }}</span>
    </template>

    <template v-slot:bottom>
      <div class="flex flex-col-reverse sm:flex-row w-full sm:w-auto justify-end p-6 pb-4 sm:pb-6">
        <ButtonElement class="my-2 sm:my-0 sm:mr-2" variant="secondary-small" @click.native="hide">{{
          $t('Modal.Generic.Cancel')
        }}</ButtonElement>
        <ButtonElement @click.native="confirm" variant="primary-small">{{
          $t(`Modal.Confirm.${variant}.Action.Continue`)
        }}</ButtonElement>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal';
import ButtonElement from '@/components/ButtonElement';

export default {
  name: 'ConfirmModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal,
    ButtonElement
  },
  props: {
    variant: {
      type: String,
      default: 'General',
      required: false
    }
  },
  methods: {
    confirm() {
      this.hide();
      this.$emit('didconfirm');
    }
  }
};
</script>
