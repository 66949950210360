var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('InputMessage',{attrs:{"placeholder":_vm.placeholder},on:{"send":_vm.addComment},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_vm._l((_vm.comments),function(comment,index){return _c('div',{key:index},[_c('div',{staticClass:"flex mt-6",class:{
        [`animation-list-scale-in animation-once animation-fill-backwards animation-300 animation-delay-${index}`]: true
      }},[(comment.user && comment.user.avatar_id)?_c('img',{staticClass:"block w-8 h-8",attrs:{"src":_vm._f("resolve")(`@/assets/profile/profile-${comment.user.avatar_id}.svg`),"alt":""}}):_vm._e(),_c('div',{staticClass:"ml-4 w-full"},[_c('div',{staticClass:"flex w-full justify-between items-center py-1.5"},[(comment.user && comment.user.role && comment.user.role.name === 'Admin')?_c('span',{},[_vm._v(" Beheerder ")]):(comment.user && comment.user.first_name && comment.user.last_name)?_c('span',{staticClass:"block font-bold text-darkblue text-100"},[_vm._v(_vm._s(comment.user.first_name + ' ' + comment.user.last_name))]):_c('span',{staticClass:"block font-bold text-darkblue text-100"},[_vm._v(" Gebruiker ")]),_c('div',{staticClass:"text-50"},[_c('span',{staticClass:"text-lightblue-hover"},[_vm._v(_vm._s(_vm._f("timeago")(comment.created_at)))])])]),(!comment.show_edit_mode)?_c('ReadMoreSpan',{staticClass:"block mt-2 text-100 w-full",attrs:{"text":comment.message,"maxLength":140}}):_vm._e(),(comment.show_edit_mode)?_c('InputMessage',{attrs:{"placeholder":_vm.placeholder},on:{"send":function($event){return _vm.editComment(comment)}},model:{value:(comment.message),callback:function ($$v) {_vm.$set(comment, "message", $$v)},expression:"comment.message"}}):_vm._e(),(
            !comment.show_edit_mode &&
              comment.user &&
              (_vm.$store.state.currentUser.id === comment.user.id || _vm.$permissions.isAdmin())
          )?_c('div',{staticClass:"w-full flex justify-end text-25"},[_c('span',{staticClass:"text-lightblue-link",on:{"click":function($event){comment.show_edit_mode = true}}},[_vm._v(_vm._s(_vm.$t('Comments.Action.Edit')))]),_c('span',{staticClass:"text-lightblue mx-2"},[_vm._v("|")]),_c('span',{staticClass:"text-red-500",on:{"click":function($event){_vm.$modal.confirm(() => {
                _vm.deleteComment(comment);
              }, 'ConfirmDeleteComment')}}},[_vm._v(_vm._s(_vm.$t('Comments.Action.Delete')))])]):_vm._e()],1)]),_c('div',{staticClass:"border-b border-gray-200 w-full mt-4"})])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }