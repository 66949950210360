<template>
  <article
    class="bg-white relative p-5 lg:p-6 rounded-md border border-b-4 border-gray-200 flex gap-5 lg:gap-8"
    :class="{
      'opacity-50': isHistory
    }"
  >
    <i :id="`event-${event.id}`" class="absolute" style="top: -140px"></i>
    <EventsDate v-if="event.date" :date="event.date" />
    <div class="lg:flex flex-grow gap-12">
      <div class="flex-grow">
        <ul v-if="event.type || event.duration" class="flex gap-1 mb-1">
          <li v-if="event.type" class="font-bold text-lightblue-link text-50 uppercase">{{ event.type }}</li>
          <li v-if="event.duration" class="font-medium text-50 text-gray-500">{{ event.duration }}</li>
        </ul>
        <div class="pt-1">
          <h3 v-if="event.title" class="font-semibold text-300 lg:text-310 mb-1 text-gray-900">{{ event.title }}</h3>
          <ReadMoreSpan
            v-if="event.description"
            class="block mt-2 text-100 text-gray-500 leading-relaxed w-full"
            :text="event.description"
            :maxLength="140"
            :markdown="true"
          />
        </div>
      </div>

      <div
        v-if="(event.signup_visible || event.calendar_visible) && !isHistory"
        class="flex-shrink-0 gap-2 flex flex-col lg:mt-0 mt-5"
      >
        <ButtonElement v-if="event.signup_visible" variant="primary-small" @click.native="openSignupModal(event)">
          {{ $t('Events.Signup') }}
        </ButtonElement>

        <EventsAddToCalendarButton
          v-if="event.calendar_visible && $permissions.has('event-registration-create')"
          :event="event"
        />
      </div>
    </div>
  </article>
</template>

<script>
import ButtonElement from '@/components/ButtonElement';
import EventsDate from '@/components/events/EventsDate.vue';
import ReadMoreSpan from '@/components/ReadMoreSpan';
import EventsAddToCalendarButton from './EventsAddToCalendarButton.vue';

export default {
  components: {
    ButtonElement,
    ReadMoreSpan,
    EventsDate,
    EventsAddToCalendarButton
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    isHistory() {
      return new Date(this.event.date) < new Date();
    }
  },
  methods: {
    openSignupModal() {
      this.$modal.show('SignupModal', {
        title: `${this.$t('Modal.Signup.Title')} ${this.event.title}`,
        event: this.event
      });
    }
  }
};
</script>
