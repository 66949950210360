var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col mb-1"},[_c('div',{staticClass:"rounded-md shadow-sm flex items-center relative mb-1 border transition ease-in-out duration-300",class:{
      'border-lightblue hover:border-lightblue-hover focus-within:border-lightblue-hover': !_vm.disabled,
      'border-lightblue bg-textfield-disabled': _vm.disabled,
      'bg-error-balloon-background border-error-balloon-text text-error-balloon-text': _vm.hasError,
      'bg-white': !_vm.hasError
    }},[_c('div',{staticClass:"flex-shrink w-full",style:({
        height: `${_vm.boxHeight()}px`
      })},[(_vm.multiline)?_c('textarea',{ref:"box",staticClass:"appearance-none resize-none block bg-transparent h-full w-full px-4 py-4 leading-tight placeholder-lightblue-text text-100 font-normal text-darkblue focus:outline-none focus:z-10",attrs:{"maxlength":"1000","required":_vm.required,"disabled":_vm.disabled,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue($event.target.value)}}}):_vm._e(),(!_vm.multiline)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendMessage.apply(null, arguments)}}},[_c('input',{staticClass:"appearance-none block bg-transparent h-12 w-full px-4 py-3 placeholder-lightblue-text text-100 font-normal text-darkblue focus:outline-none focus:z-10",attrs:{"type":"text","required":_vm.required,"disabled":_vm.disabled,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue($event.target.value)}}}),_c('input',{staticClass:"invisible",attrs:{"type":"submit"}})]):_vm._e()]),(!_vm.hideSendButton)?_c('button',{staticClass:"flex-shrink-0 w-10 h-9 mr-2 flex justify-center items-center focus:outline-none",on:{"click":_vm.sendMessage}},[(_vm.iconVariant === 'add')?_c('IconAdd',{staticClass:"transition ease-in-out duration-300",class:{
          'text-lightblue-hover': !_vm.hasText && !_vm.allowEmptySend,
          'text-orange': _vm.hasText || _vm.allowEmptySend
        }}):_c('IconSend',{staticClass:"transition ease-in-out duration-300",class:{
          'text-lightblue-hover': !_vm.hasText && !_vm.allowEmptySend,
          'text-orange': _vm.hasText || _vm.allowEmptySend
        }})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }