<template>
  <DashboardCard :title="$t('Dashboard.Report.Title')" icon="IconProgressReport" url="/startkwalificatie">
    <div class="flex flex-col gap-2">
      <router-link
        :to="{
          path: '/startkwalificatie#1',
          params: {
            homeID
          }
        }"
      >
        <DashboardButton>
          {{ $t('Dashboard.Report.Portfolio') }}
        </DashboardButton>
      </router-link>
      <router-link
        :to="{
          path: '/startkwalificatie#2',
          params: {
            homeID
          }
        }"
      >
        <DashboardButton>
          <span class="flex-grow truncate">
            {{ $t('Dashboard.Report.Qualification') }}
          </span>
          <div
            v-if="progressReportRadius"
            class="flex text-theme-50 rounded-xs px-1 h-6 border-2 border-gray-200"
            :class="{
              'bg-light-green border-light-green': progressReportRadius.base_qualification_finished,
              'bg-white': !progressReportRadius.base_qualification_finished
            }"
          >
            <span
              class="text-gray-600 flex items-center"
              :class="{ 'text-progress-fill': progressReportRadius.base_qualification_finished }"
            >
              <IconStar class="flex-shrink-0" />
              <span class="ml-1">
                {{
                  progressReportRadius.base_qualification_finished
                    ? $t('Radius.QualityAssessment.BaseQualification.Complete')
                    : $t('Radius.QualityAssessment.BaseQualification.Incomplete')
                }}
              </span>
            </span>
          </div>
        </DashboardButton>
      </router-link>
      <router-link
        :to="{
          path: '/startkwalificatie#6',
          params: {
            homeID
          }
        }"
      >
        <DashboardButton>
          {{ $t('Dashboard.Report.ActivityPlan') }}
        </DashboardButton>
      </router-link>
    </div>
  </DashboardCard>
</template>

<script>
import DashboardButton from '@/components/dashboard/DashboardButton.vue';
import DashboardCard from '@/components/dashboard/DashboardCard.vue';

import { GraphQLQuery } from '@/graphql';

export default {
  components: {
    DashboardButton,
    DashboardCard
  },
  apollo: {
    progressReportRadius: {
      query: GraphQLQuery.ProgressReportRadius,
      fetchPolicy: 'cache-and-network',
      skip() {
        return !this.$store.state.currentUser;
      }
    }
  },
  computed: {
    homeID() {
      return this.$store.state.currentManagedHome ? Number(this.$store.state.currentManagedHome.id) : undefined;
    }
  }
};
</script>
