import Vue from 'vue';
import store from '@/store';

class Permissions {
  has(key, user = null) {
    const permissions = this.permissionsForKeys([key], user);
    return permissions.length > 0;
  }

  hasAny(keys, user = null) {
    const permissions = this.permissionsForKeys(keys, user);
    return permissions.length > 0;
  }

  hasAll(keys, user = null) {
    const permissions = this.permissionsForKeys(keys, user);
    return permissions.length === keys.length;
  }

  isAdmin(user = null) {
    if (!store.state.accessToken) {
      return false;
    }

    const hasRole = this.hasAdminRole(user);
    if (user) {
      return hasRole;
    }

    if (store.state.currentManagedHome) {
      return false;
    }

    return hasRole;
  }

  // eslint-disable-next-line
  hasAdminRole(user = null) {
    if (!store.state.accessToken) {
      return false;
    }

    return this.hasRole('admin', user);
  }

  // eslint-disable-next-line
  hasRole(role, user = null) {
    if (!store.state.accessToken) {
      return false;
    }

    if (user) {
      return user.role.permissions && user.role.permissions.length > 0 && user && user.role && user.role.type === role;
    }

    return (
      store.state.permissions &&
      store.state.permissions.length > 0 &&
      store.state.currentUser &&
      store.state.currentUser.role &&
      store.state.currentUser.role.type === role
    );
  }

  // eslint-disable-next-line
  permissionsForKeys(keys, user = null) {
    if (
      !keys ||
      keys.length === 0 ||
      !store.state.accessToken ||
      !store.state.permissions ||
      store.state.permissions.length === 0
    ) {
      return [];
    }

    const dynamicKeyResults = [];
    keys = keys.filter(key => {
      if (typeof key === 'function') {
        const result = key({
          store,
          permissions: this
        });

        if (result) {
          dynamicKeyResults.push('dynamic-key-result-placeholder');
        }

        return false;
      }

      return true;
    });

    let permissionsSource = [];
    if (user) {
      permissionsSource = user.role.permissions;
    } else {
      permissionsSource = store.state.permissions;
    }

    let permissions = permissionsSource.filter(item => {
      return item.enabled && keys.includes(`${item.controller}-${item.action}`);
    });

    permissions = dynamicKeyResults.concat(permissions);

    return permissions;
  }
}

const permissionsInstance = new Permissions();

const PermissionsVue = {
  install(V) {
    V.prototype.$permissions = permissionsInstance;
  }
};
Vue.use(PermissionsVue);

export default permissionsInstance;
