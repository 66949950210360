<template>
  <DashboardCard :title="$t('Dashboard.Services.Title')" icon="IconServices">
    <div class="grid gap-2">
      <DashboardButton @click.native="openLinkGezinspiratie">
        <div class="flex-grow">
          <img src="@/assets/logo-gezinspiratie.png" alt="Gezinspiratie" class="object-contain" />
        </div>
        <IconExternal class="text-gray-500" />
      </DashboardButton>

      <DashboardButton @click.native="openLinkAudit">
        <span class="flex-grow">Audits aanvragen</span>
        <IconExternal class="text-gray-500" />
      </DashboardButton>

      <DashboardButton v-if="viewKrachtInHuis" @click.native="openLinkKIH">
        <span class="flex-grow">Kracht in huis</span>
        <IconExternal class="text-gray-500" />
      </DashboardButton>

      <DashboardButton v-if="viewKrachtInHuis" @click.native="openLinkVOG">
        <span class="flex-grow">Aanvraag VOG</span>
        <IconExternal class="text-gray-500" />
      </DashboardButton>
    </div>
  </DashboardCard>
</template>

<script>
import get from 'lodash/get';
import DashboardButton from '@/components/dashboard/DashboardButton.vue';
import DashboardCard from '@/components/dashboard/DashboardCard.vue';
import { GraphQLMutation } from '@/graphql';

export default {
  components: {
    DashboardButton,
    DashboardCard
  },
  computed: {
    homeID() {
      return this.$store.state.currentManagedHome ? Number(this.$store.state.currentManagedHome.id) : undefined;
    },
    viewKrachtInHuis() {
      const type = get(this.$store.state, 'currentUser.role.type', '');
      const disabledTypes = ['contact', 'external_auditor'];

      return !disabledTypes.includes(type);
    },
    currentHome() {
      if (this.$permissions.hasAdminRole() && this.$store.state.currentManagedHome) {
        return this.$store.state.currentManagedHome;
      }

      if (
        !this.$store.state.currentUser ||
        !this.$store.state.currentUser.homes ||
        this.$store.state.currentUser.homes.length === 0
      ) {
        return null;
      }

      if (!this.homeID) {
        return this.$store.state.currentUser.homes[0];
      }

      return this.$store.state.currentUser.homes.find(home => {
        return Number(home.id) === this.homeID;
      });
    }
  },
  data() {
    return {
      token: ''
    };
  },
  mounted() {
    if (this.viewKrachtInHuis) {
      this.loadToken();
    }
  },

  methods: {
    async loadToken() {
      if (!this.$store.state.currentUser || !this.currentHome || !this.$permissions.has('user-krachtinhuistoken')) {
        return;
      }

      const tokenInfo = await this.$apollo.mutate({
        mutation: GraphQLMutation.KrachtInHuisToken
      });

      this.token = get(tokenInfo, 'data.token.token', '') || '';
    },

    openLinkAudit() {
      window.open(`https://www.keurmerkgezinshuizen.nl/audits-aanvragen/`);
    },

    openLinkVOG() {
      const url = `https://mijn.justis.nl/vog/vogorganisatie/inloggen.htm`;
      window.open(url);
    },

    openLinkKIH() {
      const url = `https://krachtinhuis.nl/gezinshuis-com/`;
      window.open(url);
    },

    openLinkGezinspiratie() {
      const url = `https://trainingen.gezinspiratieplein.nl/`;
      window.open(url);
    }
  }
};
</script>
