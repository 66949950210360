<template>
  <main class="bg-gray-100 py-16">
    <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
      <RadiusHeader
        selected="collaborationassessment"
        :collaborationAssessmentYear="selectedYear"
        simple
        :title="$t('Radius.CollaborationAssessment.Title', [selectedYear])"
      />
      <div class="flex flex-row animation-page-scale-in animation-once animation-fill-backwards animation-300">
        <div class="flex-shrink-0 mr-10 min-w-64 max-w-sm">
          <span class="font-semibold text-darkblue text-50">{{ $t('Radius.CollaborationAssessment.SelectYear') }}</span>
          <DropdownSelectElement
            :selectedValue="currentYear"
            :values="availableYears"
            :mutable="availableYears.length > 0"
            @selected="changeYear"
          />
          <div
            v-if="collaboration && (collaboration.status === 'notsend' || collaboration.status === 'finished')"
            class="w-full mt-4"
          >
            <span class="font-semibold text-darkblue text-50">{{
              $t('Radius.CollaborationAssessment.Summary.Filter')
            }}</span>
            <DropdownSelectElement
              :values="[
                '',
                'parent',
                'guardian',
                'health_insurer',
                'contact',
                'behavioral_scientist',
                'pedagogical_employee',
                'other'
              ]"
              translationFormat="CollaborationAssessment.Modal.Invite.Role.{0}"
              :selectedValue="highlightedType"
              :placeholder="$t('CollaborationAssessment.Modal.Invite.Role.Placeholder')"
              @selected="filterStatistics"
            />

            <div v-if="collaboration.status === 'finished'" class="flex items-center justify-center w-full mt-8">
              <ButtonElement
                class="my-2 sm:my-0 sm:mr-2 w-full"
                variant="primary-small"
                @click.native="downloadResults"
              >
                <img v-if="downloadingResults" class="w-8 h-8" src="@/assets/loading.gif" alt="Loading" />
                <span v-if="!downloadingResults">{{ $t('Radius.CollaborationAssessment.DownloadResults') }}</span>
              </ButtonElement>
            </div>

            <div
              class="
                flex flex-col
                bg-white
                w-full
                mt-10
                p-8
                border border-b-4 border-gray-200
                rounded-md
                text-theme-100
              "
            >
              <span class="text-theme-250 text-darkblue font-semibold">{{
                $t('Radius.CollaborationAssessment.Summary.Title')
              }}</span>
              <span class="text-100">{{
                $t('Radius.CollaborationAssessment.Summary.Description') | format(responsePercentage)
              }}</span>
              <div
                v-for="(type, index) in [
                  'parent',
                  'guardian',
                  'health_insurer',
                  'contact',
                  'behavioral_scientist',
                  'pedagogical_employee',
                  'other'
                ]"
                :key="index"
                class="flex flex-row items-center w-full mt-4 cursor-pointer select-none"
                :class="{
                  'opacity-50': highlightedType != null && highlightedType !== type
                }"
                @click="filterStatistics(type)"
              >
                <img class="block w-8 h-8" :src="`@/assets/profile/profile-${index % 4}.svg` | resolve" alt="" />
                <div class="flex flex-col w-full items-start justify-start py-1.5 ml-4">
                  <span class="block font-bold text-darkblue text-50">{{
                    $t($filters.format('CollaborationAssessment.Modal.Invite.Role.{0}', type))
                  }}</span>
                  <span class="block font-normal text-lightblue-text text-50">{{
                    $t('Radius.CollaborationAssessment.Summary.Participated') | format(statsForType(type))
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="!collaboration || collaboration.status === 'open' || collaboration.status === 'send'"
            class="flex flex-col bg-white w-full mt-10 p-8 border border-b-4 border-gray-200 rounded-md text-theme-100"
          >
            <span class="text-theme-250 text-darkblue font-semibold mb-4">{{
              $t('Radius.CollaborationAssessment.Invites.Title')
            }}</span>
            <span class="text-100">{{ $t('Radius.CollaborationAssessment.Invites.Description') }}</span>
            <div class="flex flex-col items-center justify-center my-8 h-64 border border-gray-200 rounded-md">
              <div
                v-if="!collaboration || !collaboration.swo_respondents || collaboration.swo_respondents.length === 0"
                class="flex flex-col items-center justify-center"
              >
                <IconPerson class="mb-4" />
                {{ $t('Radius.CollaborationAssessment.Invites.Empty') }}
              </div>
              <div
                class="py-2 w-full h-full overflow-scroll"
                v-if="collaboration && collaboration.swo_respondents && collaboration.swo_respondents.length > 0"
              >
                <div
                  v-for="(invitee, index) in collaboration.swo_respondents"
                  :key="index"
                  class="flex flex-row items-center w-full px-4"
                  :class="{
                    'bg-textfield-disabled': index % 2 == 0
                  }"
                >
                  <img class="block w-8 h-8" :src="`@/assets/profile/profile-${index % 4}.svg` | resolve" alt="" />
                  <div class="flex flex-col w-full items-start justify-start py-1.5 ml-4">
                    <div class="flex flex-row items-center gap-2 font-bold text-darkblue text-50">
                      <span>{{ invitee.email }}</span>
                      <div class="rounded-full text-progress-fill bg-light-green p-1">
                        <IconCheckSmall />
                      </div>
                    </div>
                    <span class="block font-normal text-lightblue-text text-50">{{
                      $t($filters.format('CollaborationAssessment.Modal.Invite.Role.{0}', invitee.segment))
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="collaboration" class="flex flex-row space-x-2">
              <span
                v-html="
                  $filters.markdown2html(
                    $filters.format($t('Radius.CollaborationAssessment.Invites.AmountSent'), sentInvitesAmount)
                  )
                "
              ></span>
              <ButtonElement class="my-2 sm:my-0 sm:mr-2" variant="primary-small" @click.native="displayInviteModal">{{
                $t('Radius.CollaborationAssessment.Invites.Action.Send')
              }}</ButtonElement>
            </div>
          </div>
        </div>

        <div
          v-if="!collaboration || collaboration.status === 'open' || collaboration.status === 'send'"
          class="
            flex flex-col
            items-center
            justify-center
            bg-white
            flex-grow
            px-8
            pt-12
            pb-20
            border border-b-4 border-gray-200
            rounded-md
            text-theme-200
            w-full
          "
        >
          <div class="flex flex-col items-center justify-center text-center flex-grow w-full">
            <IconCollaboration class="mb-4" />
            <span class="text-theme-250 text-darkblue font-semibold mb-4">{{
              $t('Radius.CollaborationAssessment.Title') | format(currentYear)
            }}</span>
            <span class="text-100">{{ $t('Radius.CollaborationAssessment.Description') }}</span>
          </div>
          <span
            v-if="collaboration"
            class="text-100 text-center mt-16"
            v-html="
              $filters.markdown2html(
                $filters.format(
                  $t('Radius.CollaborationAssessment.EndDateDescription'),
                  $filters.date(collaboration.swo.end_date)
                )
              )
            "
          ></span>
        </div>

        <div
          v-if="collaboration && (collaboration.status === 'notsend' || collaboration.status === 'finished')"
          class="w-full"
        >
          <div
            v-if="statistics"
            class="
              flex flex-row
              items-center
              justify-center
              bg-white
              w-full
              flex-shrink-0
              h-40
              border border-b-4 border-gray-200
              rounded-md
              text-theme-200
              mb-10
              divide-x divide-lightblue
            "
          >
            <div
              v-for="(key, scoreIndex) in Object.keys(statistics.score)"
              :key="scoreIndex"
              class="flex flex-col items-center justify-center w-full h-full text-200 text-lightblue-text"
            >
              <div
                class="flex items-center justify-center w-10 h-10 rounded-md text-white text-300 font-bold"
                :class="{
                  'bg-progress-fill': (Number(statistics.score[key]) || 0) >= 5.5,
                  'bg-gray-200': (Number(statistics.score[key]) || 0) === 0,
                  'bg-error-balloon-text':
                    (Number(statistics.score[key]) || 0) < 5.5 && (Number(statistics.score[key]) || 0) > 0
                }"
              >
                <span>{{ statistics.score[key] === 'NaN' ? 0 : Number(statistics.score[key]) }}</span>
              </div>
              <span class="font-bold text-darkblue mt-3">{{
                $t(`Radius.CollaborationAssessment.Summary.Score.${key}`)
              }}</span>
              <span class="text-100">{{ textualScoreForScore(statistics.score[key]) }}</span>
            </div>
          </div>
          <div
            class="
              flex flex-col
              items-center
              justify-center
              bg-white
              flex-grow
              px-8
              pt-12
              pb-20
              border border-b-4 border-gray-200
              rounded-md
              text-theme-200
              w-full
            "
          >
            <div
              class="
                flex flex-col
                items-center
                justify-center
                text-center
                flex-grow
                w-full
                pb-6
                mb-6
                border-b border-gray-200
              "
            >
              <div class="flex flex-row justify-start items-center w-full">
                <span class="flex-grow text-400 text-darkblue font-bold text-left"
                  >{{ selectedQuestionnaireSectionIndex + 1 }}. {{ selectedQuestionnaireSection.title }}</span
                >
                <div
                  class="
                    flex flex-row
                    items-center
                    justify-center
                    flex-shrink-0
                    text-lightblue-text text-100
                    select-none
                    cursor-default
                  "
                >
                  <div
                    class="flex items-center justify-center cursor-pointer w-8 h-8"
                    :class="{
                      'text-darkblue': selectedQuestionnaireSectionIndex > 0
                    }"
                    @click="switchSection(-1)"
                  >
                    <IconChevronLeft />
                  </div>
                  <span class="font-bold text-darkblue"
                    >{{ selectedQuestionnaireSectionIndex + 1 }}/{{ questionnaireSections.length }}</span
                  >
                  <div
                    class="flex items-center justify-center cursor-pointer w-8 h-8"
                    :class="{
                      'text-darkblue': selectedQuestionnaireSectionIndex < questionnaireSections.length - 1
                    }"
                    @click="switchSection(1)"
                  >
                    <IconChevronRight />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-for="(ratingQuestion, ratingQuestionIndex) in selectedQuestionnaireSection.ratingquestions"
              :key="ratingQuestionIndex"
              class="w-full mb-6"
            >
              <div v-if="ratingQuestion.layout === 'twocolumns'" class="w-full">
                <div class="flex flex-row justify-between items-center w-full text-100 mb-4">
                  <span class="font-bold text-darkblue">{{
                    $t('Radius.CollaborationAssessment.Summary.Answers')
                  }}</span>
                  <span class="font-bold text-darkblue">{{
                    $t('Radius.CollaborationAssessment.Summary.AnswersCount')
                  }}</span>
                </div>
                <div
                  v-for="i in 7"
                  :key="i"
                  class="flex flex-row justify-between items-center w-full text-100 h-10 px-4 rounded-md"
                  :class="{
                    'bg-textfield-disabled': i % 2 == 1
                  }"
                >
                  <span>{{
                    ratingQuestion[`name_rating_${i}`] | empty($t('Radius.CollaborationAssessment.Questions.NoChoice'))
                  }}</span>
                  <span class="font-bold">{{
                    scoreForIndex(`${selectedQuestionnaireSection.id}_${ratingQuestion.id}`, i)
                  }}</span>
                </div>
              </div>

              <div v-if="ratingQuestion.layout === 'fullwidth'" class="w-full">
                <div class="flex flex-row justify-between items-center w-full text-100 mb-4">
                  <span class="font-bold text-darkblue">{{ ratingQuestion.title }}</span>
                </div>
                <div
                  class="
                    flex flex-row
                    justify-between
                    items-end
                    text-100
                    border border-lightblue
                    rounded-md
                    divide-x divide-lightblue
                    overflow-hidden
                    bg-textfield-disabled
                  "
                >
                  <div
                    class="p-3 flex flex-col justify-end items-center h-21 w-32 text-center w-full text-darkblue"
                    v-for="i in 7"
                    :key="i"
                    :class="{
                      'bg-white font-normal':
                        scoreForIndex(`${selectedQuestionnaireSection.id}_${ratingQuestion.id}`, i) === 0,
                      'bg-textfield-disabled font-bold':
                        scoreForIndex(`${selectedQuestionnaireSection.id}_${ratingQuestion.id}`, i) > 0
                    }"
                  >
                    <span class="mb-2 font-normal text-50">{{
                      ratingQuestion[`name_rating_${i}`]
                        | empty($t('Radius.CollaborationAssessment.Questions.NoChoiceShorter'))
                    }}</span>
                    <div class="flex-grow h-full"></div>
                    <div class="flex flex-shrink-0 justify-center items-center rounded-full text-darkblue text-50">
                      {{ scoreForIndex(`${selectedQuestionnaireSection.id}_${ratingQuestion.id}`, i) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="commentsForQuestionnaireSection.length > 0" class="w-full">
              <div class="flex flex-row justify-between items-center w-full text-100 mt-6 mb-4">
                <span class="font-bold text-darkblue">{{ $t('Radius.CollaborationAssessment.Summary.Comments') }}</span>
              </div>
              <div
                v-for="(comment, commentIndex) in commentsForQuestionnaireSection"
                :key="commentIndex"
                class="
                  flex flex-row
                  justify-start
                  items-start
                  w-full
                  text-100
                  border border-textfield-disabled
                  rounded-md
                  p-4
                  mb-4
                "
              >
                <IconSpeech class="text-darkblue flex-shrink-0 mr-4" />
                <div class="flex flex-col">
                  <div class="text-50">
                    <span class="font-bold mr-2">{{ $t('Radius.CollaborationAssessment.Summary.Answer') }}:</span>
                    <span>{{ comment.answer }}</span>
                  </div>
                  <ReadMoreSpan
                    class="font-normal text-100 text-lightblue-text"
                    :text="comment.text"
                    :maxLength="9000"
                    :markdown="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import get from 'lodash/get';
import { GraphQLQuery } from '@/graphql';
import RadiusHeader from '@/views/Radius/RadiusHeader';
import ReadMoreSpan from '@/components/ReadMoreSpan';
import ButtonElement from '@/components/ButtonElement';
import DropdownSelectElement from '@/components/DropdownSelectElement';

export default {
  name: 'CollaborationAssessment',
  components: {
    RadiusHeader,
    ReadMoreSpan,
    ButtonElement,
    DropdownSelectElement
  },
  apollo: {
    collaborations: {
      query: GraphQLQuery.RadiusCollaboration,
      result() {
        if (!this.selectedYear) {
          this.selectedYear = this.availableYears?.[0] || null;
        }
      }
    },
    questionnaires: {
      query: GraphQLQuery.RadiusCollaborationQuestionSections
    }
  },
  computed: {
    homeID() {
      if (this.$route.params.homeID) {
        return Number(this.$route.params.homeID);
      }

      return this.$store.state.currentManagedHome ? Number(this.$store.state.currentManagedHome.id) : undefined;
    },

    collaboration() {
      const collabs = get(this, 'collaborations', []) || [];
      if (collabs.length === 0) {
        return null;
      }

      for (let i = 0; i < collabs.length; i += 1) {
        const year = this.$filters.date(collabs[i].swo.end_date, 'YYYY');
        if (!this.selectedYear || year === this.selectedYear) {
          return collabs[i];
        }
      }

      return null;
    },

    currentYear() {
      if (!this.collaboration || !this.collaboration.swo) {
        return this.$filters.date(new Date(), 'YYYY');
      }

      return this.$filters.date(this.collaboration.swo.end_date, 'YYYY');
    },

    availableYears() {
      if (!this.collaborations || this.collaborations.length === 0) {
        return [this.currentYear];
      }

      const years = [];
      for (let i = 0; i < this.collaborations.length; i += 1) {
        const collab = this.collaborations[i];
        const date = new Date(get(collab, 'swo.end_date', '2020-01-01'));
        years.push(this.$filters.date(date, 'YYYY'));
      }

      return years;
    },

    questionnaire() {
      if (!this.questionnaires || this.questionnaires.length === 0) {
        return null;
      }

      return this.questionnaires[0];
    },

    questionnaireSections() {
      if (!this.questionnaire) {
        return [];
      }

      return this.questionnaire.SWOsection;
    },

    selectedQuestionnaireSection() {
      if (!this.questionnaireSections || this.selectedQuestionnaireSectionIndex >= this.questionnaireSections.length) {
        return { id: 0, title: '-', ratingquestions: [], openquestions: [], has_comment: false };
      }

      return this.questionnaireSections[this.selectedQuestionnaireSectionIndex];
    },

    commentsForQuestionnaireSection() {
      if (
        !this.statistics ||
        !this.selectedQuestionnaireSection ||
        (!this.selectedQuestionnaireSection.has_comment && this.selectedQuestionnaireSection.openquestions.length === 0)
      ) {
        return [];
      }

      let comments = [];

      if (this.selectedQuestionnaireSection.openquestions.length > 0) {
        for (let i = 0; i < this.selectedQuestionnaireSection.openquestions.length; i += 1) {
          const openQuestion = this.selectedQuestionnaireSection.openquestions[i];
          for (let j = 0; j < openQuestion.fields.length; j += 1) {
            const field = openQuestion.fields[j];
            if (this.statistics.comments[`${this.selectedQuestionnaireSection.id}_${openQuestion.id}_${field.id}`]) {
              comments = comments.concat(
                this.statistics.comments[`${this.selectedQuestionnaireSection.id}_${openQuestion.id}_${field.id}`].map(
                  comment => {
                    return {
                      answer: field.name,
                      text: comment
                    };
                  }
                )
              );
            }
          }
        }
      }

      if (this.statistics.comments[`${this.selectedQuestionnaireSection.id}_comment`]) {
        comments = comments.concat(
          this.statistics.comments[`${this.selectedQuestionnaireSection.id}_comment`].map(comment => {
            return {
              text: comment
            };
          })
        );
      }

      return comments;
    },

    statistics() {
      if (!this.collaboration || !this.collaboration.stats) {
        return null;
      }

      let filter = 'overall';
      if (this.highlightedType && this.highlightedType.length > 0) {
        filter = this.highlightedType;
      }
      return this.collaboration.stats[filter];
    },

    sentInvitesAmount() {
      if (
        !this.collaboration ||
        !this.collaboration.swo_respondents ||
        this.collaboration.swo_respondents.length === 0
      ) {
        return 0;
      }
      return this.collaboration.swo_respondents.length;
    },

    responsePercentage() {
      if (!this.collaboration || !this.collaboration.stats || !this.collaboration.stats.overall) {
        return '-';
      }

      const percentage =
        Number(
          Math.round(
            (this.collaboration.stats.overall.stats.response_count /
              this.collaboration.stats.overall.stats.respondent_count) *
              100
          )
        ) || 0;

      return `${percentage}% (${this.collaboration.stats.overall.stats.response_count} / ${this.collaboration.stats.overall.stats.respondent_count})`;
    }
  },
  data() {
    return {
      highlightedType: null,
      selectedYear: null,
      selectedQuestionnaireSectionIndex: 0,
      downloadingResults: false
    };
  },
  mounted() {
    this.$store.commit('navigationBarNotice', null);
  },
  methods: {
    displayInviteModal() {
      this.$modal.show('CollaborationAssessmentInviteModal', {
        collaborationID: Number(this.collaboration.id)
      });
    },

    changeYear(year) {
      if (!year || year.length === 0) {
        return;
      }

      this.selectedYear = year;
    },

    filterStatistics(type) {
      if (this.highlightedType === type) {
        this.highlightedType = null;
        return;
      }

      this.highlightedType = type;
    },

    textualScoreForScore(score) {
      if (score === 'NaN') {
        return this.$t(`Radius.CollaborationAssessment.Summary.Score.Textual.0`);
      }

      return this.$t(`Radius.CollaborationAssessment.Summary.Score.Textual.${Math.floor(score)}`);
    },

    statsForType(type) {
      if (!this.collaboration?.stats?.[type]?.stats) {
        return '0 / 0';
      }

      return `${this.collaboration?.stats?.[type]?.stats?.response_count ?? 0} / ${this.collaboration?.stats?.[type]
        ?.stats?.respondent_count ?? 0}`;
    },

    scoreForIndex(key, index) {
      if (!this.statistics || !this.statistics.score_detail || !this.statistics.score_detail[key]) {
        return 0;
      }

      let i = index;
      if (i >= 7) {
        i = 0;
      }

      return this.statistics.score_detail[key][i];
    },

    switchSection(direction = 1) {
      let target = this.selectedQuestionnaireSectionIndex + direction;

      if (target >= this.questionnaireSections.length) {
        target = 0;
      } else if (target < 0) {
        target = this.questionnaireSections.length - 1;
      }

      this.selectedQuestionnaireSectionIndex = target;
    },

    async downloadResults() {
      if (!this.collaboration || this.downloadingResults) {
        return;
      }

      this.downloadingResults = true;
      await this.$helper.downloadURL(
        `/home-swos/download/${this.collaboration.id}`,
        `resultaten-${this.currentYear}.pdf`
      );
      this.downloadingResults = false;
    }
  }
};
</script>
