<template>
  <div
    class="bg-white relative rounded-md border border-b-4 border-gray-200  animation-tile-scale-in animation-once animation-fill-both animation-300"
  >
    <div class="h-18 flex justify-between items-center relative px-6 font-semibold text-gray-800">
      <div class="flex items-center">
        <div class="w-8 h-8 relative flex items-center justify-center text-lightblue-link mr-4">
          <i class="bg-lightblue-link absolute top-0 left-0 w-full h-full rounded-lg opacity-20"></i>
          <component :is="icon" class="relative z-10" />
        </div>
        <h3>{{ title }}</h3>
      </div>
      <IconChevronRightBig v-if="url" class="mr-2" />
      <router-link v-if="url" :to="url" class="absolute top-0 left-0 w-full h-full z-10"></router-link>
      <i class="absolute left-6 right-6 bottom-0 border-b border-gray-200"></i>
    </div>
    <div class="p-6">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: false,
      default: null
    }
  }
};
</script>
