<template>
  <div class="text-progress-fill flex justify-center items-center w-11 h-11">
    <div class="relative w-full h-full origin-center transform -rotate-90">
      <svg>
        <circle class="stroke-current stroke-2 text-gray-200" cx="22" cy="22" r="20" fill="none"></circle>
        <circle
          class="transition-all ease-in-out duration-500 stroke-current stroke-2"
          fill="none"
          :style="{
            'stroke-dasharray': 125,
            'stroke-dashoffset': 120,
            'stroke-dashoffset': 125 - (125 * currentPercentage) / 100
          }"
          cx="22"
          cy="22"
          r="20"
        ></circle>
      </svg>
    </div>
    <IconCheckSmall
      v-if="currentPercentage >= 100"
      class="block absolute z-10 animation-general-overscale-in animation-once animation-fill-backwards animation-300 animation-delay-10"
    />
  </div>
</template>

<script>
export default {
  name: 'ProgressCircle',
  props: {
    percentage: {
      type: Number,
      required: true
    }
  },
  watch: {
    percentage: {
      immediate: false,
      handler(newVal) {
        this.currentPercentage = newVal;
      }
    }
  },
  data() {
    return {
      currentPercentage: 0
    };
  },
  mounted() {
    setTimeout(() => {
      this.currentPercentage = this.percentage;
    }, 300);
  }
};
</script>
