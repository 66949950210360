var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"group relative flex justify-center items-center border border-transparent font-semibold text-white focus:outline-none focus:outline-none transition duration-150 ease-in-out",class:{
    'py-3 px-10 text-white bg-orange hover:bg-orange-hover focus:border-orange focus:shadow-outline-orange rounded-full text-theme-250':
      _vm.variant === 'primary',
    'py-3 px-10 text-darkblue bg-white border-lightblue hover:bg-lightblue-50 hover:border-lightblue-hover focus:border-lightblue-hover focus:shadow-outline-lightblue-hover rounded-full text-theme-250':
      _vm.variant === 'secondary',
    'py-3 px-10 text-darkblue bg-transparent border-lightblue hover:bg-lightblue-50 hover:border-lightblue-hover focus:border-lightblue-hover focus:shadow-outline-lightblue-hover rounded-full text-theme-250':
      _vm.variant === 'secondary-transparent',
    'py-2 px-8 text-white bg-orange hover:bg-orange-hover focus:border-orange focus:shadow-outline-orange rounded-full text-100':
      _vm.variant === 'primary-small',
    'py-2 px-8 text-darkblue bg-white border-lightblue hover:bg-lightblue-50 hover:border-lightblue-hover focus:border-lightblue-hover focus:shadow-outline-lightblue-hover rounded-full text-100':
      _vm.variant === 'secondary-small',
    'py-2 px-3 text-darkblue bg-white border-lightblue hover:bg-lightblue-50 hover:border-lightblue-hover focus:border-lightblue-hover focus:shadow-outline-lightblue-hover rounded-md text-theme-250':
      _vm.variant === 'secondary-small-square'
  },attrs:{"type":_vm.type}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }