var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DashboardTile',{attrs:{"to":{
    name: 'QualityAssessment',
    params: {
      homeID: _vm.homeID
    }
  }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"w-full flex group justify-between items-center mb-4"},[_c('div',{staticClass:"text-theme-300 flex-grow flex-shrink-0"},[_c('span',{staticClass:"font-semibold text-100 sm:text-200"},[_vm._v(_vm._s(_vm.$t('Dashboard.Widget.Radius.Title')))])]),_c('div',{staticClass:"text-theme-300 transform group-hover:translate-x-1 transition ease-in-out duration-150"},[_c('IconChevronRightBig')],1)])]},proxy:true}])},[(_vm.$permissions.has('home-viewqualityassessment'))?_c('div',{staticClass:"flex w-full justify-between mt-6 mb-4"},[_c('DashboardTileLink',{attrs:{"title":_vm.$t('Dashboard.Widget.Radius.Action.QualityAssessment'),"to":{
        name: 'QualityAssessment',
        params: {
          homeID: _vm.homeID
        }
      }}}),(_vm.progressReportRadius)?_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"w-10"},[_c('ProgressBar',{attrs:{"percentage":_vm.progressReportRadius.progress_percentage}})],1),_c('span',{staticClass:"text-theme-50"},[_c('span',{staticClass:"text-progress-fill ml-2"},[_vm._v(" "+_vm._s(_vm.progressReportRadius.progress_percentage)+"% ")])])]):_vm._e()],1):_vm._e(),(_vm.$permissions.has('home-viewlivingenvironmentassessment'))?_c('div',{staticClass:"flex mb-4"},[_c('DashboardTileLink',{attrs:{"title":_vm.$t('Dashboard.Widget.Radius.Action.EnvironmentAssessment'),"useClickCallback":true},on:{"click":_vm.showLivingEnvironmentAssessment}})],1):_vm._e(),(_vm.$permissions.has('home-viewcollaborationassessment'))?_c('div',{staticClass:"flex items-center group"},[_c('DashboardTileLink',{attrs:{"title":_vm.$t('Dashboard.Widget.Radius.Action.CollaborationAssessment'),"to":{
        name: 'CollaborationAssessment',
        params: {
          homeID: _vm.homeID
        }
      }}}),_c('CollaborationAssessmentLabel',{staticClass:"transform transition ease-in-out duration-150 group-hover:translate-x-1"})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }