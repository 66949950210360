<template>
  <div
    class="flex flex-col items-stretch rounded-md border border-b-4 border-gray-200 bg-white md:min-w-dashboard-tile xl:ml-10 xl:w-dashboard-tile w-full min-h-intercom-tile animation-tile-scale-in animation-once animation-fill-both animation-300 overflow-hidden text-lightblue-text pt-8 px-6"
  >
    <div class="w-full flex justify-between border-b border-line" @click="displayNews">
      <div class="w-full flex justify-between items-center mb-4">
        <div class="text-theme-300 flex-grow flex-shrink-0">
          <span class="font-semibold text-100 sm:text-200">{{ $t('Dashboard.Widget.News.Title') }}</span>
        </div>
      </div>
    </div>

    <div v-if="news && news.length > 0" class="overflow-scroll hide-scrollbar h-intercom-tile flex-grow">
      <div class="w-full overflow-scroll hide-scrollbar my-6">
        <div
          v-for="(article, index) in news"
          :key="index"
          class="flex mb-4 cursor-pointer px-2 transform hover:-translate-x-1 transition ease-in-out duration-300"
          :class="{
            [`animation-list-scale-in group animation-once animation-fill-backwards animation-300 animation-delay-${index +
              5}`]: true
          }"
          @click="displayNewsArticle(article)"
        >
          <div class="relative flex-shrink-0 w-14 h-14 rounded-md overflow-hidden">
            <img
              class="object-cover absolute inset-0 w-full h-full"
              v-if="article.image && article.image[0]"
              :src="article.image[0].url"
              alt=""
            />
            <div v-else class="w-full h-full bg-gray-200"></div>
          </div>
          <div class="ml-4 h-14 truncate text-theme-100">
            <div class="flex items-center gap-x-2">
              <IconPin v-if="article.pinned" class="w-3 text-darkblue" />
              <span class="block text-theme-50">{{ article.created_at | date }}</span>
            </div>
            <span class="block h-full text-darkblue font-medium whitespace-pre-wrap">{{ article.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="news && news.length > 0"
      @click="displayNews"
      class="flex items-center justify-center flex-shrink-0 p-4 text-theme-100 border-t border-line -mx-8 cursor-pointer"
    >
      <span class="text-lightblue-link font-medium">
        {{ $t('Dashboard.Widget.News.Action.View') }}
      </span>
    </div>

    <div
      v-if="!$apollo.loading && (!news || news.length === 0)"
      class="flex flex-col justify-center items-center h-intercom-tile"
    >
      <div class="flex items-center justify-center rounded-full border-2 border-line w-18 h-18">
        <IconFileNotAvailable />
      </div>
      <span class="text-center w-full mt-4 text-100">{{ $t('Dashboard.Widget.News.BodyUnavailable') }}</span>
    </div>
    <div v-if="$apollo.loading" class="flex flex-col items-center justify-center w-full h-full pt-8">
      <img class="w-8 h-8 mb-4" src="@/assets/loading-dark.gif" alt="Loading" />
      <span class="text-150 font-semibold text-darkblue">
        {{ $t('General.Loading') }}
      </span>
    </div>
  </div>
</template>

<script>
import { GraphQLQuery } from '@/graphql';

export default {
  name: 'DashboardNews',
  apollo: {
    news: {
      query: GraphQLQuery.News
    }
  },
  methods: {
    displayNews() {
      this.$router.push({ name: 'News' });
    },
    displayNewsArticle(article) {
      this.$router.push({
        name: 'NewsDetail',
        params: {
          articleID: article.id
        }
      });
    }
  }
};
</script>
