<template>
  <BaseModal v-bind="$props" :withBottomDivider="true" @backdropClicked="hide">
    <UploadModal
      ref="uploadModal"
      @completed="updateCompleted"
      :uploadLibraryType="doc.libraryType"
      :libraryEnabled="false"
      :multiple="false"
      :replacementDocument="doc"
      :homeID="homeID"
    />
    <template>
      <div class="max-h-modal-document-details -mt-4 w-full sm:w-modal">
        <div class="text-theme-100 flex flex-col w-full">
          <div class="flex text-left items-center focus:outline-none cursor-pointer flex-1 mb-6">
            <IconFile class="flex-shrink-0" :document="doc" />
            <div class="ml-8 flex flex-col flex-1">
              <span class="text-theme-150 text-darkblue font-semibold flex-1 break-all">{{
                $filters.documentname(doc)
              }}</span>
              <span class="text-theme-100" v-if="doc.file.length > 0">{{ doc.file[0].size | filesize }}</span>
            </div>
          </div>
          <div class="flex flex-row w-full text-theme-100 mb-6">
            <div class="flex flex-col mr-14">
              <span class="text-darkblue font-semibold">{{ $t('Library.UploadDate') }}</span>
              <span>{{ doc.created_at | date }}</span>
            </div>
            <div class="flex flex-col">
              <span class="text-darkblue font-semibold">{{ $t('Library.ExpirationDate') }}</span>
              <div v-if="hasWritePermission && mutationEnabled">
                <button
                  @click="toggleDatePicker(doc)"
                  class="focus:outline-none"
                  :class="{
                    'text-lightblue-link': !doc.expiration_date,
                    'hover:text-lightblue-link transition ease-in-out duration-150': doc.expiration_date
                  }"
                >
                  {{ doc.expiration_date | date | empty($t('Library.Action.AddExpirationDate')) }}
                </button>
                <button v-if="doc.expiration_date" class="ml-2 focus:outline-none" @click="removeExpiryDate(doc)">
                  <IconCross class="w-2 h-2 text-red-500" />
                </button>

                <DateSelector
                  :visible="datePickerVisible"
                  v-model="doc.expiration_date"
                  @change="saveExpiryDate(doc)"
                />
              </div>
              <span v-else>{{ doc.expiration_date | date | empty('-') }}</span>
            </div>
          </div>

          <div v-if="chapters" class="mb-6">
            <span class="text-darkblue font-semibold">{{ $t('Library.DocumentUsage') }}</span>
            <div v-for="(paragraphs, index) in chapters" :key="index" class="flex flex-col">
              {{ paragraphs[0].chapter.name }}
              <span v-for="(paragraph, j) in paragraphs" :key="j">- {{ paragraph.name }}</span>
            </div>
          </div>

          <div class="flex flex-col mb-6" v-if="doc.tags && doc.tags.length > 0">
            <span class="text-darkblue font-semibold">{{ $t('Library.Tags') }}</span>
            <div class="flex mt-1">
              <span
                v-for="(tag, index) in doc.tags"
                :key="index"
                class="bg-lightblue-border text-lightblue-text rounded-md mr-1 p-1 text-50 font-bold uppercase"
              >
                {{ tag.name }}
              </span>
            </div>
          </div>

          <div class="flex flex-col mb-6">
            <div class="text-darkblue font-semibold">{{ $t('Library.Map') }}</div>
            <div class="mt-1">
              {{ folderNames }}
            </div>
            <div v-if="hasWritePermission && mutationEnabled" class="flex mt-1">
              <ButtonElement variant="secondary-small-square" @click.native="moveToFolder(doc)">
                <span class="text-100">{{ $t('Modal.DocumentDetailsModal.Action.MoveFolder') }}</span>
                <IconMoveFolder class="ml-3 flex-shrink-0" />
              </ButtonElement>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:bottom>
      <div class="flex flex-col sm:flex-row w-full sm:w-auto justify-end p-6">
        <ButtonElement class="mb-2 sm:mb-0 sm:mr-2" variant="secondary-small-square" @click.native="downloadDocument">
          <span class="text-100">{{ $t('Modal.DocumentDetailsModal.Action.Download') }}</span>
          <IconDownload class="ml-3 flex-shrink-0" />
        </ButtonElement>
        <ButtonElement
          v-if="hasWritePermission && mutationEnabled"
          class="mb-2 sm:mb-0 sm:mr-2"
          variant="secondary-small-square"
          @click.native="replaceDocument"
        >
          <span class="text-100">{{ $t('Modal.DocumentDetailsModal.Action.Replace') }}</span>
          <IconReplace class="ml-3 flex-shrink-0" />
        </ButtonElement>
        <ButtonElement
          v-if="hasWritePermission && mutationEnabled"
          class="mb-2 sm:mb-0"
          variant="secondary-small-square"
          @click.native="deleteDocument"
        >
          <span class="text-100">{{ $t('Modal.DocumentDetailsModal.Action.Delete') }}</span>
          <IconTrash class="ml-3 flex-shrink-0" />
        </ButtonElement>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal';
import UploadModal from '@/components/modals/UploadModal';
import ButtonElement from '@/components/ButtonElement';
import { GraphQLQuery, GraphQLMutation } from '@/graphql';

export default {
  name: 'DocumentDetailsModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal,
    UploadModal,
    ButtonElement
  },
  props: {
    document: {
      type: Object,
      required: true
    },
    mutationEnabled: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  computed: {
    hasWritePermission() {
      let canWrite = false;
      if (this.doc.libraryType === 'private') {
        canWrite = !this.$permissions.has('home-find') || this.$store.state.currentManagedHome;
      } else if (this.doc.libraryType === 'healthinsurer') {
        canWrite = this.$permissions.has('document-managehealthinsurerdocuments') || this.$permissions.hasAdminRole();
      } else if (this.doc.libraryType === 'public') {
        canWrite = this.$permissions.hasAdminRole();
      }

      return canWrite;
    },

    doc() {
      return this.document;
    },

    folderNames() {
      if (!this.doc.folder) {
        return 'Hoofdmap';
      }

      let name = this.doc.folder.title || '';
      if (this.doc.folder.folder) {
        name = `${this.doc.folder.folder.title} / ${name}`;

        if (this.doc.folder.folder.folder) {
          name = `${this.doc.folder.folder.folder.title} / ${name}`;
        }
      }

      return name;
    },

    homeID() {
      if (this.$route.params.homeID) {
        return Number(this.$route.params.homeID);
      }

      return this.$store.state.currentManagedHome ? Number(this.$store.state.currentManagedHome.id) : undefined;
    },

    chapters() {
      let chapters = {};

      this.doc.home_paragraph.forEach(paragraphProgress => {
        if (!paragraphProgress.paragraph || !paragraphProgress.paragraph.chapter) {
          return;
        }

        if (!chapters[paragraphProgress.paragraph.chapter.name]) {
          chapters[paragraphProgress.paragraph.chapter.name] = [];
        }

        chapters[paragraphProgress.paragraph.chapter.name].push(paragraphProgress.paragraph);
      });

      if (Object.keys(chapters).length === 0) {
        chapters = null;
      }

      return chapters;
    }
  },
  data() {
    return {
      datePickerVisible: false
    };
  },
  methods: {
    downloadDocument() {
      this.hide();
      this.$helper.downloadDocument(this.doc);
    },

    async deleteDocument() {
      this.hide();

      try {
        await this.$apollo.mutate({
          mutation: GraphQLMutation.DocumentDelete,
          variables: {
            documentID: this.doc.id
          },
          update: (queryCacheStore, { data: removedDocument }) => {
            const queryInfo = {
              query: GraphQLQuery.Documents,
              variables: {
                libraryType: this.doc.libraryType
              }
            };

            const data = queryCacheStore.readQuery(queryInfo);
            data.documents = data.documents.filter(doc => {
              return doc.id !== removedDocument.deleteDocument.document.id;
            });
            data.count = data.count.filter(doc => {
              return doc.id !== removedDocument.deleteDocument.document.id;
            });

            queryCacheStore.writeQuery({
              ...queryInfo,
              data
            });
          }
        });
      } catch (error) {
        if (error.graphQLErrors && error.graphQLErrors[0].message === 'document.inUse') {
          this.$modal.show('ErrorModal', {
            title: this.$t('Library.Error.DocumentInUse.Title'),
            error: this.$t('Library.Error.DocumentInUse.Body')
          });
        }
      }
    },

    async replaceDocument() {
      this.$refs.uploadModal.show();
    },

    async updateCompleted(updatedDocuments) {
      this.hide();

      if (updatedDocuments.length > 0) {
        this.$emit('updated', updatedDocuments[0]);
        if (this.$route.meta.library) this.$store.commit('forceUpdate', true);
      }
    },

    toggleDatePicker(doc) {
      if (!doc.expiration_date) {
        doc.expiration_date = new Date();
      }

      this.datePickerVisible = !this.datePickerVisible;
    },

    hideDatePicker() {
      this.datePickerVisible = false;
    },

    removeExpiryDate(doc) {
      doc.expiration_date = null;
      this.saveExpiryDate();
    },

    moveToFolder(doc) {
      this.$modal.show('DocumentMoveModal', {
        doc,
        libraryType: doc.libraryType
      });
    },

    async saveExpiryDate(doc) {
      if (!doc || !doc.file || doc.file.length === 0) {
        return;
      }

      let expirationDate = doc.expiration_date;
      if (expirationDate) {
        expirationDate.setHours(expirationDate.getHours() + 5);
        [expirationDate] = expirationDate.toISOString().split('T');
      }

      this.hideDatePicker();
      await this.$apollo.mutate({
        mutation: GraphQLMutation.DocumentUpdateExpiration,
        variables: {
          documentID: doc.id,
          expirationDate
        }
      });
    }
  }
};
</script>
