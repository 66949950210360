<template>
  <BaseModal v-bind="$props" :title="$t('Modal.CreateFolder.Title')" :with-bottom-divider="true">
    <template>
      <div class="flex flex-col mb-6">
        <InputVerify
          class="w-full "
          required
          :placeholder="$t('Modal.CreateFolder.Placeholder')"
          v-model="folderName"
        />
      </div>
    </template>
    <template v-slot:bottom>
      <div class="flex justify-end p-6">
        <ButtonElement class="mr-2" variant="secondary-small" @click.native="hide">{{
          $t('Modal.Generic.Cancel')
        }}</ButtonElement>
        <ButtonElement @click.native="submit" variant="primary-small">{{
          $t('Modal.CreateFolder.Action.Create')
        }}</ButtonElement>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { GraphQLMutation } from '@/graphql';
import BaseModal from '@/components/modals/BaseModal';
import ButtonElement from '@/components/ButtonElement';
import InputVerify from '@/components/InputVerify';

export default {
  name: 'CreateFolderModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal,
    InputVerify,
    ButtonElement
  },
  props: {
    parent: {
      type: [String, Number],
      default: null,
      required: false
    },
    libraryType: {
      type: String,
      default: 'private',
      required: false
    }
  },
  data() {
    return {
      folderName: ''
    };
  },
  methods: {
    async submit() {
      if (!this.folderName.length) {
        return;
      }

      await this.$apollo.mutate({
        mutation: GraphQLMutation.DocumentFolderCreate,
        variables: {
          title: this.folderName,
          parent: this.parent,
          libraryType: this.libraryType
        }
      });

      this.folderName = '';
      this.$emit('completed');
      this.hide();
    }
  }
};
</script>
