<template>
  <div class="flex flex-col">
    <span ref="body" class="whitespace-pre-wrap" v-if="!markdown">{{ trimmedText }}</span>
    <span ref="body" v-if="markdown" v-html="markdownText"></span>
    <div @click="isExpanded = !isExpanded" class="cursor-pointer" v-if="text.length > this.maxLength">
      <slot name="more" v-if="!isExpanded">
        <span class="text-25 text-darkblue">{{ $t('General.Action.ReadMore') }}</span>
      </slot>
      <slot name="less" v-if="isExpanded">
        <span class="text-25 text-darkblue">{{ $t('General.Action.ReadLess') }}</span>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReadMoreSpan',
  props: {
    text: {
      type: String,
      required: true
    },
    markdown: {
      type: Boolean,
      default: false,
      required: false
    },
    maxLength: {
      type: Number,
      default: 140,
      required: false
    },
    centerImages: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  watch: {
    text: {
      immediate: true,
      handler(newValue) {
        this.isExpanded = newValue && newValue.length <= this.maxLength;
      }
    }
  },
  computed: {
    trimmedText() {
      if (this.isExpanded || this.text.length <= this.maxLength) {
        return this.text;
      }

      return `${this.text
        .substr(0, this.maxLength)
        .replace(/\n\n\n\n/g, ' ')
        .trim()}...`;
    },

    markdownText() {
      let html = this.$filters.markdown2html(this.trimmedText);

      // Apply tailwind styling
      html = html.replace(/<p>/gi, '<p class="mt-4 whitespace-pre-wrap">');
      html = html.replace(/<ul>/gi, '<ul class="mt-4 ml-4 list-disc">');
      html = html.replace(/<ol>/gi, '<ol class="mt-4 ml-4 list-decimal">');
      html = html.replace(/<a href/gi, '<a class="font-semibold underline text-lightblue-link" target="_blank" href');

      if (this.centerImages) {
        html = html.replace(/<img/gi, '<img style="margin: 0 auto;"');
      }

      const pdfRegex = /<a.*? href="(.*?.pdf)".*?>(.*?)<\/a>/gi;
      let matches = null;
      // eslint-disable-next-line no-cond-assign
      while ((matches = pdfRegex.exec(html)) !== null) {
        if (matches.length <= 2) {
          continue;
        }

        html = html.replace(
          matches[0],
          `<iframe class="w-full mt-4" src="${matches[1]}" style="aspect-ratio: 1/1.3"></iframe><br /><a class="font-semibold underline text-lightblue-link" href="${matches[1]}" target="_blank">Open in nieuw venster (${matches[2]})</span>`
        );
      }

      const youtubeRegex = /<a.*? href="(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?">.*?<\/a>/gi;
      // eslint-disable-next-line no-cond-assign
      while ((matches = youtubeRegex.exec(html)) !== null) {
        if (matches.length <= 1) {
          continue;
        }

        html = html.replace(
          matches[0],
          `<iframe class="w-full mt-4" allow="fullscreen" src="https://www.youtube.com/embed/${matches[1]}" style="aspect-ratio: 16/9"></iframe>`
        );
      }

      return html;
    }
  },
  data() {
    return {
      isExpanded: false
    };
  }
};
</script>
