<template>
  <div>
    <InputMessage :placeholder="placeholder" v-model="message" @send="addComment" />
    <div v-for="(comment, index) in activeUserDataParagraphID > 0 ? comments : []" :key="index">
      <div
        class="flex mt-6"
        :class="{
          [`animation-list-scale-in animation-once animation-fill-backwards animation-300 animation-delay-${index}`]: true
        }"
      >
        <img
          v-if="comment.user && comment.user.avatar_id"
          class="block w-8 h-8"
          :src="`@/assets/profile/profile-${comment.user.avatar_id}.svg` | resolve"
          alt=""
        />
        <div class="ml-4 w-full">
          <div class="flex w-full justify-between items-center py-1.5">
            <span
              v-if="comment.user && comment.user.first_name && comment.user.last_name"
              class="block font-bold text-darkblue text-100"
              >{{ comment.user.first_name + ' ' + comment.user.last_name }}</span
            >
            <span v-else class="block font-bold text-darkblue text-100">
              Gebruiker
            </span>
            <div class="text-50">
              <span class="text-lightblue-hover">{{ comment.created_at | timeago }}</span>
            </div>
          </div>

          <ReadMoreSpan
            v-if="!comment.show_edit_mode"
            class="block mt-2 text-100 w-full"
            :text="comment.message"
            :maxLength="140"
          />
          <InputMessage
            v-if="comment.show_edit_mode"
            :placeholder="placeholder"
            v-model="comment.message"
            @send="editComment(comment)"
          />

          <div
            class="w-full flex justify-end text-25"
            v-if="
              !comment.show_edit_mode &&
                comment.user &&
                ($store.state.currentUser.id === comment.user.id || $permissions.isAdmin())
            "
          >
            <span class="text-lightblue-link" @click="comment.show_edit_mode = true">{{
              $t('Comments.Action.Edit')
            }}</span>
            <span class="text-lightblue mx-2">|</span>
            <span
              class="text-red-500"
              @click="
                $modal.confirm(() => {
                  deleteComment(comment);
                }, 'ConfirmDeleteComment')
              "
              >{{ $t('Comments.Action.Delete') }}</span
            >
          </div>
        </div>
      </div>
      <div class="border-b border-gray-200 w-full mt-4"></div>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import { GraphQLQuery, GraphQLMutation } from '@/graphql';
import InputMessage from '@/components/InputMessage';
import ReadMoreSpan from '@/components/ReadMoreSpan';

export default {
  components: {
    InputMessage,
    ReadMoreSpan
  },
  props: {
    placeholder: {
      required: true,
      type: String
    },
    paragraph: {
      required: true,
      type: Object
    },
    activeUserDataParagraph: {
      required: false,
      default: null,
      type: Object
    }
  },
  watch: {
    activeUserDataParagraph: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          this.activeUserDataParagraphID = 0;
          return;
        }

        this.activeUserDataParagraphID = Number(newValue.id);
      }
    }
  },
  apollo: {
    comments: {
      query: GraphQLQuery.RadiusQualityParagraphComments,
      pollInterval: 30000,
      fetchPolicy: 'cache-and-network',
      skip() {
        if (this.comments && this.comments.length > 0) {
          const result = this.comments.find(comment => {
            return comment.show_edit_mode;
          });
          if (result) {
            return true;
          }
        }
        return this.activeUserDataParagraphID === 0;
      },
      variables() {
        return {
          activeUserDataParagraphID: this.activeUserDataParagraphID
        };
      },
      update(data) {
        const comments = get(data, 'paragraphHome.comments', []);
        comments.map(comment => {
          comment.show_edit_mode = false;
          return comment;
        });
        return comments;
      },
      result() {
        this.$forceUpdate();
      }
    }
  },
  data() {
    return {
      message: '',
      activeUserDataParagraphID: 0
    };
  },
  methods: {
    async addComment() {
      if (!this.message || this.message.length === 0) {
        return;
      }

      try {
        await this.$apollo.mutate({
          mutation: GraphQLMutation.RadiusQualityParagraphCommentCreate,
          variables: {
            paragraphID: this.paragraph.id,
            message: this.message.trim()
          },
          update: (
            queryCacheStore,
            {
              data: {
                createComment: { comment }
              }
            }
          ) => {
            const queryInfo = {
              query: GraphQLQuery.RadiusQualityParagraphComments,
              variables: {
                activeUserDataParagraphID: Number(comment.paragraph_home.id)
              }
            };

            let data = {};
            try {
              data = queryCacheStore.readQuery(queryInfo);
            } catch {
              data = {
                paragraphHome: {
                  comments: []
                }
              };
            }

            data.paragraphHome.comments = [comment].concat(data.paragraphHome.comments);

            queryCacheStore.writeQuery({
              ...queryInfo,
              data
            });

            this.activeUserDataParagraphID = Number(comment.paragraph_home.id);
            this.$apollo.queries.comments.refresh();

            this.$emit('paragraphUserDataCreated', comment.paragraph_home);
          }
        });

        this.message = '';
      } catch {
        this.$modal.show('ErrorModal', {
          title: this.$t('Radius.Error.CommentCreateFailed.Title'),
          error: this.$t('Radius.Error.CommentCreateFailed.Body')
        });
      }
    },
    async deleteComment(comment) {
      try {
        await this.$apollo.mutate({
          mutation: GraphQLMutation.RadiusQualityParagraphCommentDelete,
          variables: {
            commentID: comment.id
          },
          update: queryCacheStore => {
            const queryInfo = {
              query: GraphQLQuery.RadiusQualityParagraphComments,
              variables: {
                activeUserDataParagraphID: this.activeUserDataParagraphID
              }
            };

            const data = queryCacheStore.readQuery(queryInfo);

            data.paragraphHome.comments = data.paragraphHome.comments.filter(existingComment => {
              return existingComment.id !== comment.id;
            });

            queryCacheStore.writeQuery({
              ...queryInfo,
              data
            });

            this.$apollo.queries.comments.refresh();
          }
        });
      } catch {
        this.$modal.show('ErrorModal', {
          title: this.$t('Radius.Error.CommentDeleteFailed.Title'),
          error: this.$t('Radius.Error.CommentDeleteFailed.Body')
        });
      }
    },
    async editComment(comment) {
      try {
        await this.$apollo.mutate({
          mutation: GraphQLMutation.RadiusQualityParagraphCommentUpdate,
          variables: {
            commentID: comment.id,
            message: comment.message.trim()
          },
          update: queryCacheStore => {
            const queryInfo = {
              query: GraphQLQuery.RadiusQualityParagraphComments,
              variables: {
                activeUserDataParagraphID: this.activeUserDataParagraphID
              }
            };

            const data = queryCacheStore.readQuery(queryInfo);

            data.paragraphHome.comments.map(existingComment => {
              if (existingComment.id === comment.id) {
                comment.show_edit_mode = false;
                return comment;
              }
              return existingComment;
            });

            queryCacheStore.writeQuery({
              ...queryInfo,
              data
            });

            this.$apollo.queries.comments.refresh();
          }
        });
      } catch {
        this.$modal.show('ErrorModal', {
          title: this.$t('Radius.Error.CommentCreateFailed.Title'),
          error: this.$t('Radius.Error.CommentCreateFailed.Body')
        });
      }
    }
  }
};
</script>
