<template>
  <DashboardTile :homeID="homeID" :to="{ name: 'Library' }">
    <template v-slot:header>
      <div class="w-full group flex justify-between items-center mb-4">
        <div class="text-theme-300 flex-grow flex-shrink-0">
          <span class="font-semibold text-100 sm:text-200">{{ $t('Library.Title') }}</span>
        </div>
        <div class="text-theme-300 transform group-hover:translate-x-1 transition ease-in-out duration-150">
          <IconChevronRightBig />
        </div>
      </div>
    </template>
    <div class="mt-6">
      <div
        class="flex mb-4"
        v-if="
          (!$permissions.has('home-find') || $store.state.currentManagedHome) &&
            $permissions.has('document-viewprivatedocuments')
        "
      >
        <DashboardTileLink :title="$t('Library.Section.Private')" :to="{ name: 'Library' }" />
      </div>
      <div class="flex mb-4" v-if="$permissions.has('document-viewhealthinsurerdocuments')">
        <DashboardTileLink
          :title="$t('Library.Section.Healthinsurer')"
          :to="{
            name: 'Library',
            params: {
              type: 'zorgaanbieder'
            }
          }"
        />
      </div>
      <div class="flex mb-4" v-if="$permissions.has('document-viewpublicdocuments')">
        <DashboardTileLink
          :title="$t('Library.Section.Public')"
          :to="{
            name: 'Library',
            params: {
              type: 'publiek'
            }
          }"
        />
      </div>
    </div>
  </DashboardTile>
</template>

<script>
import DashboardTile from '@/components/dashboard-tiles/DashboardTile';
import DashboardTileLink from '@/components/DashboardTileLink';

export default {
  name: 'DashboardTileLibrary',
  extends: DashboardTile,
  inheritAttrs: false,
  components: {
    DashboardTile,
    DashboardTileLink
  },
  props: {}
};
</script>
