var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseModal',_vm._b({attrs:{"title":_vm.$t('ManageUsers.Modal.Manage.ModalTitle'),"withBottomDivider":true},on:{"backdropClicked":_vm.hide},scopedSlots:_vm._u([{key:"bottom",fn:function(){return [_c('div',{staticClass:"flex flex-col-reverse sm:flex-row w-full sm:w-auto justify-end p-6 pb-4 sm:pb-6"},[_c('ButtonElement',{staticClass:"my-2 sm:my-0 sm:mr-2",attrs:{"variant":"secondary-small"},nativeOn:{"click":function($event){return _vm.hide.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('Modal.Generic.Cancel')))]),_c('ButtonElement',{attrs:{"variant":"primary-small"},nativeOn:{"click":function($event){return _vm.confirm.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.user ? _vm.$t('ManageUsers.Modal.Manage.Action.Edit') : _vm.$t('ManageUsers.Modal.Manage.Action.Add')))])],1)]},proxy:true}])},'BaseModal',_vm.$props,false),[[_c('div',{staticClass:"flex flex-col w-full text-theme-100 mb-5"},[(_vm.error.length > 0)?_c('div',{staticClass:"rounded-md shadow-sm mb-8 p-4 text-error-balloon-text bg-error-balloon-background width-full text-center flex flex-col animation-error-scale-in animation-once animation-fill-both animation-500"},[_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.error))])]):_vm._e(),_c('span',{staticClass:"text-100 text-darkblue font-semibold mb-2"},[_vm._v(_vm._s(_vm.$t('ManageUsers.Modal.Manage.Email')))]),_c('InputVerify',{staticClass:"w-full max-w-account-textfield",attrs:{"required":"","disabled":_vm.user != null,"placeholder":_vm.$t('ManageUsers.Modal.Manage.Email.Placeholder'),"messageIconVisible":_vm.error.length > 0,"messageVariant":"error"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),(_vm.user)?_c('ButtonElement',{staticClass:"my-2 w-full max-w-account-textfield",attrs:{"variant":"secondary-small"},nativeOn:{"click":function($event){_vm.$modal.confirm(() => {
            _vm.remove();
          }, 'ConfirmDeleteManagedUser')}}},[_vm._v(_vm._s(_vm.$t('ManageUsers.Modal.Manage.Action.Remove')))]):_vm._e(),_c('span',{staticClass:"font-semibold text-darkblue mt-4 mb-2",class:{
          'text-error-balloon-text font-bold': !_vm.expiryDate && _vm.error
        }},[_vm._v(_vm._s(_vm.$t('ManageUsers.Modal.Manage.ExpiryDate')))]),_c('div',[_c('button',{staticClass:"focus:outline-none",class:{
            'text-lightblue-link': !_vm.expiryDate,
            'hover:text-lightblue-link transition ease-in-out duration-150': _vm.expiryDate
          },on:{"click":_vm.toggleDatePicker}},[_vm._v(" "+_vm._s(_vm._f("empty")(_vm._f("date")(_vm.expiryDate),_vm.$t('ManageUsers.Modal.Manage.Action.AddExpirationDate')))+" ")]),(_vm.expiryDate)?_c('button',{staticClass:"ml-2 focus:outline-none",on:{"click":_vm.removeExpiryDate}},[_c('IconCross',{staticClass:"w-2 h-2 text-red-500"})],1):_vm._e(),_c('DateSelector',{attrs:{"visible":_vm.datePickerVisible},on:{"change":_vm.hideDatePicker},model:{value:(_vm.expiryDate),callback:function ($$v) {_vm.expiryDate=$$v},expression:"expiryDate"}})],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }