<template>
  <div>
    <DropZone
      v-if="dropOptions"
      id="upload-dropzone"
      ref="dropzone"
      :options="dropOptions"
      :include-styling="false"
      :useCustomSlot="true"
      @vdropzone-files-added="dropzoneFilesAdded($event)"
      @vdropzone-removed-file="dropzoneFilesRemoved([$event])"
      @vdropzone-total-upload-progress="dropzoneUploadProgress"
      @vdropzone-complete="dropzoneSingleUploadFinished"
    >
      <button
        class="
          w-full
          py-5
          flex flex-col
          justify-center
          items-center
          bg-gray-100
          rounded-md
          border-2 border-dashed border-gray-300
          focus:outline-none
          mb-2
        "
      >
        <IconUpload class="text-darkblue w-5 h-5" />
        <div class="flex flex-row flex-wrap justify-center text-theme-100">
          <span class="text-lightblue-link font-semibold mr-1">{{ $t('FileUpload.UploadFile') }}</span>
          <span class="font-normal"> {{ $t('FileUpload.DragAndDrop') }}</span>
          <div class="w-full">
            <span class="text-50">
              <span v-if="types && types.length > 0">
                <span v-for="(type, index) in types" :key="index">
                  {{ $t(`FileType.${type}`) }}
                </span>
              </span>
              <span v-else>{{ $t('FileUpload.FileDescription.All') }}</span>
              {{ $t('FileUpload.FileDescription.SizeLimit') | format(dropOptions.maxFilesize) }}
            </span>
          </div>
        </div>
      </button>
    </DropZone>

    <div
      v-if="error && error.length > 0"
      class="
        w-full
        rounded-md
        shadow-sm
        text-theme-150
        p-2
        text-error-balloon-text
        bg-error-balloon-background
        width-full
        text-center
        flex flex-col
        animation-error-scale-in animation-once animation-fill-both animation-500
      "
    >
      <span class="flex items-center">
        <IconExclamationMark class="w-5 h-5 mr-2" />
        <span class="font-normal">
          {{ error }}
        </span>
      </span>
    </div>

    <div class="max-h-48">
      <div
        class="w-full flex flex-col justify-between items-center my-4"
        v-for="(file, index) in displayPending ? pendingFiles : []"
        :key="index"
      >
        <div class="flex flex-row items-center w-full px-2">
          <IconFile :file="file" />
          <div class="ml-5 w-full">
            <p class="w-full text-theme-150 text-darkblue font-semibold truncate">{{ file.name }}</p>
            <div class="text-theme-100 flex items-center justify-start">
              <span>{{ (file.size / 1000) | filesize }}</span>
              <span class="text-gray-300 text-400 mx-1">•</span>
              <button
                @click="toggleDatePicker(file)"
                class="focus:outline-none"
                :class="{
                  'text-lightblue-link': !file.expiryDate,
                  'hover:text-lightblue-link transition ease-in-out duration-150': file.expiryDate
                }"
              >
                {{ file.expiryDate | date | empty($t('FileUpload.Action.AddExpirationDate')) }}
              </button>
              <button v-if="file.expiryDate" class="ml-2 focus:outline-none" @click="removeExpiryDate(file)">
                <IconCross class="w-2 h-2 text-red-500" />
              </button>

              <DateSelector
                :visible="datePickerTargetFile == file"
                v-model="file.expiryDate"
                @change="datePickerTargetFile = null"
              />
            </div>
          </div>
          <button class="focus:outline-none" @click="removeFile(file)">
            <IconTrash class="w-5 h-5 text-gray-400" />
          </button>
        </div>
      </div>
    </div>
    <ProgressBar v-if="uploadProgress > 0" :percentage="uploadProgress" class="mt-3"></ProgressBar>
  </div>
</template>

<script>
import DropZone from 'vue2-dropzone';
import env from '@/env';
import store from '@/store';
import { GraphQLQuery, GraphQLMutation } from '@/graphql';
import ProgressBar from '@/components/ProgressBar';

export default {
  name: 'FileUpload',
  components: {
    DropZone,
    ProgressBar
  },
  props: {
    displayPending: {
      type: Boolean,
      default: true,
      required: false
    },
    homeID: {
      type: Number,
      default: undefined,
      required: false
    },
    folderID: {
      type: Number,
      default: undefined,
      required: false
    },
    tags: {
      type: Array,
      default() {
        return [];
      },
      required: false
    },
    uploadLibraryType: {
      type: String,
      default: 'private',
      required: false
    },
    multiple: {
      type: Boolean,
      default: true,
      required: false
    },
    replacementDocument: {
      type: Object,
      default: null,
      required: false
    },
    types: {
      type: Array,
      default() {
        return [];
      },
      required: false
    }
  },
  data() {
    return {
      dropOptions: {
        url: `${env.value('VUE_APP_API_BASE_URL', 'http://localhost:1337')}${env.value(
          'VUE_APP_UPLOAD_URL',
          '/upload'
        )}`,
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`
        },
        paramName: 'files',
        maxFilesize: 25, // MB
        addRemoveLinks: false,
        autoProcessQueue: false,
        previewTemplate: '<div></div>',
        maxFiles: this.multiple ? 50 : 1,
        ...this.additionalDropOptions
      },
      error: '',
      uploadProgress: 0,
      pendingFiles: [],
      documentResponses: [],
      datePickerTargetFile: null
    };
  },
  computed: {
    additionalDropOptions() {
      if (!this.types || this.types.length === 0) {
        return {};
      }

      let parameters = {};

      if (this.types.includes('image')) {
        parameters = {
          ...parameters,
          resizeMethod: 'contain',
          resizeWidth: '640px'
        };
      }

      return parameters;
    }
  },
  methods: {
    toggleDatePicker(file) {
      if (!file.expiryDate) {
        file.expiryDate = new Date();
      }

      this.datePickerTargetFile = file;
    },

    hideDatePicker() {
      this.datePickerTargetFile = null;
    },

    removeFile(file) {
      this.$refs.dropzone.removeFile(file);
    },

    removeExpiryDate(file) {
      file.expiryDate = null;
      this.$forceUpdate();
    },

    startUpload() {
      this.error = '';

      const emptyExpiryDate = this.pendingFiles.find(file => !file.expiryDate);
      if (emptyExpiryDate) {
        this.error = this.$t('FileUpload.Error.ExpiryDateRequired');
        return;
      }

      this.uploadProgress = 1;
      setTimeout(() => {
        this.$refs.dropzone.processQueue();
      }, 100);
    },

    dropzoneFilesAdded(files) {
      if (files.length > this.dropOptions.maxFiles) {
        this.error = this.$filters.format(this.$t('FileUpload.Error.TooManyFiles'), this.dropOptions.maxFiles);
        return;
      }

      let hasError = false;
      for (let i = 0; i < files.length; i += 1) {
        const fileExtension = `.${files[i].name.split('.').pop()}`;
        if (files[i].status === 'error') {
          hasError = true;
        } else if (
          this.types &&
          this.types.length > 0 &&
          !this.types.includes(this.$helper.typeForFileExtension(fileExtension))
        ) {
          hasError = true;
        } else {
          this.pendingFiles.push(files[i]);
        }
      }

      if (hasError) {
        if (this.types && this.types.length > 0) {
          this.error = this.$t('FileUpload.Error.FileTooLargeOrTypeMismatch');
        } else {
          this.error = this.$t('FileUpload.Error.FileTooLarge');
        }
      }

      this.$emit('fileadded', this.pendingFiles);
    },

    dropzoneFilesRemoved(files) {
      for (let i = 0; i < files.length; i += 1) {
        const index = this.pendingFiles.indexOf(files[i]);
        if (index >= 0) {
          this.pendingFiles.splice(index, 1);
        }
      }
    },

    dropzoneUploadProgress(progress, totalBytes) {
      if (totalBytes === 0) {
        this.uploadProgress = 0;
        return;
      }
      this.uploadProgress = progress;
    },

    async dropzoneSingleUploadFinished(response) {
      try {
        if (response.status === 'canceled') {
          return;
        }

        if (response.status !== 'success') {
          if (response.xhr && response.xhr.status === 0) {
            throw new Error('Uploadserver niet bereikbaar.');
          } else {
            throw new Error(`Ongeldige reactie (${response.xhr.status}) van uploadserver.`);
          }
        }

        const jsonResponse = JSON.parse(response.xhr.response);
        if (!jsonResponse || jsonResponse.length === 0) {
          throw new Error('Ongeldige reactie van uploadserver.');
        }

        let expirationDate = response && response.expiryDate ? response.expiryDate : null;
        if (expirationDate) {
          expirationDate.setHours(expirationDate.getHours() + 5);
          [expirationDate] = expirationDate.toISOString().split('T');
        }

        const documentParameters = {
          name: jsonResponse[0].name,
          homeID: this.homeID,
          folderID: this.folderID,
          fileID: jsonResponse[0].id,
          libraryType: this.uploadLibraryType,
          expirationDate,
          tagString: this.tags && this.tags.length > 0 ? this.tags.join(';') : null
        };

        if (this.replacementDocument) {
          documentParameters.documentID = this.replacementDocument.id;

          const documentUpdateResponse = await this.$apollo.mutate({
            mutation: GraphQLMutation.DocumentUpdate,
            variables: documentParameters
          });

          this.documentResponses.push(documentUpdateResponse.data.updateDocument.document);
        } else {
          const documentCreationResponse = await this.$apollo.mutate({
            mutation: GraphQLMutation.DocumentCreate,
            variables: documentParameters
          });

          this.documentResponses.push(documentCreationResponse.data.createDocument.document);
        }
      } catch (error) {
        if (!this.error || this.error.length === 0) {
          this.error = error && error.message ? error.message : this.$t('FileUpload.Error.FileTooLarge');
        }

        if (error.graphQLErrors && error.graphQLErrors[0].message === 'file.oldFile') {
          this.error = this.$t('FileUpload.Error.UpdateFailed');
        }

        setTimeout(() => {
          this.removeFile(response);
        }, 200);
        return;
      }

      this.error = '';
      const index = this.pendingFiles.indexOf(response);
      if (index >= 0) {
        this.pendingFiles.splice(index, 1);
      }

      if (this.pendingFiles.length === 0) {
        this.dropzoneQueueComplete();
      }
    },

    dropzoneQueueComplete() {
      if (!this.replacementDocument) {
        let libraryType = this.uploadLibraryType;

        if (libraryType === 'private_admin') {
          libraryType = 'private';
        }

        const queryInfo = {
          query: GraphQLQuery.Documents,
          variables: {
            libraryType
          }
        };

        try {
          const queryCacheStore = this.$apollo.provider.defaultClient.cache;
          const data = queryCacheStore.readQuery(queryInfo);
          data.documents = this.documentResponses.concat(data.documents);
          data.count = this.documentResponses.concat(data.count);
          queryCacheStore.writeQuery({
            ...queryInfo,
            data
          });
        } catch (error) {
          // Ignore errors
        }
      }

      this.$emit('completed', this.documentResponses);

      this.uploadProgress = 0;
      this.pendingFiles = [];
      this.documentResponses = [];
      this.$refs.dropzone.removeAllFiles(true);
    }
  }
};
</script>
