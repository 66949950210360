<template>
  <div v-if="visible">
    <div
      @click="backdropClicked"
      class="z-20 fixed w-full h-full left-0 top-0 flex justify-center items-center bg-gray-500 opacity-50 overflow-hidden"
    ></div>
    <div
      @click.self="backdropClicked"
      class="z-20 fixed w-full h-full flex left-0 top-0 justify-center items-center overflow-hidden animation-modal-scale-in animation-once animation-100"
      :class="{ 'animation-modal-scale-out': displayHideAnimation }"
    >
      <div class="mx-4 sm:mx-0 sm:min-w-modal bg-white opacity-100 rounded-md">
        <div
          class="w-full items-center justify-between flex p-6"
          :class="{ 'border-b border-gray-300 mb-6': withTitleDivider }"
        >
          <span class="text-theme-250 font-semibold text-darkblue">{{ title }}</span>
          <div v-if="tabs && tabs.length > 0">
            <span
              v-for="(tab, index) in tabs"
              :key="index"
              @click="selectTab(index)"
              class="text-theme-250 font-semibold hover:text-darkblue cursor-pointer"
              :class="{
                'ml-6': index > 0,
                'text-lightblue-hover': selectedTabIndex !== index,
                'text-darkblue': selectedTabIndex === index
              }"
            >
              {{ tab }}
            </span>
          </div>
          <button class="transform -translate-x-1 -translate-y-12 focus:outline-none" @click="hide">
            <div class="bg-red-500 rounded-full absolute">
              <svg class="w-3 h-3 m-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
                <defs />
                <g fill="none" fill-rule="evenodd" transform="translate(-963 -224)">
                  <path
                    fill="#FFF"
                    d="M969.69 229l2.96-2.96c.47-.47.47-1.21 0-1.69a1.19 1.19 0 00-1.7 0l-2.95 2.96-2.96-2.96a1.19 1.19 0 00-1.69 0 1.19 1.19 0 000 1.7l2.96 2.95-2.96 2.96a1.19 1.19 0 000 1.69c.48.47 1.22.47 1.7 0l2.95-2.96 2.96 2.96c.47.47 1.21.47 1.69 0 .47-.48.47-1.22 0-1.7l-2.96-2.95z"
                  />
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div class="w-full p-6 pt-0" :class="{ 'pb-0': withBottomDivider }">
          <slot></slot>
        </div>
        <div class="w-full flex items-center border-t border-t-gray-200 justify-start" v-if="withBottomDivider">
          <slot name="bottom"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    title: {
      type: String,
      default: '',
      required: false
    },
    tabs: {
      type: Array,
      default() {
        return [];
      },
      required: false
    },
    selectedTab: {
      type: Number,
      default: 0,
      required: false
    },
    withTitleDivider: {
      type: Boolean,
      default: false,
      required: false
    },
    withBottomDivider: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  watch: {
    selectedTab: {
      immediate: true,
      handler(newValue) {
        this.selectedTabIndex = Number(newValue);
      }
    }
  },
  data() {
    return {
      visible: false,
      displayHideAnimation: false,
      selectedTabIndex: 0
    };
  },
  methods: {
    show() {
      this.visible = true;
      this.$children.forEach(child => {
        child.visible = true;
      });

      this.$emit('didshow');
    },

    hide() {
      this.displayHideAnimation = true;
      this.$children.forEach(child => {
        child.displayHideAnimation = true;
      });

      setTimeout(() => {
        this.visible = false;
        this.displayHideAnimation = false;
        this.$children.forEach(child => {
          child.visible = false;
          child.displayHideAnimation = false;
        });

        this.$emit('didhide');
      }, 100);
    },

    backdropClicked() {
      this.$emit('backdropClicked');
    },

    selectTab(index) {
      this.selectedTabIndex = index;
      this.$emit('tabselected', index);
    }
  }
};
</script>
