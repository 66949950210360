<template>
  <BaseModal v-bind="$props" @backdropClicked="hide">
    <span class="text-theme-100 block w-full sm:w-modal whitespace-pre-wrap">{{ error }}</span>

    <div class="flex justify-center mt-6">
      <ButtonElement @click.native="hide" variant="primary-small">{{ $t('Modal.Generic.Close') }}</ButtonElement>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal';
import ButtonElement from '@/components/ButtonElement';

export default {
  name: 'ErrorModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal,
    ButtonElement
  },
  props: {
    error: {
      type: String,
      required: true
    }
  }
};
</script>
