<template>
  <div class="bg-white w-full p-6 lg:p-10 mb-6 border border-b-4 border-gray-200 rounded-md text-black">
    <header
      class="flex items-center gap-4 text-300 font-semibold text-black"
      :class="{
        'mb-8': !paragraph.description,
        'mb-4': paragraph.description
      }"
    >
      <div class="rounded bg-orange w-8 text-200 h-8 flex items-center justify-center text-white">
        <span>{{ index + 1 }}</span>
      </div>
      <div>{{ paragraph.name }}</div>
    </header>

    <div v-if="paragraph.description" class="text-100 text-gray-500 mb-4">{{ paragraph.description }}</div>

    <section
      class="grid gap-6"
      :class="{
        'grid-cols-2': paragraph.type === 'two-column'
      }"
    >
      <div v-for="(field, i) of paragraph.fields" :key="i" class="w-full">
        <label
          v-if="field.type !== 'children'"
          class="font-medium block text-100 mb-1"
          :class="{
            'text-red-700': errorField && /*errorField.name === field.name*/ !values[field.name]?.length
          }"
          v-html="field.name.replace(/\n/gim, '<br />')"
        ></label>

        <InputVerify v-if="field.type === 'text'" v-model="values[field.name]" class="w-full" />
        <textarea
          v-if="field.type === 'textarea'"
          v-model="values[field.name]"
          class="appearance-none resize-none block bg-transparent h-36 w-full p-6 leading-tight rounded placeholder-lightblue-text text-100 font-normal text-darkblue focus:outline-none focus:z-10 border"
        ></textarea>
        <div v-if="field.type === 'date'" class="w-full">
          <InputVerify v-model="values[field.name]" class="w-full" @click.native="showDatePicker(field)" />

          <DateSelector :visible="Boolean(datePickerField)" v-model="pickedDate" @change="pickedDateChanged" />
        </div>
        <ProgressReportSectionFormChildrenSelector
          v-if="field.type === 'children'"
          ref="childrenElement"
          :value="values[field.name]"
          @update="updateValueForChildren(field, $event)"
        />
      </div>
    </section>
  </div>
</template>

<script>
import InputVerify from '@/components/InputVerify';
import ProgressReportSectionFormChildrenSelector from '@/views/ProgressReport/Sections/ProgressReportSectionFormChildrenSelector';

export default {
  name: 'ProgressReportSectionForm',
  components: {
    InputVerify,
    ProgressReportSectionFormChildrenSelector
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    paragraph: {
      type: Object,
      required: true
    }
  },
  watch: {
    values: {
      handler() {
        this.checkComplete();
      }
    }
  },
  data() {
    return {
      isInitial: true,
      values: {},
      errorField: null,
      pickedDate: new Date(),
      datePickerField: null,
      checkCompleteTimeoutRef: null
    };
  },
  methods: {
    setValues(newValues) {
      if (!newValues) {
        return;
      }
      this.isInitial = true;
      if (Number(newValues.id) === this.paragraph?.id) {
        this.values = newValues.answers || {};
      }
    },

    showDatePicker(field) {
      this.datePickerField = field;
    },

    updateValueForChildren(field, children) {
      this.values[field.name] = children;
      this.$forceUpdate();
      this.checkComplete();
    },

    pickedDateChanged(newDate) {
      if (!newDate) {
        this.datePickerField = null;
        return;
      }

      this.pickedDate = newDate;
      this.values[this.datePickerField.name] = this.pickedDate.toLocaleDateString();
      this.$emit('date-picked', { field: this.datePickerField, value: this.pickedDate });
      this.datePickerField = null;
      this.checkComplete();
    },

    checkComplete() {
      const isInitial = this.isInitial;
      if (this.checkCompleteTimeoutRef) {
        clearTimeout(this.checkCompleteTimeoutRef);
      }

      this.checkCompleteTimeoutRef = setTimeout(() => {
        const isComplete = this.validate();
        this.$emit('completion-changed', { isComplete, isInitial });
        this.isInitial = false;
      }, 700);
    },

    validate(showError = false) {
      this.errorField = null;
      let hasError = false;

      for (let i = 0; i < this.paragraph.fields.length; i += 1) {
        const field = this.paragraph.fields[i];

        let isValid = this.values[field.name]?.length;
        if (field.type === 'children') {
          isValid = this.$refs.childrenElement?.[0].validate(showError);
        }

        if (!isValid) {
          if (showError) {
            this.errorField = field;
          }
          hasError = true;
        }
      }

      return !hasError;
    },

    answers() {
      if (!Object.keys(this.values).length) {
        return null;
      }

      return this.values;
    }
  }
};
</script>
