<template>
  <div class="relative w-full text-theme-150">
    <div
      v-if="calloutVisible"
      class="absolute -mt-11 -ml-6 top-0 px-3 py-2 rounded-lg bg-darkblue text-white text-50 font-medium animation-general-scale-in animation-once animation-fill-both animation-300 animation-delay-18"
      :style="{ left: `${currentPercentage}%` }"
    >
      <span class="">{{ Math.floor(currentPercentage) }}%</span>
      <div class="absolute bottom-0 left-1/2 w-2 h-2 transform rotate-45 bg-darkblue -ml-1 -mb-1"></div>
    </div>
    <div class="w-full bg-gray-200 rounded-full overflow-hidden">
      <div
        class="bg-progress-fill text-xs leading-none h-1 transition-width ease-in-out duration-500"
        :style="{ width: `${currentPercentage}%` }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    percentage: {
      type: Number,
      required: true
    },
    calloutVisible: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  watch: {
    percentage: {
      immediate: false,
      handler(newVal) {
        this.currentPercentage = newVal;
      }
    }
  },
  data() {
    return {
      currentPercentage: 0
    };
  },
  mounted() {
    setTimeout(() => {
      this.currentPercentage = this.percentage;
    }, 300);
  }
};
</script>
