<template>
  <DashboardTile>
    <template v-slot:header>
      <div class="w-full group flex justify-between items-center mb-4">
        <div class="text-theme-300 flex-grow flex-shrink-0">
          <span class="font-semibold text-100 sm:text-200">{{ $t('Dashboard.Widget.KrachtInHuis.Title') }}</span>
        </div>
      </div>
    </template>
    <div v-if="token" class="mt-6 text-theme-100">
      <DashboardTileLink
        :title="$t('Dashboard.Widget.KrachtInHuis.Login')"
        :useClickCallback="true"
        @click="openLink"
      />
    </div>
    <div v-else class="flex flex-col items-center justify-center w-full h-full">
      <img class="w-8 h-8 mb-4" src="@/assets/loading-dark.gif" alt="Loading" />
      <span class="text-150 font-semibold text-darkblue">
        {{ $t('General.Loading') }}
      </span>
    </div>
  </DashboardTile>
</template>

<script>
import get from 'lodash/get';
import DashboardTile from '@/components/dashboard-tiles/DashboardTile';
import DashboardTileLink from '@/components/DashboardTileLink';
import { GraphQLMutation } from '@/graphql';

export default {
  name: 'DashboardTileKrachtInHuis',
  extends: DashboardTile,
  inheritAttrs: false,
  components: {
    DashboardTile,
    DashboardTileLink
  },
  computed: {
    currentHome() {
      if (this.$permissions.hasAdminRole() && this.$store.state.currentManagedHome) {
        return this.$store.state.currentManagedHome;
      }

      if (
        !this.$store.state.currentUser ||
        !this.$store.state.currentUser.homes ||
        this.$store.state.currentUser.homes.length === 0
      ) {
        return null;
      }

      if (!this.homeID) {
        return this.$store.state.currentUser.homes[0];
      }

      return this.$store.state.currentUser.homes.find(home => {
        return Number(home.id) === this.homeID;
      });
    }
  },
  data() {
    return {
      token: ''
    };
  },
  mounted() {
    this.loadToken();
  },
  methods: {
    async loadToken() {
      if (!this.$store.state.currentUser || !this.currentHome || !this.$permissions.has('user-krachtinhuistoken')) {
        return;
      }

      const tokenInfo = await this.$apollo.mutate({
        mutation: GraphQLMutation.KrachtInHuisToken
      });

      this.token = get(tokenInfo, 'data.token.token', '') || '';
    },

    openLink() {
      if (!this.token || this.token.length === 0) {
        return;
      }

      const url = `https://krachtinhuis.nl/gezinshuis-com/?jwt=${this.token}`;
      this.$modal.confirm(() => {
        window.open(url);
      }, 'ExternalData');
    }
  }
};
</script>
