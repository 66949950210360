<template>
  <BaseModal v-bind="$props" :title="$t('Modal.PlanConfirmed.Title')" @backdropClicked="hide">
    <span class="text-theme-100 block w-full sm:w-modal whitespace-pre-wrap">{{ $t('Modal.PlanConfirmed.Text') }}</span>
    <a :href="url" target="_blank" class="text-lightblue-link text-100 mt-4 block">{{ filename }}</a>

    <div class="flex justify-center mt-6">
      <ButtonElement @click.native="navigate" variant="primary-small">{{
        $t('Modal.PlanConfirmed.Action.Back')
      }}</ButtonElement>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal';
import ButtonElement from '@/components/ButtonElement';

export default {
  name: 'PlanCreatedModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal,
    ButtonElement
  },
  props: {
    filename: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  methods: {
    navigate() {
      this.hide();

      this.$router.push({
        name: 'QualityAssessment'
      });
    }
  }
};
</script>
