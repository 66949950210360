<template>
  <DashboardCard :title="$t('Dashboard.Library.Title')" icon="IconLibrary" url="/bibliotheek">
    <div class="grid gap-2">
      <router-link
        v-if="
          (!$permissions.has('home-find') || $store.state.currentManagedHome) &&
            $permissions.has('document-viewprivatedocuments')
        "
        :to="{
          name: 'Library'
        }"
      >
        <DashboardButton>
          {{ $t('Dashboard.Library.PersonalDocuments') }}
        </DashboardButton>
      </router-link>
      <router-link
        v-if="$permissions.has('document-viewhealthinsurerdocuments')"
        :to="{
          name: 'Library',
          params: {
            type: 'zorgaanbieder'
          }
        }"
      >
        <DashboardButton>
          {{ $t('Dashboard.Library.HealthinsurerDocuments') }}
        </DashboardButton>
      </router-link>
      <router-link
        v-if="$permissions.has('document-viewpublicdocuments')"
        :to="{
          name: 'Library',
          params: {
            type: 'publiek'
          }
        }"
      >
        <DashboardButton>
          {{ $t('Dashboard.Library.PublicDocuments') }}
        </DashboardButton>
      </router-link>
    </div>
  </DashboardCard>
</template>

<script>
import DashboardButton from '@/components/dashboard/DashboardButton.vue';
import DashboardCard from '@/components/dashboard/DashboardCard.vue';

export default {
  components: {
    DashboardButton,
    DashboardCard
  }
};
</script>
