<template>
  <BaseModal v-bind="$props" :title="$filters.empty(title, $t('Modal.ComingSoon.Title'))" @backdropClicked="hide">
    <span class="text-theme-100 block w-full sm:w-modal">{{ body | empty($t('Modal.ComingSoon.Text')) }}</span>
    <div class="flex justify-center mt-6">
      <ButtonElement @click.native="hide" variant="primary-small">{{ $t('Modal.Generic.Close') }}</ButtonElement>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal';
import ButtonElement from '@/components/ButtonElement';

export default {
  name: 'ComingSoonModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal,
    ButtonElement
  },
  props: {
    title: {
      type: String,
      default: '',
      required: false
    },
    body: {
      type: String,
      default: '',
      required: false
    }
  }
};
</script>
