<template>
  <div class="text-theme-150 text-darkblue font-semibold" :class="{ 'bg-lightblue-background': !minimal }">
    <div
      class="flex flex-col sm:flex-row justify-between items-center sm:items-start max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8 text-50"
    >
      <div class="ml-2 flex flex-col items-center sm:items-start" @click="showBuildDate()">
        <span>&copy; {{ currentYear }} Gezinshuis.com</span>
        <span class="text-gray-400 text-25" :title="$store.getters.application.date"
          >Version {{ $store.getters.application.commit }}</span
        >
        <span
          v-if="buildDateShown"
          class="text-gray-400 text-25 animation-general-slide-in animation-once animation-fill-both animation-300"
          >{{ $store.getters.application.date }}</span
        >
      </div>
      <div class="flex flex-col mt-2 sm:mt-0 sm:block text-center sm:text-left">
        <a
          href="https://www.gezinshuis.com/de-formule-samen-sterk-in-de-franchise/kwaliteitskader/"
          target="_blank"
          class="mt-2 sm:mt-0 sm:ml-4"
          >{{ $t('Footer.TermsOfService') }}</a
        >
        <span class="sm:ml-4 text-gray-400 hidden sm:inline">&bullet;</span>
        <a href="https://www.gezinshuis.com/privacy-beleid/" target="_blank" class="mt-2 sm:mt-0 sm:ml-4">{{
          $t('Footer.PrivacyPolicy')
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import 'dayjs/locale/nl';

export default {
  name: 'Footer',
  props: {
    minimal: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      buildDateShown: false
    };
  },
  computed: {
    currentYear() {
      return dayjs()
        .locale('nl')
        .format('YYYY');
    }
  },
  methods: {
    showBuildDate() {
      this.buildDateShown = !this.buildDateShown;
    }
  }
};
</script>
