<template>
  <svg :class="fileClass" width="16" height="20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M7.354 3.612V0H3.34C1.512 0 0 1.512 0 3.34v13.012c0 1.83 1.512 3.342 3.34 3.342h8.44c1.83 0 3.341-1.513 3.341-3.342V6.225c0-.033-.003-.066-.004-.099H9.868a2.514 2.514 0 01-2.514-2.514" fill="currentColor"/><path d="M9.953.528C9.6.176 9.109 0 8.616 0H7.354v3.612a2.514 2.514 0 002.514 2.514h5.249c-.025-.494-.23-.979-.593-1.308L9.953.528z" opacity="0.2" fill="currentColor"/></g></svg>
</template>

<script>
export default {
  props: {
    document: {
      type: Object,
      required: false,
      default: null
    },
    file: {
      type: File,
      required: false,
      default: null
    }
  },
  data() {
    return {
      fileClass: {}
    };
  },
  watch: {
    document: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.file.length > 0)
        {
          const className = this.classForExt(newVal.file[0].ext);
          this.fileClass = {
            [className]: true
          };
        }
      }
    },
    file: {
      immediate: true,
      handler(newVal) {
        if (newVal)
        {
          const fileExtension = `.${ newVal.name.split('.').pop() }`;
          const className = this.classForExt(fileExtension);
          this.fileClass = {
            [className]: true
          };
        }
      }
    }
  },
  methods: {
    classForExt(ext) {
      const type = this.$helper.typeForFileExtension(ext);
      switch (type) {
        case 'presentation':
          return 'text-red-500';

        case 'image':
        case 'movie':
        case 'audio':
          return 'text-yellow-300';

        case 'document':
          return 'text-file-blue';

        case 'spreadsheet':
          return 'text-green-500';

        case 'archive':
          return 'text-gray-300';

        default:
      }

      return 'text-gray-300';
    }
  }
}
</script>