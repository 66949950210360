<template>
  <main class="bg-gray-100 py-16">
    <div class="max-w-5xl mx-auto pb-12 px-4 sm:px-6 lg:px-8 flex flex-col gap-y-8 sm:gap-y-16">
      <div class="flex flex-col sm:flex-row justify-between items-center w-full">
        <h1 class="text-theme-500 flex-grow">{{ $t('ActivityList.Title') }}</h1>
      </div>
      <ActivityList />

      <div
        class="flex flex-col text-100 w-full p-8 bg-white rounded-md border border-lightblue text-lightblue-text focus:outline-none"
      >
        <h3 class="text-300 font-semibold text-black mb-4">{{ $t('ActivityList.Explanation.Title') }}</h3>
        <span class="text-100 whitespace-pre-line">{{ $t('ActivityList.Explanation.Description') }}</span>
      </div>
    </div>
  </main>
</template>

<script>
import ActivityList from '@/components/ActivityList';

export default {
  name: 'ActivityListPage',
  components: {
    ActivityList
  }
};
</script>
