export default function(value) {
  if (value === null || value === undefined) {
    return '0 Bytes';
  }
  /* eslint-disable */
  const sizes = ['KB', 'MB', 'GB', 'TB'];
  const i = Math.max(parseInt(Math.floor(Math.log(value) / Math.log(1024))), 0);
  return Math.round(value / Math.pow(1024, i), 2) + ' ' + sizes[i];
  /* eslint-enable */
}
