<template>
  <div class="flex flex-col mb-1">
    <div
      class="rounded-md shadow-sm flex items-center relative mb-1 border transition ease-in-out duration-300"
      :class="{
        'border-lightblue hover:border-lightblue-hover focus-within:border-lightblue-hover': !disabled,
        'border-lightblue bg-textfield-disabled': disabled,
        'bg-error-balloon-background border-error-balloon-text text-error-balloon-text': hasError,
        'bg-white': !hasError
      }"
    >
      <div
        class="flex-shrink w-full"
        :style="{
          height: `${boxHeight()}px`
        }"
      >
        <textarea
          v-if="multiline"
          ref="box"
          class="appearance-none resize-none block bg-transparent h-full w-full px-4 py-4 leading-tight placeholder-lightblue-text text-100 font-normal text-darkblue focus:outline-none focus:z-10"
          maxlength="1000"
          :required="required"
          :disabled="disabled"
          :placeholder="placeholder"
          :value="value"
          @input="updateValue($event.target.value)"
        ></textarea>
        <form v-if="!multiline" @submit.prevent="sendMessage">
          <input
            class="appearance-none block bg-transparent h-12 w-full px-4 py-3 placeholder-lightblue-text text-100 font-normal text-darkblue focus:outline-none focus:z-10"
            type="text"
            :required="required"
            :disabled="disabled"
            :placeholder="placeholder"
            :value="value"
            @input="updateValue($event.target.value)"
          />
          <input type="submit" class="invisible" />
        </form>
      </div>
      <button
        v-if="!hideSendButton"
        class="flex-shrink-0 w-10 h-9 mr-2 flex justify-center items-center focus:outline-none"
        @click="sendMessage"
      >
        <IconAdd
          v-if="iconVariant === 'add'"
          class="transition ease-in-out duration-300"
          :class="{
            'text-lightblue-hover': !hasText && !allowEmptySend,
            'text-orange': hasText || allowEmptySend
          }"
        />
        <IconSend
          v-else
          class="transition ease-in-out duration-300"
          :class="{
            'text-lightblue-hover': !hasText && !allowEmptySend,
            'text-orange': hasText || allowEmptySend
          }"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputMessage',
  props: {
    value: {
      type: String
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    multiline: {
      type: Boolean,
      default: true,
      required: false
    },
    allowEmptySend: {
      type: Boolean,
      default: false,
      required: false
    },
    hideSendButton: {
      type: Boolean,
      default: false,
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    iconVariant: {
      type: String,
      default: 'send',
      required: false
    },
    hasError: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.hasText = newValue && newValue.length > 0;
      }
    }
  },
  data() {
    return {
      hasText: false
    };
  },
  mounted() {
    this.$forceUpdate();
  },
  methods: {
    boxHeight() {
      if (!this.multiline || !this.$refs.box || !this.value || this.value.length < 10) {
        return 4 * 12;
      }

      return 4 * Math.round(Math.min(4 * 24, this.$refs.box.scrollHeight) / 4);
    },

    sendMessage() {
      if (!this.allowEmptySend && (!this.value || !this.value || this.value.length === 0)) {
        return;
      }

      this.$emit('send');
    },
    updateValue(value) {
      this.$emit('input', value);
    }
  }
};
</script>
